import { useEffect, useRef, memo  } from 'react';
import { Network, DataSet } from 'vis-network/standalone/umd/vis-network';

const getNodeColor = (nodeType) => {
  if (!nodeType) return "gray";
  const colors = {
    skills: "crimson",
    applied_skills: "darkorange",
    appliedskills: "darkorange",
    certification: "indigo",
    extracted_task: "cornflowerblue",
    module: "cyan",
    learningpath: "teal",
    examination: "green",
  };
  return colors[nodeType.toLowerCase()] || "gray";
}
const getNodeTitle = (node) => {
  let title = [];
  Object.keys(node).forEach(key => {
    title.push(`${key}: ${node[key]}`);
  });
  return title.join('\n');
}

const Graph = ({ graphItems }) => {
  const networkRef = useRef(null);
  const nodes = graphItems.nodes.map((node) => ({
    id: node.id,
    label: node.label,
    color: getNodeColor(node.type),
    shape: 'dot',
    title: getNodeTitle(node.raw),
    size: 20
  }));
  const edges = graphItems.relationships.map((relationship) => ({
    from: relationship.source,
    to: relationship.target,
  }));

  useEffect(() => {
    const container = networkRef.current;
    const data = {
      nodes: new DataSet(nodes),
      edges: new DataSet(edges),
    };
    const options = {};
    const network = new Network(container, data, options);
    const style = document.createElement('style');
    style.innerHTML = `
      .vis-tooltip {
        white-space: pre-line;
      }
    `;
    document.head.appendChild(style);

    return () => {
      network.destroy();
      document.head.removeChild(style);
    };
  }, [nodes, edges]);

  return <div ref={networkRef} style={{ height: '70vh', minHeight: '500px' }} />;
};

export default memo(Graph);