import React, { Component } from 'react';
import jwtDecode from 'jwt-decode';
import { msalApp, LOGIN_SCOPES, acquireManagementTokenSilent } from './auth-utils';

// If you support IE, our recommendation is that you sign-in using Redirect APIs
//const useRedirectFlow = isIE();
const useRedirectFlow = true;

const isInIframe = () => window.parent !== window;

export default (C) =>
  class AuthProvider extends Component {
    constructor(props) {
      super(props);

      this.state = {
        account: null,
        error: null,
        emailMessages: null,
        graphProfile: null,
        roles: {},
        userId: null,
      };
    }

    async onSignIn(redirect) {
      if (redirect) {
        return msalApp.loginRedirect({ scopes: LOGIN_SCOPES });
      }

      const loginResponse = await msalApp.loginPopup({ scopes: LOGIN_SCOPES }).catch((error) => {
        this.setState({
          error: error.message,
        });
      });

      if (loginResponse) {
        this.setState({
          account: loginResponse.account,
          error: null,
        });
      }
    }

    onSignOut() {
      msalApp.logout();
    }

    async acquireTokens() {
      const managementToken = await acquireManagementTokenSilent();
      if (!managementToken || !managementToken.accessToken) {
        return;
      }

      const managementTokenObj = jwtDecode(managementToken.accessToken);
      let roles = this.state.roles;
      let userId = managementTokenObj.unique_name;
      
      roles.ManagementRoles = managementTokenObj.roles;
      this.setState({ roles, userId });
    }

    async componentDidMount() {
      if (msalApp.urlContainsHash(window.location.hash) && isInIframe()) {
        return null;
      }
      msalApp.handleRedirectCallback((error) => {
        if (error) {
          const errorMessage = error.errorMessage
            ? error.errorMessage
            : 'Unable to acquire access token.';
          // setState works as long as navigateToLoginRequestUrl: false
          this.setState({
            error: errorMessage,
          });
        }
      });

      const account = msalApp.getAccount();
      this.setState({
        account,
      });

      // avoid loginRedirect to go in loop
      if (
        !account &&
        !msalApp.urlContainsHash(window.location.hash) &&
        !msalApp.getLoginInProgress()
      ) {
        return this.onSignIn(true);
      }

      if (account) {
        this.acquireTokens();
      }
    }

    render() {
      if (msalApp.urlContainsHash(window.location.hash) && isInIframe()) {
        return null;
      }

      return (
        <C
          {...this.props}
          account={this.state.account}
          error={this.state.error}
          roles={this.state.roles}
          userId={this.state.userId}
          onSignIn={() => this.onSignIn(useRedirectFlow)}
          onSignOut={() => this.onSignOut()}
        />
      );
    }
  };
