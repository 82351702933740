import {
  Stack,
  ActionButton,
  PrimaryButton,
  ComboBox,
  TextField,
  SelectionMode,
  Text,
  DetailsList,
  DialogType,
  Dialog,
  DialogFooter,
  DefaultButton,
  IconButton,
  mergeStyleSets,
  mergeStyles,
  Pivot,
  PivotItem,
  StackItem,
} from "@fluentui/react";

import { useState, memo, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BackHomeButton from "../../Components/BackHomeButton";
import TaskAPI from "../../Common/API/taskApi";
import _ from "lodash";
import { MessageActions } from "../../Common/Utils/Message";
import { useHistory } from "react-router-dom";
import { useBoolean } from "@uifabric/react-hooks";
import Resources from "../../Common/resources";
import { isValidAdoLink, isAdoLinkEndWithNumber }  from "../../Common/Utils/adoLinkValidator";
import cloneDeep from "lodash/cloneDeep";
import { CSVToArray } from "../../Common/csvHelper";
import ReactLoading from 'react-loading';

const TaskSubmitStyles = {
  Title: { root: { textAlign: "center", display: "block" } },
  InputRootWidth: { root: { width: 300 } },
  TeamListWidth: { root: { width: 200 } },
  TemplateListWidth: { root: { width: 250 } },
  InputTitleRootWidth: { root: { width: "60%" } },
  InputTitleRootWidthAndMargin: { root: { width: "60%", marginTop: "16px" } },
  DefaultOpContainerTokens: { childrenGap: "m", padding: "m" },
  InnerOpTokens: { childrenGap: "m" },
  InputRepoName: {
    root: { width: 500, display: "inline-block", "margin-right": "30px" },
  },
  InputMetadataWidth: { root: { width: "80%" } },
  metadataTitle: {
    root: { display: "block", textAlign: "center", "font-weight": "bold" },
  },
  metadataSubTitle: {
    root: {
      display: "block",
      "margin-bottom": "3px",
      "font-size": "16px",
      "font-weight": "bold",
    },
  },
  RepoFolderSubmit: { root: { "margin-top": "20px" } },
  PivotBlock: {
    root: {
      "border-top": "3px double black",
      "padding-top": "30px",
      "padding-bottom": "30px",
    },
  },
};

const globalMetadataPrefix = "globalMetadata-";
const fileMetadataPrefix = "fileMetadata-";
const fileMetadataKeySubkeySeparator = "--";
const requestIndexPrefix = "requestIndex-";
const tabUsage = "Metadata Usage";
const tabFilelist = "File List";
const taxonomySDP = "Yaml Porperty Usage";
const tabRepoFolder = "Repo/Folder";
const fileListContentUrl = "Content Url/Docfx Url";

const DefaultTaskState = {
  title: "",
  description: "",
  recipient: "",
  recipients: [],
  adoLink: "",
  autoMergePR: "AUTO_MERGE_AFTER_FEEDBACK_CYCLE",
  op: "Delete",
  metaKey: "",
  metaValue: "",
  con: "==",
  conditionKey: "",
  conditionValue: "",
  items: [],
  repoNameValue: "",
  conditions: [],
  filepaths: [],
  fileMetadataMap: {},
  globalMetadata: {},
  fileMetadata: {},
  selectFilepath: "",
  requests: "",
  tabValue: tabUsage,
  //tabValue: tabFilelist,
  fileListMetadatas: [],
  fileListMetadataKeys: [],
  fileListContentUrlInput: "",
  fileListErrorMessage:"",
  fileListOprationNums: "",
  fileListOprations: [],
  fileListConditions: [],
  fileListOp: "Delete",
  fileListMetaKey: "",
  fileListMetaValue: "",
  fileListCon: "==",
  fileListConditionKey: "",
  fileListConditionValue: "",
  sourceTeam:"",
  templateConfigId:"",
  templateListFiltered:[],
};
const DefaultUIState = {
  showEditor: false,
  showCCEditor: false,
  showConditionEditor: false,
  showFileListConditionEditor: false,
  showTeamSelector: false,
};

const dialogContentProps = {
  type: DialogType.normal,
  title: Resources.Tasks.UnsavedDialogTitle,
  subText: Resources.Tasks.UnsavedDialogMsg,
};

const dialogFieldContentProps = {
  type: DialogType.normal,
  title: Resources.Tasks.DialogFieldsTitle,
  subText: Resources.Tasks.DialogFieldsText,
};

const dialogSubmitContentProps = {
  type: DialogType.normal,
  title: Resources.Tasks.DialogSubmitTitle,
  subText: Resources.Tasks.DialogSubmitText,
};

const dialogSubmitSuccessContentProps = {
  type: DialogType.normal,
  title: Resources.Tasks.DialogSubmitTitle,
  subText: Resources.Tasks.DialogSubmitSuccessText,
};

const modalProps = {
  isBlocking: true,
  styles: { main: { maxWidth: 450 } },
};

const ActionColumnClassNames = mergeStyleSets({
  ActionIconHeaderIcon: {
    padding: 0,
    fontSize: "12px",
  },
  ActionIconCell: {
    textAlign: "center",
    selectors: {
      "&:before": {
        content: ".",
        display: "inline-block",
        verticalAlign: "middle",
        height: "100%",
        width: "0px",
        visibility: "hidden",
      },
    },
  },
  ActionIcon: {
    verticalAlign: "middle",
    maxHeight: "12px",
    maxWidth: "12px",
  },
});

const AddOperation = ({ userId }) => {
  document.title = "Metadata Management";
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const queryConditions = query.get('queryConditions');

  const history = useHistory();

  // general editor state
  const [title, setTitle] = useState([DefaultTaskState.title]);
  const [adoLink, setAdoLink] = useState([DefaultTaskState.adoLink]);
  const [description, setDescription] = useState([
    DefaultTaskState.description,
  ]);
  const [autoMergePR, setautoMergePR] = useState([
    DefaultTaskState.autoMergePR,
  ]);
  const [sourceTeam, setSourceTeam] = useState([
    DefaultTaskState.sourceTeam,
  ]);
  const [templateConfigId, setTemplateConfigId] = useState([
    DefaultTaskState.templateConfigId,
  ]);
  const [templateListFiltered,setTemplateListFiltered] = useState([DefaultTaskState.templateListFiltered])
  const [isTaskCloseLoading, setIsTaskCloseLoading] = useState(true);

  // operation editor state
  const [showEditor, setShowEditor] = useState([DefaultUIState.showEditor]);
  const [op, setOp] = useState([DefaultTaskState.op]);
  const [metaKey, setMetaKey] = useState([DefaultTaskState.metaKey]);
  const [metaValue, setMetaValue] = useState([DefaultTaskState.metaValue]);

  // CC editor state
  const [showCCEditor, setShowCCEditor] = useState([
    DefaultUIState.showCCEditor,
  ]);
  const [recipient, setRecipient] = useState([DefaultTaskState.recipient]);

  // team state
  const [showTeamSelector, setShowTeamSelector] = useState(DefaultUIState.showTeamSelector);
  const [teamList, setTeamList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [templateFieldsList,setTemplateFieldsList] = useState([]);
  const [taxonomyList, setTaxonomyList] = useState([]);

  // condition editor state
  const [showConditionEditor, setShowConditionEditor] = useState([
    DefaultUIState.showConditionEditor,
  ]);
  const [con, setCon] = useState([DefaultTaskState.con]);
  const [conditionKey, setConditionKey] = useState([
    DefaultTaskState.conditionKey,
  ]);
  const [conditionValue, setConditionValue] = useState([
    DefaultTaskState.conditionValue,
  ]);

  // table states
  const [items, setItems] = useState([DefaultTaskState.items]);
  const [conditions, setConditions] = useState([DefaultTaskState.conditions]);
  const [recipients, setRecipients] = useState([DefaultTaskState.recipients]);

  // modal state
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [hideFieldCheckDialog, { toggle: toggleHideFieldCheckDialog }] = useBoolean(true);
  const [hideSubmitDialog, { toggle: toggleHideSubmitDialog }] = useBoolean(true);

  const [repoNameValue, setRepoNameValue] = useState([
    DefaultTaskState.repoNameValue,
  ]);
  const [filepaths, setFilepaths] = useState([
    cloneDeep(DefaultTaskState.filepaths),
  ]);
  const [fileMetadataMap, setFileMetadataMap] = useState([
    cloneDeep(DefaultTaskState.fileMetadataMap),
  ]);
  // input globalMetadata
  const [globalMetadata, setGlobalMetadata] = useState([
    cloneDeep(DefaultTaskState.globalMetadata),
  ]);
  // default fileMetadata from api
  const [defaultGlobalMetadata, setDefaultGlobalMetadata] = useState([
    cloneDeep(DefaultTaskState.globalMetadata),
  ]);
  // input fileMetadata
  const [fileMetadata, setFileMetadata] = useState([
    cloneDeep(DefaultTaskState.fileMetadata),
  ]);
  // default fileMetadata from api
  const [defaultFileMetadata, setDefaultFileMetadata] = useState([
    cloneDeep(DefaultTaskState.fileMetadata),
  ]);
  const [selectFilepath, setSelectFilepath] = useState([
    DefaultTaskState.selectFilepath,
  ]);
  const [requests, setRequests] = useState([DefaultTaskState.requests]);
  const [tabValue, setTabValue] = useState([DefaultTaskState.tabValue]);

  // fileList Metadata list
  const [fileListMetadatas, setFileListMetadatas] = useState([
    cloneDeep(DefaultTaskState.fileListMetadatas),
  ]);

  const [fileListMetadataKeys, setFileListMetadataKeys] = useState([
    cloneDeep(DefaultTaskState.fileListMetadataKeys),
  ]);
  
  const [fileListContentUrlInput, setFileListContentUrlInput] = useState([
    cloneDeep(DefaultTaskState.fileListContentUrlInput),
  ]);

  const [fileListErrorMessage, setFileListErrorMessage] = useState([
    cloneDeep(DefaultTaskState.fileListErrorMessage),
  ]);

  const [fileListOprationNums, setFileListOprationNums] = useState([
    [DefaultTaskState.fileListOprationNums],
  ]);

  const [opFileList, setOpFileList] = useState([
    cloneDeep([DefaultTaskState.fileListOp]),
  ]);
  const [metaKeyFileList, setMetaKeyFileList] = useState([
    cloneDeep([DefaultTaskState.fileListMetaKey]),
  ]);
  const [metaValueFileList, setMetaValueFileList] = useState([
    cloneDeep([DefaultTaskState.fileListMetaValue]),
  ]);

  const [showFileListConditionEditor, setShowFileListConditionEditor] =
    useState([cloneDeep([DefaultUIState.showFileListConditionEditor])]);

  const [conFileList, setConFileList] = useState([
    cloneDeep([DefaultTaskState.fileListCon]),
  ]);
  const [conditionKeyFileList, setConditionKeyFileList] = useState([
    cloneDeep([DefaultTaskState.fileListConditionKey]),
  ]);
  const [conditionValueFileList, setConditionValueFileList] = useState([
    cloneDeep([DefaultTaskState.fileListConditionValue]),
  ]);
  const [operationsFileList, setOperationsFileList] = useState([
    cloneDeep([DefaultTaskState.fileListOprations]),
  ]);
  const [conditionsFileList, setConditionsFileList] = useState([
    cloneDeep([DefaultTaskState.fileListConditions]),
  ]);

  // metadata's value is array
  const [arrayMetadataKey, setArrayMetadataKey] = useState(new Set());
  //const arrayMetadataKey = new Set();

  const allFileListOperationEditList = [
    {
      key: opFileList,
      setfunc: setOpFileList,
      defaultValue: DefaultTaskState.fileListOp,
    },
    {
      key: metaKeyFileList,
      setfunc: setMetaKeyFileList,
      defaultValue: DefaultTaskState.fileListMetaKey,
    },
    {
      key: metaValueFileList,
      setfunc: setMetaValueFileList,
      defaultValue: DefaultTaskState.fileListMetaValue,
    },
    {
      key: showFileListConditionEditor,
      setfunc: setShowFileListConditionEditor,
      defaultValue: DefaultUIState.showFileListConditionEditor,
    },
    {
      key: conFileList,
      setfunc: setConFileList,
      defaultValue: [DefaultTaskState.fileListCon],
    },
    {
      key: conditionKeyFileList,
      setfunc: setConditionKeyFileList,
      defaultValue: DefaultTaskState.fileListConditionKey,
    },
    {
      key: conditionValueFileList,
      setfunc: setConditionValueFileList,
      defaultValue: DefaultTaskState.fileListConditionValue,
    },
    {
      key: operationsFileList,
      setfunc: setOperationsFileList,
      defaultValue: DefaultTaskState.fileListOprations,
    },
    {
      key: conditionsFileList,
      setfunc: setConditionsFileList,
      defaultValue: DefaultTaskState.fileListConditions,
    },
    {
      key: fileListOprationNums,
      setfunc: setFileListOprationNums,
      defaultValue: DefaultTaskState.fileListOprationNums,
    },
  ];

  const AddFileListOperations = (index) => {
    allFileListOperationEditList.forEach((item) => {
      const tmp = cloneDeep(item.key);
      tmp[index].push(cloneDeep(item.defaultValue));
      item.setfunc(tmp);
    });
  };

  const DeleteFileListOperations = (index, oprationsIndex) => {
    allFileListOperationEditList.forEach((item) => {
      const tmp = cloneDeep(item.key);
      tmp[index].splice(oprationsIndex, 1);
      item.setfunc(tmp);
    });
  };

  const ResetFileListOpEditor = (index = -1, operationIndex = -1) => {
    if (index == -1) {
      setOpFileList([cloneDeep([DefaultTaskState.fileListOp])]);
      setMetaKeyFileList([cloneDeep([DefaultTaskState.fileListMetaKey])]);
      setMetaValueFileList([cloneDeep([DefaultTaskState.fileListMetaValue])]);
    } else {
      const tmpOpFileList = cloneDeep(opFileList);
      tmpOpFileList[index][operationIndex] = cloneDeep(
        DefaultTaskState.fileListOp
      );
      setOpFileList(tmpOpFileList);

      const tmpMetaKeyFileList = cloneDeep(metaKeyFileList);
      tmpMetaKeyFileList[index][operationIndex] = cloneDeep(
        DefaultTaskState.fileListMetaKey
      );
      setMetaKeyFileList(tmpMetaKeyFileList);

      const tmpMetaValueFileList = cloneDeep(metaValueFileList);
      tmpMetaValueFileList[index][operationIndex] = cloneDeep(
        DefaultTaskState.fileListMetaValue
      );
      setMetaValueFileList(tmpMetaValueFileList);
    }
  };

  const ResetFileListConditionEditor = (index = -1, operationIndex = -1) => {
    if (index == -1) {
      setShowFileListConditionEditor([
        cloneDeep([DefaultUIState.showFileListConditionEditor]),
      ]);
      setConFileList([cloneDeep([DefaultTaskState.fileListCon])]);
      setConditionKeyFileList([
        cloneDeep([DefaultTaskState.fileListConditionKey]),
      ]);
      setConditionValueFileList([
        cloneDeep([DefaultTaskState.fileListConditionValue]),
      ]);
    } else {
      const tmpShowFileListConditionEditor = cloneDeep(
        showFileListConditionEditor
      );
      tmpShowFileListConditionEditor[index][operationIndex] = cloneDeep(
        DefaultUIState.showFileListConditionEditor
      );
      setShowFileListConditionEditor(tmpShowFileListConditionEditor);

      const tmpConditionKeyFileList = cloneDeep(conditionKeyFileList);
      tmpConditionKeyFileList[index][operationIndex] = cloneDeep(
        DefaultTaskState.fileListConditionKey
      );
      setConditionKeyFileList(tmpConditionKeyFileList);

      const tmpConditionValueFileList = cloneDeep(conditionValueFileList);
      tmpConditionValueFileList[index][operationIndex] = cloneDeep(
        DefaultTaskState.fileListConditionValue
      );
      setConditionValueFileList(tmpConditionValueFileList);
    }
  };

  const RefreshFileListMetadataValues = (index) => {
    const tmpFileListMetadatas = cloneDeep(fileListMetadatas);
    tmpFileListMetadatas[index].map((metadatas, metadataIndex) => {
      // Restore all values first when refresh
      Object.keys(tmpFileListMetadatas[index][metadataIndex]).forEach(function (
        key
      ) {
        if (key != fileListContentUrl) {
          tmpFileListMetadatas[index][metadataIndex][key]["isDelete"] = false;
          delete tmpFileListMetadatas[index][metadataIndex][key].newValue;
        }
      });

      fileListOprationNums[index].map((_, opIndex) => {
        // check condition
        let flag = true;
        let conditionMap = {};
        for (let i = 0; i < conditionsFileList[index][opIndex]?.length; i++) {
          const item = conditionsFileList[index][opIndex][i];
          conditionMap[item.conditionKey] = item.conditionValue;
          // not match
          if (
            !metadatas ||
            !(item.conditionKey in metadatas) ||
            !metadatas[item.conditionKey] ||
            !("oldValue" in metadatas[item.conditionKey])
          ) {
            flag = false;
            break;
          }

          // array
          if (arrayMetadataKey.has(item.conditionKey)) {
            if (
              !metadatas[item.conditionKey]["oldValue"].includes(
                item.conditionValue
              )
            ) {
              flag = false;
              break;
            }
          } else {
            // string
            if (
              metadatas[item.conditionKey]["oldValue"] != item.conditionValue
            ) {
              flag = false;
              break;
            }
          }
        }

        if (flag) {
          // execute operation
          for (let i = 0; i < operationsFileList[index][opIndex]?.length; i++) {
            let item = operationsFileList[index][opIndex][i];
            // array
            if (arrayMetadataKey.has(item.metaKey)) {
              // do not have this Metadata
              if (
                !(item.metaKey in tmpFileListMetadatas[index][metadataIndex])
              ) {
                tmpFileListMetadatas[index][metadataIndex][item.metaKey] = {
                  oldValue: [],
                  isDelete: false,
                  canDelete: true,
                };
              }
              if (
                !(
                  "newValue" in
                  tmpFileListMetadatas[index][metadataIndex][item.metaKey]
                )
              ) {
                tmpFileListMetadatas[index][metadataIndex][item.metaKey][
                  "newValue"
                ] = cloneDeep(
                  tmpFileListMetadatas[index][metadataIndex][item.metaKey][
                    "oldValue"
                  ]
                );
              }
            }

            if (item.op == "Delete") {
              // array
              if (arrayMetadataKey.has(item.metaKey)) {
                let ind = tmpFileListMetadatas[index][metadataIndex][
                  item.metaKey
                ]["oldValue"].indexOf(item.metaValue);
                if (ind > -1) {
                  tmpFileListMetadatas[index][metadataIndex][item.metaKey][
                    "newValue"
                  ][ind] = "";
                }
              } else {
                if (tmpFileListMetadatas[index][metadataIndex][item.metaKey]) {
                  if (
                    tmpFileListMetadatas[index][metadataIndex][item.metaKey][
                      "canDelete"
                    ]
                  ) {
                    tmpFileListMetadatas[index][metadataIndex][item.metaKey][
                      "isDelete"
                    ] = true;
                  }
                }
              }
            } else {
              // array
              if (arrayMetadataKey.has(item.metaKey)) {
                if (item.metaKey in conditionMap) {
                  let ind = tmpFileListMetadatas[index][metadataIndex][
                    item.metaKey
                  ]["oldValue"].indexOf(conditionMap[item.metaKey]);
                  if (ind > -1) {
                    // update
                    tmpFileListMetadatas[index][metadataIndex][item.metaKey][
                      "newValue"
                    ][ind] = item.metaValue;
                  }
                  continue;
                }
                // add
                tmpFileListMetadatas[index][metadataIndex][item.metaKey][
                  "newValue"
                ].push(item.metaValue);
              } else {
                if (tmpFileListMetadatas[index][metadataIndex][item.metaKey]) {
                  tmpFileListMetadatas[index][metadataIndex][item.metaKey][
                    "newValue"
                  ] = item.metaValue;
                } else {
                  tmpFileListMetadatas[index][metadataIndex][item.metaKey] = {
                    oldValue: "",
                    newValue: item.metaValue,
                    isDelete: false,
                    canDelete: true,
                  };
                }
              }
            }
          }
        }
      });
    });

    setFileListMetadatas(tmpFileListMetadatas);
  };

  const uploaderWrapperStyle = mergeStyles([
    {
      display: "flex",
      flexDirection: "row-reverse",
    },
  ]);

  const AddFileListContentMetadataToTable = (index, path, data) => {
    const contentMetadata = {};
    const metadataKeys = [];
    data.map((item) => {
      contentMetadata[item["metadataKey"]] = {
        oldValue:
          typeof item["metadataValue"] === "boolean"
            ? item["metadataValue"].toString()
            : item["metadataValue"], // Todo: boolean value will be converted to string
        isDelete: false,
        canDelete: item["canDelete"],
      };
      metadataKeys.push({
        value: item["metadataKey"],
        isArray: Array.isArray(item["metadataValue"]),
      });
    });
    RefreshFileListContentMetadataKeys(index, metadataKeys);
    contentMetadata[fileListContentUrl] = path;
    fileListMetadatas[index].push(contentMetadata);
  };

  const BatchAddFileListContentMetadata = async (index, paths) => {
    if (paths) {
      try{
        const resp = await TaskAPI.getFileListMetadata({ Paths: paths });
        const metadataKeys = [];
        let respValid = true;
        Object.entries(resp.data).map(([path, data])=>{
          if(!data.isSuccess) {
            respValid = false;
            return;
          }
        });
        if (respValid) {
          Object.entries(resp.data).map(([path, data]) => {
            const metadatas = FilterUnSupportMetadataType(data.metadata);
            metadatas.forEach((item) => {
              metadataKeys.push({
                value: item["metadataKey"],
                isArray: Array.isArray(item["metadataValue"]),
              });
            });
          AddFileListContentMetadataToTable(index, path, metadatas);
          RefreshFileListMetadataValues(index);
          });
          const tmpFileListErrorMessage = cloneDeep(fileListErrorMessage);
          tmpFileListErrorMessage[index] = ""
          setFileListErrorMessage(tmpFileListErrorMessage);
          RefreshFileListContentMetadataKeys(index, metadataKeys);
        }
        else {
          let invalidPathList = "";
          Object.entries(resp.data).map(([path, data]) => {
            if(!data.isSuccess) {
              invalidPathList.length == 0 ? invalidPathList = `${data.exceptionMessage}: ${path} ;` : invalidPathList = invalidPathList + `<br/>${data.exceptionMessage}: ${path} ;`;
            }
          });
          const tmpFileListErrorMessage = cloneDeep(fileListErrorMessage);
          tmpFileListErrorMessage[index] = `<div>${invalidPathList}<br/><br/>Please check and correct the path, then add again</div>`;
          setFileListErrorMessage(tmpFileListErrorMessage);
        }     
      }
      catch (e) {
        MessageActions.errorHandler(e);
      }     
    }
  };

  const FilterUnSupportMetadataType = (metadatas) => {
    const newMetadatas = [];
    metadatas.forEach((value) => {
      const metadataValue = value["metadataValue"];
      if (typeof metadataValue !== "object" || Array.isArray(metadataValue)) {
        newMetadatas.push(value);
      }
    });
    return newMetadatas;
  };

  const AddFileListContentMetadata = async (index) => {
    const path = fileListContentUrlInput[index];
    try{
      const resp = await TaskAPI.getFileListMetadata({ Paths: [path] });
      if (resp && resp.data[path].isSuccess) {
        const metadatas = FilterUnSupportMetadataType(resp.data[path].metadata);
        AddFileListContentMetadataToTable(index, path, metadatas);
        fileListContentUrlInput[index] = cloneDeep(
        DefaultTaskState.fileListContentUrlInput
        );
        const tmpFileListErrorMessage = cloneDeep(fileListErrorMessage);
        tmpFileListErrorMessage[index] = ""
        setFileListErrorMessage(tmpFileListErrorMessage);
      }
      else{
        const tmpFileListErrorMessage = cloneDeep(fileListErrorMessage);
        tmpFileListErrorMessage[index] = `<div>${resp.data[path].exceptionMessage}: ${path} ;<br/><br/>Please check and correct the path, then add again</div>`
        setFileListErrorMessage(tmpFileListErrorMessage);
      }
    }
    catch (e) {
      MessageActions.errorHandler(e);
    }
    RefreshFileListMetadataValues(index);
  };

  const RefreshFileListContentMetadataKeys = (index, metadataKeys) => {
    const currentMetadataSet = new Set();
    const tmpFileListMetadataKeys = cloneDeep(fileListMetadataKeys);
    tmpFileListMetadataKeys[index].forEach((item) => {
      currentMetadataSet.add(item.key);
    });

    const tmpArrayMetadataKey = cloneDeep(arrayMetadataKey);

    metadataKeys.forEach(function (item) {
      let key = item.value;
      if (!currentMetadataSet.has(key)) {
        currentMetadataSet.add(key);
        tmpFileListMetadataKeys[index].push({
          key: key,
          name: key,
          fieldName: key,
          minWidth: 100,
          maxWidth: 200,
        });
      }
      if (item.isArray) {
        tmpArrayMetadataKey.add(key);
      }
    });
    setFileListMetadataKeys(tmpFileListMetadataKeys);
    setArrayMetadataKey(tmpArrayMetadataKey);
  };

  const GetStringField = (fieldObj) => {
    let fieldContent = "";
    let fieldStyle = {};
    // set and delete will not exist at the same time
    if ("newValue" in fieldObj) {
      fieldContent = fieldObj["newValue"];
      fieldStyle = { color: "green" };
    } else {
      fieldContent = fieldObj["oldValue"];
    }

    if ("canDelete" in fieldObj && !fieldObj["canDelete"]) {
      fieldStyle = { color: "red" };
    }
    if ("isDelete" in fieldObj) {
      if (fieldObj["isDelete"]) {
        fieldStyle = { textDecoration: "line-through" };
      }
    }
    return <span className={mergeStyles(fieldStyle)}>{fieldContent}</span>;
  };

  const GetArrayField = (fieldObj) => {
    let res = "";
    if ("newValue" in fieldObj) {
      // newValue.length always >= oldValue.length
      fieldObj["newValue"].map((item, index) => {
        let fieldContent = "";
        let fieldStyle = "display:block;";
        if (item == "") {
          // delete
          fieldStyle += "text-decoration: line-through;";
          fieldContent = fieldObj["oldValue"][index];
        } else if (index >= fieldObj["oldValue"].length) {
          // add
          fieldStyle += "color: green;";
          fieldContent = item;
        } else if (item != fieldObj["oldValue"][index]) {
          // update
          fieldStyle += "color: green;";
          fieldContent = item;
        } else {
          fieldContent = fieldObj["oldValue"][index];
        }
        res += '<span style="' + fieldStyle + '">' + fieldContent + "</span>";
      });
    } else {
      fieldObj["oldValue"].map((item, index) => {
        let fieldContent = item;
        res += '<span style="display:block">' + fieldContent + "</span>";
      });
    }
    return <div dangerouslySetInnerHTML={{ __html: res }} />;
  };

  const FileMetadataRenderItemColumn = (item, index, column) => {
    if (column.fieldName == fileListContentUrl) {
      return <span>{item[column.fieldName]}</span>;
    }

    const fieldObj = item[column.fieldName];
    if (fieldObj) {
      if (Array.isArray(fieldObj["oldValue"])) {
        return GetArrayField(fieldObj);
      } else {
        return GetStringField(fieldObj);
      }
    }
    return <span></span>;
  };

  const GenFileListItem = (index) => {
    const data = {};
    data.MutipleOperations = fileListOprationNums[index].map((_, opIndex) => {
      const itemData = {};
      itemData.Operations = operationsFileList[index][opIndex].map((i) => {
        const opData = {
          Op: i.op,
          Item: {
            Key: i.metaKey,
            Value: i.metaValue,
            IsArray: arrayMetadataKey.has(i.metaKey),
          },
        };
        return opData;
      });

      itemData.Conditions = conditionsFileList[index][opIndex].map((i) => {
        const conditionData = {
          Con: i.con,
          Metadata: {
            Key: i.conditionKey,
            Value: i.conditionValue,
            IsArray: arrayMetadataKey.has(i.conditionKey),
          },
        };
        if (conditionData.Con == "==") {
          conditionData.Con = "Equal";
        } else if (conditionData.Con == "<>") {
          conditionData.Con = "NotEqual";
        }
        return conditionData;
      });
      return itemData;
    });

    // file group
    const groupFilePaths = {};
    const noAuthorPaths = {};
    fileListMetadatas[index].map((item) => {
      const parseFile = ParseFilePath(item[fileListContentUrl]);

      if (parseFile.length > 0) {
        const repoOwner = parseFile[0];
        const repoName = parseFile[1];
        const filePath = parseFile[2];
        const repoKey = repoOwner + "/" + repoName;
        if ("ms.author" in item) {
          if (!(repoKey in groupFilePaths)) {
            groupFilePaths[repoKey] = {};
          }
          const author = item["ms.author"]["oldValue"];
          if (!(author in groupFilePaths[repoKey])) {
            groupFilePaths[repoKey][author] = [];
          }
          groupFilePaths[repoKey][author].push(filePath);
        } else {
          if (!(repoKey in noAuthorPaths)) {
            noAuthorPaths[repoKey] = [];
          }
          noAuthorPaths[repoKey].push(filePath);
        }
      }
    });
    data.GroupPaths = groupFilePaths;
    data.NoAuthorPaths = noAuthorPaths;
    return data;
  };

  const SubmitFileList = async (items) => {
    try {
      const recipientsList = [];
      recipients[0].forEach((item) => {
        recipientsList.push(item["recipient"]);
      });
      const data = {
        Title: title[0],
        AdoLink: adoLink[0],
        Description: description[0],
        Recipient: recipientsList.join(";"),
        PRAutoMergeOption: autoMergePR[0],
        SourceTeam: sourceTeam[0],
        TemplateConfigId: templateConfigId[0],
      };
      data.FileListItems = items;
      data.Submitter = userId;
      const resp = await TaskAPI.submitFileListTask(data);
      if(resp.status === 200) {
        setIsTaskCloseLoading(false);
      }
    } catch (e) {
      MessageActions.errorHandler(e);
    }
  };

  const ParseFilePath = (path) => {
    path = path.replace(new RegExp("https://github.com/", "g"), "");
    const pathList = path.split("/");
    if (pathList.length < 5) {
      return [];
    }
    const repoOwner = pathList[0];
    const repoName = pathList[1];
    const filePath = pathList.slice(4, pathList.length).join("/");
    return [repoOwner, repoName, filePath];
  };

  const BackFn = useCallback(() => {
    history.push("/tasks");
  }, [history]);

  const ResetCCEditor = (index = -1) => {
    if (index == -1) {
      //setShowCCEditor([DefaultUIState.showCCEditor]);
      setRecipient([DefaultTaskState.recipient]);
    } else {
      //const tmpShowCCEditor = cloneDeep(showCCEditor);
      //tmpShowCCEditor[index] = DefaultUIState.showCCEditor;
      //setShowCCEditor(tmpShowCCEditor);

      const tmpRecipient = cloneDeep(recipient);
      tmpRecipient[index] = DefaultTaskState.recipient;
      setRecipient(tmpRecipient);
    }
  };

  const ResetOpEditor = (index = -1) => {
    if (index == -1) {
      setShowEditor([DefaultUIState.showEditor]);
      setOp([DefaultTaskState.op]);
      setMetaKey([DefaultTaskState.metaKey]);
      setMetaValue([DefaultTaskState.metaValue]);
    } else {
      const tmpShowEditor = cloneDeep(showEditor);
      tmpShowEditor[index] = DefaultUIState.showEditor;
      setShowEditor(tmpShowEditor);

      const tmpOp = cloneDeep(op);
      tmpOp[index] = DefaultTaskState.op;
      setOp(tmpOp);

      const tmpMetaKey = cloneDeep(metaKey);
      tmpMetaKey[index] = DefaultTaskState.metaKey;
      setMetaKey(tmpMetaKey);

      const tmpMetaValue = cloneDeep(metaValue);
      tmpMetaValue[index] = DefaultTaskState.metaValue;
      setMetaValue(tmpMetaValue);
    }
  };

  const ResetConditionEditor = (index = -1) => {
    if (index == -1) {
      setShowConditionEditor([DefaultUIState.showConditionEditor]);
      setConditionKey([DefaultTaskState.conditionKey]);
      setConditionValue([DefaultTaskState.conditionValue]);
    } else {
      const tmpShowConditionEditor = cloneDeep(showConditionEditor);
      tmpShowConditionEditor[index] = DefaultUIState.showConditionEditor;
      setShowConditionEditor(tmpShowConditionEditor);

      const tmpConditionKey = cloneDeep(conditionKey);
      tmpConditionKey[index] = DefaultTaskState.conditionKey;
      setConditionKey(tmpConditionKey);

      const tmpConditionValue = cloneDeep(conditionValue);
      tmpConditionValue[index] = DefaultTaskState.conditionValue;
      setConditionValue(tmpConditionValue);
    }
  };

  const ResetGeneral = () => {
    setTitle([cloneDeep(DefaultTaskState.title)]);
    setAdoLink([cloneDeep(DefaultTaskState.adoLink)]);
    setDescription([cloneDeep(DefaultTaskState.description)]);
    setRecipients([cloneDeep(DefaultTaskState.recipients)]);
    setItems([cloneDeep(DefaultTaskState.items)]);
    setConditions([cloneDeep(DefaultTaskState.conditions)]);
  };

  const allEditList = [
    {
      key: title,
      setfunc: setTitle,
      defaultValue: DefaultTaskState.title,
    },
    {
      key: adoLink,
      setfunc: setAdoLink,
      defaultValue: DefaultTaskState.adoLink,
    },
    {
      key: description,
      setfunc: setDescription,
      defaultValue: DefaultTaskState.description,
    },
    {
      key: recipient,
      setfunc: setRecipient,
      defaultValue: DefaultTaskState.recipient,
    },
    {
      key: autoMergePR,
      setfunc: setautoMergePR,
      defaultValue: DefaultTaskState.autoMergePR,
    },
    {
      key: sourceTeam,
      setfunc: setSourceTeam,
      defaultValue: DefaultTaskState.sourceTeam,
    },
    {
      key: templateListFiltered,
      setfunc: setTemplateListFiltered,
      defaultValue: DefaultTaskState.templateListFiltered,
    },
    {
      key: templateConfigId,
      setfunc: setTemplateConfigId,
      defaultValue: DefaultTaskState.templateConfigId,
    },
    {
      key: showEditor,
      setfunc: setShowEditor,
      defaultValue: DefaultUIState.showEditor,
    },
    {
      key: showCCEditor,
      setfunc: setShowCCEditor,
      defaultValue: DefaultUIState.showCCEditor,
    },
    {
      key: showConditionEditor,
      setfunc: setShowConditionEditor,
      defaultValue: DefaultUIState.showConditionEditor,
    },
    { key: op, setfunc: setOp, defaultValue: DefaultTaskState.op },
    {
      key: metaKey,
      setfunc: setMetaKey,
      defaultValue: DefaultTaskState.metaKey,
    },
    {
      key: metaValue,
      setfunc: setMetaValue,
      defaultValue: DefaultTaskState.metaValue,
    },
    {
      key: con,
      setfunc: setCon,
      defaultValue: DefaultTaskState.con,
    },
    {
      key: conFileList,
      setfunc: setConFileList,
      defaultValue: [DefaultTaskState.fileListCon],
    },
    {
      key: conditionKey,
      setfunc: setConditionKey,
      defaultValue: DefaultTaskState.conditionKey,
    },
    {
      key: conditionValue,
      setfunc: setConditionValue,
      defaultValue: DefaultTaskState.conditionValue,
    },
    {
      key: repoNameValue,
      setfunc: setRepoNameValue,
      defaultValue: DefaultTaskState.repoNameValue,
    },
    {
      key: filepaths,
      setfunc: setFilepaths,
      defaultValue: DefaultTaskState.filepaths,
    },
    {
      key: fileMetadataMap,
      setfunc: setFileMetadataMap,
      defaultValue: DefaultTaskState.fileMetadataMap,
    },
    {
      key: globalMetadata,
      setfunc: setGlobalMetadata,
      defaultValue: DefaultTaskState.globalMetadata,
    },
    {
      key: defaultGlobalMetadata,
      setfunc: setDefaultGlobalMetadata,
      defaultValue: DefaultTaskState.defaultGlobalMetadata,
    },
    {
      key: fileMetadata,
      setfunc: setFileMetadata,
      defaultValue: DefaultTaskState.fileMetadata,
    },
    {
      key: defaultFileMetadata,
      setfunc: setDefaultFileMetadata,
      defaultValue: DefaultTaskState.defaultFileMetadata,
    },
    {
      key: selectFilepath,
      setfunc: setSelectFilepath,
      defaultValue: DefaultTaskState.selectFilepath,
    },
    { key: items, setfunc: setItems, defaultValue: DefaultTaskState.items },
    {
      key: recipients,
      setfunc: setRecipients,
      defaultValue: DefaultTaskState.recipients,
    },
    {
      key: conditions,
      setfunc: setConditions,
      defaultValue: DefaultTaskState.conditions,
    },
    {
      key: tabValue,
      setfunc: setTabValue,
      defaultValue: tabValue ? tabValue[0] : DefaultTaskState.tabValue,
    },
    {
      key: requests,
      setfunc: setRequests,
      defaultValue: DefaultTaskState.requests,
    },
    {
      key: fileListMetadatas,
      setfunc: setFileListMetadatas,
      defaultValue: DefaultTaskState.fileListMetadatas,
    },
    {
      key: fileListMetadataKeys,
      setfunc: setFileListMetadataKeys,
      defaultValue: DefaultTaskState.fileListMetadataKeys,
    },
    {
      key: fileListContentUrlInput,
      setfunc: setFileListContentUrlInput,
      defaultValue: DefaultTaskState.fileListContentUrlInput,
    },
    {
      key: fileListErrorMessage,
      setfunc: setFileListErrorMessage,
      defaultValue: DefaultTaskState.fileListErrorMessage,
    },
    {
      key: opFileList,
      setfunc: setOpFileList,
      defaultValue: [DefaultTaskState.fileListOp],
    },
    {
      key: metaKeyFileList,
      setfunc: setMetaKeyFileList,
      defaultValue: [DefaultTaskState.fileListMetaKey],
    },
    {
      key: metaValueFileList,
      setfunc: setMetaValueFileList,
      defaultValue: [DefaultTaskState.fileListMetaValue],
    },
    {
      key: showFileListConditionEditor,
      setfunc: setShowFileListConditionEditor,
      defaultValue: [DefaultUIState.showFileListConditionEditor],
    },
    {
      key: conditionKeyFileList,
      setfunc: setConditionKeyFileList,
      defaultValue: [DefaultTaskState.fileListConditionKey],
    },
    {
      key: conditionValueFileList,
      setfunc: setConditionValueFileList,
      defaultValue: [DefaultTaskState.fileListConditionValue],
    },
    {
      key: operationsFileList,
      setfunc: setOperationsFileList,
      defaultValue: [DefaultTaskState.fileListOprations],
    },
    {
      key: conditionsFileList,
      setfunc: setConditionsFileList,
      defaultValue: [DefaultTaskState.fileListConditions],
    },
    {
      key: fileListOprationNums,
      setfunc: setFileListOprationNums,
      defaultValue: [DefaultTaskState.fileListOprationNums],
    },
  ];

  const AddRequest = () => {
    allEditList.forEach((item) => {
      const tmp = cloneDeep(item.key);
      tmp.push(cloneDeep(item.defaultValue));
      item.setfunc(tmp);
    });
  };

  const DeleteRequest = (index) => {
    allEditList.forEach((item) => {
      const tmp = cloneDeep(item.key);
      tmp.splice(index, 1);
      item.setfunc(tmp);
    });
  };

  const ParseIndexFromClassName = (classnames) => {
    const className = classnames
      .split(" ")
      .find((x) => x.indexOf(requestIndexPrefix) == 0);
    if (!className || className.length == 0) {
      return -1;
    }
    return parseInt(className.substring(requestIndexPrefix.length));
  };

  const ToggleEditor = (node) => {
    const index = GetRequestIndexFromTree(node);
    if (index != -1) {
      const tmpShowEditor = cloneDeep(showEditor);
      tmpShowEditor[index] = !tmpShowEditor[index];
      setShowEditor(tmpShowEditor);
    }
  };

  const ToggleCCEditor = (node) => {
    const index = GetRequestIndexFromTree(node);
    if (index != -1) {
      const tmpShowCCEditor = cloneDeep(showCCEditor);
      tmpShowCCEditor[index] = !tmpShowCCEditor[index];
      setShowCCEditor(tmpShowCCEditor);
    }
  };

  const ToggleConditionEditor = (node) => {
    const index = GetRequestIndexFromTree(node);
    if (index != -1) {
      const tmpShowConditionEditor = cloneDeep(showConditionEditor);
      tmpShowConditionEditor[index] = !tmpShowConditionEditor[index];
      setShowConditionEditor(tmpShowConditionEditor);
    }
  };

  const onOpTypeChange = (_event, option) => {
    const tmpOp = cloneDeep(op);
    tmpOp[parseInt(option.className)] = option.key;
    setOp(tmpOp);
  };

  const onMetaKeyChange = (node, newValue) => {
    const index = GetRequestIndexFromTree(node);
    if (index != -1) {
      const tmpMetaKey = cloneDeep(metaKey);
      tmpMetaKey[index] = newValue;
      setMetaKey(tmpMetaKey);
    }
  };

  const onRecipientChange = (node, newValue) => {
    const index = GetRequestIndexFromTree(node);
    if (index != -1) {
      const tmpRecipient = cloneDeep(recipient);
      tmpRecipient[index] = newValue;
      setRecipient(tmpRecipient);
    }
  };

  const onMetaValueChange = (node, newValue) => {
    const index = GetRequestIndexFromTree(node);
    if (index != -1) {
      const tmpMetaValue = cloneDeep(metaValue);
      tmpMetaValue[index] = newValue;
      setMetaValue(tmpMetaValue);
    }
  };

  const onConTypeChange = (_event, option) => {
    const tmpCondition = cloneDeep(con);
    tmpCondition[parseInt(option.className)] = option.key;
    setCon(tmpCondition);
  };

  const onConditionKeyChange = (node, newValue) => {
    const index = GetRequestIndexFromTree(node);
    if (index != -1) {
      const tmpConditionKey = cloneDeep(conditionKey);
      tmpConditionKey[index] = newValue;
      setConditionKey(tmpConditionKey);
    }
  };

  const onConditionValueChange = (node, newValue) => {
    const index = GetRequestIndexFromTree(node);
    if (index != -1) {
      const tmpConditionValue = cloneDeep(conditionValue);
      tmpConditionValue[index] = newValue;
      setConditionValue(tmpConditionValue);
    }
  };

  const GetRequestIndexFromTree = (node) => {
    node = node.target;
    while (node) {
      const index = ParseIndexFromClassName(node.className);
      if (index != -1) {
        return index;
      }
      node = node.parentNode;
    }
    return -1;
  };

  const TabSwitch = (item, node) => {
    ResetOpEditor()
    ResetConditionEditor()
    setItems([cloneDeep(DefaultTaskState.items)]);
    setConditions([cloneDeep(DefaultTaskState.conditions)]);
    const index = GetRequestIndexFromTree(node);
    const tmpTabValue = cloneDeep(tabValue);
    tmpTabValue[index] = item.props.headerText;
    setTabValue(tmpTabValue);
  };

  const AddRecipientToList = (node) => {
    const index = GetRequestIndexFromTree(node);
    const recipientId = _.uniqueId("recipient");
    const recipientList = {
      recipient: recipient[index] + "@microsoft.com",
      t_id: recipientId,
    };
    const tmpRecipients = cloneDeep(recipients);
    tmpRecipients[index].push(recipientList);
    setRecipients(tmpRecipients);
    ResetCCEditor(index);
  };

  const AddItemToList = (node) => {
    const index = GetRequestIndexFromTree(node);
    const metaOpId = _.uniqueId("metaOp");
    const item = {
      op: op[index],
      metaKey: metaKey[index],
      metaValue: metaValue[index],
      isArrayValue: false,
      t_id: metaOpId,
    };
    const tmpItems = cloneDeep(items);
    tmpItems[index].push(item); //= [...items[index], item];
    setItems(tmpItems);

    if (item.op === "Delete") {
      const condition = {
        con: "==",
        conditionKey: item.metaKey,
        conditionValue: item.metaValue,
        isArrayValue: item.isArrayValue,
        relatedOp: metaOpId,
        t_id: _.uniqueId("metaCondition"),
      };
      const tmpConditions = cloneDeep(conditions);
      tmpConditions[index].push(condition); // = [...conditions[index], condition];
      setConditions(tmpConditions);
    }
    ResetOpEditor(index);
  };

  const AddConditionToList = (node) => {
    const index = GetRequestIndexFromTree(node);
    const condition = {
      con: con[index],
      conditionKey: conditionKey[index],
      conditionValue: conditionValue[index],
      isArrayValue: false,
      t_id: _.uniqueId("metaCondition"),
    };
    if (condition.con == "Existent" || condition.con == "NonExistent") {
      condition.conditionValue = "n/a";
    }

    const tmpConditions = cloneDeep(conditions);
    tmpConditions[index].push(condition); // = [...conditions[index], condition];
    setConditions(tmpConditions);

    ResetConditionEditor(index);
  };

  const onFilePathChange = (node, option) => {
    const index = parseInt(option.className);
    const tmpSelectFilepath = cloneDeep(selectFilepath);
    tmpSelectFilepath[index] = option.key;
    setSelectFilepath(tmpSelectFilepath);
    setAllMetadata(fileMetadataMap[index], option.key, index);
  };

  const onRepoNameValueChange = (node, newValue) => {
    const index = GetRequestIndexFromTree(node);

    if (index != -1) {
      const tmpRepoNameValue = cloneDeep(repoNameValue);
      tmpRepoNameValue[index] = newValue;
      setRepoNameValue(tmpRepoNameValue);
    }
  };

  const setAllMetadata = (data, key, index) => {
    //const globalMetadata = data[key]["globalMetadata"];
    const tmpGlobalMetadata = cloneDeep(globalMetadata);
    tmpGlobalMetadata[index] = data[key]["globalMetadata"];
    setGlobalMetadata(tmpGlobalMetadata);

    const tmpDefaultGlobalMetadata = cloneDeep(defaultGlobalMetadata);
    tmpDefaultGlobalMetadata[index] = data[key]["globalMetadata"];
    setDefaultGlobalMetadata(tmpDefaultGlobalMetadata);

    //const fileMetadata = data[key]["fileMetadata"];
    const tmpFileMetadata = cloneDeep(fileMetadata);
    tmpFileMetadata[index] = data[key]["fileMetadata"];
    setFileMetadata(tmpFileMetadata);

    const tmpDefaultFileMetadata = cloneDeep(defaultFileMetadata);
    tmpDefaultFileMetadata[index] = data[key]["fileMetadata"];
    setDefaultFileMetadata(tmpDefaultFileMetadata);
  };

  const resetRepoFolder = () => {
    setFileMetadataMap([cloneDeep(DefaultTaskState.fileMetadataMap)]);
    setGlobalMetadata([cloneDeep(DefaultTaskState.globalMetadata)]);
    setDefaultGlobalMetadata([cloneDeep(DefaultTaskState.globalMetadata)]);
    setFileMetadata([cloneDeep(DefaultTaskState.fileMetadata)]);
    setDefaultFileMetadata([cloneDeep(DefaultTaskState.fileMetadata)]);
    setSelectFilepath([DefaultTaskState.selectFilepath]);
    setRepoNameValue([DefaultTaskState.repoNameValue]);
  };

  const onGlobalMetadataChange = (node, newValue) => {
    const index = GetRequestIndexFromTree(node);
    const globalMetadataClass = node.target.className
      .split(" ")
      .find((x) => x.indexOf(globalMetadataPrefix) == 0);
    if (globalMetadataClass.length > 0) {
      const globalMetadataKey = globalMetadataClass.substring(
        globalMetadataPrefix.length
      );
      const newGlobalMetadata = cloneDeep(globalMetadata);
      newGlobalMetadata[index][globalMetadataKey] = newValue;
      setGlobalMetadata(newGlobalMetadata);
    }
  };

  const onFileMetadataChange = (node, newValue) => {
    const index = GetRequestIndexFromTree(node);
    const fileMetadataClass = node.target.className
      .split(" ")
      .find((x) => x.indexOf(fileMetadataPrefix) == 0);
    if (fileMetadataClass.length > 0) {
      const fileMetadataKey = fileMetadataClass
        .substring(fileMetadataPrefix.length)
        .split(fileMetadataKeySubkeySeparator);
      const key = fileMetadataKey[0];
      const subKey = fileMetadataKey[1];
      const newFileMetadata = cloneDeep(fileMetadata);
      newFileMetadata[index][key][subKey] = newValue;
      setFileMetadata(newFileMetadata);
    }
  };

  const GetDocfxList = async (node) => {
    try {
      const index = GetRequestIndexFromTree(node);
      if (index == -1) {
        return;
      }

      const path = repoNameValue[index];
      const docfxListResp = await TaskAPI.getDocfxList(path);
      const docfxList = docfxListResp.data;
      const newFilePaths = cloneDeep(DefaultTaskState.filepaths);
      const newFileMetadataMap = cloneDeep(DefaultTaskState.fileMetadataMap);

      docfxList.forEach((docfx) => {
        const newGlobalMetadata = cloneDeep(DefaultTaskState.globalMetadata);
        const newFileMetadata = cloneDeep(DefaultTaskState.fileMetadata);
        const docfxPath = docfx["filePath"];
        newFilePaths.push(docfxPath);

        if ("content" in docfx && "build" in docfx["content"]) {
          if ("globalMetadata" in docfx["content"]["build"]) {
            const globalMetadatasResp = docfx["content"]["build"]["globalMetadata"];
            Object.keys(globalMetadatasResp).forEach(function (key) {
              if (typeof globalMetadatasResp[key] == "string") {
                newGlobalMetadata[key] = globalMetadatasResp[key];
              }
            });
          }

          if ("fileMetadata" in docfx["content"]["build"]) {
            const fileMetadatasResp = docfx["content"]["build"]["fileMetadata"];
            Object.keys(fileMetadatasResp).forEach(function (key) {
              const newFileMetadataInner = {};
              const fileMetadataInner = fileMetadatasResp[key];
              Object.keys(fileMetadataInner).forEach(function (innerkey) {
                if (typeof fileMetadataInner[innerkey] == "string") {
                  newFileMetadataInner[innerkey] = fileMetadataInner[innerkey];
                }
              });
              if (Object.keys(newFileMetadataInner).length > 0) {
                newFileMetadata[key] = newFileMetadataInner;
              }
            });
          }
        }
        
        newFileMetadataMap[docfxPath] = cloneDeep(
          DefaultTaskState.fileMetadataMap
        );
        newFileMetadataMap[docfxPath]["globalMetadata"] = newGlobalMetadata;
        newFileMetadataMap[docfxPath]["fileMetadata"] = newFileMetadata;
      });
      if (newFilePaths.length > 0) {
        const tmpFilePaths = cloneDeep(filepaths);
        tmpFilePaths[index] = newFilePaths;
        setFilepaths(tmpFilePaths);

        const tmpSelectFilepath = cloneDeep(selectFilepath);
        tmpSelectFilepath[index] = newFilePaths[0];
        setSelectFilepath(tmpSelectFilepath);

        setAllMetadata(newFileMetadataMap, newFilePaths[0], index);

        const tmpFileMetadataMap = cloneDeep(fileMetadataMap);
        tmpFileMetadataMap[index] = newFileMetadataMap;
        setFileMetadataMap(tmpFileMetadataMap);
      }
    } catch (e) {
      MessageActions.errorHandler(e);
    }
  };

  const GenUsageData = (index, type) => {
    const recipientsList = [];
    recipients[index].forEach((item) => {
      recipientsList.push(item["recipient"]);
    });
    const data = {
      Title: title[index],
      AdoLink: adoLink[index],
      Description: description[index],
      Recipient: recipientsList.join(";"),
      PRAutoMergeOption: autoMergePR[index],
      SourceTeam: sourceTeam[index],
      TemplateConfigId: templateConfigId[index],
    };
    const KeyType = type ===  tabUsage ? "Metadata" : "Taxonomy";
    data.Operations = items[index].map((i) => {
      const itemData = {
        Op: i.op,
        Item: {
          Key: i.metaKey,
          Value: i.metaValue,
          IsArray: i.isArrayValue,
          KeyType: KeyType,
        },
      };
      return itemData;
    });
    data.Conditions = conditions[index].map((i) => {
      const conditionData = {
        Con: i.con,
        Metadata: {
          Key: i.conditionKey,
          Value: i.conditionValue,
          IsArray: i.isArrayValue,
        },
      };
      if (conditionData.Con == "==") {
        conditionData.Con = "Equal";
      } else if (conditionData.Con == "<>") {
        conditionData.Con = "NotEqual";
      }
      return conditionData;
    });
    data.Submitter = userId;
    return data;
  };

  const isTemplateNotSelected = (index) => {
    return templateList.length === 0 ? false 
    : ( sourceTeam[index].length > 0 && templateListFiltered[index].length === 0) ? false 
    : templateConfigId[index].length === 0 ? true : false;
  }

  const SubmitDisabled = () => {
    // check items
    for (let index = 0; index < requests.length; index++) {
      if (
        title[0].length === 0 ||
        adoLink[0].length === 0 ||
        !isValidAdoLink(adoLink[0]) ||
        description[0].length === 0 ||
        !isAdoLinkEndWithNumber(adoLink[0]) ||
        isTemplateNotSelected(index)||
        (tabValue[index] === tabFilelist &&
          (title[0].length === 0 ||
            adoLink[0].length === 0 ||
            !isValidAdoLink(adoLink[0]) ||
            !isAdoLinkEndWithNumber(adoLink[0]) ||
            description[0].length === 0 ||
            fileListMetadatas[index].length === 0 ||
            operationsFileList[index][0].length === 0 ||
            isTemplateNotSelected(index))) ||
        ((tabValue[index] === tabUsage || tabValue[index] == taxonomySDP) &&
          (title[index].length === 0 ||
            adoLink[index].length === 0 ||
            !isValidAdoLink(adoLink[index]) ||
            !isAdoLinkEndWithNumber(adoLink[index]) ||
            description[index].length === 0 ||
            items[index].length === 0 ||
            conditions[index].length === 0 ||
            isTemplateNotSelected(index)))
      ) {
        return true;
      }
    }

    return false;
  };

  const SubmitOperations = async () => {
    // check
    // usage flag
    let flag = false;
    for (let index = 0; index < requests.length; index++) {
      if (tabValue[index] == tabUsage) {
        if (items[index].length > 0 && showEditor[index]) {
          flag = true;
        }
      } else if (tabValue[index] == tabRepoFolder) {
        const options = GenRepoFolderDataOption(index);
        // todo: need a better error reporting type
        if (options.length == 0) {
          MessageActions.error(`Repo/Folder Metadata has no changes`);
          return;
        }
      }
    }

    if (flag) {
      toggleHideDialog();
    } else {
      await MetadataFieldCheck();
    }
  };

  const MetadataFieldCheck = async () => {
    let flag = false;
    for (let index = 0; index < requests.length; index++) {
      if (tabValue[index] != tabRepoFolder) {
        items[index].forEach((e)=>{
          let fieldList = templateFieldsList.filter((i)=>i.key == templateConfigId)[0].fields;
          if(fieldList.indexOf(e.metaKey) == -1){
            flag = true;
          }
        })
      } 
    }

    if (flag) {
      toggleHideFieldCheckDialog();
    } else {
      await FinalSubmit();
    }
  }

  const FinalSubmit = async () => {
    const usageData = [];
    const fileListData = [];
    const repoFolderData = [];
    requests.map((_, index) => {
      if (tabValue[index] === tabUsage || tabValue[index] === taxonomySDP) {
        usageData.push(GenUsageData(index, tabValue[index]));
      } else if (tabValue[index] === tabFilelist) {
        fileListData.push(GenFileListItem(index));
      } else {
        repoFolderData.push(GenRepoFolderItem(index));
      }
    });

    try {
      toggleHideSubmitDialog();
      if (usageData.length > 0) {
        const resp = await TaskAPI.usageBatchSubmit({ Requests: usageData });
        if(resp.status === 200) {
          setIsTaskCloseLoading(false);
        }
      }
      if (fileListData.length > 0) {
        SubmitFileList(fileListData);
      }
      if (repoFolderData.length > 0) {
        SubmitRepoFolder(repoFolderData);
      }
      MessageActions.success(Resources.Tasks.TaskSubmitted);
      ResetOpEditor();
      ResetCCEditor();
      resetRepoFolder();
      ResetGeneral();
      //history.push("/tasks");
    } catch (e) {
      MessageActions.errorHandler(e);
    }
  };

  const GenRepoFolderDataOption = (index) => {
    const options = [];
    // global metadata(repo Metadata)
    Object.keys(globalMetadata[index]).forEach(function (key) {
      if (globalMetadata[index][key] != defaultGlobalMetadata[index][key]) {
        // need change matedata
        const itemData = {
          Item: {
            Key: key,
            Value: defaultGlobalMetadata[index][key],
            IsArray: false,
          },
        };
        if (globalMetadata[index][key]?.length > 0) {
          itemData.UpdateValue = globalMetadata[index][key];
          itemData.Op = "Update";
        } else {
          itemData.Op = "Delete";
        }
        options.push(itemData);
      }
    });

    // file Metadata(folder Metadata)
    Object.keys(fileMetadata[index]).forEach(function (key) {
      const fileMetadataInner = fileMetadata[index][key];
      Object.keys(fileMetadataInner).forEach(function (innerKey) {
        if (
          fileMetadataInner[innerKey] !=
          defaultFileMetadata[index][key][innerKey]
        ) {
          // need change matedata
          const itemData = {
            Item: {
              Key: key,
              Value: defaultFileMetadata[index][key][innerKey],
              IsArray: false,
            },
          };
          if (fileMetadataInner[innerKey]?.length > 0) {
            itemData.UpdateValue = fileMetadataInner[innerKey];
            itemData.Op = "Update";
          } else {
            itemData.Op = "Delete";
          }
          options.push(itemData);
        }
      });
    });
    return options;
  };

  const GenRepoFolderItem = (index) => {
    const data = {
      Folder: repoNameValue[index],
      FilePath: selectFilepath[index],
    };
    data.Operations = GenRepoFolderDataOption(index);
    if (data.Operations.length === 0) {
      throw new Error("Metadata has no changes");
    }
    return data;
  };

  //const Submit
  const SubmitRepoFolder = async (items) => {
    try {
      const recipientsList = [];
      recipients[0].forEach((item) => {
        recipientsList.push(item["recipient"]);
      });
      const data = {
        Title: title[0],
        AdoLink: adoLink[0],
        Description: description[0],
        Recipient: recipientsList.join(";"),
        PRAutoMergeOption: autoMergePR[0],
        SourceTeam: sourceTeam[0],
        TemplateConfigId: templateConfigId[0],
      };
      data.RepoFolderItems = items;
      data.Submitter = userId;
      const resp = await TaskAPI.submitRepoFolderTask(data);
      if(resp.status === 200) {
        setIsTaskCloseLoading(false);
      }
    } catch (e) {
      MessageActions.errorHandler(e);
    }
  };

  const getTeamList = async () => {
    try{
      const teamTemplateList = await TaskAPI.loadTeamTemplate();
      if (teamTemplateList.data?.length > 1 ) {
        setShowTeamSelector(true);
        var teamList = [];
        teamTemplateList.data.forEach((e)=>{
          teamList.push({key:e.id,text:e.teamAlias});
        })
        setTeamList(teamList);
      };
      var templateList = [];
      var templateFieldsList = [];
      teamTemplateList.data.forEach((e)=>{
        e.templateConfigs.forEach((item)=>{
          templateList.push({key:item.id,text:item.name,team:e.id});
          templateFieldsList.push({key:item.id,fields:item.fields})
        });
      })
      setTemplateList(templateList);
      setTemplateFieldsList(templateFieldsList);
      const sdpList = await TaskAPI.getTaxonomyList();
      var taxonomyList = [];
      sdpList.data.forEach((e)=>{taxonomyList.push({key:e,text:e})});
      setTaxonomyList(taxonomyList);
    }catch (error) {
      const message = error.message;
      MessageActions.error(message);
    };
  }

  const initUsageCondition = () => {
    if (queryConditions){
      try{
        let conditions = JSON.parse(queryConditions)
        let tmpConditions  = [];
        conditions.forEach((item)=>{
          const tmpCon = {
            con: item?.con,
            conditionKey: item?.key,
            conditionValue: item?.con === "Existent" || item?.con === "NonExistent"? "n/a": item?.value,
            isArrayValue: false,
            t_id: _.uniqueId("metaCondition"),
          };
          tmpConditions.push(tmpCon);
        });
        setConditions([tmpConditions]);
      }catch{}
    }
  }

  useEffect(() => {
    getTeamList();
    initUsageCondition();
  }, []);
  

  return (
    <div style={{ "overflow-y": "auto", height: "90vh" }}>
      <BackHomeButton text={Resources.Tasks.BackToTasklist} backFn={BackFn} />
      <Text as="h1" variant="xxLarge" styles={TaskSubmitStyles.Title}>
        {Resources.Tasks.TaskSubmitTopTitle}
      </Text>
      <Stack
        tokens={TaskSubmitStyles.DefaultOpContainerTokens}
        verticalAlign="space-between"
      >
        <ActionButton iconProps={{ iconName: "Add" }} onClick={AddRequest}>
          Request
        </ActionButton>
        {requests.map((item, index) => {
          return (
            <Stack styles={TaskSubmitStyles.PivotBlock}>
              <Stack tokens={TaskSubmitStyles.InnerOpTokens}>
                {index > 0 ? (
                  <IconButton
                    className={ActionColumnClassNames.ActionIcon}
                    iconProps={{ iconName: "Delete" }}
                    onClick={() => {
                      DeleteRequest(index);
                    }}
                  />
                ) : (
                  ""
                )}
                <div
                  style={{
                    display:
                      index === 0 || tabValue[index] === tabUsage
                        ? "block"
                        : "none",
                  }}
                >
                  <TextField
                    label="Title"
                    underlined
                    required={title[index]?.length === 0}
                    styles={TaskSubmitStyles.InputTitleRootWidth}
                    value={title[index]}
                    onChange={(_, newValue) => {
                      const tmp = cloneDeep(title);
                      tmp[index] = newValue;
                      setTitle(tmp);
                    }}
                  />
                  <TextField
                    label="AdoLink"
                    underlined
                    required={adoLink[index]?.length === 0}
                    styles={TaskSubmitStyles.InputTitleRootWidthAndMargin}
                    value={adoLink[index]}
                    onChange={(_, newValue) => {
                      const tmp = cloneDeep(adoLink);
                      tmp[index] = newValue;
                      setAdoLink(tmp);
                    }}
                    errorMessage={
                      !isValidAdoLink(adoLink[index])
                        ? Resources.Tasks.NotValidAdoLink
                        : isAdoLinkEndWithNumber(adoLink[index]) ? undefined : Resources.Tasks.AdoLinkNotEndWithNumber
                    }
                  />
                  <TextField
                    label="Description"
                    underlined
                    required={description[index]?.length === 0}
                    multiline
                    styles={TaskSubmitStyles.InputTitleRootWidthAndMargin}
                    value={description[index]}
                    onChange={(_, newValue) => {
                      const tmp = cloneDeep(description);
                      tmp[index] = newValue;
                      setDescription(tmp);
                    }}
                  />
                  <Stack.Item>
                    <ActionButton
                      iconProps={{ iconName: "Add" }}
                      allowDisabledFocus
                      className={requestIndexPrefix + index}
                      onClick={ToggleCCEditor}
                    >
                      CC Recipient
                    </ActionButton>
                    <Stack
                      styles={{
                        root: {
                          display: showCCEditor[index] ? "block" : "none",
                        },
                      }}
                      tokens={TaskSubmitStyles.DefaultOpContainerTokens}
                    >
                      <Stack horizontal tokens={TaskSubmitStyles.InnerOpTokens}>
                        <TextField
                          label="CC EmailAddress"
                          styles={TaskSubmitStyles.InputRootWidth}
                          value={recipient[index]}
                          inputClassName={requestIndexPrefix + index}
                          onChange={onRecipientChange}
                          suffix="@microsoft.com"
                        />
                      </Stack>
                      <ActionButton
                        disabled={recipient[index]?.length === 0}
                        iconProps={{ iconName: "AddToShoppingList" }}
                        text="add to CC"
                        className={requestIndexPrefix + index}
                        onClick={AddRecipientToList}
                      />
                    </Stack>
                  </Stack.Item>
                  <Stack.Item>
                    {recipients[index]?.length > 0 ? (
                      recipients[index].map((item, idx) => (
                        <span style={{ "marginRight": "20px" }}>
                          <Text>{item["recipient"]}</Text>
                          <IconButton
                            className={ActionColumnClassNames.ActionIcon}
                            iconProps={{ iconName: "Cancel" }}
                            onClick={() => {
                              const filtered = recipients[index].filter(
                                (i) => i.t_id !== item.t_id
                              );

                              const tmpRecipients = cloneDeep(recipients);
                              tmpRecipients[index] = filtered;
                              setRecipients(tmpRecipients);
                            }}
                          />
                        </span>
                      ))
                    ) : (
                      <Text>{Resources.Tasks.MissingCCHint}</Text>
                    )}
                  </Stack.Item>
                  <ComboBox
                    label="Auto Merge Option"
                    selectedKey={autoMergePR[index]}
                    onChange={(_event, option) => {
                      const tmp = cloneDeep(autoMergePR);
                      tmp[index] = option.key;
                      setautoMergePR(tmp);
                    }}
                    options={[
                      {
                        key: "DIRECT_AUTO_MERGE",
                        text: "DIRECTLY",
                        className: index,
                      },
                      {
                        key: "AUTO_MERGE_AFTER_FEEDBACK_CYCLE",
                        text: "AFTER_FEEDBACK_CYCLE",
                        className: index,
                      },
                      {
                        key: "DO_NOT_AUTO_MERGE",
                        text: "NEVER",
                        className: index,
                      },
                    ]}
                    styles={TaskSubmitStyles.InputRootWidth}
                  />
                  {showTeamSelector ?  
                    <ComboBox
                    label="Team"
                    selectedKey={sourceTeam[index]}
                    onChange={(_event, option) => {
                      const tmp = cloneDeep(sourceTeam);
                      tmp[index] = option.key;
                      setSourceTeam(tmp);
                      const tmplist = cloneDeep(templateListFiltered);
                      tmplist[index] = templateList.filter((i) => i.team == option.key)
                      setTemplateListFiltered(tmplist);
                      const tmpTemplate = cloneDeep(templateConfigId);
                      tmpTemplate[index] = "";
                      setTemplateConfigId(tmpTemplate);
                      }}
                    options={teamList}
                    styles={TaskSubmitStyles.TeamListWidth}
                    />
                    : null }
                    <ComboBox
                    label="Template"
                    options={templateListFiltered[index]?.length > 0 ? templateListFiltered[index]: templateList}
                    styles={TaskSubmitStyles.TemplateListWidth}
                    selectedKey={templateConfigId[index]}
                    required={templateConfigId[index]?.length === 0}
                    onChange={(_event, option) => {
                      const tmp = cloneDeep(templateConfigId);
                      tmp[index] = option.key;
                      setTemplateConfigId(tmp);
                      }}
                    errorMessage={templateList?.length>0 ? null : "There is no template available, please set in repo config otherwise default template will be used."}
                    />
                </div>
              </Stack>
              <Pivot
                aria-label="Basic Pivot Example"
                onLinkClick={TabSwitch}
                className={requestIndexPrefix + index}
                selectedKey={tabValue[index]}
              >
                <PivotItem
                  itemKey={tabUsage}
                  headerText={tabUsage}
                  headerButtonProps={{
                    "data-order": 1,
                  }}
                >
                  <Stack.Item>
                    <ActionButton
                      iconProps={{ iconName: "Add" }}
                      allowDisabledFocus
                      className={requestIndexPrefix + index}
                      onClick={ToggleEditor}
                    >
                      {Resources.Tasks.AddOpBtnText}
                    </ActionButton>
                    <Stack
                      styles={{
                        root: { display: showEditor[index] ? "block" : "none" },
                      }}
                      tokens={TaskSubmitStyles.DefaultOpContainerTokens}
                    >
                      <Stack horizontal tokens={TaskSubmitStyles.InnerOpTokens}>
                        <ComboBox
                          useComboBoxAsMenuWidth={true}
                          selectedKey={op[index]}
                          onChange={onOpTypeChange}
                          label="Operation type"
                          options={[
                            { key: "Delete", text: "Delete", className: index },
                            { key: "Set", text: "Set", className: index },
                          ]}
                          styles={TaskSubmitStyles.InputRootWidth}
                        />
                      </Stack>
                      <Stack horizontal tokens={TaskSubmitStyles.InnerOpTokens}>
                        <TextField
                          label="Metadata key"
                          required={metaKey[index]?.length === 0}
                          styles={TaskSubmitStyles.InputRootWidth}
                          value={metaKey[index]}
                          inputClassName={requestIndexPrefix + index}
                          onChange={onMetaKeyChange}
                        />
                        <TextField
                          label="Metadata value"
                          required={metaValue[index]?.length === 0}
                          styles={TaskSubmitStyles.InputRootWidth}
                          value={metaValue[index]}
                          inputClassName={requestIndexPrefix + index}
                          onChange={onMetaValueChange}
                        />
                      </Stack>
                      <ActionButton
                        disabled={
                          metaKey[index]?.length === 0 ||
                          metaValue[index]?.length === 0
                        }
                        iconProps={{ iconName: "AddToShoppingList" }}
                        text="add to list"
                        className={requestIndexPrefix + index}
                        onClick={AddItemToList}
                      />
                    </Stack>
                  </Stack.Item>
                  <Stack.Item>
                    {items[index]?.length > 0 ? (
                      <DetailsList
                        setKey="t_id"
                        selectionMode={SelectionMode.none}
                        columns={[
                          {
                            key: "Action",
                            name: "",
                            className:
                              ActionColumnClassNames.ActionIconHeaderIcon,
                            iconClassName:
                              ActionColumnClassNames.ActionIconCell,
                            fieldName: "t_id",
                            onRender: (item, _index) => (
                              <IconButton
                                className={ActionColumnClassNames.ActionIcon}
                                iconProps={{ iconName: "Delete" }}
                                onClick={() => {
                                  const filtered = items[index].filter(
                                    (i) => i.t_id !== item.t_id
                                  );
                                  const filteredConditions = conditions[
                                    index
                                  ].filter((c) => c.relatedOp !== item.t_id);

                                  const tmpConditions = cloneDeep(conditions);
                                  tmpConditions[index] = filteredConditions;
                                  setConditions(tmpConditions);

                                  const tmpItems = cloneDeep(items);
                                  tmpItems[index] = filtered;
                                  setItems(tmpItems);
                                }}
                              />
                            ),
                            maxWidth: 50,
                            minWidth: 30,
                          },
                          {
                            key: "Op",
                            name: "Operation",
                            fieldName: "op",
                            maxWidth: 150,
                            minWidth: 50,
                          },
                          {
                            key: "metaKey",
                            name: "Metadata key",
                            fieldName: "metaKey",
                            minWidth: 100,
                            maxWidth: 200,
                          },
                          {
                            key: "metaValue",
                            name: "Metadata value",
                            fieldName: "metaValue",
                            minWidth: 100,
                            maxWidth: 300,
                          },
                        ]}
                        items={items[index]}
                      />
                    ) : (
                      <Text>{Resources.Tasks.MissingOpHint}</Text>
                    )}
                  </Stack.Item>
                  <Stack.Item>
                    <ActionButton
                      iconProps={{ iconName: "Add" }}
                      onClick={ToggleConditionEditor}
                      className={requestIndexPrefix + index}
                    >
                      {Resources.Tasks.AddConditionBtnText}
                    </ActionButton>
                    <Stack
                      styles={{
                        root: {
                          display: showConditionEditor[index]
                            ? "block"
                            : "none",
                        },
                      }}
                      tokens={TaskSubmitStyles.DefaultOpContainerTokens}
                    >
                      <Stack horizontal tokens={TaskSubmitStyles.InnerOpTokens}>
                        <ComboBox
                          useComboBoxAsMenuWidth={true}
                          selectedKey={con[index]}
                          onChange={onConTypeChange}
                          label="Condition type"
                          options={[
                            { key: "==", text: "==", className: index },
                            { key: "<>", text: "<>", className: index },
                            {
                              key: "Existent",
                              text: "Existent",
                              className: index,
                            },
                            {
                              key: "NonExistent",
                              text: "NonExistent",
                              className: index,
                            },
                          ]}
                          styles={TaskSubmitStyles.InputRootWidth}
                        />
                      </Stack>
                      <Stack horizontal tokens={TaskSubmitStyles.InnerOpTokens}>
                        <TextField
                          label="Condition Metadata Key"
                          required={conditionKey[index]?.length === 0}
                          styles={TaskSubmitStyles.InputRootWidth}
                          value={conditionKey[index]}
                          onChange={onConditionKeyChange}
                          inputClassName={requestIndexPrefix + index}
                        />
                        <TextField
                          label="Condition Metadata Value"
                          required={conditionValue[index]?.length === 0}
                          styles={{
                            root: {
                              display:
                                con[index] == "==" || con[index] == "<>"
                                  ? "block"
                                  : "none",
                              width: 300,
                            },
                          }}
                          value={conditionValue[index]}
                          onChange={onConditionValueChange}
                          inputClassName={requestIndexPrefix + index}
                        />
                      </Stack>
                      <ActionButton
                        disabled={
                          ((con[index] == "==" || con[index] == "<>") &&
                            (conditionKey[index]?.length === 0 ||
                              conditionValue[index]?.length === 0)) ||
                          ((con[index] == "Existent" ||
                            con[index] == "NonExistent") &&
                            conditionKey[index]?.length === 0)
                        }
                        iconProps={{ iconName: "AddToShoppingList" }}
                        text="add to condition list"
                        className={requestIndexPrefix + index}
                        onClick={AddConditionToList}
                      />
                    </Stack>
                  </Stack.Item>
                  <Stack.Item>
                    {conditions[index]?.length > 0 ? (
                      <DetailsList
                        setKey="t_id"
                        selectionMode={SelectionMode.none}
                        columns={[
                          {
                            key: "Action",
                            name: "",
                            className:
                              ActionColumnClassNames.ActionIconHeaderIcon,
                            iconClassName:
                              ActionColumnClassNames.ActionIconCell,
                            fieldName: "t_id",
                            onRender: (item, _index) => (
                              <IconButton
                                className={ActionColumnClassNames.ActionIcon}
                                iconProps={{ iconName: "Delete" }}
                                disabled={!!item.relatedOp}
                                onClick={() => {
                                  if (!!item.relatedOp) {
                                    return;
                                  }
                                  const filtered = conditions[index].filter(
                                    (i) => i.t_id !== item.t_id
                                  );

                                  const tmpConditions = cloneDeep(conditions);
                                  tmpConditions[index] = filtered;
                                  setConditions(tmpConditions);
                                }}
                              />
                            ),
                            maxWidth: 50,
                            minWidth: 30,
                          },
                          {
                            key: "con",
                            name: "Condition type",
                            fieldName: "con",
                            minWidth: 100,
                            maxWidth: 200,
                          },
                          {
                            key: "conditionKey",
                            name: "Condition key",
                            fieldName: "conditionKey",
                            minWidth: 100,
                            maxWidth: 200,
                          },
                          {
                            key: "conditionValue",
                            name: "Condition value",
                            fieldName: "conditionValue",
                            minWidth: 100,
                            maxWidth: 300,
                          },
                        ]}
                        items={conditions[index]}
                      />
                    ) : (
                      <Text>{Resources.Tasks.MissingConditionHint}</Text>
                    )}
                  </Stack.Item>
                </PivotItem>
                <PivotItem
                  itemKey={taxonomySDP}
                  headerText={taxonomySDP}
                  headerButtonProps={{
                    "data-order": 1,
                  }}
                >
                  <Stack.Item>
                    <ActionButton
                      iconProps={{ iconName: "Add" }}
                      allowDisabledFocus
                      className={requestIndexPrefix + index}
                      onClick={ToggleEditor}
                    >
                      {Resources.Tasks.AddOpBtnText}
                    </ActionButton>
                    <Stack
                      styles={{
                        root: { display: showEditor[index] ? "block" : "none" },
                      }}
                      tokens={TaskSubmitStyles.DefaultOpContainerTokens}
                    >
                      <Stack horizontal tokens={TaskSubmitStyles.InnerOpTokens}>
                        <ComboBox
                          useComboBoxAsMenuWidth={true}
                          selectedKey={op[index]}
                          onChange={onOpTypeChange}
                          label="Operation type"
                          options={[
                            { key: "Delete", text: "Delete", className: index },
                            { key: "Set", text: "Set", className: index },
                          ]}
                          styles={TaskSubmitStyles.InputRootWidth}
                        />
                      </Stack>
                      <Stack horizontal tokens={TaskSubmitStyles.InnerOpTokens}>
                      <ComboBox
                          useComboBoxAsMenuWidth={true}
                          selectedKey={metaKey[index]}
                          required={metaKey[index]?.length === 0}
                          onChange={(_, value) => {
                            const tmpMetaKey = cloneDeep(metaKey);
                            tmpMetaKey[index] = value.key;
                            setMetaKey(tmpMetaKey);
                          }}
                          label="Taxonomy Name"
                          options={taxonomyList}
                          styles={TaskSubmitStyles.InputRootWidth}
                        />
                        <TextField
                          label="Taxonomy Term Value"
                          required={metaValue[index]?.length === 0}
                          styles={TaskSubmitStyles.InputRootWidth}
                          value={metaValue[index]}
                          inputClassName={requestIndexPrefix + index}
                          onChange={onMetaValueChange}
                        />
                      </Stack>
                      <ActionButton
                        disabled={
                          metaKey[index]?.length === 0 ||
                          metaValue[index]?.length === 0
                        }
                        iconProps={{ iconName: "AddToShoppingList" }}
                        text="add to list"
                        className={requestIndexPrefix + index}
                        onClick={AddItemToList}
                      />
                    </Stack>
                  </Stack.Item>
                  <Stack.Item>
                    {items[index]?.length > 0 ? (
                      <DetailsList
                        setKey="t_id"
                        selectionMode={SelectionMode.none}
                        columns={[
                          {
                            key: "Action",
                            name: "",
                            className:
                              ActionColumnClassNames.ActionIconHeaderIcon,
                            iconClassName:
                              ActionColumnClassNames.ActionIconCell,
                            fieldName: "t_id",
                            onRender: (item, _index) => (
                              <IconButton
                                className={ActionColumnClassNames.ActionIcon}
                                iconProps={{ iconName: "Delete" }}
                                onClick={() => {
                                  const filtered = items[index].filter(
                                    (i) => i.t_id !== item.t_id
                                  );
                                  const filteredConditions = conditions[
                                    index
                                  ].filter((c) => c.relatedOp !== item.t_id);

                                  const tmpConditions = cloneDeep(conditions);
                                  tmpConditions[index] = filteredConditions;
                                  setConditions(tmpConditions);

                                  const tmpItems = cloneDeep(items);
                                  tmpItems[index] = filtered;
                                  setItems(tmpItems);
                                }}
                              />
                            ),
                            maxWidth: 50,
                            minWidth: 30,
                          },
                          {
                            key: "Op",
                            name: "Operation",
                            fieldName: "op",
                            maxWidth: 150,
                            minWidth: 50,
                          },
                          {
                            key: "metaKey",
                            name: "Taxonomy Name",
                            fieldName: "metaKey",
                            minWidth: 100,
                            maxWidth: 200,
                          },
                          {
                            key: "metaValue",
                            name: "Taxonomy Term Value",
                            fieldName: "metaValue",
                            minWidth: 100,
                            maxWidth: 300,
                          },
                        ]}
                        items={items[index]}
                      />
                    ) : (
                      <Text>{Resources.Tasks.MissingOpHint}</Text>
                    )}
                  </Stack.Item>
                  <Stack.Item>
                    <ActionButton
                      iconProps={{ iconName: "Add" }}
                      onClick={ToggleConditionEditor}
                      className={requestIndexPrefix + index}
                    >
                      {Resources.Tasks.AddConditionBtnText}
                    </ActionButton>
                    <Stack
                      styles={{
                        root: {
                          display: showConditionEditor[index]
                            ? "block"
                            : "none",
                        },
                      }}
                      tokens={TaskSubmitStyles.DefaultOpContainerTokens}
                    >
                      <Stack horizontal tokens={TaskSubmitStyles.InnerOpTokens}>
                        <ComboBox
                          useComboBoxAsMenuWidth={true}
                          selectedKey={con[index]}
                          onChange={onConTypeChange}
                          label="Condition type"
                          options={[
                            { key: "==", text: "==", className: index },
                          ]}
                          styles={TaskSubmitStyles.InputRootWidth}
                        />
                      </Stack>
                      <Stack horizontal tokens={TaskSubmitStyles.InnerOpTokens}>
                        <ComboBox
                          useComboBoxAsMenuWidth={true}
                          selectedKey={conditionKey[index]}
                          required={conditionKey[index]?.length === 0}
                          onChange={(_, value) => {
                            const tmpConditionKey = cloneDeep(conditionKey);
                            tmpConditionKey[index] = value.key;
                            setConditionKey(tmpConditionKey);
                          }}
                          label="Condition Taxonomy Name"
                          options={taxonomyList}
                          styles={TaskSubmitStyles.InputRootWidth}
                        />
                        <TextField
                          label="Condition Taxonomy Term Value"
                          required={conditionValue[index]?.length === 0}
                          styles={{
                            root: {
                              display:
                                con[index] == "==" || con[index] == "<>"
                                  ? "block"
                                  : "none",
                              width: 300,
                            },
                          }}
                          value={conditionValue[index]}
                          onChange={onConditionValueChange}
                          inputClassName={requestIndexPrefix + index}
                        />
                      </Stack>
                      <ActionButton
                        disabled={
                          ((con[index] == "==" || con[index] == "<>") &&
                            (conditionKey[index]?.length === 0 ||
                              conditionValue[index]?.length === 0)) ||
                          ((con[index] == "Existent" ||
                            con[index] == "NonExistent") &&
                            conditionKey[index]?.length === 0)
                        }
                        iconProps={{ iconName: "AddToShoppingList" }}
                        text="add to condition list"
                        className={requestIndexPrefix + index}
                        onClick={AddConditionToList}
                      />
                    </Stack>
                  </Stack.Item>
                  <Stack.Item>
                    {conditions[index]?.length > 0 ? (
                      <DetailsList
                        setKey="t_id"
                        selectionMode={SelectionMode.none}
                        columns={[
                          {
                            key: "Action",
                            name: "",
                            className:
                              ActionColumnClassNames.ActionIconHeaderIcon,
                            iconClassName:
                              ActionColumnClassNames.ActionIconCell,
                            fieldName: "t_id",
                            onRender: (item, _index) => (
                              <IconButton
                                className={ActionColumnClassNames.ActionIcon}
                                iconProps={{ iconName: "Delete" }}
                                disabled={!!item.relatedOp}
                                onClick={() => {
                                  if (!!item.relatedOp) {
                                    return;
                                  }
                                  const filtered = conditions[index].filter(
                                    (i) => i.t_id !== item.t_id
                                  );

                                  const tmpConditions = cloneDeep(conditions);
                                  tmpConditions[index] = filtered;
                                  setConditions(tmpConditions);
                                }}
                              />
                            ),
                            maxWidth: 50,
                            minWidth: 30,
                          },
                          {
                            key: "con",
                            name: "Condition type",
                            fieldName: "con",
                            minWidth: 100,
                            maxWidth: 200,
                          },
                          {
                            key: "conditionKey",
                            name: "Taxonomy  Name",
                            fieldName: "conditionKey",
                            minWidth: 100,
                            maxWidth: 200,
                          },
                          {
                            key: "conditionValue",
                            name: "Taxonomy Term Value",
                            fieldName: "conditionValue",
                            minWidth: 100,
                            maxWidth: 300,
                          },
                        ]}
                        items={conditions[index]}
                      />
                    ) : (
                      <Text>{Resources.Tasks.MissingConditionHint}</Text>
                    )}
                  </Stack.Item>
                </PivotItem>
                <PivotItem itemKey={tabFilelist} headerText={tabFilelist}>
                  <Stack align="start" horizontal>
                    <TextField
                      label={fileListContentUrl}
                      required={fileListContentUrlInput[index]?.length === 0}
                      styles={TaskSubmitStyles.InputRepoName}
                      value={fileListContentUrlInput[index]}
                      onChange={(_, newVal) => {
                        const tmpFileListContentUrlInput = cloneDeep(
                          fileListContentUrlInput
                        );
                        tmpFileListContentUrlInput[index] = newVal;
                        setFileListContentUrlInput(tmpFileListContentUrlInput);
                      }}
                      errorMessage={fileListErrorMessage[index].length > 0 ?  <div dangerouslySetInnerHTML={{__html: fileListErrorMessage[index]}}></div> : fileListErrorMessage[index]}
                    />
                    <StackItem styles={{root: { "margin-top": "29px" }}}>
                    <DefaultButton
                      onClick={() => {
                        AddFileListContentMetadata(index);
                        RefreshFileListMetadataValues(index);
                      }}
                      disabled={fileListContentUrlInput[index]?.length === 0}
                    >
                      Add
                    </DefaultButton>
                    </StackItem>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      accept=".csv"
                      id={"filelist-uploader-" + index}
                      onChange={(event) => {
                        let button = document.getElementById(
                          "Import-" + index
                        );
                        button.disabled = true;
                        button.children[0].children[0].children[0].innerText = "Loading";
                        button.className = "ms-Button ms-Button--default is-disabled root-164"
                        const file = event.target.files[0];
                        var fileReader = new FileReader();
                        fileReader.onload = (ev) => {
                          try {
                            if (!_.endsWith(file.name, ".csv")) {
                              throw new Error("only support .csv file");
                            }
                            var csv = ev.target.result;
                            var json = CSVToArray(csv);

                            let paths = [];
                            _.forEach(json, (path) => {
                              path &&
                                path[0] &&
                                path[0].trim().length > 0 &&
                                paths.push(path[0]);
                            });
                            BatchAddFileListContentMetadata(index, paths);
                          } catch (error) {
                            MessageActions.errorHandler(error);
                          }
                        };
                        fileReader.readAsText(file, "utf-8");
                        event.target.value = null;
                        button.disabled = false;
                        button.children[0].children[0].children[0].innerText = "Import CSV";
                        button.className = "ms-Button ms-Button--default root-164"
                      }}
                    />
                    <StackItem styles={{root: { "margin-top": "29px" }}}>
                    <DefaultButton
                      style={{ "margin-left": "30px" }}
                      id={"Import-" + index}
                      onClick={(e) => {
                        let uploader = document.getElementById(
                          "filelist-uploader-" + index
                        );
                        uploader.click();
                      }}
                    >
                      Import CSV
                    </DefaultButton>
                    </StackItem>
                  </Stack>
                  <Stack.Item>
                    {fileListMetadatas[index]?.length > 0 ? (
                      <DetailsList
                        setKey="t_id"
                        selectionMode={SelectionMode.none}
                        columns={[
                          {
                            key: "Action",
                            name: "",
                            className:
                              ActionColumnClassNames.ActionIconHeaderIcon,
                            iconClassName:
                              ActionColumnClassNames.ActionIconCell,
                            fieldName: "t_id",
                            onRender: (item, _index) => (
                              <IconButton
                                className={ActionColumnClassNames.ActionIcon}
                                iconProps={{ iconName: "Delete" }}
                                onClick={() => {
                                  const tmpFileListMetadatas =
                                    cloneDeep(fileListMetadatas);
                                  tmpFileListMetadatas[index].splice(_index, 1);
                                  setFileListMetadatas(tmpFileListMetadatas);
                                }}
                              />
                            ),
                            maxWidth: 50,
                            minWidth: 30,
                          },
                          {
                            key: fileListContentUrl,
                            name: fileListContentUrl,
                            fieldName: fileListContentUrl,
                            minWidth: 100,
                            maxWidth: 200,
                          },
                          ...fileListMetadataKeys[index],
                        ]}
                        onRenderItemColumn={FileMetadataRenderItemColumn}
                        items={fileListMetadatas[index]}
                      />
                    ) : (
                      ""
                    )}
                  </Stack.Item>
                  <Stack.Item>
                    <ActionButton
                      iconProps={{ iconName: "Add" }}
                      allowDisabledFocus
                      onClick={() => {
                        AddFileListOperations(index);
                      }}
                    >
                      {Resources.Tasks.AddOpBtnText}
                    </ActionButton>
                  </Stack.Item>
                  {fileListOprationNums[index].map(
                    (fileListOperationValue, fileListOperationIndex) => {
                      return (
                        <Stack.Item
                          styles={{
                            root: {
                              "border-top": "dashed black",
                              "margin-top": "15px",
                            },
                          }}
                        >
                          <Stack
                            tokens={TaskSubmitStyles.DefaultOpContainerTokens}
                          >
                            <Stack
                              styles={{
                                root: {
                                  display:
                                    operationsFileList[index][
                                      fileListOperationIndex
                                    ]?.length > 0
                                      ? "none"
                                      : "block",
                                },
                              }}
                            >
                              <Stack
                                horizontal
                                tokens={TaskSubmitStyles.InnerOpTokens}
                              >
                                <ComboBox
                                  useComboBoxAsMenuWidth={true}
                                  selectedKey={
                                    opFileList[index][fileListOperationIndex]
                                  }
                                  onChange={(_, value) => {
                                    const tmpOpFileList = cloneDeep(opFileList);
                                    tmpOpFileList[index][
                                      fileListOperationIndex
                                    ] = value.key;
                                    setOpFileList(tmpOpFileList);
                                  }}
                                  label="Operation type"
                                  options={[
                                    { key: "Delete", text: "Delete" },
                                    { key: "Set", text: "Set" },
                                  ]}
                                  styles={TaskSubmitStyles.InputRootWidth}
                                />
                                {fileListOperationIndex > 0 ? (
                                  <IconButton
                                    styles={{
                                      root: {
                                        marginTop: "20px",
                                        marginLeft: "15px",
                                      },
                                    }}
                                    iconProps={{ iconName: "Delete" }}
                                    onClick={() => {
                                      DeleteFileListOperations(
                                        index,
                                        fileListOperationIndex
                                      );
                                      RefreshFileListMetadataValues(index);
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </Stack>
                              <Stack
                                horizontal
                                tokens={TaskSubmitStyles.InnerOpTokens}
                              >
                                <TextField
                                  label="Metadata key"
                                  required={
                                    metaKeyFileList[index][
                                      fileListOperationIndex
                                    ]?.length === 0
                                  }
                                  styles={TaskSubmitStyles.InputRootWidth}
                                  value={
                                    metaKeyFileList[index][
                                      fileListOperationIndex
                                    ]
                                  }
                                  onChange={(_, value) => {
                                    const tmpMetaKeyFileList =
                                      cloneDeep(metaKeyFileList);
                                    tmpMetaKeyFileList[index][
                                      fileListOperationIndex
                                    ] = value;
                                    setMetaKeyFileList(tmpMetaKeyFileList);
                                  }}
                                />
                                <TextField
                                  label="Metadata value"
                                  required={
                                    metaValueFileList[index][
                                      fileListOperationIndex
                                    ]?.length === 0
                                  }
                                  styles={TaskSubmitStyles.InputRootWidth}
                                  value={
                                    metaValueFileList[index][
                                      fileListOperationIndex
                                    ]
                                  }
                                  onChange={(_, value) => {
                                    const tmpMetaValueFileList =
                                      cloneDeep(metaValueFileList);
                                    tmpMetaValueFileList[index][
                                      fileListOperationIndex
                                    ] = value;
                                    setMetaValueFileList(tmpMetaValueFileList);
                                  }}
                                />
                              </Stack>
                              <ActionButton
                                disabled={
                                  metaKeyFileList[index][fileListOperationIndex]
                                    ?.length === 0 ||
                                  metaValueFileList[index][
                                    fileListOperationIndex
                                  ]?.length === 0
                                }
                                iconProps={{ iconName: "AddToShoppingList" }}
                                text="add operation"
                                onClick={() => {
                                  const metaOpId = _.uniqueId(
                                    "metaOpFileList" + fileListOperationIndex
                                  );
                                  const operation = {
                                    op: opFileList[index][
                                      fileListOperationIndex
                                    ],
                                    metaKey:
                                      metaKeyFileList[index][
                                        fileListOperationIndex
                                      ],
                                    metaValue:
                                      metaValueFileList[index][
                                        fileListOperationIndex
                                      ],
                                    //isArrayValue: false,
                                    t_id: metaOpId,
                                  };
                                  operationsFileList[index][
                                    fileListOperationIndex
                                  ].push(operation);

                                  if (operation.op === "Delete") {
                                    const condition = {
                                      con: "==",
                                      conditionKey: operation.metaKey,
                                      conditionValue: operation.metaValue,
                                      relatedOp: metaOpId,
                                      t_id: _.uniqueId(
                                        "metaConditionFileList" +
                                          fileListOperationIndex
                                      ),
                                    };
                                    conditionsFileList[index][
                                      fileListOperationIndex
                                    ].push(condition);
                                  }
                                  ResetFileListOpEditor(
                                    index,
                                    fileListOperationIndex
                                  );
                                  if (
                                    opFileList[index][fileListOperationIndex] ==
                                    "Set"
                                  ) {
                                    RefreshFileListContentMetadataKeys(index, [
                                      {
                                        value:
                                          metaKeyFileList[index][
                                            fileListOperationIndex
                                          ],
                                        isArray: false,
                                      },
                                    ]);
                                  }
                                  RefreshFileListMetadataValues(index);
                                }}
                              />
                            </Stack>
                          </Stack>
                          <stack>
                            {operationsFileList[index][fileListOperationIndex]
                              ?.length > 0 ? (
                              <DetailsList
                                setKey="t_id"
                                selectionMode={SelectionMode.none}
                                columns={[
                                  {
                                    key: "Action",
                                    name: "",
                                    className:
                                      ActionColumnClassNames.ActionIconHeaderIcon,
                                    iconClassName:
                                      ActionColumnClassNames.ActionIconCell,
                                    fieldName: "t_id",
                                    onRender: (_item, _index) => (
                                      <IconButton
                                        className={
                                          ActionColumnClassNames.ActionIcon
                                        }
                                        iconProps={{ iconName: "Delete" }}
                                        onClick={() => {
                                          const filteredConditions =
                                            conditionsFileList[index][
                                              fileListOperationIndex
                                            ].filter(
                                              (c) => c.relatedOp !== _item.t_id
                                            );

                                          const tmpConditionsFileList =
                                            cloneDeep(conditionsFileList);
                                          tmpConditionsFileList[index][
                                            fileListOperationIndex
                                          ] = filteredConditions;
                                          setConditionsFileList(
                                            tmpConditionsFileList
                                          );

                                          operationsFileList[index][
                                            fileListOperationIndex
                                          ].splice(_index, 1);

                                          RefreshFileListMetadataValues(index);
                                        }}
                                      />
                                    ),
                                    maxWidth: 50,
                                    minWidth: 30,
                                  },
                                  {
                                    key: "Op",
                                    name: "Operation",
                                    fieldName: "op",
                                    maxWidth: 150,
                                    minWidth: 50,
                                  },
                                  {
                                    key: "metaKey",
                                    name: "Metadata key",
                                    fieldName: "metaKey",
                                    minWidth: 100,
                                    maxWidth: 200,
                                  },
                                  {
                                    key: "metaValue",
                                    name: "Metadata value",
                                    fieldName: "metaValue",
                                    minWidth: 100,
                                    maxWidth: 300,
                                  },
                                ]}
                                items={
                                  operationsFileList[index][
                                    fileListOperationIndex
                                  ]
                                }
                              />
                            ) : (
                              ""
                            )}
                          </stack>
                          <ActionButton
                            iconProps={{ iconName: "Add" }}
                            onClick={() => {
                              const tmpShowFileListConditionEditor = cloneDeep(
                                showFileListConditionEditor
                              );
                              tmpShowFileListConditionEditor[index][
                                fileListOperationIndex
                              ] =
                                !tmpShowFileListConditionEditor[index][
                                  fileListOperationIndex
                                ];
                              setShowFileListConditionEditor(
                                tmpShowFileListConditionEditor
                              );
                            }}
                          >
                            Condition of operation
                          </ActionButton>
                          <Stack
                            styles={{
                              root: {
                                display: showFileListConditionEditor[index][
                                  fileListOperationIndex
                                ]
                                  ? "block"
                                  : "none",
                              },
                            }}
                            tokens={TaskSubmitStyles.DefaultOpContainerTokens}
                          >
                            <Stack
                              horizontal
                              tokens={TaskSubmitStyles.InnerOpTokens}
                            >
                              <ComboBox
                                useComboBoxAsMenuWidth={true}
                                selectedKey={conFileList[index][fileListOperationIndex]}
                                onChange={(_, value) => {
                                  const tmpConFileList = cloneDeep(conFileList);
                                  tmpConFileList[index][
                                    fileListOperationIndex
                                  ] = value.key;
                                  setConFileList(tmpConFileList);
                                }}
                                label="Condition type"
                                options={[
                                  { key: "==", text: "==", className: index },
                                  { key: "<>", text: "<>", className: index },
                                  {
                                    key: "Existent",
                                    text: "Existent",
                                    className: index,
                                  },
                                  {
                                    key: "NonExistent",
                                    text: "NonExistent",
                                    className: index,
                                  },
                                ]}
                                styles={TaskSubmitStyles.InputRootWidth}
                              />
                            </Stack>
                            <Stack
                              horizontal
                              tokens={TaskSubmitStyles.InnerOpTokens}
                            >
                              <TextField
                                label="Condition Metadata Key"
                                required={
                                  conditionKeyFileList[index][
                                    fileListOperationIndex
                                  ]?.length === 0
                                }
                                styles={TaskSubmitStyles.InputRootWidth}
                                value={
                                  conditionKeyFileList[index][
                                    fileListOperationIndex
                                  ]
                                }
                                onChange={(_, value) => {
                                  const tmpConditionKeyFileList =
                                    cloneDeep(conditionKeyFileList);
                                  tmpConditionKeyFileList[index][
                                    fileListOperationIndex
                                  ] = value;
                                  setConditionKeyFileList(
                                    tmpConditionKeyFileList
                                  );
                                }}
                              />
                              <TextField
                                label="Condition Metadata Value"
                                required={
                                  conditionValueFileList[index][
                                    fileListOperationIndex
                                  ]?.length === 0
                                }
                                styles={{
                                  root: {
                                    display:
                                      conFileList[index][fileListOperationIndex] == "==" ||
                                      conFileList[index][fileListOperationIndex] == "<>"
                                        ? "block"
                                        : "none",
                                    width: 300,
                                  },
                                }}
                                value={
                                  conditionValueFileList[index][
                                    fileListOperationIndex
                                  ]
                                }
                                onChange={(_, value) => {
                                  const tmpConditionValueFileList = cloneDeep(
                                    conditionValueFileList
                                  );
                                  tmpConditionValueFileList[index][
                                    fileListOperationIndex
                                  ] = value;
                                  setConditionValueFileList(
                                    tmpConditionValueFileList
                                  );
                                }}
                              />
                            </Stack>
                            <ActionButton
                              disabled={
                                ((conFileList[index][fileListOperationIndex] == "==" ||
                                  conFileList[index][fileListOperationIndex] == "<>") &&
                                  (conditionKeyFileList[index][
                                    fileListOperationIndex
                                  ]?.length === 0 ||
                                    conditionValueFileList[index][
                                      fileListOperationIndex
                                    ]?.length === 0)) ||
                                ((conFileList[index][fileListOperationIndex] == "Existent" ||
                                  conFileList[index][fileListOperationIndex] == "NonExistent") &&
                                  conditionKeyFileList[index][
                                    fileListOperationIndex
                                  ]?.length === 0)
                              }
                              iconProps={{ iconName: "AddToShoppingList" }}
                              text="add to condition list"
                              onClick={() => {
                                const condition = {
                                  con: conFileList[index][
                                    fileListOperationIndex
                                  ],
                                  conditionKey:
                                    conditionKeyFileList[index][
                                      fileListOperationIndex
                                    ],
                                  conditionValue:
                                    conditionValueFileList[index][
                                      fileListOperationIndex
                                    ],
                                  isArrayValue: false,
                                  t_id: _.uniqueId("metaConditionFileList"),
                                };

                                if (
                                  condition.con == "Existent" ||
                                  condition.con == "NonExistent"
                                ) {
                                  condition.conditionValue = "n/a";
                                }

                                conditionsFileList[index][
                                  fileListOperationIndex
                                ].push(condition);
                                ResetFileListConditionEditor(
                                  index,
                                  fileListOperationIndex
                                );
                                RefreshFileListMetadataValues(index);
                              }}
                            />
                          </Stack>
                          {conditionsFileList[index][fileListOperationIndex]
                            ?.length > 0 ? (
                            <DetailsList
                              setKey="t_id"
                              selectionMode={SelectionMode.none}
                              columns={[
                                {
                                  key: "Action",
                                  name: "",
                                  className:
                                    ActionColumnClassNames.ActionIconHeaderIcon,
                                  iconClassName:
                                    ActionColumnClassNames.ActionIconCell,
                                  fieldName: "t_id",
                                  onRender: (item, _index) => (
                                    <IconButton
                                      className={
                                        ActionColumnClassNames.ActionIcon
                                      }
                                      iconProps={{ iconName: "Delete" }}
                                      disabled={!!item.relatedOp}
                                      onClick={() => {
                                        if (!!item.relatedOp) {
                                          return;
                                        }
                                        const tmpConditionsFileList =
                                          cloneDeep(conditionsFileList);
                                        tmpConditionsFileList[index][
                                          fileListOperationIndex
                                        ].splice(_index, 1);
                                        setConditionsFileList(
                                          tmpConditionsFileList
                                        );
                                        RefreshFileListMetadataValues(index);
                                      }}
                                    />
                                  ),
                                  maxWidth: 50,
                                  minWidth: 30,
                                },
                                {
                                  key: "con",
                                  name: "Condition type",
                                  fieldName: "con",
                                  minWidth: 100,
                                  maxWidth: 200,
                                },
                                {
                                  key: "conditionKey",
                                  name: "Condition key",
                                  fieldName: "conditionKey",
                                  minWidth: 100,
                                  maxWidth: 200,
                                },
                                {
                                  key: "conditionValue",
                                  name: "Condition value",
                                  fieldName: "conditionValue",
                                  minWidth: 100,
                                  maxWidth: 300,
                                },
                              ]}
                              items={
                                conditionsFileList[index][
                                  fileListOperationIndex
                                ]
                              }
                            />
                          ) : (
                            <Text
                              styles={{
                                root: {
                                  display: "block",
                                },
                              }}
                            >
                              {Resources.Tasks.MissingConditionHint}
                            </Text>
                          )}
                        </Stack.Item>
                      );
                    }
                  )}
                </PivotItem>

                <PivotItem headerText={tabRepoFolder} itemKey={tabRepoFolder}>
                  <Stack.Item align="end">
                    <TextField
                      label="Repo name"
                      required={repoNameValue[index]?.length === 0}
                      styles={TaskSubmitStyles.InputRepoName}
                      value={repoNameValue[index]}
                      onChange={onRepoNameValueChange}
                      placeholder="repoOrganization/repoName"
                      inputClassName={requestIndexPrefix + index}
                    />
                    <DefaultButton
                      onClick={GetDocfxList}
                      style={{ padding: 0 }}
                      className={requestIndexPrefix + index}
                      disabled={repoNameValue[index]?.length === 0}
                    >
                      Search
                    </DefaultButton>
                  </Stack.Item>
                  <Stack.Item>
                    {filepaths[index]?.length > 0 ? (
                      <div>
                        <ComboBox
                          useComboBoxAsMenuWidth={true}
                          onChange={onFilePathChange}
                          label="Docfx file"
                          styles={TaskSubmitStyles.InputRootWidth}
                          options={filepaths[index].map((item) => ({
                            key: item,
                            text: item,
                            className: index,
                          }))}
                          selectedKey={selectFilepath[index]}
                        />
                        <Text
                          as="h3"
                          variant="large"
                          styles={TaskSubmitStyles.metadataTitle}
                        >
                          Repo metadata
                        </Text>
                        {Object.entries(globalMetadata[index]).map(
                          ([key, value]) => {
                            return (
                              <TextField
                                label={key}
                                styles={TaskSubmitStyles.InputMetadataWidth}
                                value={value}
                                onChange={onGlobalMetadataChange}
                                inputClassName={
                                  globalMetadataPrefix +
                                  key +
                                  " " +
                                  requestIndexPrefix +
                                  index
                                }
                              />
                            );
                          }
                        )}
                        <Text
                          as="h3"
                          variant="large"
                          styles={TaskSubmitStyles.metadataTitle}
                        >
                          Folder metadata
                        </Text>
                        {Object.entries(fileMetadata[index]).map(
                          ([key, value]) => {
                            return (
                              <div>
                                <Text
                                  as="h5"
                                  variant="large"
                                  styles={TaskSubmitStyles.metadataSubTitle}
                                >
                                  {key}
                                </Text>
                                {Object.entries(value).map(
                                  ([subKey, subValue]) => {
                                    return (
                                      <TextField
                                        label={subKey}
                                        styles={
                                          TaskSubmitStyles.InputMetadataWidth
                                        }
                                        value={subValue}
                                        onChange={onFileMetadataChange}
                                        inputClassName={
                                          fileMetadataPrefix +
                                          key +
                                          fileMetadataKeySubkeySeparator +
                                          subKey +
                                          " " +
                                          requestIndexPrefix +
                                          index
                                        }
                                      />
                                    );
                                  }
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </Stack.Item>
                </PivotItem>
              </Pivot>
            </Stack>
          );
        })}
        <Stack.Item>
          <PrimaryButton
            text="Submit"
            onClick={SubmitOperations}
            disabled={SubmitDisabled()}
          />
        </Stack.Item>
      </Stack>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={toggleHideDialog}
            text={Resources.Tasks.DialogBackBtnText}
          />
          <DefaultButton
            onClick={() => {
              toggleHideDialog();
              MetadataFieldCheck();
            }}
            text={Resources.Tasks.DialogProceedBtnText}
          />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={hideFieldCheckDialog}
        onDismiss={toggleHideFieldCheckDialog}
        dialogContentProps={dialogFieldContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={toggleHideFieldCheckDialog}
            text={Resources.Tasks.DialogBackBtnText}
          />
          <DefaultButton
            onClick={() => {
              toggleHideFieldCheckDialog();
              FinalSubmit();
            }}
            text={Resources.Tasks.DialogProceedBtnText}
          />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={hideSubmitDialog}
        onDismiss={toggleHideSubmitDialog}
        dialogContentProps={isTaskCloseLoading ? dialogSubmitContentProps : dialogSubmitSuccessContentProps}
        modalProps={modalProps}
      >
        {isTaskCloseLoading ? <ReactLoading type={"spinningBubbles"} color={"black"} height={'20%'} width={'20%'} /> : null}
        <DialogFooter>
          <PrimaryButton
            onClick={() => {history.push("/tasks");}}
            text="OK"
            disabled={isTaskCloseLoading}
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default memo(AddOperation);
