export default {
  TaxonomyApp: {
    endpoint: '/',
    clientId: '91430780-2651-4aa6-982d-8616f5a26158',
    authority: 'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47',
  },
  TaskApp:{
    endpoint:'https://metadatamanagement-public-hpbyfkbnhrh9c3aq.z01.azurefd.net',
    metadataListReport: 'https://msit.powerbi.com/reportEmbed?reportId=ac1ecbfe-eec9-472c-b5f3-5ed028c371b1&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47',
    conceptReport:'https://msit.powerbi.com/reportEmbed?reportId=07855390-e61e-4e92-a48c-6a952f963b15&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47',
  },
  RagApp:{
    contentProxyClientIdURI: 'https://content-proxy-publicreader.learn.microsoft.com',
    contentProxyEndpoint: 'https://contentproxypublic-c3gthxgjcnc0h0d7.b02.azurefd.net/',
  }
};
