import EventEmitter from 'events';

const EventNames = {
  ShowMessageBar: 'ShowMessageBar',
};

class SimpleEventClient {
  constructor() {
    this.eventEmitter = new EventEmitter();
  }

  on(eventName, listener) {
    this.eventEmitter.on(eventName, listener);
  }

  addEventListener(eventName, listener) {
    this.eventEmitter.on(eventName, listener);
  }

  removeEventListener(eventName, listener) {
    this.eventEmitter.removeListener(eventName, listener);
  }

  emit(event, payload, error = false) {
    this.eventEmitter.emit(event, payload, error);
  }
}

const EventClient = new SimpleEventClient();

export { EventClient, EventNames };
