import dayjs from 'dayjs';
import React from 'react';
import { ExportToCsv } from 'export-to-csv';
import { CommandBarButton, Stack } from '@fluentui/react';

import { btnContainerStyle } from '../Common/customStyle'
import { dateTimeForFile } from '../Common/constants';
import Resources from '../Common/resources';
import _ from 'lodash';

class DownloadTableButton extends React.Component {
    constructor(props) {
        super(props);
        
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const {currentTopic, toItem, taxonomies, columns} = this.props;
        if (!currentTopic || !toItem || !taxonomies || !columns) {
            return;
        }

        var fileTitle = currentTopic + '_job_' + dayjs(toItem.startedAt).format(dateTimeForFile);
        var dataTitle = 'version:' + toItem.version;
        const csvColumns = columns.map((column, index)=>{
            return column.name;
        })
        const dataItems = taxonomies.map((taxonomy, index) => {
            var dataItem = {};
            columns.forEach((column, index) => {
                if (taxonomy.hasOwnProperty(column.fieldName)) {
                    let cell = taxonomy[column.fieldName];
                    if(_.isArray(cell)) {
                        cell = cell.join(";")
                    }
                    dataItem[column.name] = cell;
                } else {
                    dataItem[column.name] = '';
                }              
            })

            return dataItem;
        })
           
        const options = { 
            filename: fileTitle, 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            showTitle: true,
            title: dataTitle,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
        };
           
          const csvExporter = new ExportToCsv(options);
           
          csvExporter.generateCsv(dataItems);
    }

    render() {
        return (<Stack horizontal styles={btnContainerStyle}>
            <CommandBarButton
              iconProps={{ iconName: 'Download' }}
              text={Resources.Common.download}
              onClick={this.handleClick}
            />
          </Stack>);
    }
}

export default DownloadTableButton;