import { useEffect, useState, memo, useCallback } from "react";
import BackHomeButton from "../../Components/BackHomeButton";
import RepoConfigAPI from "../../Common/API/repoConfigApi";
import { useParams, useHistory } from "react-router-dom";
import {
  Text,
  Stack,
  Toggle,
  ScrollablePane,
  GroupedList,
  IconButton,
  Spinner,
  SpinnerSize,
  ComboBox,
} from "@fluentui/react";
import _ from "lodash";
import Resources from "../../Common/resources";
import { MessageActions } from "../../Common/Utils/Message";

const emptyArray = [];

const RepoStyles = {
  Container: { position: "relative", height: "100vh" },
  InnerScroll: { position: "absolute", top: "150px", bottom: "50px" },
  Spinner: { marginTop: "250px" },
  Title: { root: { textAlign: "center" } },
  SubmitButton: { root: { width: 150 } },
  RepoName: { fontSize: 14, margin: 10 },
  InputRootWidth: { root: { width: 300 } },
};

// all repos
const reposMap = {};

const RepoConfig = () => {
  const DropDownAll = "ALL";

  const [isLoading, setLoading] = useState(false);

  // repos show in list
  const [showRepos, setShowRepos] = useState(emptyArray);

  // repoOwner drop list
  const [repoOwners, setRepoOwners] = useState(emptyArray);

  // repoName drop list
  const [repoNames, setRepoNames] = useState(emptyArray);

  const { routeRepoOwner, routeRepoName } = useParams();

  const [repoOwner, setRepoOwner] = useState(
    routeRepoOwner ? routeRepoOwner : DropDownAll
  );

  const [repoName, setRepoName] = useState(
    routeRepoName ? routeRepoName : DropDownAll
  );

  const onRepoOwnerChange = (_, newVal) => {
    setRepoOwner(newVal.key);
    RefreshRepoNames(newVal.key);
    RefreshShowRepos(newVal.key, DropDownAll);
    setRepoName(DropDownAll);
  };

  const onRepoNameChange = (_, newVal) => {
    setRepoName(newVal.key);
    RefreshShowRepos(repoOwner, newVal.key);
  };

  const UpdateConfigItem = async (
    id,
    canBypassBranchPolicy,
    canNotification,
    pRAutoMergeOption
  ) => {
    try {
      let data = {
        canBypassBranchPolicy: canBypassBranchPolicy,
        canNotification: canNotification,
        pRAutoMergeOption: pRAutoMergeOption,
      };
      await RepoConfigAPI.updateConfigItem(id, data);
      const idx = showRepos.findIndex((g) => g.id === id);
      let newRepos = _.cloneDeep(showRepos);
      newRepos[idx].configItem = data;
      setShowRepos(newRepos);
    } catch (error) {
      MessageActions.errorHandler(error);
    }
  };

  const RefreshShowRepos = (tmpRepoOwner, tmpRepoName) => {
    const repos = [];
    Object.entries(reposMap).map(([owner, repoObjs]) => {
      repoObjs.map((repoObj) => {
        if (
          (!tmpRepoOwner || tmpRepoOwner == DropDownAll || tmpRepoOwner == owner) &&
          (!tmpRepoName ||
            tmpRepoName == DropDownAll ||
            tmpRepoName == repoObj["repoName"])
        ) {
          repos.push(repoObj);
        }
      });
    });

    repos.sort(function (x, y) {
      if (
        x["repoOwner"] > y["repoOwner"] ||
        (x["repoOwner"] == y["repoOwner"] && x["repoName"] > y["repoName"])
      ) {
        return 1;
      }
      return -1;
    });

    setShowRepos(repos);
  };

  const GetRepoNamesByRepoOwner = (tmpRepoOwner) => {
    const tmpRepoNames = [];
    reposMap[tmpRepoOwner].map((repoObj) => {
      tmpRepoNames.push(repoObj["repoName"]);
    });
    return tmpRepoNames;
  };

  const InitReposMap = (repolist) => {
    repolist.map((item) => {
      const owner = item["repoOwner"];
      if (!(owner in reposMap)) {
        reposMap[owner] = [];
      }
      reposMap[owner].push(item);
    });
    
  };

  const RefreshRepoOwners = () => {
    const tmpRepoOwners = [];
    Object.entries(reposMap).map(([owner, repoObjs]) => {
      tmpRepoOwners.push(owner);
    });
    const uniqueRepoOwners = [...new Set(tmpRepoOwners)];
    uniqueRepoOwners.sort();
    setRepoOwners([DropDownAll, ...uniqueRepoOwners]);
  };

  const RefreshRepoNames = (tmpRepoOwner) => {
    const tmpRepoNames = [];
    if (!tmpRepoOwner || tmpRepoOwner == DropDownAll) {
      Object.entries(reposMap).map(([owner, repoObjs]) => {
        tmpRepoNames.push(...GetRepoNamesByRepoOwner(owner));
      });
    } else {
      tmpRepoNames.push(...GetRepoNamesByRepoOwner(tmpRepoOwner));
    }
    
    const uniqueRepoNames = [...new Set(tmpRepoNames)];
    uniqueRepoNames.sort();
    
    setRepoNames([DropDownAll, ...uniqueRepoNames]);
  };

  const initFn = async () => {
    try {
      setLoading(true);
      const repoDetail = await RepoConfigAPI.getRepoConfigs();
      InitReposMap(repoDetail.data);
      RefreshShowRepos(repoOwner,repoName);
      RefreshRepoOwners();
      RefreshRepoNames();
      setLoading(false);
    } catch (error) {
      MessageActions.errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    initFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createGroups = (items) => {
    const groups = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      groups[i] = {
        key: `${item.id}`,
        name: `Repo: ${item.repoOwner}/${item.repoName}`,
        startIndex: i,
        count: 1,
        level: 0,
      };
    }
    return groups;
  };

  const onRenderCell = (_, item, _itemIndex) => {
    return item ? (
      <>
        <div role="row" key="BranchPolicy">
          <span role="cell">
            <Toggle
              label="Bypass BranchPolicy"
              checked={item?.configItem.canBypassBranchPolicy}
              inlineLabel
              onText="On"
              offText="Off"
              onChange={() => {
                UpdateConfigItem(
                  item?.id,
                  !item?.configItem.canBypassBranchPolicy,
                  item?.configItem.canNotification,
                  item?.configItem.pRAutoMergeOption
                );
              }}
            />
          </span>
        </div>
        <div role="row" key="Notification">
          <span role="cell">
            <Toggle
              label="Notification"
              checked={item?.configItem.canNotification}
              inlineLabel
              onText="On"
              offText="Off"
              onChange={() => {
                UpdateConfigItem(
                  item?.id,
                  item?.configItem.canBypassBranchPolicy,
                  !item?.configItem.canNotification,
                  item?.configItem.pRAutoMergeOption
                );
              }}
            />
          </span>
        </div>
      </>
    ) : null;
  };

  const groupProps = {
    onRenderHeader: (props) => {
      if (props) {
        const toggleCollapse = () => {
          props.onToggleCollapse(props.group);
        };
        return (
          <div style={RepoStyles.RepoName}>
            <IconButton
              iconProps={
                !props.group.isCollapsed
                  ? { iconName: "ChevronDown" }
                  : { iconName: "ChevronRight" }
              }
              onClick={toggleCollapse}
            />
            {props.group.name}
          </div>
        );
      }

      return null;
    },
  };

  const repoConfigGroups = createGroups(showRepos);
  const history = useHistory();

  return (
    <div style={RepoStyles.Container}>
      <BackHomeButton
        text={Resources.Tasks.BackToTasklist}
        backFn={() => {
          history.go(-1);
        }}
      />
      <Text variant={"xxLarge"} styles={RepoStyles.Title} block>
        {Resources.RepoConfig.RepoConfigTitle}
      </Text>
      <Stack tokens={{ padding: "m" }}>
        {isLoading ? (
          <Spinner size={SpinnerSize.large} style={RepoStyles.Spinner} />
        ) : (
          <ScrollablePane style={RepoStyles.InnerScroll}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <ComboBox
                useComboBoxAsMenuWidth={true}
                onChange={onRepoOwnerChange}
                label={Resources.RepoConfig.SearchRepoConfigByRepoOwner}
                styles={RepoStyles.InputRootWidth}
                options={repoOwners.map((item) => ({ key: item, text: item }))}
                selectedKey={repoOwner}
              />
              <ComboBox
                useComboBoxAsMenuWidth={true}
                onChange={onRepoNameChange}
                label={Resources.RepoConfig.SearchRepoConfigByRepoName}
                styles={RepoStyles.InputRootWidth}
                options={repoNames.map((item) => ({ key: item, text: item }))}
                selectedKey={repoName}
              />
            </Stack>

            <Stack tokens={{ padding: "20px 10px" }}>
              <GroupedList
                items={showRepos}
                groups={repoConfigGroups}
                onRenderCell={onRenderCell}
                groupProps={groupProps}
              />
            </Stack>

            {!showRepos.length && (
              <Stack horizontalAlign="center">
                <Text>No repos of filtered status</Text>
              </Stack>
            )}
          </ScrollablePane>
        )}
      </Stack>
    </div>
  );
};

export default memo(RepoConfig);
