const Resources = {
  Common: {
    back: "Back Home",
    download: "Download as CSV",
    privacy: "MS Data Privacy Notice",
  },
  Login: {
    SignIn: "Sign In",
    SignOut: "Sign Out",
    loginRequired: "User login is required to access the content",
    loginInProgress: "Login in progress ...",
    loginNotifyModalTitleAriaId: "login notify modal",
  },
  ManualTriggerDialog: {
    title: (mdOnly) => {
      return mdOnly ? "Update Contribution guide" : "Manual Trigger";
    },
    subText: (mdOnly) => {
      return mdOnly
        ? "Update contribution guide succeeded"
        : "Import job succeeded";
    },
    closeButtonAriaLabel: "Close",
    Ok: "Ok",
    ImportSucceeded: (mdOnly) => {
      return mdOnly
        ? "update contribution guide succeeded"
        : "import succeeded";
    },
    ImportErrorMessage: (mdOnly) => {
      return `${
        mdOnly ? "update contribution guide failed" : "Import failed"
      }, please refresh current page and go to the failure report of latest failed job to see the details`;
    },
  },
  Jobs: {
    LastSuccessLabel: "Last success job",
    Trigger: "Trigger",
    Cancel: "Cancel",
    SelectProjects: "Select projects to import",
    SelectProjectsDescription:
      "You can trigger manual import job to target taxonomy projects.",
  },
  JobForceImportConfirm: {
    Confirm: "Confirm",
    SubText: "Are you sure you want to force import?",
    Label: "Force import",
    OnText: "On",
    OffText: "Off"
    },
    SubTasksConfirm: {
        Confirm: "Confirm",
        rerun: "This operation will recreate a PR and when you wait a few minutes and refresh the page you will see a new PR.",
        rerunDisable: "Only abandoned PR can do rerun operation.",
        taskRerun: "This operation will rerun the task, and when you click confirm, you will be redirected to a new task detail page.",
        taskRerunDisable: "The status of the task needs to be Finished.",
        taskClose: "This operation will abandon the task.",
        taskCloseDisable: "This operation is not available for finished task.",
        reopen: "This operation will reopen the PR.",
        reopenDisable: "Only abandoned PR can do reopen operation.",
        close: "This operation will abandon the PR.",
        closeDisable: "This operation is not available for PRs that have merged or abandoned.",
        merge: "This operation will merge the PR.",
        mergeDisable: "This PR cannot be merged.",
        skip: "This operation will change the PR status from Abandon to Skip, which means that we cannot re-open this PR, and the process of this PR is completely over.",
        skipDisable: "Only abandoned PR can do skip operation.",
        queryData: "The query page will open and query data using the conditions of the current task.",
    },
  AdminPage: {
    PageHeader: "Taxonomy Service Admin Page",
    ManualTriggerBtn: (mdOnly) => {
      return mdOnly ? "Update Contribution Guide" : "Manual Trigger";
    },
    ariaLabelForShimmer: "Content is being fetched",
    ariaLabelForGrid: "Job details",
  },
  JobErrorReport: {
    PageHeader: "Import Error Report",
  },
  JobDetails: {
    pivotArialLabel: "Switch between the different topic of taxonomies",
    partialSuccessHint:
      "Taxonomies in red contain errors that need to be resolved, their values are copied from the last successful version.",
  },
  Labels: {
    startedAt: "Started Time:",
    compareWithLastJob: "Compared with last success Job:",
    jobId: "JobId:",
    status: "Status:",
  },
  TopMessage: {
    UnKnownError: "Unknown error occurred!",
    GetJobFailed: "Failed to get job info of this failure report",
  },
  Tasks: {
    TaskEntryBtnText: "Manage tasks",
    TaskListTopTitle: "Task List",
    TaskDetailLinkText: "Detail",
    TaskDetailTopTitle: "Task Detail",
    TaskDetailSubTitleTask: "Task",
    TaskDetailSubTitleSubTask: "Subtasks",
    TaskSubmitTopTitle: "Submit metadata operations",
    DashboardTitle: "Impact Dashboard",
    UnsavedDialogTitle: "Unsaved changes",
    UnsavedDialogMsg:
      "Do you want to submit without current editing operation?",
    AddOpBtnText: "Operation",
    AddConditionBtnText: "Condition",
    MissingOpHint: "No operations added yet",
    MissingCCHint: "No CC Recipient added yet",
    MissingConditionHint: "No conditions added yet",
    DialogBackBtnText: "Back to Check",
    DialogFieldsTitle: "Metadata not in template!",
    DialogFieldsText: "Metadata fields involved in this task are not included in the chosen template.",
    DialogSubmitTitle: "Submit Task",
    DialogSubmitText: "Task submitted, please wait...",
    DialogSubmitSuccessText: "Task submitted successfully!",
    DialogProceedBtnText: "Save anyway",
    BackToTasklist: "Back",
    TaskSubmitted: "Operations Submitted",
    NotValidAdoLink:
      "It is not a valid ado link, please copy the link from browser and try again",
    AdoLinkNotEndWithNumber:
      "ado link should be ended with number, please update and try again",
    TasksStatusFilterLabel: "Filter tasks by status",
    SearchAdoLabel: "Search by ado number",
    SearchAdoErrorMessage: "value can only contain number",
    NoTasks: "No tasks of filtered status",
    PRsAreBuilding: "Pull Requests are building",
    PRsAreWaiting: "Pull Requests are waiting for trigger",
    SucceededRetagging: "Created retagging requests",
    FailedRetagging: "Failed retagging requests",
    MetadataKey: "Metadata Key",
    MetadataValue: "Metadata Value",
    SetAdoLink: "Set ado link for task",
    SetNewAdoLink: "Set new ado link for task"
  },
  RepoConfig: {
    RepoConfigTitle: "Repo Config",
    SearchRepoConfigByRepoOwner: "Repo Organization",
    SearchRepoConfigByRepoName: "Repo Name",
    PullRequestStatusBlocked: "Blocked",
  },
  MetadataDetail: {
    MetadataListTopTitle: "Metadata List",
    MetadataDetailTitle: "Azure Document | Microsoft Learn",
    NoMetadatas: "No Metadata Loaded",
    NoMetadataFields: "Please select a metadata field and run query",
    NoGeneratedMetadatas: "No Generated Metadata Loaded",
  },
  Myself: {
    MyselfTitle: "Myself",
  },
  Concepts: {
    ConceptsTitle: "Taxonomy LifeCycle Manager"
  }
};

export default Resources;
