import React from 'react';
import { CommandBarButton, Stack } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { btnContainerStyle } from '../Common/customStyle'
import Resources from '../Common/resources';

const BackHomeButton = ({ text, backFn }) => {
  const history = useHistory();
  return (<Stack horizontal styles={btnContainerStyle}>
    <CommandBarButton
      id="ManualTriggerJob"
      iconProps={{ iconName: 'Back' }}
      text={text || Resources.Common.back}
      onClick={() => {
        if(backFn){
          backFn();
        } else {
          history.push('/');
        }
      }}
    />
  </Stack>);
}

export default BackHomeButton;