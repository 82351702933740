export default () => {
  var origin = window.location.origin;
  if (!origin) {
    // Workaround: Internet Explorer does not have access to window.location.origin variable.
    origin =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '');
  }
  return origin;
};
