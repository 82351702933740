import React from 'react';
import { IconButton } from '@fluentui/react';
const Collapse = ({ header, renderHeader, collapsed, children }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(collapsed);

  const style = {
    collapsed: {
      display: 'none'
    },
    expanded: {
      display: 'block'
    },
    buttonStyle: {
      height: '32px'
    }
  };

  const collapseHeader = renderHeader ? renderHeader(isCollapsed, setIsCollapsed) : <div>
    {header}
    <IconButton style={style.buttonStyle} iconProps={isCollapsed ? { iconName: 'ChevronDown' } : { iconName: 'ChevronUp' }}
      title="toggle sub slugs" onClick={() => setIsCollapsed(!isCollapsed)}>
    </IconButton>
  </div>;

  return (
    <div>
      {collapseHeader}
      <div
        className="collapse-content"
        style={isCollapsed ? style.collapsed : style.expanded}
        aria-expanded={isCollapsed}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapse;