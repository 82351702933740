import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { mergeStyleSets, getTheme, ScrollablePane } from "@fluentui/react";
import Resources from "../Common/resources";

const theme = getTheme();
const TaxonomyMetadataTaskList = ({ metadataTasks, topic }) => {
  const mmsTxStyles = mergeStyleSets({
    Container: {
      // marginTop: 100,
      position: "relative",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.neutralQuaternaryAlt,
      padding: ".25em",
      minHeight: "20vh",
      maxHeight: 350,
    },
    SuccessPart: [],
    ErrorPart: [
      {
        background: "rgba(255, 0, 0, 0.15)",
      },
    ],
    Content: {
      padding: ".5em",
    },
    ItemContent: {
      marginBottom: ".25em",
      borderBottomStyle: "solid",
      borderBottomColor: theme.palette.neutralLighterAlt,
      borderBottomWidth: "1px",
      padding: ".25em",
    },
    Title: {
      marginTop: ".5em",
      marginBottom: ".25em",
      paddingLeft: ".25em",
      paddingTop: ".25em",
      fontSize: 16,
    },
    SubTitle: {
      marginBottom: ".25em",
      fontWeight: "bold",
    },
  });

  if (_.isArray(metadataTasks) && metadataTasks.length > 0) {
    const createdTasks = metadataTasks.filter((t) => {
      if (topic) {
        return t.StatusCode < 300 && t.Taxonomy === topic;
      } else {
        return t.StatusCode < 300;
      }
    });
    const failedTasks = metadataTasks.filter((t) => {
      if (topic) {
        return t.StatusCode >= 300 && t.Taxonomy === topic;
      } else {
        return t.StatusCode >= 300;
      }
    });
    const succeededPart = createdTasks.length > 0 && (
      <div className={mmsTxStyles.SuccessPart}>
        <h4 className={mmsTxStyles.Title}>
          {Resources.Tasks.SucceededRetagging}
        </h4>
        <div className={mmsTxStyles.Content}>
          {createdTasks.map((taskResponse) => {
            return (
              <div className={mmsTxStyles.ItemContent}>
                <a href={taskResponse.Content} target="_blank">{taskResponse.Title}</a>
              </div>
            );
          })}
        </div>
      </div>
    );
    const failedPart = failedTasks.length > 0 && (
      <div className={mmsTxStyles.ErrorPart}>
        <h4 className={mmsTxStyles.Title}>{Resources.Tasks.FailedRetagging}</h4>
        <div className={mmsTxStyles.Content}>
          {failedTasks.map((taskResponse) => {
            return (
              <div className={mmsTxStyles.ItemContent}>
                <p className={mmsTxStyles.SubTitle}>{taskResponse.Title}</p>
                <p>{taskResponse.Content}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
    return (
      <div className={mmsTxStyles.Container}>
        <ScrollablePane scrollContainerFocus={true}>
          {succeededPart}
          {failedPart}
        </ScrollablePane>
      </div>
    );
  }

  return null;
};
export default React.memo(TaxonomyMetadataTaskList);
