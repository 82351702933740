import DEV_CONFIG from './dev';
import PUBDEV_CONFIG from './pubdev';
import PUBLIC_CONFIG from './public';

const url = window.location.href;
let envId = '';
let configs = DEV_CONFIG;

if (url.indexOf('://localhost') > 0) {
    envId = 'dev';
    configs = DEV_CONFIG;
} else if (url.indexOf('-pubdev') > 0 || url.indexOf('ppe.') > 0 || url.indexOf('dev.') > 0) {
    envId = 'pubdev';
    configs = PUBDEV_CONFIG;
} else {
    envId = 'public';
    configs = PUBLIC_CONFIG;
}

export { envId, configs };
