import _ from "lodash";
import { NumberRegex } from "../constants";

// we consider all valid url with ado host to be a valid ado link, but actually it could be much more complex if necessary.
export const isValidAdoLink = (link) => {
  const adoHosts = ["dev.azure.com", "visualstudio.com"];
  let url;
  try {
    url = new URL(link);
  } catch (_) {
    return false;
  }

  return (
    url.protocol === "https:" &&
    adoHosts.some((h) => _.endsWith(url.hostname, h))
  );
};

export const isAdoLinkEndWithNumber = (link)=>{
  const endOfLink = _.trimEnd(link, "/").split("/").pop();
  return NumberRegex.test(endOfLink);
};

export default isValidAdoLink;
