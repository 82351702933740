import _ from "lodash";

const getMetadataTask = (importStatusString) => {
  let tasks = null;
  if (_.isString(importStatusString)) {
    let splitIndex = importStatusString.indexOf("|");
    let tasksString = "";
    if (splitIndex > -1) {
      tasksString = importStatusString.slice(splitIndex + 1);
    } else {
      tasksString = importStatusString;
    }
    try {
      tasks = JSON.parse(tasksString);
    } catch (ex) {
      console.warn("invalid input" + ex);
    }
  }
  if (_.isArray(importStatusString)) {
    tasks = importStatusString;
  }
  return _.isArray(tasks) ? tasks : null;
};

export default getMetadataTask;
