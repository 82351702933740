import React, { useEffect } from "react";
import { Dropdown, Stack, Text } from "@fluentui/react";
import _ from "lodash";
import ManagementAPI from "../Common/API/managementAPI";
import { useState } from "react";

/* eslint-disable react/jsx-no-bind */
const stackStyles = {
  root: {
    display: "inline-flex",
    position: "relative",
    width: 220,
  },
};

const dropdownStyles = {
  root: [
    {
      selectors: {
        ".ms-Label": {
          float: "left",
          marginRight: "0.5em",
        },
      },
    },
    { width: 120 },
  ],
};

const labelStyles = { root: { lineHeight: "30px", fontWeight: 600 } };
const LabelText = "Select a locale";

const LocalesSelector = ({ locale, topic, setLocale }) => {
  const [locales, setLocales] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (_, item) => {
    setLocale(item.key);
  };

  useEffect(() => {
    const namesPromise = ManagementAPI.getLocalizableTaxonomyNames();
    const localesPromise = ManagementAPI.getLocales();
    Promise.all([namesPromise, localesPromise])
      .then((res) => {
        const topics = _.compact(_.get(res, "[0].data", []));
        const locales = _.compact(_.get(res, "[1].data", []));
        if (_.isEmpty(topics) || _.isEmpty(locales)) {
          setIsVisible(false);
          return;
        }
        if (_.includes(topics, topic)) {
          setIsVisible(true);
          setLocales(
            locales.map((l) => {
              return { key: l, text: l };
            })
          );
        } else {
          if (locale !== "en-us") {
            setLocale && setLocale("en-us");
          }
        }
      })
      .catch((error) => {
        setIsVisible(false);
        console.log(error);
        if (locale !== "en-us") {
          setLocale && setLocale("en-us");
        }
      });
  }, [topic]);

  return isVisible ? (
    <Stack horizontal styles={stackStyles} horizontalAlign="space-between">
      <Text styles={labelStyles} block>
        {LabelText}
      </Text>
      <Dropdown
        placeholder={LabelText}
        selectedKey={locale}
        onChange={onChange}
        options={locales}
        styles={dropdownStyles}
      />
    </Stack>
  ) : null;
};

export default React.memo(LocalesSelector);
