
import { MessageActions } from './Utils/Message';
import Resources from './resources';
import _ from 'lodash';
import { xml2js } from 'xml-js';
const ResponseErrorHandler = (error, customUnKnownError) => {
  let errorMessage;
  const unKnownErrorMsg = Resources.TopMessage.UnKnownError;
  if (error.response) {
    errorMessage =_.get(error, "response.data.error", unKnownErrorMsg);
    if (_.isString(error.response.data)) {
      if(error.response.data.includes("<?xml")) {
        errorMessage = _.get(xml2js(error.response.data, { compact: true }), ['Error', 'Message', '_text'], unKnownErrorMsg);;
      } else {
        errorMessage = error.response.data
      }
    }

  } else {
    if (_.isFunction(_.get(error, 'toJSON'))) {
      errorMessage = error.toJSON();
    } else if (_.isString(error)) {
      errorMessage = error;
    }
  }
  errorMessage = errorMessage || unKnownErrorMsg;
  if (errorMessage === unKnownErrorMsg && customUnKnownError) {
    MessageActions.error(customUnKnownError);
  } else {
    MessageActions.error(errorMessage);
  }
}

export default ResponseErrorHandler;