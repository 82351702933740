import React from "react";
import { initializeIcons, mergeStyles } from "@fluentui/react";
import "./index.css";
import { HashRouter as Router } from "react-router-dom"; 
import Taxonomy from "./Taxonomy";
import TopMessage from "./Components/TopMessage";
import withAuth from "./Common/Auth/authProvider";
import { LoginSection, SignOutButton } from "./Components/loginSection";
import { mainContaier, footer } from "./Common/customStyle";
import "core-js/proposals/url";
import "core-js/full/url-search-params"
import Resources from "./Common/resources";

initializeIcons();
const App = (props) => {
  const { roles, error , userId} = props;
  if (error) {
    return <LoginSection {...props} />;
  }
  return (
    <Router>
      <div className={mergeStyles(mainContaier)}>
        <TopMessage />
        <SignOutButton {...props} />
        <Taxonomy roles={roles} userId={userId} />
        <footer className={mergeStyles(footer)}>
          <a
            href="http://go.microsoft.com/fwlink/?LinkId=518021"
            target="_blank"
            rel="noopener noreferrer"
            title= {Resources.Common.privacy}
          >
            {Resources.Common.privacy}
          </a>
        </footer>
      </div>
   </Router>
  );
};

export default withAuth(App);
