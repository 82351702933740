import { memo, useMemo, useCallback } from "react";
import {
  DefaultButton,
  ShimmeredDetailsList,
  SelectionMode,
  Stack,
  Text,
  Toggle,
  mergeStyleSets,
  IconButton,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  TooltipHost,
  Checkbox,
  ComboBox,
  TextField,
  mergeStyles,
} from "@fluentui/react";
import { SharedColors } from "@fluentui/theme";
import { useBoolean, useId } from "@uifabric/react-hooks";
import _ from "lodash";
import Resources from "../../Common/resources";
import TaskAPI from "../../Common/API/taskApi";
import { MessageActions } from "../../Common/Utils/Message";
import { useState } from "react";
import {
  isValidAdoLink,
  isAdoLinkEndWithNumber,
} from "../../Common/Utils/adoLinkValidator";
import { expandCell } from "../../Common/customStyle";
import ReactLoading from 'react-loading';
import { useHistory } from "react-router-dom";

const classNames = mergeStyleSets({
  operationDisableStatus: {
    backgroundColor: "transparent",
    cursor: "not-allowed",
    i: {
      color: "rgb(210, 208, 206)",
    },
  },
  itemCenter: {
    alignItems: "center",
    display: "inline-flex!important",
  },
  componentCenter: {
    display: "inline-flex!important",
    alignItems: "flex-end",
  },
});
const renderItemColumn = (item, _index, column) => {
  let content = item[column.fieldName];
  let customStyles = mergeStyles(expandCell);
  return <span className={customStyles}>{content}</span>;
};
const Task = (props) => {
  const { task, isLoading, userId } = props;
  const [title, setTitle] = useState("");
  const [subText, setSubText] = useState("");
  const [confirmFunc, setConfirmFunc] = useState({});
  const [isTaskCloseLoading, setIsTaskCloseLoading] = useState(true);
  const [isTaskCloseFailed, setIsTaskCloseFailed] = useState(false);
  const useSubTasksConfirmDialog = () => {
    const [
      hideSubTasksConfirmDialog,
      { toggle: toggleHideSubTasksConfirmDialog },
    ] = useBoolean(true);
    const [
      hideTaskConfirmDialog,
      { toggle: toggleHideTaskConfirmDialog },
    ] = useBoolean(true);
    const labelId = useId("dialogLabel");
    const subTextId = useId("subTextLabel");
    const subTasksConfirmDialogContentProps = {
      title: title,
      type: DialogType.normal,
      subText: subText,
    };

    const subTasksConfirmModalProps = useMemo(
      () => ({
        titleAriaId: labelId,
        subtitleAriaId: subTextId,
        isBlocking: true,
        styles: { main: { maxWidth: 350 } },
      }),
      [labelId, subTextId]
    );

    return [
      hideSubTasksConfirmDialog,
      toggleHideSubTasksConfirmDialog,
      hideTaskConfirmDialog,
      toggleHideTaskConfirmDialog,
      subTasksConfirmDialogContentProps,
      subTasksConfirmModalProps,
    ];
  };
  const [
    hideSubTasksConfirmDialog,
    toggleHideSubTasksConfirmDialog,
    hideTaskConfirmDialog,
    toggleHideTaskConfirmDialog,
    subTasksConfirmDialogContentProps,
    subTasksConfirmModalProps,
  ] = useSubTasksConfirmDialog();

  const useAdoLinkConfirmDialog = () => {
    const [hideAdoLinkDialog, { toggle: toggleHideAdoLinkDialog }] =
      useBoolean(true);
    const [hideNewAdoLinkDialog, { toggle: toggleHideNewAdoLinkDialog }] =
      useBoolean(true);
    const [adoLinkDialogDefaultSubText] = useState(
      "Below source will be impacted by this task. Please double confirm if changes are valid.\n"
    );
    const [adoLinkDialogSubText, setAdoLinkDialogSubText] = useState(
      adoLinkDialogDefaultSubText
    );
    const [adoLink, setAdoLink] = useState("");
    const [triggerOption, setTriggerOption] = useState(false);
    const [currentTaskId, setCurrentTaskId] = useState("");

    const adoLinkDialogContentProps = {
      type: DialogType.normal,
      title: "Adding AdoLink",
      subText: adoLinkDialogSubText,
    };

    const newAdoLinkDialogContentProps = {
      type: DialogType.normal,
      title: "Changing AdoLink",
    };

    const adoLinkModalProps = useMemo(() => ({
      isBlocking: true,
      styles: { main: { maxWidth: 440, whiteSpace: "pre-wrap" } },
    }));

    const onAdoLinkChange = (_, newVal) => {
      setAdoLink(newVal);
    };

    const onTriggerOptionChange = () => {
      setTriggerOption(!triggerOption);
    };

    const initDetail = useCallback(async (id) => {
      try {
        var taskDetail = await TaskAPI.getTaskDetail(id);
        var subText = adoLinkDialogDefaultSubText;
        taskDetail.data.subTasks.forEach((e) => {
          subText =
            subText +
            "\nrepo:  " +
            (e.repo ? e.repo : "") +
            "\nImpactDataAmount:  " +
            (e.estimateCount ? e.estimateCount : "") +
            "\n";
        });
        setAdoLinkDialogSubText(subText);
      } catch (error) {
        MessageActions.errorHandler(error);
      }
    });

    const attachLink = useCallback(async () => {
      try {
        const attachRequest = {
          adoLink,
          triggerOption,
        };
        var res = await TaskAPI.attachAdoLink(attachRequest, currentTaskId);
        window.location.reload();
      } catch (error) {
        MessageActions.errorHandler(error);
      }
      resetDialog();
    });

    const attachNewLink = useCallback(async () => {
      try {
        const attachRequest = {
          adoLink,
          triggerOption,
        };
        var res = await TaskAPI.attachAdoLink(attachRequest, currentTaskId);
        window.location.reload();
      } catch (error) {
        MessageActions.errorHandler(error);
      }
      resetNewLinkDialog();
    });

    const setDialogContent = (id) => {
      resetDialog(false);
      setCurrentTaskId(id);
      initDetail(id);
    };

    const setNewLinkDialogContent = (id) => {
      resetNewLinkDialog();
      setCurrentTaskId(id);
      initDetail(id);
    };

    const resetDialog = () => {
      setAdoLinkDialogSubText(adoLinkDialogDefaultSubText);
      setAdoLink("");
      setTriggerOption(false);
      setCurrentTaskId("");
      toggleHideAdoLinkDialog();
    };

    const resetNewLinkDialog = () => {
      setAdoLink("");
      setTriggerOption(false);
      setCurrentTaskId("");
      toggleHideNewAdoLinkDialog();
    };

    return [
      hideAdoLinkDialog,
      toggleHideAdoLinkDialog,
      adoLinkDialogContentProps,
      adoLinkModalProps,
      setDialogContent,
      adoLink,
      onAdoLinkChange,
      attachLink,
      attachNewLink,
      triggerOption,
      onTriggerOptionChange,
      hideNewAdoLinkDialog,
      toggleHideNewAdoLinkDialog,
      newAdoLinkDialogContentProps,
      setNewLinkDialogContent,
    ];
  };

  const [
    hideAdoLinkDialog,
    toggleHideAdoLinkDialog,
    adoLinkDialogContentProps,
    adoLinkModalProps,
    setDialogContent,
    adoLink,
    onAdoLinkChange,
    attachLink,
    attachNewLink,
    triggerOption,
    onTriggerOptionChange,
    hideNewAdoLinkDialog,
    toggleHideNewAdoLinkDialog,
    newAdoLinkDialogContentProps,
    setNewLinkDialogContent,
  ] = useAdoLinkConfirmDialog();

  const OperationPR = async (params) => {
    const { taskId, Submitter, operation } = params;
    if (taskId) {
      try {
        if (operation === 'close') {
          setTitle("Abandon Task");
          setSubText("Abandoning task, please wait...");
          toggleHideTaskConfirmDialog();
        }
        var resp = operation === 'taskrerun' ? await TaskAPI.taskRerun(
          {
            Submitter: Submitter,
          },
          taskId
        ): await TaskAPI.taskAbandon(
          {
            Submitter: Submitter,
          },
          taskId
        );
        if (resp != null && resp.data != null && resp.status === 200 && operation === 'close') {
          setSubText("Abandoning task successfully, click ok to return to the task list.");
          setIsTaskCloseFailed(false);
          setIsTaskCloseLoading(false);
        } else if (resp != null && resp.data != null && operation === 'taskrerun') {
          let id = "";
          Object.keys(resp.data).forEach((item) => {
            if (!_.isEmpty(resp.data[item].taskId)) {
              id = resp.data[item].taskId;
            }
          });
          if (id) {
            const location = window.location;
            window.open(`${location.origin}/#/tasks/${id}`);
          }
        } else {
          setSubText(resp.data.exceptionMessage);
          setIsTaskCloseFailed(true);
          setIsTaskCloseLoading(false);
        }
      } catch (e) {
        setSubText(e.response.data.Message);
        setIsTaskCloseFailed(true);
        setIsTaskCloseLoading(false);
      } 
    }
  };
  const CanOperationPR = (status, operation) => {
    if (status && operation) {
      const allowlist = {
        taskrerun: ["FINISHED"],
        close: ["FINISHED"],
      };
      return allowlist[operation].includes(status);
    }
  };
  
  const taskRerunIcon = { iconName: "ReturnToSession" };
  const queryDataIcon = { iconName: "QueryList" };
  const closeIcon = {iconName:'StatusCircleErrorX'};
  const calloutProps = { gapSpace: 0 };
  const hostStyles = { root: { display: "inline-block" } };
  const history = useHistory();
  return (
    <Stack.Item>
      <Text variant={"large"}>{Resources.Tasks.TaskDetailSubTitleTask}</Text>
      <ShimmeredDetailsList
        enableShimmer={isLoading}
        selectionMode={SelectionMode.none}
        onRenderItemColumn={renderItemColumn}
        columns={[
          {
            key: "adoLink",
            name: "ADO link",
            fieldName: "adoLink",
            minWidth: 100,
            maxWidth: 150,
            className: classNames.itemCenter,
            onRender: (item, _index) => {
              const url = item?.adoLink;
              if (url && url.includes("/")) {
                return (
                  <div>
                    <a target="_blank" rel="noopener noreferrer" href={url}>
                      {_.trimEnd(url, "/").split("/").pop()}
                    </a>
                    <IconButton
                      iconProps={{ iconName: "Edit" }}
                      title="Alter"
                      ariaLabel="Alter"
                      style={{ marginRight: "2px" }}
                      onClick={() => {
                        setNewLinkDialogContent(item?.id);
                      }}
                    />
                  </div>
                );
              }
              return (
                <IconButton
                  iconProps={{ iconName: "Add" }}
                  title="Add"
                  ariaLabel="Add"
                  style={{ marginRight: "2px" }}
                  onClick={() => {
                    setDialogContent(item?.id);
                  }}
                />
              );
            },
          },
          {
            key: "title",
            name: "Title",
            fieldName: "title",
            minWidth: 100,
            maxWidth: 300,
            className: classNames.itemCenter,
          },
          {
            key: "status",
            name: "Status",
            fieldName: "status",
            minWidth: 100,
            maxWidth: 200,
            className: classNames.itemCenter,
          },
          {
            key: "description",
            name: "Description",
            fieldName: "description",
            className: classNames.itemCenter,
          },
          {
            key: "Auto Merge Option",
            name: "Auto Merge Option",
            fieldName: "Auto Merge Option",
            className: classNames.itemCenter,
            minWidth: 150,
            maxWidth: 250,
            onRender: (item, _index) => {
              return (
                <TooltipHost
                  content={
                    task?.pRAutoMergeOption == "DIRECT_AUTO_MERGE"
                      ? "PR will be merged directly"
                      : task?.pRAutoMergeOption ==
                        "AUTO_MERGE_AFTER_FEEDBACK_CYCLE"
                      ? "PR will be auto merged in 14 days"
                      : task?.pRAutoMergeOption == "DO_NOT_AUTO_MERGE"
                      ? "PR will not be auto merged"
                      : "Invalid Records"
                  }
                >
                  <label>
                    {task?.pRAutoMergeOption == "DIRECT_AUTO_MERGE"
                      ? "DIRECTLY"
                      : task?.pRAutoMergeOption ==
                        "AUTO_MERGE_AFTER_FEEDBACK_CYCLE"
                      ? "AFTER_FEEDBACK_CYCLE"
                      : task?.pRAutoMergeOption == "DO_NOT_AUTO_MERGE"
                      ? "NEVER"
                      : "ERR"}
                  </label>
                </TooltipHost>
              );
            },
          },
          {
            key: "operation",
            name: "Operation",
            fieldName: "operation",
            minWidth: 110,
            maxWidth: 110,
            onRender: (item, index) => {
              return (
                <>
                  <TooltipHost
                    content={`TaskRerun ${
                      !CanOperationPR(item?.status, "taskrerun")
                        ? ": " + Resources.SubTasksConfirm.taskRerunDisable
                        : ""
                    }`}
                    calloutProps={calloutProps}
                    styles={hostStyles}
                    setAriaDescribedBy={false}
                  >
                    <IconButton
                      iconProps={taskRerunIcon}
                      className={
                        !CanOperationPR(item?.status, "taskrerun") &&
                        classNames.operationDisableStatus
                      }
                      style={{
                        color: SharedColors.cyanBlue10,
                        marginRight: "2px",
                      }}
                      disabled={!CanOperationPR(item?.status, "taskrerun")}
                      onClick={() => {
                        setTitle("TaskRerun");
                        setSubText(Resources.SubTasksConfirm.taskRerun);
                        toggleHideSubTasksConfirmDialog();
                        setConfirmFunc({func: ()=>{OperationPR(Object.assign(
                          {},
                          {
                            taskId: task?.id,
                            Submitter: userId,
                            operation: "taskrerun",
                          }
                        ))}})
                      }}
                    />
                  </TooltipHost>
                  {item?.conditions?.length > 0 ? (
                    <TooltipHost
                      content={`Query data`}
                      calloutProps={calloutProps}
                      styles={hostStyles}
                      setAriaDescribedBy={false}
                    >
                      <IconButton
                        iconProps={queryDataIcon}
                        style={{
                          color: SharedColors.cyanBlue10,
                          marginRight: "2px",
                        }}
                        disabled={false}
                        onClick={() => {
                          let subText = Resources.SubTasksConfirm.queryData;
                          let conditionArr = [];
                          item?.conditions?.forEach((item) => {
                            let con = item?.con;
                            if (con === "Equal") {
                              con = "==";
                            }else if (con === "NotEqual") {
                              con = "<>"
                            }
                            conditionArr.push(`${item?.metadata.key} ${con} ${item?.metadata.value === 'n/a'?"":item?.metadata.value}`);
                          })
                          subText += ` Current conditions: ${conditionArr.join(' and ')}`;
                          setTitle("Query data");
                          setSubText(subText);
                          toggleHideSubTasksConfirmDialog();
                          setConfirmFunc({func: () => {
                            window.open(`${window.location.origin}/#/metadata/query/${task?.id}`)
                          }});
                        }}
                      />
                    </TooltipHost>
                  ) : (
                    ""
                  )}
                  <TooltipHost
                    content={`Abandon ${CanOperationPR(item?.pullRequestStatus,'close') ? ': '+Resources.SubTasksConfirm.taskCloseDisable : ''}`}
                    calloutProps={calloutProps}
                    styles={hostStyles}
                    setAriaDescribedBy={false}
                  >
                    <IconButton
                      iconProps={closeIcon}
                      className={
                        CanOperationPR(item?.pullRequestStatus,'close') &&
                        classNames.operationDisableStatus
                      }
                      style={{color:SharedColors.red10,marginRight:"2px"}}
                      disabled={CanOperationPR(item?.pullRequestStatus,'close')}
                      onClick={() => {
                        setTitle("Abandon");
                        setSubText(Resources.SubTasksConfirm.taskClose);
                        toggleHideSubTasksConfirmDialog();
                        setConfirmFunc({func: ()=>{OperationPR(Object.assign(
                          {},
                          {
                            taskId: task?.id,
                            Submitter: userId,
                            operation: "close",
                          }
                        ))}})
                      }}
                    />
                  </TooltipHost>
                </>
              );
            },
          },
        ]}
        setKey="id"
        items={[task]}
      />
      <Dialog
        hidden={hideSubTasksConfirmDialog}
        onDismiss={toggleHideSubTasksConfirmDialog}
        dialogContentProps={subTasksConfirmDialogContentProps}
        modalProps={subTasksConfirmModalProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              confirmFunc.func();
              toggleHideSubTasksConfirmDialog();
            }}
            text={Resources.SubTasksConfirm.Confirm}
          />
          <DefaultButton
            onClick={() => {
              toggleHideSubTasksConfirmDialog();
            }}
            text={Resources.Jobs.Cancel}
          />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={hideTaskConfirmDialog}
        onDismiss={toggleHideTaskConfirmDialog}
        dialogContentProps={subTasksConfirmDialogContentProps}
        modalProps={subTasksConfirmModalProps}
      >
        {isTaskCloseLoading ? <ReactLoading type={"spinningBubbles"} color={"black"} height={'20%'} width={'20%'} /> : null}
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              if(isTaskCloseFailed) {setIsTaskCloseLoading(true);toggleHideTaskConfirmDialog()}
              else {history.push("/tasks")}}}
            text="OK"
            disabled={isTaskCloseLoading}
          />
        </DialogFooter>
      </Dialog>
      <div style={{ whiteSpace: "pre-wrap" }}>
        <Dialog
          hidden={hideAdoLinkDialog}
          onDismiss={toggleHideAdoLinkDialog}
          dialogContentProps={adoLinkDialogContentProps}
          modalProps={adoLinkModalProps}
          maxWidth={440}
        >
          <Checkbox
            label="Trigger PR directly"
            checked={triggerOption}
            onChange={onTriggerOptionChange}
          />
          <TextField
            label={Resources.Tasks.SetAdoLink}
            onChange={onAdoLinkChange}
            errorMessage={
              !isValidAdoLink(adoLink)
                ? Resources.Tasks.NotValidAdoLink
                : isAdoLinkEndWithNumber(adoLink)
                ? undefined
                : Resources.Tasks.AdoLinkNotEndWithNumber
            }
          />
          <DialogFooter>
            <PrimaryButton
              text="Add"
              onClick={attachLink}
              disabled={
                !isValidAdoLink(adoLink) || !isAdoLinkEndWithNumber(adoLink)
              }
            ></PrimaryButton>
            <DefaultButton
              text="Cancel"
              onClick={toggleHideAdoLinkDialog}
            ></DefaultButton>
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={hideNewAdoLinkDialog}
          onDismiss={toggleHideNewAdoLinkDialog}
          dialogContentProps={newAdoLinkDialogContentProps}
          modalProps={adoLinkModalProps}
          maxWidth={440}
        >
          <TextField
            label={Resources.Tasks.SetNewAdoLink}
            onChange={onAdoLinkChange}
            errorMessage={
              !isValidAdoLink(adoLink)
                ? Resources.Tasks.NotValidAdoLink
                : isAdoLinkEndWithNumber(adoLink)
                ? undefined
                : Resources.Tasks.AdoLinkNotEndWithNumber
            }
          />
          <DialogFooter>
            <PrimaryButton
              text="Add"
              onClick={attachNewLink}
              disabled={
                !isValidAdoLink(adoLink) || !isAdoLinkEndWithNumber(adoLink)
              }
            ></PrimaryButton>
            <DefaultButton
              text="Cancel"
              onClick={toggleHideNewAdoLinkDialog}
            ></DefaultButton>
          </DialogFooter>
        </Dialog>
      </div>
    </Stack.Item>
  );
};
export default memo(Task);
