import { useEffect, useState, memo, useCallback, useMemo } from "react";
import TaskApi from "../../Common/API/taskApi";
import BackHomeButton from "../../Components/BackHomeButton";
import { Link, useHistory } from "react-router-dom";
import {
  Text,
  ShimmeredDetailsList,
  Stack,
  SelectionMode,
  PrimaryButton,
  ScrollablePane,
  Dropdown,
  TextField,
  DefaultButton,
  Icon,
  mergeStyleSets,
  Dialog,
  DialogType,
  DialogFooter,
  ActionButton,
  Checkbox,
  IconButton,
  StackItem,
  mergeStyles,
} from "@fluentui/react";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import Resources from "../../Common/resources";
import { NumberRegex } from "../../Common/constants";
import { MessageActions } from "../../Common/Utils/Message";
import SingleDatePicker from "../../Components/DateRangePicker";
import { useBoolean } from "@uifabric/react-hooks";
import { isValidAdoLink, isAdoLinkEndWithNumber }  from "../../Common/Utils/adoLinkValidator";
import { overflowCellHover } from "../../Common/customStyle";


const emptyArray = [];

const TaskStyles = {
  Container: { position: "relative", height: "100vh" },
  InnerScroll: { position: "absolute", top: "150px", bottom: "50px" },
  Title: { root: { textAlign: "center" } },
    SubmitButton: { root: { width: 150 } },
    ConfigButton: { root: { width: 150, marginLeft: 15, background: "white", color: "black" } },
};

const SubmitSpacePHStyle = {
  visibility: "hidden",
  display: "block",
  height: 21,
};

const classNames = mergeStyleSets({
  itemCenter:{
    alignItems: "center",
    display: "inline-flex!important"
  },
});

const StatusOptions = [
  {
    key: "WAIT_FOR_ATTACH",
    text: "WAIT_FOR_ATTACH",
  },
  {
    key: "WAIT_FOR_TRIGGER",
    text: "WAIT_FOR_TRIGGER",
  },
  {
    key: "IN_PROGRESS",
    text: "IN_PROGRESS",
  },
  {
    key: "FINISHED",
    text: "FINISHED",
  },
  {
    key: "ALL",
    text: "ALL",
  },
];

const defaultStatusKey = "IN_PROGRESS";
const dropdownStyles = { dropdown: { width: 180 } };

const renderItemColumn = (item, _index, column) => {
  let content = item[column.fieldName];
  let customStyles = mergeStyles(overflowCellHover);
  return <span className={customStyles}>{content}</span>;
};

const useTask = () => {
  const defaultStart = new Date();
  defaultStart.setDate(defaultStart.getDate() - 60);
  const [isLoading, setLoading] = useState(true);
  const [tasks, setTasks] = useState(emptyArray);
  const [showStatus, setShowStatus] = useState(defaultStatusKey);
  const [adoNumber, setAdoNumber] = useState("");
  const [startDate, setStartDate] = useState(defaultStart);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [hideDialog, {toggle: toggleHideDialog}] = useBoolean(true)
  const [dialogDefaultSubText] = useState("Below source will be impacted by this task. Please double confirm if changes are valid.\n")
  const [dialogSubText, setDialogSubText] = useState(dialogDefaultSubText)
  const [metaDataList, setMetaDataList] = useState([]);
  const [adoLink,setAdoLink] = useState("");
  const [triggerOption,setTriggerOption] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState("");
  const [userScope, setUserScope] = useState(true);
 
  
  const dialogContentProps = {
      type: DialogType.normal,
      title: 'Adding AdoLink',
      subText: dialogSubText,
    };
  
  const modalProps =  useMemo(
    () => ({
      isBlocking: true,
      styles: { main: { maxWidth: 440 , whiteSpace:'pre-wrap'} },
    })
  );

  const setDialogContent = (id) => {
    resetDialog();
    setCurrentTaskId(id);
    initDetail(id);
  };

  const onDropDownChange = (_, newVal) => {
    setShowStatus(newVal.key);
  };

  const onAdoNumberChange = (_, newVal) => {
    setAdoNumber(newVal);
  };

  const onAdoLinkChange = (_, newVal) => {
    setAdoLink(newVal);
  };

  const onTriggerOptionChange = () => {
    setTriggerOption(!triggerOption);
  };

  const onUserScopeChange = () => {
    setUserScope(!userScope);
  };

  const resetDialog = () => {
    setDialogSubText(dialogDefaultSubText);
    setAdoLink("");
    setTriggerOption(false);
    setCurrentTaskId("");
    toggleHideDialog();
  };

  const attachLink = useCallback(async () =>{
    try{
    const attachRequest = {
      adoLink,
      triggerOption
    }
    var res = await TaskApi.attachAdoLink(attachRequest,currentTaskId);
    resetDialog();
    initFn();
    }catch (error) {
      MessageActions.errorHandler(error);
      resetDialog();
    }
  }
  );

  const initDetail = useCallback(async (id) => {
    try {
      var taskDetail = await TaskApi.getTaskDetail(id);
      var subText = dialogDefaultSubText;
      taskDetail.data.subTasks.forEach(e => {
        subText = subText+'\nrepo:  '+(e.repo?e.repo:'')+'\nImpactDataAmount:  '+(e.estimateCount?e.estimateCount:'')+'\n';
      });
      setDialogSubText(subText);
    }catch (error) {
      MessageActions.errorHandler(error);
    }
  }
  );

  const AddItemToFilter = ()=>{
    const metaData = {
      metaKey: "",
      metaValue: "",
    };
    const tmpList = cloneDeep(metaDataList);
    tmpList.push(metaData);
    setMetaDataList(tmpList);
  };

  const initFn = useCallback(async () => {
    try {
      setLoading(true);
      var metaDataQuery={};
      metaDataList.forEach(e=>{
        if(e.metaKey.length > 0 && e.metaValue.length > 0){
          metaDataQuery[e.metaKey]=e.metaValue
        }});
      const filterCondition = showStatus == "ALL" ? {
        adoNumber,
        startDate,
        metaDataQuery,
        userScope,
      }:
      {
        showStatus,
        adoNumber,
        startDate,
        metaDataQuery,
        userScope,
      }
      const taskDetail = await TaskApi.getTasks(filterCondition);
      const tasks = _.orderBy(taskDetail.data, ["creationTime"], ["desc"]);
      setLoading(false);
      setTasks(tasks);
    } catch (error) {
      MessageActions.errorHandler(error);
      setLoading(false);
      setTasks(emptyArray);
    }
  }, [showStatus, adoNumber, startDate, metaDataList, userScope]);

  useEffect(() => {
    initFn();
  }, [userScope]);

  return {
    isLoading,
    tasks,
    showStatus,
    adoNumber,
    startDate,
    onDropDownChange,
    onAdoNumberChange,
    initFn,
    setStartDate,
    isCollapsed,
    setIsCollapsed,
    hideDialog,
    toggleHideDialog,
    modalProps,
    dialogContentProps,
    setDialogContent,
    AddItemToFilter,
    metaDataList,
    setMetaDataList,
    adoLink,
    onAdoLinkChange,
    triggerOption,
    onTriggerOptionChange,
    attachLink,
    userScope,
    onUserScopeChange,
  };
};

const TaskList = () => {
  document.title = "Metadata Management";
  const {
    isLoading,
    tasks,
    showStatus,
    adoNumber,
    startDate,
    onDropDownChange,
    onAdoNumberChange,
    initFn,
    setStartDate,
    isCollapsed,
    setIsCollapsed,
    hideDialog,
    toggleHideDialog,
    modalProps,
    dialogContentProps,
    setDialogContent,
    AddItemToFilter,
    metaDataList,
    setMetaDataList,
    adoLink,
    onAdoLinkChange,
    triggerOption,
    onTriggerOptionChange,
    attachLink,
    userScope,
    onUserScopeChange,
  } = useTask();
  const history = useHistory();
  return (
    <div style={TaskStyles.Container}>
      <BackHomeButton />
      <Text variant={"xxLarge"} styles={TaskStyles.Title} block>
        {Resources.Tasks.TaskListTopTitle}
      </Text>
      <Stack tokens={{ padding: "m" }}>
        <Stack.Item align="end">
          <PrimaryButton
            text="Submit a task"
            styles={TaskStyles.SubmitButton}
            onClick={() => {
              history.push("/tasks/op");
            }}
          />
          <DefaultButton 
            text="Resource Config"
            styles={TaskStyles.ConfigButton}
            onClick={() => {
                history.push("/myself");
            }}
          />
          <DefaultButton 
            text="Dashboard"
            styles={TaskStyles.ConfigButton}
            onClick={() => {
                history.push("/dashboard");
            }}
          />
        </Stack.Item>
        <ScrollablePane style={TaskStyles.InnerScroll}>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Dropdown
              styles={dropdownStyles}
              label={Resources.Tasks.TasksStatusFilterLabel}
              selectedKey={showStatus}
              options={StatusOptions}
              onChange={onDropDownChange}
            />
            <TextField
              label={Resources.Tasks.SearchAdoLabel}
              onChange={onAdoNumberChange}
              errorMessage={
                NumberRegex.test(adoNumber)
                  ? null
                  : Resources.Tasks.SearchAdoErrorMessage
              }
            />
            <SingleDatePicker
              initialDate={startDate}
              setDate={setStartDate}
              label="Start Date"
            />
            <Stack.Item align="end">
              <IconButton 
              iconProps={isCollapsed ? { iconName: 'ChevronDown' } : { iconName: 'ChevronUp' }}
              onClick={() => setIsCollapsed(!isCollapsed)}>
              </IconButton>
            </Stack.Item>
            <Stack.Item align="end">
              <DefaultButton
                text="Filter"
                onChange={initFn}
                disabled={!NumberRegex.test(adoNumber)}
                onClick={initFn}
              />
              {NumberRegex.test(adoNumber) ? null : (
                <span style={SubmitSpacePHStyle}> layout place holder</span>
              )}
            </Stack.Item>
            <Stack.Item align="center" style={{marginTop: '25px', marginLeft: "auto"}} >
              <Checkbox label="Only My Team" onChange={onUserScopeChange} checked={userScope} />
            </Stack.Item>
          </Stack>
          <div className="collapse-content" style={isCollapsed ? {display: 'none'} : {display: 'block'}} aria-expanded={isCollapsed}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
            <StackItem align="center">
            {metaDataList.length == 0 ? <label class="ms-Label root-131">Search By Metadata</label> : <label class="ms-Label root-131">Search By</label>}
            </StackItem>
            <Stack.Item align="end">
              <ActionButton 
                iconProps={{ iconName: "Add" }}
                onClick={AddItemToFilter}   
                />
              </Stack.Item>
            </Stack>
            {metaDataList.map((item, index) => {
              return (
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <TextField
                  label={Resources.Tasks.MetadataKey}
                  onChange={(_, newVal)=>{
                    var tmpList = cloneDeep(metaDataList);
                    tmpList[index].metaKey=newVal;
                    setMetaDataList(tmpList);
                  }}
                  required={metaDataList[index].metaKey?.length === 0}
                  value={metaDataList[index].metaKey}
                />
                <TextField
                  label={Resources.Tasks.MetadataValue}
                  onChange={(_, newVal)=>{
                    var tmpList = cloneDeep(metaDataList);
                    tmpList[index].metaValue=newVal;
                    setMetaDataList(tmpList);
                  }}
                  required={metaDataList[index].metaValue?.length === 0}
                  value={metaDataList[index].metaValue}
                />
                <Stack.Item align="end">
                <IconButton iconProps={{ iconName: "Delete" }}
                    onClick={() => {
                      var filtered = cloneDeep(metaDataList);
                      filtered.splice(index,1);
                      setMetaDataList(filtered);
                    }}
                  />
                </Stack.Item>
              </Stack>
                  )
            })}
          </div>

          <ShimmeredDetailsList
            onRenderItemColumn={renderItemColumn}
            enableShimmer={isLoading}
            selectionMode={SelectionMode.none}
            layoutMode={1}
            columns={[
              {
                key: "warning",
                name: "",
                fieldName: "warning",
                maxWidth:16,
                minWidth: 16,
                onRender: (item, _index) => {
                  const warning = item?.warning;
                  const isGoLive = item?.isGoLive;
                  const status = item?.status;
                  return (warning===true&&status==="IN_PROGRESS"&&<Icon iconName="Important" style={{color:"red"}}/>) || (isGoLive===true&&status==="FINISHED"&&<Icon iconName="CompletedSolid" style={{color:"black"}}/>)
                },
              },
              {
                key: "adoLink",
                name: "ADO link",
                fieldName: "adoLink",
                minWidth: 100,
                maxWidth: 200,
                className:classNames.itemCenter,
                onRender: (item, _index) => {
                  const url = item?.adoLink;
                  if (url && url.includes("/"))  {
                    return (
                      <a target="_blank" rel="noopener noreferrer" href={url}>
                        {_.trimEnd(url, "/").split("/").pop()}
                      </a>
                    );
                  }
                  return <IconButton 
                  iconProps={{ iconName: 'Add' }} title="Add" ariaLabel="Add" 
                  style={{marginRight:"2px"}} 
                  onClick={()=>{setDialogContent(item?.id)}}
                  />;
                },
              },
              {
                key: "title",
                name: "Title",
                fieldName: "title",   
                minWidth: 100,
                maxWidth: 300,
                className:classNames.itemCenter,
              },
              {
                key: "status",
                name: "Status",
                fieldName: "status",
                minWidth: 100,
                maxWidth: 200,
                className:classNames.itemCenter,
              },

              {
                key: "description",
                name: "Description",
                fieldName: "description",
                className:classNames.itemCenter,
              },
              {
                key: "id",
                name: "Action",
                fieldName: "id",
                className:classNames.itemCenter,
                onRender: (item, _index) => {
                  const id = item?.id;
                  if (id)
                    return (
                      <Link to={`/tasks/${id}`}>
                        {Resources.Tasks.TaskDetailLinkText}
                      </Link>
                    );
                },
              },
            ]}
            setKey="id"
            items={tasks}
          />
          {!tasks.length &&!isLoading && (
            <Stack horizontalAlign="center">
              <Text>{Resources.Tasks.NoTasks}</Text>
            </Stack>
          )}
        </ScrollablePane>
      </Stack>
      <div style={{whiteSpace:'pre-wrap'}}>
      <Dialog 
        hidden = {hideDialog}
        onDismiss = {toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
        maxWidth={440} 
      >
        <Checkbox label="Trigger PR directly" checked={triggerOption} onChange={onTriggerOptionChange} />
        <TextField
              label={Resources.Tasks.SetAdoLink}
              onChange={onAdoLinkChange}
              errorMessage={
                !isValidAdoLink(adoLink)
                  ? Resources.Tasks.NotValidAdoLink
                  : isAdoLinkEndWithNumber(adoLink) ? undefined : Resources.Tasks.AdoLinkNotEndWithNumber
              }
            />
        <DialogFooter>
          <PrimaryButton text="Add" onClick={attachLink} disabled={!isValidAdoLink(adoLink)||!isAdoLinkEndWithNumber(adoLink)}></PrimaryButton>
          <DefaultButton text="Cancel" onClick={toggleHideDialog}></DefaultButton>
        </DialogFooter>
        
      </Dialog>
      </div>
    </div>
  );
};

export default memo(TaskList);
