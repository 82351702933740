import { useEffect, useState, memo, useCallback } from "react";
import TaskApi from "../../Common/API/taskApi";
import BackHomeButton from "../../Components/BackHomeButton";
import DropdownSearch from "../../Components/DropdownSearch";
import { useParams, useHistory,useLocation } from "react-router-dom";
import {
    Text,
    Stack,
    SelectionMode,
    PrimaryButton,
    TextField,
    DetailsList,
    ScrollablePane,
    StackItem,
    Label,
  } from "@fluentui/react";
import Resources from "../../Common/resources";
import { MessageActions } from "../../Common/Utils/Message";
import _ from "lodash";

const LabelStyles = {
  root: {
      fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
      WebkitFontSmoothing: 'antialiased',
      fontSize: '14px',
      fontWeight: '600',
      color: 'rgb(50, 49, 48)',
      boxSizing: 'border-box',
      boxShadow: 'none',
      margin: '0px',
      display: 'block',
      padding: '5px 0px',
      overflowWrap: 'break-word'
  }
}

const DocumentStyles = {
    Container: { position: "relative", height: "100vh" },
    InnerScroll: { position: "absolute", top: "50px", bottom: "50px" },
  };

const emptyArray = [];

const useMetadata = () => {
    const [persistentId, setPersistentId] = useState("");
    const [branch, setBranch] = useState("live");
    const [locale, setLocale] = useState("en-us");
    const [metadatas, setMetadatas] = useState(emptyArray);
    const [generatedMetadatas, setGeneratedMetadatas] = useState(emptyArray);
    const [branchOption,setBranchOption] = useState([]);
    const location = useLocation();
    const history = useHistory();

    const onPersistentIdChange = (_, newVal) => {
        setPersistentId(newVal.trim());
      };

    const onLocaleChange = (_, newVal) => {
        setLocale(newVal.trim());
      };

    const onBranchOptionChange = (newVal) => {
      setBranch(newVal.key);
    };
   
    const getmetadataByPersistentId = useCallback(async (persistentId, locale, branch) => {
        try {
          setPersistentId(persistentId);
          setLocale(locale);
          setBranch(branch);
          const metadataDetail = await TaskApi.getMetadata(
              persistentId,
              locale,
              branch
          );
          setMetadatas(metadataDetail.data.metadata);
          setGeneratedMetadatas(metadataDetail.data.generatedMetadata);
          const branchList = metadataDetail.data.branchList;
          const tmpBranchOption = [];
          branchList.sort().forEach(e => {
            tmpBranchOption.push({
              key: e ,
              text: e
            })
          });
          setBranchOption(tmpBranchOption);
          setBranch(metadataDetail.data.branch);
        } catch (error) {
            MessageActions.errorHandler(error);
            setMetadatas(emptyArray);
            setGeneratedMetadatas(emptyArray);
            setBranchOption(emptyArray);
            setBranch("");
        }
    }, [persistentId, locale, branch]);
    
    const searchFn = useCallback(async () => {
      history.push(`/metadata/detail/${persistentId}/${locale}`);
      getmetadataByPersistentId(persistentId, locale, branch);
    }, [persistentId, locale, branch]);
     
    return {
        persistentId,
        onPersistentIdChange,
        locale,
        onLocaleChange,
        branch,
        searchFn,
        metadatas,
        generatedMetadatas,
        getmetadataByPersistentId,
        branchOption,
        onBranchOptionChange,
        location,
    };
}

const MetadataDetail = () => {
    const {
        persistentId,
        onPersistentIdChange,
        locale,
        onLocaleChange,
        branch,
        searchFn,
        metadatas,
        generatedMetadatas,
        getmetadataByPersistentId,
        branchOption,
        onBranchOptionChange,
        location,
    } = useMetadata();

    const params = useParams();
    useEffect(() => {
        if (params.id) {
            getmetadataByPersistentId(params.id?params.id:persistentId, 
              params.locale?params.locale:locale, branch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    return (
      <div style={DocumentStyles.Container}>
        <BackHomeButton />
        <ScrollablePane style={DocumentStyles.InnerScroll}>
        <Text variant={"xxLarge"} block>
            {Resources.MetadataDetail.MetadataDetailTitle}
        </Text>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <TextField 
            label="PersistentId"
            required={persistentId?.length === 0}
            onChange={onPersistentIdChange}
            value={persistentId}
            />
            <TextField 
            label="Locale"
            required={locale?.length === 0}
            onChange={onLocaleChange}
            value={locale}
            />
            <StackItem>
            <Label styles={LabelStyles}>Branch</Label>
            <DropdownSearch
              options={branchOption}
              multiSelect={false}
              selectedKey={branch}
              onChange={onBranchOptionChange}
            />
            </StackItem>
            <Stack.Item align="end">
                <PrimaryButton
                text="Search"
                disabled={false}
                onClick={searchFn}
            />
            </Stack.Item>
        </Stack>
        <Stack style={{marginTop: '20px'}}>
            <text style={{ fontWeight :'bold', fontSize: 'large'}} >Metadata</text>
            <DetailsList 
            selectionMode={SelectionMode.none}
            layoutMode={1}
            isHeaderVisible={true}
            onRenderRow={(props,defaultRender)=>{
              return (props.itemIndex % 2 == 0 ? 
              <div classname='metadataList'>
                {defaultRender({...props, styles:{root: {background: 'rgba(0,0,0,.05)'}}})}
              </div>
              :<div classname='metadataList'>
                {defaultRender({...props, styles:{root: {background: 'white'}}})}
              </div>)
            }}
            columns={[
                {
                    key: "key",
                    name: "Property Name",
                    fieldName: "key",
                    minWidth: 100,
                    maxWidth: 200,
                },
                {
                    key: "value",
                    name: "Property Value",
                    fieldName: "value",
                    minWidth: 100,
                    maxWidth: 500,
                },
                {
                    key: "source",
                    name: "Property Source",
                    fieldName: "source",
                    minWidth: 100,
                    maxWidth: 200,
                },
            ]}
            items={metadatas}
            /> 
            {!metadatas.length && (
            <Stack horizontalAlign="center">
              <Text>{Resources.MetadataDetail.NoMetadatas}</Text>
            </Stack>
          )} 
        </Stack>
        <Stack>
            <text style={{ fontWeight :'bold', fontSize: 'large'}} >GeneratedMetadata</text>
            <DetailsList 
            selectionMode={SelectionMode.none}
            layoutMode={1}
            isHeaderVisible={true}
            onRenderRow={(props,defaultRender)=>{
              return (props.itemIndex % 2 == 0 ? 
              <div classname='metadataList'>
                {defaultRender({...props, styles:{root: {background: 'rgba(0,0,0,.05)'}}})}
              </div>
              :<div classname='metadataList'>
                {defaultRender({...props, styles:{root: {background: 'white'}}})}
              </div>)
            }}
            columns={[
                {
                    key: "key",
                    name: "Property Name",
                    fieldName: "key",
                    minWidth: 100,
                    maxWidth: 200,
                },
                {
                    key: "value",
                    name: "Property Value",
                    fieldName: "value",
                    minWidth: 100,
                    maxWidth: 500,
                },
                {
                    key: "source",
                    name: "Property Source",
                    fieldName: "source",
                    minWidth: 100,
                    maxWidth: 200,
                },
            ]}
            items={generatedMetadatas}
            />  
            {!generatedMetadatas.length && (
            <Stack horizontalAlign="center">
              <Text>{Resources.MetadataDetail.NoGeneratedMetadatas}</Text>
            </Stack>
            )} 
        </Stack>
        </ScrollablePane>
      </div>
    );
}

export default memo(MetadataDetail);