import { useEffect, useState, memo, useCallback } from "react";
import BackHomeButton from "../../Components/BackHomeButton";
import { Link, useHistory } from "react-router-dom";
import {
  Text,
  Stack,
} from "@fluentui/react";
import _ from "lodash";
import Resources from "../../Common/resources";
import { configs } from "../../Common/Config";

const MetadataStyles = {
  Container: { position: "relative", height: "100vh" },
  InnerContainer: { height: "calc(100% - 75px)" },
  Title: { root: { textAlign: "center", marginBottom: 30 } }
};


const Concepts = () => {
  document.title = "Taxonomy Lifecycle Tracker";
  console.log(configs);
  return (
    <div style={MetadataStyles.Container}>
      <BackHomeButton />
      <Text variant={"xxLarge"} styles={MetadataStyles.Title} block>
        {Resources.Concepts.ConceptsTitle}
      </Text>
      <div style={ MetadataStyles.InnerContainer }>
      <iframe title="TaxonomyLifeCycle" width="100%" height="100%" src={configs.TaskApp.conceptReport} frameborder="0" allowFullScreen="true"></iframe>
      </div>
    </div>
  );
};

export default memo(Concepts);