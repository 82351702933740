import { useEffect, useState, memo, useCallback } from "react";
import BackHomeButton from "../../Components/BackHomeButton";
import { Link, useHistory } from "react-router-dom";
import TaskApi from "../../Common/API/taskApi";
import {
  Text,
  Stack,
  Label,
  StackItem,
  TooltipHost,
  IconButton,
  Pivot,
  PivotItem,
  ScrollablePane,
  DefaultButton,
} from "@fluentui/react";
import { GroupedVerticalBarChart } from '@fluentui/react-charting';
import SingleDatePicker from "../../Components/DateRangePicker";
import _ from "lodash";
import Resources from "../../Common/resources";
import DropdownSearch from "../../Components/DropdownSearch";
import { MessageActions } from "../../Common/Utils/Message";
import { SharedColors } from "@fluentui/theme";

const LabelStyles = {
    root: {
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        WebkitFontSmoothing: 'antialiased',
        fontSize: '14px',
        fontWeight: '600',
        color: 'rgb(50, 49, 48)',
        boxSizing: 'border-box',
        boxShadow: 'none',
        margin: '0px',
        display: 'block',
        padding: '5px 0px',
        overflowWrap: 'break-word'
    }
  }

const dropdownPlaceholder = "Not Selected as Filter";
const tabRepo = "Repo";
const tabMetadataKey = "Metadata Key";
const tabMetadataValue = "Metadata Value";

const useDashBoard = () => {
    const defaultStart = new Date();
    defaultStart.setFullYear(defaultStart.getFullYear() - 1);
    const [startDate, setStartDate] = useState(defaultStart);
    const [endDate, setEndDate] = useState(new Date());
    const [repoList, setRepoList] = useState([]);
    const [metadataKeyList, setMetadataKeyList] = useState([]);
    const [metadataValueList, setMetadataValueList] = useState([]);
    const [selectedRepo, setSelectedRepo] = useState("");
    const [selectedMetadataKey, setSelectedMetadataKey] = useState("");
    const [selectedMetadataValue, setSelectedMetadataValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [tabValue, setTabValue] = useState("");
    const [repoData, setRepoData] = useState([]);
    const [metadataKeyData, setMetadataKeyData] = useState([]);
    const [metadataValueData, setMetadataValueData] = useState([]);
    const [fileCount, setFileCount] = useState(0);
    const [prCount, setPrCount] = useState(0);
    const [taskCount, setTaskCount] = useState(0);
    const [teamList, setTeamList] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState("");
    const [isRepoSelected, setIsRepoSelected] = useState(false);
    const [isMetadataKeySelected, setIsMetadataKeySelected] = useState(false);
    const [isMetadataValueSelected, setIsMetadataValueSelected] = useState(false);

    const tabSwitch = (item) => {
        setTabValue(item.props.itemKey);
    }

    const getTeamList = async () => {
        try{
          const teamTemplateList = await TaskApi.loadDashboardTeam();
          if (teamTemplateList.data?.length > 0 ) {
            var teamList = [];
            teamTemplateList.data.forEach((e)=>{
              teamList.push({key:e.id,text:e.teamAlias});
            })
            setTeamList(teamList);
          };
        }catch (error) {
          const message = error.message;
          MessageActions.error(message);
        };
      }

    const getData = async () => {
        var indexOfRepoName = selectedRepo.indexOf('/');
        let RepoName = "";
        if (indexOfRepoName !== -1) {
            RepoName = selectedRepo.substring(indexOfRepoName + 1);
        } else {
            RepoName = selectedRepo;
        }
        let request = {
            StartDate: startDate,
            EndDate: endDate,
            MetadataKey: selectedMetadataKey,
            MetadataValue: selectedMetadataValue,
            RepoName: RepoName,
            SourceTeam: selectedTeam
        }
        try {
            setIsLoading(true);
            const response = await TaskApi.getDashboardData(request);
            let data = response.data;
            let tmpRepoList = [];
            let tmpRepoData = [];
            let tmpKeyList = [];
            let tmpKeyData = [];
            let tmpValueList = [];
            let tmpValueData = [];
            let repoData = data['repoDistribution'];
            let keyData = data['metadataKeyDistribution'];
            let valueData = data['metadataValueDistribution'];
            setFileCount(data['totalFileCount']);
            setPrCount(data['totalPRCount']);
            setTaskCount(data['totalTaskCount']);
            for (var e in repoData) {
                tmpRepoList.push({text:e,key:e});
                tmpRepoData.push({
                    name:e,
                    series:[{key:"item1", data:repoData[e]["item1"], legend:"File Count", color: SharedColors.cyanBlue10},
                            {key:"item2", data:repoData[e]["item2"], legend:"PR Count", color: SharedColors.orange10}]
                });
            }
            for (var e in keyData) {
                tmpKeyList.push({text:e,key:e});
                tmpKeyData.push({
                    name:e,
                    series:[{key:"item1", data:keyData[e]["item1"], legend:"File Count", color: SharedColors.cyanBlue10},
                            {key:"item2", data:keyData[e]["item2"], legend:"PR Count", color: SharedColors.orange10}]
                });
            } 
            for (var e in valueData) {
                tmpValueList.push({text:e,key:e});
                tmpValueData.push({
                    name:e,
                    series:[{key:"item1", data:valueData[e]["item1"], legend:"File Count", color: SharedColors.cyanBlue10},
                            {key:"item2", data:valueData[e]["item2"], legend:"PR Count", color: SharedColors.orange10}]
                });
            }
            setRepoList(tmpRepoList);
            setRepoData(tmpRepoData);
            setMetadataKeyList(tmpKeyList);
            setMetadataKeyData(tmpKeyData);
            setMetadataValueList(tmpValueList);
            setMetadataValueData(tmpValueData);
            setTabValue(selectedRepo.length == 0 ? tabRepo : selectedMetadataKey.length == 0 ? tabMetadataKey : tabMetadataValue);
            selectedRepo.length > 0 ? setIsRepoSelected(true) : setIsRepoSelected(false);
            selectedMetadataKey.length > 0 ? setIsMetadataKeySelected(true) : setIsMetadataKeySelected(false);
            selectedMetadataValue.length > 0 ? setIsMetadataValueSelected(true) : setIsMetadataValueSelected(false);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            const message = error.message;
            MessageActions.error(message);
        }
    }

    const initFn = () =>{
        getTeamList();
        getData();
    };

    useEffect(() => {
          initFn();
      } ,[]);

    useEffect(() => {
        if(selectedRepo === ""){
            initFn();
        }
      } ,[selectedRepo]);
    
    useEffect(() => {
        if(selectedMetadataKey === ""){
            initFn();
        }
      } ,[selectedMetadataKey]);
    
    useEffect(() => {
        if(selectedMetadataValue === ""){
            initFn();
        }
      } ,[selectedMetadataValue]);

    useEffect(() => {
        if(selectedTeam === ""){
            initFn();
        }
      } ,[selectedTeam]);

    return { 
    startDate, 
    setStartDate, 
    endDate, 
    setEndDate,
    repoList,
    metadataKeyList,
    metadataValueList,
    selectedRepo,
    selectedMetadataKey,
    selectedMetadataValue,
    setSelectedRepo,
    setSelectedMetadataKey,
    setSelectedMetadataValue,
    isLoading,
    tabValue,
    tabSwitch,
    repoData,
    metadataKeyData,
    metadataValueData,
    fileCount,
    prCount,
    teamList,
    selectedTeam,
    setSelectedTeam,
    taskCount,
    initFn,
    isRepoSelected,
    isMetadataKeySelected,
    isMetadataValueSelected
    };
}

const Dashboard = () => {
  document.title = "Impact Dashboard";
  const history = useHistory();
  const { 
    startDate, 
    setStartDate, 
    endDate, 
    setEndDate,
    repoList,
    metadataKeyList,
    metadataValueList,
    selectedRepo,
    selectedMetadataKey,
    selectedMetadataValue,
    setSelectedRepo,
    setSelectedMetadataKey,
    setSelectedMetadataValue,
    isLoading,
    tabValue,
    tabSwitch,
    repoData,
    metadataKeyData,
    metadataValueData,
    fileCount,
    prCount,
    teamList,
    selectedTeam,
    setSelectedTeam,
    taskCount,
    initFn,
    isRepoSelected,
    isMetadataKeySelected,
    isMetadataValueSelected
    } = useDashBoard();

  return (
  <div style={{ position: "relative", height: "100vh" }}>
    <BackHomeButton />
    <Text variant={"xxLarge"} styles={{ root: { textAlign: "center" } }} block>
        {Resources.Tasks.DashboardTitle}
    </Text>
    <Stack horizontal tokens={{ childrenGap: 10 }}>
        <SingleDatePicker initialDate={startDate} setDate={setStartDate} label="Start Date"/>
        <SingleDatePicker initialDate={endDate} setDate={setEndDate} label="End Date"/>
    </Stack>
    <Stack horizontal tokens={{ childrenGap: 10 }}>
        <StackItem>
            <Label styles={LabelStyles}>Repo Name</Label>
            <Stack horizontal>
                <DropdownSearch
                disabled={isLoading}
                placeholder={dropdownPlaceholder}
                options={repoList}
                multiSelect={false}
                selectedKey={selectedRepo}
                onChange={(_, newValue) => {setSelectedRepo(newValue.key)}}
                />
                {selectedRepo.length > 0 && 
                <TooltipHost
                content={"Remove Filter"}
                calloutProps={{ gapSpace: 0 }}
                styles={{ root: { display: 'inline-block'} }}
                setAriaDescribedBy={false}
                >
                <IconButton 
                disabled={isLoading}
                iconProps={{iconName:'Cancel'}}
                style={{color:SharedColors.red10,marginRight:"2px"}}
                onClick={() => {setSelectedRepo("")}} 
                />
                </TooltipHost>}
            </Stack>
        </StackItem>
        <StackItem>
            <Label styles={LabelStyles}>Metadata Key</Label>
            <Stack horizontal>
                <DropdownSearch
                disabled={isLoading}
                placeholder={dropdownPlaceholder}
                options={metadataKeyList}
                multiSelect={false}
                selectedKey={selectedMetadataKey}
                onChange={(_, newValue) => {setSelectedMetadataKey(newValue.key)}}
                />
                {selectedMetadataKey.length > 0 && 
                <TooltipHost
                content={"Remove Filter"}
                calloutProps={{ gapSpace: 0 }}
                styles={{ root: { display: 'inline-block'} }}
                setAriaDescribedBy={false}
                >
                <IconButton 
                disabled={isLoading}
                iconProps={{iconName:'Cancel'}}
                style={{color:SharedColors.red10,marginRight:"2px"}}
                onClick={() => {setSelectedMetadataKey("")}} 
                />
                </TooltipHost>}
            </Stack>
        </StackItem>
        <StackItem>
            <Label styles={LabelStyles}>Metadata Value</Label>
            <Stack horizontal>
                <DropdownSearch
                disabled={isLoading}
                placeholder={dropdownPlaceholder}
                options={metadataValueList}
                multiSelect={false}
                selectedKey={selectedMetadataValue}
                onChange={(_, newValue) => {setSelectedMetadataValue(newValue.key)}}
                />
                {selectedMetadataValue.length > 0 && 
                <TooltipHost
                content={"Remove Filter"}
                calloutProps={{ gapSpace: 0 }}
                styles={{ root: { display: 'inline-block'} }}
                setAriaDescribedBy={false}
                >
                <IconButton 
                disabled={isLoading}
                iconProps={{iconName:'Cancel'}}
                style={{color:SharedColors.red10,marginRight:"2px"}}
                onClick={() => {setSelectedMetadataValue("")}} 
                />
                </TooltipHost>}
            </Stack>
        </StackItem>
        <StackItem>
            <Label styles={LabelStyles}>Team</Label>
            <Stack horizontal>
                <DropdownSearch
                disabled={isLoading}
                placeholder={dropdownPlaceholder}
                options={teamList}
                multiSelect={false}
                selectedKey={selectedTeam}
                onChange={(_, newValue) => {setSelectedTeam(newValue.key)}}
                />
                {selectedTeam.length > 0 && 
                <TooltipHost
                content={"Remove Filter"}
                calloutProps={{ gapSpace: 0 }}
                styles={{ root: { display: 'inline-block'} }}
                setAriaDescribedBy={false}
                >
                <IconButton 
                disabled={isLoading}
                iconProps={{iconName:'Cancel'}}
                style={{color:SharedColors.red10,marginRight:"2px"}}
                onClick={() => {setSelectedTeam("")}} 
                />
                </TooltipHost>}
            </Stack>
        </StackItem>
        <StackItem style={{marginTop: '28px'}}>
            <DefaultButton
            text="Search"
            styles={{ root: { width: 100 } }}
            onClick={initFn}
            />
        </StackItem>
    </Stack>
    <ScrollablePane style={{ position: "absolute", top: "220px", bottom: "50px" }}>
    <Stack horizontal tokens={{ childrenGap: 10 }}>
    <Text variant={"xLarge"}  block> Chart </Text>
    <Stack align="center" style={{marginTop: '10px', marginLeft: "auto"}} horizontal tokens={{ childrenGap: 20 }}>
    <Text variant={"large"}  block> File Count: {fileCount} </Text>
    <Text variant={"large"}  block> PR Count: {prCount}</Text>
    <Text variant={"large"}  block> Task Count: {taskCount}</Text>
    </Stack>
    </Stack>
        <Pivot aria-label="Basic Pivot Example" onLinkClick={tabSwitch} selectedKey={tabValue}>
            {!(isRepoSelected || isLoading) && <PivotItem itemKey={tabRepo} headerText={tabRepo} headerButtonProps={{"data-order": 1,}}>
                {repoData.length>0 &&
                <div style={{ width: "80vw" }}>
                <GroupedVerticalBarChart
                chartTitle="Repo Distribution"
                data={repoData}
                barwidth={40}
                enableReflow={true}
                hideLabels={false}
                />
                </div> }
            </PivotItem>}
            {!(isMetadataKeySelected || isLoading) && <PivotItem itemKey={tabMetadataKey} headerText={tabMetadataKey} headerButtonProps={{"data-order": 1,}}>
                {metadataKeyData.length>0 &&
                <div style={{ width: "80vw" }}>
                <GroupedVerticalBarChart
                chartTitle="Metadata Key Distribution"
                data={metadataKeyData}
                barwidth={40}
                enableReflow={true}
                hideLabels={false}
                />
                </div> }
            </PivotItem>}
            {!(isMetadataValueSelected || isLoading) && <PivotItem itemKey={tabMetadataValue} headerText={tabMetadataValue} headerButtonProps={{"data-order": 1,}}>
                {metadataValueData.length>0 &&
                <div style={{ width: "80vw" }}>
                <GroupedVerticalBarChart
                chartTitle="Metadata Value Distribution"
                data={metadataValueData}
                barwidth={40}
                enableReflow={true}
                hideLabels={false}
                />
                </div> }
            </PivotItem>}
        </Pivot>
    </ScrollablePane>
  </div>);
};

export default memo(Dashboard);