import { React, useState, memo } from "react";
import DropdownSearch from "../../Components/DropdownSearch";
import { DetailsList, SelectionMode, DetailsHeader, DetailsListLayoutMode, DetailsRow, TextField, IconButton, DefaultButton, Stack, PrimaryButton, StackItem } from '@fluentui/react';
import cloneDeep from "lodash/cloneDeep";
import { SharedColors } from "@fluentui/theme";
import contentProxyAPI from "../../Common/API/contentProxyAPI";
import { MessageActions } from "../../Common/Utils/Message";

const defaultFilterItems = {
  propertyType: '',
  propertyValue: ''
}

const defaultQueryItems = {
    nodeType: '',
    properties:[defaultFilterItems],
    children: [],
};

const certificationNode = {key: "certification", text: "CERTIFICATION"};
const learningpathNode = {key: "learningpath", text: "LEARNINGPATH"};
const appliedskillsNode = {key: "appliedskills", text: "APPLIEDSKILLS"};
const moduleNode = {key: "module", text: "MODULE"};
const taxonomy_roleNode = {key: "taxonomy_role", text: "TAXONOMY_ROLE"};
const taxonomy_subjectNode = {key: "taxonomy_subject", text: "TAXONOMY_SUBJECT"};
const taxonomy_productNode = {key: "taxonomy_product", text: "TAXONOMY_PRODUCT"};
const taxonomy_levelNode = {key: "taxonomy_level", text: "TAXONOMY_LEVEL"};
const taxonomy_skillsNode = {key: "taxonomy_skills", text: "TAXONOMY_SKILLS"};
const RelatedLearningPathRelationships = {key: "Related-LearningPath", text: "Related-LearningPath"};
const ForCertificationRelationships = {key: "For-Certification", text: "For-Certification"};
const ForAppliedSkillsRelationships = {key: "For-AppliedSkills", text: "For-AppliedSkills"};
const HasModuleRelationships = {key: "Has-Module", text: "Has-Module"};
const HasPrerequisitesRelationships = {key: "Has-Prerequisites", text: "Has-Prerequisites"};
const BelongsLearningpathRelationships = {key: "Belongs-Learningpath", text: "Belongs-Learningpath"};
const ForTaxonomyProductRelationships = {key: "For-Taxonomy-Product", text: "For-Taxonomy-Product"};
const ForTaxonomyLevelRelationships = {key: "For-Taxonomy-Level", text: "For-Taxonomy-Level"};
const ForTaxonomyRoleRelationships = {key: "For-Taxonomy-Role", text: "For-Taxonomy-Role"};
const RelatedTaxonomySubjectRelationships = {key: "Related-Taxonomy-Subject", text: "Related-Taxonomy-Subject"};
const RelatedTaxonomySkillsRelationships = {key: "Related-Taxonomy-Skills", text: "Related-Taxonomy-Skills"};


const certificationRelationships = [RelatedLearningPathRelationships]

const learningpathRelationships = [
  ForCertificationRelationships,
  ForAppliedSkillsRelationships,
  HasModuleRelationships,
  HasPrerequisitesRelationships,
]

const moduleRelationships = [BelongsLearningpathRelationships]

const taxonomyRelationships = [
  ForTaxonomyProductRelationships,
  ForTaxonomyLevelRelationships,
  ForTaxonomyRoleRelationships,
  RelatedTaxonomySubjectRelationships,
  RelatedTaxonomySkillsRelationships,
]

const defaultOptions = {
  nodeType: [
    certificationNode,
    learningpathNode,
    appliedskillsNode,
    moduleNode,
    taxonomy_roleNode,
    taxonomy_subjectNode,
    taxonomy_productNode,
    taxonomy_levelNode,
    taxonomy_skillsNode
  ],
  relationship: [
    ...certificationRelationships,
    ...learningpathRelationships,
    ...moduleRelationships,
    ...taxonomyRelationships
  ],
  propertyType: [
    {key: "", text: "Select Properties Type"},
    {key: "uid", text: "uid"},
    {key: "title contains", text: "title contains"},
    {key: "persistentId", text: "platformId"},
    {key: "slug", text: "slug"}
  ]
}

const nodeRelationshipMap = {
  "certification": [
    ...certificationRelationships,
    ...taxonomyRelationships
  ],
  "learningpath":[
    ...learningpathRelationships,
    ...taxonomyRelationships
  ],
  "module":[
    ...moduleRelationships,
    ...taxonomyRelationships
  ],
  "appliedskills":[
    ...certificationRelationships,
    ...taxonomyRelationships
  ]
}

const relationshipNodeTypeMap = {
  "Related-LearningPath": [learningpathNode],
  "For-Certification": [certificationNode],
  "For-AppliedSkills": [appliedskillsNode],
  "Has-Module": [moduleNode],
  "Has-Prerequisites": [learningpathNode, moduleNode],
  "Belongs-Learningpath": [learningpathNode],
  "For-Taxonomy-Product": [taxonomy_productNode],
  "For-Taxonomy-Level": [taxonomy_levelNode],
  "For-Taxonomy-Role": [taxonomy_roleNode],
  "Related-Taxonomy-Subject": [taxonomy_subjectNode],
  "Related-Taxonomy-Skills": [taxonomy_skillsNode],
}
const NestedDetailsList = ({ items, callBackFuncs, nestingDepth = 0, indexList = [], parentItem = null }) => {
  const columns = [
    {
      key: "RemoveRelationship",
      name: "",
      fieldName: "t_id",
      onRender: (item, _index) => (
        <IconButton
          iconProps={{ iconName: "Clear" }}
          style={{ color: SharedColors.red10 }}
          onClick={() => {
            callBackFuncs.removeQuery([...indexList, _index])
          }}
        />
      ),
      maxWidth: 10,
      minWidth: 10,
    },
    {
      key: "Relationship",
      name: <span>Relationship <span style={{ color: 'red' }}>*</span></span>,
      fieldName: "relationship",
      minWidth: 150,
      maxWidth: 200,
      onRender: (item, index) => (
        <DropdownSearch
          placeholder='Select Relationship'
          options={parentItem ? nodeRelationshipMap[parentItem.nodeType.toLowerCase()] : defaultOptions.relationship}
          multiSelect={false}
          selectedKey={item.relationship}
          onChange={(_, newValue) => {
            callBackFuncs.setQuery([...indexList, index], 'relationship', newValue.key)
          }}
        />
      ),
    },
    {
      key: "NodeType",
      name: <span>NodeType <span style={{ color: 'red' }}>*</span></span>,
      fieldName: "NodeType",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item, _index) => (
        <DropdownSearch
          placeholder='Select NodeType'
          options={item.relationship ? relationshipNodeTypeMap[item.relationship]: defaultOptions.nodeType}
          multiSelect={false}
          selectedKey={item.nodeType}
          onChange={(_, newValue) => {
            callBackFuncs.setQuery([...indexList, _index], 'nodeType', newValue.key)
          }}
        />
      ),
    },
    {
      key: "Properties Type",
      name: "Properties Type",
      fieldName: "Properties Type",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item, _index) => (
        <div>
          {item.properties.map((property, propertyIndex) => (
            <DropdownSearch
              key={propertyIndex}
              placeholder='Select Properties Type'
              options={defaultOptions.propertyType}
              multiSelect={false}
              selectedKey={property.propertyType}
              onChange={(_, newValue) => {
                callBackFuncs.setFilter([...indexList, _index], propertyIndex, 'propertyType', newValue.key)
              }}
            />
          ))} 
        </div>
      ),
    },
    {
      key: "Properties Value",
      name: "Properties Value",
      fieldName: "Properties Value",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item, _index) => (
        <div>
          {item.properties.map((property, propertyIndex) => (
            <TextField
              key={propertyIndex}
              disabled={false}
              value={property.propertyValue}
              onChange={(_, newValue) => {
                callBackFuncs.setFilter([...indexList, _index], propertyIndex, 'propertyValue', newValue)
              }}
            />
          ))} 
        </div>
      ),
    },
    {
      key: "Property Action",
      name: "Property Action",
      fieldName: "t_id",
      onRender: (item, _index) => (
        <div>
          {item.properties.map((property, propertyIndex) => (
            <div>
              <IconButton
                iconProps={{ iconName: propertyIndex === 0 ? "Add": "Clear"}}
                style={propertyIndex === 0 ?{}:{color: SharedColors.red10}}
                onClick={() => {
                  if (propertyIndex === 0) {
                    callBackFuncs.addFilter([...indexList, _index])
                  }else {
                    callBackFuncs.removeFilter([...indexList, _index], propertyIndex)
                  }
                }}
              />
            </div>
          ))} 
        </div>
      ),
      maxWidth: 100,
      minWidth: 100,
    },
    {
      key: "Add Relationship",
      name: "",
      fieldName: "Add Relationship",
      onRender: (item, _index) => (
        <DefaultButton onClick={() => {callBackFuncs.addQuery([...indexList, _index])}} text="Add Relationship" />
      ),
      maxWidth: 150,
      minWidth: 150,
    },
  ];

  const onRenderRow = (props: IDetailsRowProps) => {
    return (
      <div>
        <DetailsRow {...props} />
            {props.item.children && props.item.children.length > 0 && (
        <div style={{ paddingLeft: 20 }}>
          <NestedDetailsList
            items={props.item.children}
            callBackFuncs={callBackFuncs}
            nestingDepth={nestingDepth + 1}
            indexList={[...indexList, props.itemIndex]}
            parentItem={props.item}
          />
        </div>
      )}
      </div>
    );
  }
  const onRenderDetailsHeader = (props: IDetailsHeaderProps) => {
    const columnsWithNestingDepth = props.columns.map(column => ({
      ...column,
      onRenderHeader: () => (
        <div>
          {column.name}
        </div>
      ),
    }));
  
    return <DetailsHeader {...props} columns={columnsWithNestingDepth} />;
  };

  return (
    <DetailsList
      items={items}
      columns={nestingDepth === 0 ? columns.filter(column => !['Relationship', 'RemoveRelationship'].includes(column.key)) : columns}
      styles={{
        root: {
          marginBottom: 10,
        },
      }}
      selectionMode={SelectionMode.none}
      layoutMode={DetailsListLayoutMode.fixedColumns}
      compact={true}
      isHeaderVisible={true}
      onRenderRow={(props) => onRenderRow(props)}
      onRenderDetailsHeader={(headerProps) => onRenderDetailsHeader(headerProps)}
    />
  );
};


const GraphApiInput = ({ submitInputFunc }) => {
  const [queryItems, setQueryList] = useState([defaultQueryItems]);

  const addQuery = (indexList) => {
    if (indexList.length === 0) {
      return;
    }
    const newElement = cloneDeep(queryItems);
    let tmp = newElement[indexList[0]];
    for (let i=1; i<indexList.length; i++) {
      tmp = tmp.children[indexList[i]];
    }
    tmp.children.push(defaultQueryItems);
    setQueryList(newElement);
  }

  const removeQuery = (indexList) => {
    if (indexList.length === 0) {
      return;
    }
    const newElement = cloneDeep(queryItems);
    let tmp = newElement[indexList[0]];
    for (let i=1; i<indexList.length-1; i++) {
      tmp = tmp.children[indexList[i]];
    }
    tmp.children.splice(indexList[indexList.length-1], 1);
    setQueryList(newElement);
  }

  const setQuery = (indexList, key, value) => {
    if (indexList.length === 0) {
      return;
    }
    const newElement = cloneDeep(queryItems);
    let tmp = newElement[indexList[0]];
    for (let i=1; i<indexList.length; i++) {
      tmp = tmp.children[indexList[i]];
    }
    tmp[key] = value;
    if (key === 'relationship') {
      tmp.nodeType = '';
    }
    setQueryList(newElement);
  }
  
  const addFilter = (indexList) => {
    if (indexList.length === 0) {
      return;
    }
    const newElement = cloneDeep(queryItems);
    let tmp = newElement[indexList[0]];
    for (let i=1; i<indexList.length; i++) {
      tmp = tmp.children[indexList[i]];
    }
    tmp.properties.push(defaultFilterItems);
    setQueryList(newElement);
  }

  const removeFilter = (indexList, filterIndex) => {
    if (indexList.length === 0) {
      return;
    }
    const newElement = cloneDeep(queryItems);
    let tmp = newElement[indexList[0]];
    for (let i=1; i<indexList.length; i++) {
      tmp = tmp.children[indexList[i]];
    }
    tmp.properties.splice(filterIndex, 1);
    setQueryList(newElement);
  }

  const setFilter = (indexList, filterIndex, key, value) => {
    if (indexList.length === 0) {
      return;
    }
    const newElement = cloneDeep(queryItems);
    let tmp = newElement[indexList[0]];
    for (let i=1; i<indexList.length; i++) {
      tmp = tmp.children[indexList[i]];
    }
    tmp.properties[filterIndex][key] = value;
    setQueryList(newElement);
  }
  const genReq = () => {
    let queryList = [];
    let selectList = [];
    let uniqueNodeIndex = 0
    const genReqInner = (items, parentNodeAs = '') => {
      items.forEach(item => {
        uniqueNodeIndex ++;
        let uniqueNodeAs = `node${uniqueNodeIndex}`;
        if (!selectList.includes(item.nodeType)) {
          selectList.push(item.nodeType);
        }
        if (!selectList.includes(uniqueNodeAs)) {
          selectList.push(uniqueNodeAs);
        }
        let query = {};
        if (parentNodeAs) {
          query = {
            source: {
              refer: parentNodeAs
            },
            relationship: item.relationship,
            target: {
              nodeType: item.nodeType,
              properties: genProperties(item),
              as: uniqueNodeAs
            }
          };
        }else {
          query = {
            source: {
              nodeType: item.nodeType,
              properties: genProperties(item),
              as: uniqueNodeAs
            }
          }
        }
        queryList.push(query);
        if (item.children.length > 0) {
          genReqInner(item.children, uniqueNodeAs);
        }
      });
    };

    const genProperties = (item) => {
      return item.properties.reduce((acc, property) => {
        if (property.propertyType) {
          if (property.propertyType === 'title contains') {
            acc['title'] = {contains: property.propertyValue};
          } else {
            acc[property.propertyType] = property.propertyValue;
          }
        }
        return acc;
      }, {})
    }

    genReqInner(queryItems, '');
    return {
      "query": queryList,
      "select": selectList
    }
  }
  
  const RespToGraph = (data) => {
    const nodes = [];
    const edges = [];

    Object.keys(data.nodes).forEach(key => {
      data.nodes[key].forEach((item) => {
        nodes.push({
          id: item.uid ? item.uid : item.slug,
          label: item.uid ? item.uid : item.slug,
          type: item.pageKind,
          raw: item
        });
      });
    });

    let filteredNodes = nodes.filter((node, index, self) =>{
      return index === self.findIndex((n) => n.id === node.id);
    });
    
    data.relationships.forEach((item) => {
      edges.push(item);
    });
    return {nodes: filteredNodes, relationships: edges};
  }

  const checkQuery = (items, parentItem = null) => {
    items.forEach(item => {
      if (item.nodeType.length === 0) {
        throw new Error("Please fill in all required fields");
      }
      if (parentItem && item.relationship.length === 0) {
        throw new Error("Please fill in all required fields");
      }
      checkQuery(item.children);
    });
  }

  const submitQuery = async () => {
    try {
      checkQuery(queryItems);
      const resp = await contentProxyAPI.getGraph(genReq());
      if(resp.status === 200) {
        submitInputFunc(RespToGraph(resp.data));
      }else{
        throw new Error(resp.data);
      }
    } catch (e) {
      MessageActions.errorHandler(e);
    }
  }

  return (
    <div>
      <NestedDetailsList items={queryItems} callBackFuncs={
        {
          addQuery: addQuery,
          removeQuery: removeQuery,
          setQuery: setQuery,
          addFilter: addFilter,
          removeFilter: removeFilter,
          setFilter: setFilter
        }} />
      <Stack>
        <StackItem>
          <PrimaryButton 
            styles={{ root: { marginTop: 10 } }}
            text="Submit" 
            onClick={submitQuery} 
            allowDisabledFocus 
          />
        </StackItem>
      </Stack>
    </div>
  );
};

export default memo(GraphApiInput);