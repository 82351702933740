import APIBase from "./APIBase";
import { configs } from "../Config";
import _ from "lodash";
import { NumberRegex } from "../constants";
const baseURL = configs.TaskApp.endpoint;
class TaskAPI {
  static getTaskDetail(id) {
    return APIBase.get(`${baseURL}/api/task/${id}`);
  }
  static submitTask(data) {
    return APIBase.post(`${baseURL}/api/task/submit`, data);
  }

  static queryPageSubmit(data) {
    return APIBase.post(`${baseURL}/api/task/querySubmit`, data);
  }

  static usageBatchSubmit(data) {
    return APIBase.post(`${baseURL}/api/task/batchSubmit`, data);
  }
  
  static getTasks(data) {
    return APIBase.post(`${baseURL}/api/task/list`,data);
  }

  static getSubTasks(id,pageNumber,pageSize) {
    return APIBase.get(`${baseURL}/api/subTask/${id}?pageNo=${pageNumber}&pageSize=${pageSize}`);
  }
  
  static getMetadata(platformId,locale,branch){
    let query = `${platformId}?target=MetadataViewer&`;

    if (locale.length > 0) {
      query = `${query}locale=${locale}&`;
    }
    else {
      query = `${query}locale=en-us&`;
    }

    if (branch.length > 0) {
      query = `${query}branch=${branch}&moniker=&filePath=&prLink=`;
    }
    else {
      query = `${query}branch=live&moniker=&filePath=&prLink=`;
    }

    return APIBase.get(`${baseURL}/api/metadata/${query}`)
  }

  static submitRepoFolderTask(data) {
    return APIBase.post(`${baseURL}/api/task/submitRepoFolder`, data);
  }

  static getDocfxList(path) {
    return APIBase.get(`${baseURL}/api/docfx?path=${path}`);
  }

  static getFileListMetadata(paths) {
    return APIBase.post(`${baseURL}/api/singleFileMetadata`, paths);
  }

  static submitFileListTask(data) {
    return APIBase.post(`${baseURL}/api/task/submitFileList`, data);
  }

  static subTaskFileOperation(data) {
    return APIBase.post(`${baseURL}/api/subTaskFile/operation`, data);
  }
  
  static subTaskFileMerge(data) {
    return APIBase.post(`${baseURL}/api/subTaskFile/merge`, data);
  }
  
  static taskRerun(data,taskId) {
    return APIBase.post(`${baseURL}/api/task/${taskId}/rerun`, data);
  }

  static taskAbandon(data,taskId) {
    return APIBase.post(`${baseURL}/api/task/${taskId}/abandon`, data);
  }

  static attachAdoLink(data,taskId) {
    return APIBase.post(`${baseURL}/api/task/attach/${taskId}`, data);
  }


  static getMetadataKeys(){
    return APIBase.get(`${baseURL}/api/metadata/keys`);
  }

  static getMetadataList(data){
    return APIBase.post(`${baseURL}/api/queryboard/metadata/query`, data);
  }

  static getCannedQuery(id){
    return APIBase.get(`${baseURL}/api/queryboard/queries/user/${id}`);
  }

  static saveQuery(data){
    return APIBase.post(`${baseURL}/api/queryboard/queries`, data);
  }

  static loadTeamTemplate() {
    return APIBase.get(`${baseURL}/api/resource/team/list?permission=write`);
  }

  static loadDashboardTeam() {
    return APIBase.get(`${baseURL}/api/resource/team/list`);
  }

  static templateSubmit(id, data) {
    return APIBase.post(`${baseURL}/api/resource/team/${id}/templateConfig`, data);
  }

  static templateReview(data) {
    return APIBase.post(`${baseURL}/api/resource/team/template/preview`, data);
  }

  static getDashboardData(data) {
    return APIBase.post(`${baseURL}/api/dashboard`, data);
  }

  static getTaxonomyList(){
    return APIBase.get(`${baseURL}/api/const/sdpTaxonomies`);
  }

  static getX() {
    return APIBase.get(`https://knowledgegraphrag-api-pubdev-wus.azurewebsites.net/ping`);
  }

}

export default TaskAPI;
