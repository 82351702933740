export default {
  TaxonomyApp: {
    endpoint: 'https://taxonomy.dev.learn.microsoft.com',
    // endpoint: "https://localhost:64400",
    clientId: '4b747966-149a-46f6-9002-10d31530f106',
    authority: 'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47',
  },
  TaskApp:{
    endpoint: 'https://metadatamanagement-pubdev-e8a4hagwc7awfjbw.z01.azurefd.net',
    metadataListReport: 'https://msit.powerbi.com/reportEmbed?reportId=a8ab956f-be76-494f-b645-ef38c5bdc4fb&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47',
    conceptReport:'https://msit.powerbi.com/reportEmbed?reportId=30e24ba5-418e-4180-8a99-aa777a83ecca&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47',
  },
  RagApp:{
    contentProxyClientIdURI: 'https://content-proxy-sandbox.docs.microsoft.com',
    contentProxyEndpoint: 'https://contentproxypubdev-b8g9accnbvfjcabc.b01.azurefd.net',
    knowledgeServiceEndpoint: 'https://dev.learn.microsoft.com',
    knowledgeServiceClientId: '7c78db7f-b420-4cb8-b448-fc0015661260',
  }
};
