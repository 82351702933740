import { useState, useEffect, memo} from "react";
import BackHomeButton from "../Components/BackHomeButton";
import { Pivot, PivotItem, Text } from "@fluentui/react";
import GraphApiInput from "./Form/graphApiInput";
import GraphRagInput from "./Form/graphRagInput";
import PlanList from "./List/planList";
import Graph from "./Context/graph";
import ContextTable from "./Context/table";
import PromptInput from "./Form/promptInput";
import KnowledgeServiceInput from "./Form/knowledgeServiceInput";

const InputTypeGraphApi = "GraphApi";
const InputTypeGraphRag = "GraphRag";
const InputTypeKnowledgeService = "KnowledgeService";
const ContextTypeGraph = "Graph";
const ContextTypeTable = "Table";

const Rag = (props) => {
  document.title = "Rag";
  const [graphItems, setGraphItems] = useState({});
  const [planItems, setPlanItems] = useState([]);
  const [selectedContextType, setSelectedContextType] = useState(ContextTypeGraph);

  useEffect(() => {
    document.body.style.overflowY = 'auto';

    return () => {
      document.body.style.overflowY = 'hidden';
    };
  }, []);

  const submitInput = (graphItems) => {
    setPlanItems([])
    setGraphItems(graphItems)
  }

  const submitPrompt = (planItems) => {
    setPlanItems(planItems)
  }

  const handleInputPivotClick = (item) => {
    setSelectedContextType(item.props.itemKey === InputTypeKnowledgeService ? ContextTypeTable : ContextTypeGraph);
    setGraphItems({});
    setPlanItems([]);
  };
  const handleContextPivotClick = (item) => {
    setSelectedContextType(item.props.itemKey);
  };

  return (
    <div>
      <BackHomeButton />
      <div>
        <Pivot aria-label="" linkFormat="tabs" linkSize="large"  onLinkClick={handleInputPivotClick}>
          <PivotItem itemKey={InputTypeGraphApi} headerText={InputTypeGraphApi}>
            <GraphApiInput submitInputFunc={submitInput} />
          </PivotItem>
          <PivotItem itemKey={InputTypeGraphRag} headerText={InputTypeGraphRag}>
            <GraphRagInput submitInputFunc={submitInput} />
          </PivotItem>
          <PivotItem itemKey={InputTypeKnowledgeService} headerText={InputTypeKnowledgeService}>
            <KnowledgeServiceInput submitInputFunc={submitInput} />
          </PivotItem>
        </Pivot>
      </div>
      {graphItems && "nodes" in graphItems ? (
        <div>
          <Text block styles={{ root: { marginTop: 10 } }}><b>Context</b></Text>
          <Pivot aria-label="" linkFormat="tabs" linkSize="large" selectedKey={selectedContextType}  onLinkClick={handleContextPivotClick}>
            <PivotItem itemKey={ContextTypeGraph} headerText={ContextTypeGraph}>
              <Graph graphItems={graphItems} />
            </PivotItem>
            <PivotItem itemKey={ContextTypeTable} headerText={ContextTypeTable}>
              <ContextTable graphItems={graphItems} />
            </PivotItem>
          </Pivot>
          <PromptInput graphItems={graphItems} submitPromptFunc={submitPrompt} />
        </div>
      ) : ""}
      {planItems.length > 0 ? (
        <PlanList items={planItems} />
      ) : ""}
    </div>
  );
};

export default memo(Rag);
