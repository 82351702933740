import React, { useState, useEffect } from "react";
import { MessageBar as Message } from "@fluentui/react";
import { mergeStyles } from "@fluentui/react";
import { EventClient, EventNames } from "../Common/Utils/simpleEventClient";
import { useLocation } from "react-router-dom";

const TopMessage = () => {
  const location = useLocation();
  const [message, setMessage] = useState(null);
  const onChange = (message) => {
    setMessage(message);
  };

  const Dissmiss = () => {
    setMessage(null);
  };

  const messageWrapper = mergeStyles([
    {
      position: "sticky",
      top: 0,
      zIndex: 2000,
      marginTop: 10,
    },
  ]);

  useEffect(() => {
    setMessage(null);
  }, [location]);

  useEffect(() => {
    EventClient.addEventListener(EventNames.ShowMessageBar, onChange);
    return () => {
      EventClient.removeEventListener(EventNames.ShowMessageBar, onChange);
    };
  }, []);

  const content = message ? (
    <div className={messageWrapper}>
      <Message
        messageBarType={message.type}
        isMultiline={false}
        onDismiss={Dissmiss}
        dismissButtonAriaLabel="Close error message"
        truncated={true}
        overflowButtonAriaLabel="See more"
      >
        {message.message}
      </Message>
    </div>
  ) : null;
  return content;
};

export default TopMessage;
