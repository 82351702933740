import React, { useEffect, useState, useRef } from 'react';
import { SelectionMode, Stack, Sticky, Text, ShimmeredDetailsList, mergeStyles, ActionButton, ScrollablePane } from '@fluentui/react';
import { useParams, useHistory } from "react-router-dom";
import BackHomeButton from '../Components/BackHomeButton';
import ManagementAPI from '../Common/API/managementAPI';
import ResponseErrorHandler from '../Common/responseErrorHandler';
import { MessageActions } from '../Common/Utils/Message';
import _ from 'lodash';
import dayjs from 'dayjs';
import { overflowCellHover, collapseCell, PageHeader, expandCell } from '../Common/customStyle';
import { jobIdRe } from '../Common/constants';
import { dateTimeFormatUS } from '../Common/constants';
import Resources from '../Common/resources';
import { CsvDataService } from '../Common/csvHelper';

const renderItemColumn = (item, index, column) => {
  let content = item[column.fieldName];
  // work around fabric bug here
  if (content === true || content === false) {
    content = _.toString(content);
  }
  let customStyles = mergeStyles(overflowCellHover);
  return <span className={customStyles}>{content}</span>
}

function JobErrors(props) {
  const { jobs } = props;
  const { failureReport } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [currentJob, setCurrentJob] = useState({});
  const commonTokens = { childrenGap: 20 };

  const currentReport = useRef();
  const history = useHistory();

  const clearState = () => {
    setIsLoading(false);
    setReports([]);
    setCurrentJob({});
  }

  const downloadFailureReport = () => {
    CsvDataService.exportToCsv(currentJob.jobId + '.csv', currentReport.current);
  }

  useEffect(() => {
    const failureReportUrl = failureReport;
    ManagementAPI.getFailureReport(failureReportUrl).then(res => {
      MessageActions.clear();
      setReports(res.data);
      currentReport.current = res.data;
      const currentJobIdMatch = jobIdRe.exec(failureReportUrl);
      if (currentJobIdMatch && currentJobIdMatch[0]) {
        const currentJobId = currentJobIdMatch[0];
        let currentJob = jobs.find(job => { return job.jobId === currentJobId });
        setCurrentJob(currentJob);
        setIsLoading(false);
      } else {
        ResponseErrorHandler(Resources.TopMessage.GetJobFailed);
        setIsLoading(false);
      }

    }).catch((error) => {
      ResponseErrorHandler(error);
      clearState();
    })
  }, [failureReport, jobs])

  const shimmeredDetailsListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
  };

  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <BackHomeButton
          text={Resources.Tasks.BackToTasklist}
          backFn={() => {
            history.push("/taxonomy");
          }}
        />
      <h1 className={mergeStyles(PageHeader)}>{Resources.JobErrorReport.PageHeader}</h1>
      <Stack horizontal horizontalAlign="left" verticalAlign="center" tokens={commonTokens}>
        <Stack.Item grow disableShrink>
          <Text><strong>{Resources.Labels.startedAt}</strong> {currentJob && dayjs(currentJob.startedAt).format(dateTimeFormatUS)}</Text>
        </Stack.Item>
        <Stack.Item grow disableShrink>
          <Text><strong>{Resources.Labels.jobId} </strong>{currentJob && currentJob.jobId}</Text>
        </Stack.Item>
        <Stack.Item grow disableShrink>
          <Text><strong>{Resources.Labels.status} </strong>{currentJob && currentJob.jobStatus}</Text>
        </Stack.Item>
        <Stack.Item disableShrink>
          <ActionButton primary={true} onClick={downloadFailureReport} iconProps={{ iconName: 'Download' }} text="Download" disabled={isLoading} />
        </Stack.Item>
      </Stack>

      <ScrollablePane
        style={{ position: "absolute", top: "250px", bottom: "50px" }}
      >
        <ShimmeredDetailsList
          setKey="reports"
          items={reports}
          columns={[
            {
              key: "Taxonomy",
              name: 'Taxonomy',
              fieldName: 'Taxonomy',
              maxWidth: 150,
            },
            {
              key: "ErrorCode",
              name: 'Error Code',
              fieldName: 'ErrorCode',
              maxWidth: 150,
            },
            {
              key: "ErrorMessage",
              name: 'Error Message',
              fieldName: 'ErrorMessage',
              minWidth: 200,
              onRender: item => {
                return (<span style={expandCell}>{item.ErrorMessage}</span>)
              }
            },
            {
              key: "Concepts",
              name: 'Concepts',
              fieldName: 'Uids',
              minWidth: 200,
              maxWidth: 400,
              onRender: (item, index) => {
                return (<span  >
                  {item.Uids && item.Uids.map((uid, idx) => {
                    let label = '';
                    if (item.Labels) {
                      label = item.Labels[idx];
                    }
                    if (!label) {
                      label = uid && _.last(uid.split('/'));
                    }

                    return <div key={uid} className={mergeStyles(overflowCellHover, collapseCell)}>
                      {uid && <a target='_blank' href={uid} rel="noopener noreferrer">{label}</a>}
                    </div>
                  })}
                </span>)
              }
            }
          ]}
          selectionMode={SelectionMode.none}
          shimmerLines={10}
          enableShimmer={isLoading}
          ariaLabelForShimmer="Content is being fetched"
          ariaLabelForGrid="Job errors"
          listProps={shimmeredDetailsListProps}
          onRenderItemColumn={renderItemColumn}
          onRenderDetailsHeader={
            // tslint:disable-next-line:jsx-no-lambda
            (detailsHeaderProps, defaultRender) => (
              <Sticky>{defaultRender(detailsHeaderProps)}</Sticky>
            )
          }
        />
      </ScrollablePane>
    </div>
  );
}

export default JobErrors;
