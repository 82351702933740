import APIBase from "./APIBase";
import axios from "axios";
class ManagementAPI {
  static getJobsBetweenTimeRange(startDate, endDate) {
    const startDateStr = startDate.toISOString();
    const endDateStr = endDate.toISOString();
    return APIBase.get(
      `management/jobs?startTime=${startDateStr}&endTime=${endDateStr}`
    );
  }

  static getNames(isFullView) {
    if (isFullView) {
      return APIBase.get(`management/taxonomies/names?fullview=${isFullView}`);
    }
    return APIBase.get(`management/taxonomies/names`);
  }

  static getLocalizableTaxonomyNames() {
    //  return Promise.resolve({
    //   data: [
    //     "devlang",
    //     "product",
    //     "productDevlang",
    //     "productQualifier",
    //     "productSubject",
    //     "qualifier",
    //     "subject",
    //   ],
    // });
    // returnPromise.resolve({ data: [""] });
    // return Promise.reject();
    return APIBase.get(`management/taxonomies/localizableTaxonomyNames`);
  }

  static getLocales() {
    //return Promise.resolve({ data: ["en-us", "de-de", "zh-cn", "ja-jp"] });
    // return Promise.resolve({ data: [""] });
    // return Promise.reject();
    return APIBase.get(`management/taxonomies/locales`);
  }

  static getProjectNames() {
    return APIBase.get(`management/writableProjects`);
  }

  static getTaxonomyByVersion(name, version) {
    return APIBase.get(
      `management/taxonomies/${name}?version=${encodeURIComponent(version)}`
    );
  }

  static getLocTaxonomyByVersion(name, version, locale) {
    return APIBase.get(
      `management/taxonomies/${name}?version=${encodeURIComponent(
        version
      )}&locale=${locale}`
    );
  }

  static getSimplifiedTaxonomy(name, version) {
    if (!version) {
      return APIBase.get(`management/taxonomies/simplified/${name}`);
    }
    return APIBase.get(
      `management/taxonomies/simplified/${name}?version=${version}`
    );
  }

  static getLastSuccessJob(jobId) {
    return APIBase.get(`management/jobs/lastSuccess?jobId=${jobId}`);
  }

  static getTaxonomySchema(name) {
    return APIBase.get(`management/taxonomies/${name}/schema`);
  }

  static import(
    markdownOnly = false,
    forceUpdate = false,
    ProjectNamesToProcess = []
  ) {
    return APIBase.post(
      `management/import?markdownOnly=${markdownOnly}&forceUpdate=${forceUpdate}`,
      ProjectNamesToProcess
    );
  }

  static compoundGenerator(ProjectNamesToProcess = []) {
    // return APIBase.post("management/generator", { ProjectNamesToProcess });
    return APIBase.post("management/generator", ProjectNamesToProcess);
  }

  static getFailureReport(failureReport) {
    return APIBase.get(`management/report/${failureReport}`);
  }
}

export default ManagementAPI;
