import * as React from 'react';
import { memo } from "react";
import { List, getTheme, Link, mergeStyleSets, getFocusStyle } from "@fluentui/react";

const generateStyles = (theme) => {
  const { palette, semanticColors, fonts } = theme;
  return mergeStyleSets({
    itemCell: [
      getFocusStyle(theme, { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: 'border-box',
        borderBottom: `1px solid ${semanticColors.bodyDivider}`,
        display: 'flex',
        selectors: {
          '&:hover': { background: palette.neutralLight },
        },
      },
    ],
    itemContent: {
      marginLeft: 10,
      overflow: 'hidden',
      flexGrow: 1,
    },
    itemTitle: [
      fonts.xLarge,
      {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: 10,
        marginTop: 10,
      },
    ],
  });
};

const PlanList = (props) => {
  // const { items } = props;
  const items = [
    {
      title: 'Step 1',
      children: [
        {
          title: 'Node 1.1',
          description: 'Description for Node 1.1',
        },
        {
          title: 'Node 1.2',
          description: 'Description for Node 1.2',
        },
      ],
    },
    {
      title: 'Step 2',
      children: [
        {
          title: 'Node 2.1',
          description: 'Description for Node 2.1',
        },
        {
          title: 'Node 2.2',
          description: 'Description for Node 2.2',
        },
      ],
    },
    {
      title: 'Step 3',
      children: [
        {
          title: 'Node 3.1',
          description: 'Description for Node 3.1',
        },
        {
          title: 'Node 3.2',
          description: 'Description for Node 3.2',
        },
      ],
    },
  ];
  const theme = getTheme();
  const classNames = React.useMemo(() => generateStyles(theme), [theme]);

  return (
      <List
        items={items}
        onRenderCell={(item, index) => (
          <div>
            <h5 className={classNames.itemTitle}>{item.title}</h5>
            <List 
              items={item.children}
              onRenderCell={(item, index) => (
                <div>
                  <div className={classNames.itemContent}>
                    <h6 className={classNames.itemTitle}>
                      <Link href="">{item.title}</Link>
                    </h6>
                    <p>{item.description}</p>
                  </div>
                </div>
              )}
            />
          </div>
        )}
      />
  );
};

export default memo(PlanList);
