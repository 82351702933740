import { useState, memo } from "react";
import {
  PrimaryButton,
  TextField,
  Stack,
  StackItem,
} from "@fluentui/react";
import _ from "lodash";
import { DefaultPrompt } from "../Prompt/defaultPrompt";

const PromptInput = (props) => {
  const { graph, submitPromptFunc } = props;
  const [ prompt, setPrompt] = useState("");
  return (
    <Stack>
      <Stack>
        <TextField 
          label="Prompt"
          required={prompt?.length === 0} 
          multiline 
          styles={{ root: { minWidth: 300 } }}
          rows={25} 
          // value = {DefaultPrompt}
          onChange={(_, newValue) => {
            setPrompt(newValue)
          }}/>
      </Stack>
    <Stack>
      <StackItem>
        <PrimaryButton 
          styles={{ root: { marginTop: 10 } }}
          text="Submit" 
          onClick={()=>{}} 
          allowDisabledFocus 
        />
      </StackItem>
    </Stack>
  </Stack>
  );
};

export default memo(PromptInput);
