import * as React from 'react';
import { memo } from "react";
import { DetailsList, SelectionMode, TooltipHost } from "@fluentui/react";

const ContextTable = ({ graphItems }) => {
  const getColumns = () => {
    if (!graphItems.nodes || graphItems.nodes.length === 0) {
        return [];
    }
    let columns = [];
    Object.keys(graphItems.nodes[0].raw).forEach(key => {
        columns.push({ 
            key: key, 
            name: key, 
            fieldName: key, 
            minWidth: 200, 
            maxWidth: 300, 
            isResizable: true,
            onRender: (item) => (
                key in item ? (<TooltipHost content={item[key]}>
                    <span className="truncated">{item[key]}</span>
                </TooltipHost>) : ""
            ), 
    });
    });
    return columns;
  };

  const getItems = () => {
    if (!graphItems.nodes) {
        return [];
    }
    const nodes = graphItems.nodes.map((node) => node.raw);
    return nodes;
  };

  return (
    <DetailsList
      items={getItems()}
      columns={getColumns()}
      selectionMode={SelectionMode.none}
      styles={{ root :{ height: '400px', overflowY: 'auto' }}}
    />
  );
};

export default memo(ContextTable);
