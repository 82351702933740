import React from "react";
import { useEffect, useState } from "react";
import {
   Dropdown, DropdownMenuItemType, IDropdownOption, SearchBox 
  } from "@fluentui/react";


  const DropdownSearch: React.FunctionComponent<IDropdownProps> = (props) => {
    const [searchText, setSearchText] = useState('');
    function renderOption(option: IDropdownOption): JSX.Element {
      return (option.itemType === DropdownMenuItemType.Header && option.key === "FilterHeader") ?
        <SearchBox onChange={(ev, newValue) => setSearchText(newValue)} underlined={true} placeholder="Search options" /> : <>{option.text}</>;
    }
    
    let op = [
      { key: 'FilterHeader', text: '-', itemType: DropdownMenuItemType.Header },
      { key: 'divider_filterHeader', text: '-', itemType: DropdownMenuItemType.Divider },
    ];
    if (props.options){
      op.push(...props.options.map(option => !option.disabled && option.text.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ?
        option : { ...option, hidden: true }
      ));
    }
    return (
      <Dropdown
        {...props}

        options={op}
        calloutProps={{ shouldRestoreFocus: false, setInitialFocus: false }} //not working
        onRenderOption={renderOption}
        onDismiss={() => setSearchText('')}
      />
    );
  
  };


export default DropdownSearch;