import {  useState, memo,  useMemo, useCallback, useEffect } from "react";
import Markdown from 'react-markdown'
import BackHomeButton from "../../Components/BackHomeButton";
import {
  Text,
  SelectionMode,
  PrimaryButton,
  ScrollablePane,
  TextField,
  DefaultButton,
  Dialog,
  Pivot,
  PivotItem,
  DetailsList,
  Stack,
  StackItem,
  ActionButton,
  Label,
  mergeStyles,
  Toggle,
  FocusZone,
  SearchBox,
  DirectionalHint,
} from "@fluentui/react";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import Resources from "../../Common/resources";
import { useBoolean } from "@uifabric/react-hooks";
import TaskAPI from "../../Common/API/taskApi";
import { MessageActions } from "../../Common/Utils/Message";
import { expandCell } from "../../Common/customStyle";
import RepoConfigAPI from "../../Common/API/repoConfigApi";
import { useHistory } from "react-router-dom";

const DocumentStyles = {
    Container: { position: "relative", height: "100vh" },
    InnerScroll: { position: "absolute", top: "50px", bottom: "50px" },
  };

const LabelStyles = {
    root: {
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        WebkitFontSmoothing: 'antialiased',
        fontSize: '14px',
        fontWeight: '600',
        color: 'rgb(50, 49, 48)',
        boxSizing: 'border-box',
        boxShadow: 'none',
        margin: '0px',
        display: 'block',
        padding: '5px 0px',
        overflowWrap: 'break-word'
    }
}

const TextFieldLongStyles = {
    fieldGroup: [
        { width: 300 },
      ],
};

const TextFieldShortStyles = {
    fieldGroup: [
        { width: 150 },
      ],
};

const DropdownSearch = (props) =>{
    let [items, setItems] = useState(props.items);
    const filteredItems = props.items.filter(({key}) =>
      key?.includes(props.value));
    let [value, setValue] = useState(props.value ? filteredItems.length===0 ? props.placeHolder : filteredItems[0].text : props.placeHolder);
  
    const onRenderMenuList = (itemProps, defaultRender) => {
      return (
        <FocusZone>
          <SearchBox auroComplete="off" onChange={onSearch} />
          {itemProps.totalItemCount > 0 && itemProps.items[0].key != "NOMATCH" && defaultRender(itemProps)}
          {itemProps.totalItemCount == 1 && itemProps.items[0].key == "NOMATCH" && (
            <p>{props.noMatchText ? props.noMatchText:"No Matched Result, Refine seach."}</p>)}
        </FocusZone>
      )
    }
  
    const onSearch = (e, v) => {
      const newItems = props.items.filter(({ text }) => text?.includes(v));
      if (newItems.length == 0) {
        setItems([{ text:"No Match", key:"NOMATCH"}]);
      } else { 
        setItems(newItems);
      }
    };
  
    const menuProps = {
      items: items,
      onItemClick : (e, i) =>{
        setItems(props.items);
        setValue(i.text);
        const selItems = props.items.filter(({text})=>text?.includes(i.text));
        props.onChange(selItems[0]);
      },
      onRenderMenuList: onRenderMenuList,
      styles: {container: {width:props.width, maxHeight: props.maxHeight}},
      directionalHint: DirectionalHint.bottomAutoEdge,
      directionalHintFixed: true
    };
  
    return (
      <DefaultButton 
      styles={{root:{width: props.width, textAlign: "left"}}} 
      menuIconProps={{iconName: "ChevronDownSmall"}} 
      text={value} 
      menuProps={menuProps} 
      onAfterMenuDismiss={()=>{setItems(props.items);}} 
      />
    );
  };

const renderItemColumn = (item, _index, column) => {   
    let customStyles = mergeStyles(expandCell);
    var content = "";
    if (column.fieldName == 'fields')
    {
        item[column.fieldName].forEach((e)=>{
            content.length > 0  ?  content = content + ', ' + e : content = e ;
        })
    } else {
        content = item[column.fieldName];
    }
    return <span className={customStyles}>{content}</span>;
  };

const tabTeam = "Team";
const tabRepo = "Repo";

const useMyself = () => {
    const [tabValue, setTabValue] = useState("Team");
    const [markdown, setMarkdown] = useState("");
    const [hideDialog, {toggle: toggleHideDialog}] = useBoolean(true);
    const [notificationEmail, setNotificationEmail] = useState("");
    const [name, setName] = useState("");
    const [subject, setSubject] = useState("");
    const [field, setField] = useState("");
    const [fields, setFields] = useState([]);
    const [sections, setSections] = useState([]);
    const [teamId, setTeamId] = useState("");
    const [templateId, setTemplateId] = useState("");
    const [currentTemplateId, setCurrentTemplateId] = useState("");
    const [teamTemplate, setTeamTemplate] = useState([]);
    const [adminRepoList, setAdminRepoList] = useState([]);
    const [writeRepoList, setWriteRepoList] = useState([]);
    const [adminRepoListFiltered, setAdminRepoListFiltered] = useState([]);
    const [writeRepoListFiltered, setWriteRepoListFiltered] = useState([]);
    const [adminRepoOwnerList, setAdminRepoOwnerList] = useState([]);
    const [adminRepoOwner, setAdminRepoOwner] = useState("All");
    const [adminRepoNameList, setAdminRepoNameList] = useState([]);
    const [adminRepoName, setAdminRepoName] = useState("All");
    const [writeRepoOwnerList, setWriteRepoOwnerList] = useState([]);
    const [writeRepoOwner, setWriteRepoOwner] = useState("All");
    const [writeRepoNameList, setWriteRepoNameList] = useState([]);
    const [writeRepoName, setWriteRepoName] = useState("All");
    const [writeAccessToAll, setWriteAccessToAll] = useState(false);
    const [isNameDuplicate, setIsNameDuplicate] = useState(false);
    const [templateNameList, setTemplateNameList] = useState([]);

    const onNotificationEmailChange = (_, newVal) => {
        setNotificationEmail(newVal);
      };
    
    const onNameChange = (_, newVal) => {
        setName(newVal);
        setIsNameDuplicate(false);
        templateNameList.forEach((e)=>{
            if(newVal == e && currentTemplateId != e)  setIsNameDuplicate(true);
        })
      };

    const onSubjectChange = (_, newVal) => {
        setSubject(newVal);
      };

    const onFieldChange = (_, newVal) => {
        setField(newVal);
      };

    const onAdminRepoOwnerChange = (newVal) => {
        setAdminRepoOwner(newVal.key);
        filterList(true, newVal.key, null);
      };

    const onAdminRepoNameChange = (newVal) => {
        setAdminRepoName(newVal.key);
        filterList(true, null, newVal.key);
      };

    const onWriteRepoOwnerChange = (newVal) => {
        setWriteRepoOwner(newVal.key);
        filterList(false, newVal.key, null);
      };

    const onWriteRepoNameChange = (newVal) => {
        setWriteRepoName(newVal.key);
        filterList(false, null, newVal.key);
      };

    const onTabSwitch = (item) => {
        var tmpTabValue = cloneDeep(tabValue);
        tmpTabValue = item.props.headerText;
        setTabValue(tmpTabValue);
      };
    
    const addFields = () => {
        if (field.length > 0) {
            var tempFields = cloneDeep(fields);
            tempFields.push(field);
            setFields(tempFields);
            setField("");
        }
    }

    const addSections = () => {
        const section = {
            subject: "",
            content: "",
          };
        var tempSections = cloneDeep(sections);
        tempSections.push(section);
        setSections(tempSections);
    }

    const loadDialog = (item) => {
        setName(item.name?.length > 0 ? item.name : "");
        setField("");
        setFields(item.fields?.length > 0 ? item.fields : []);
        setSubject(item.subject?.length > 0 ? item.subject : "");
        setNotificationEmail(item.notificationEmail?.length > 0 ? item.notificationEmail.split("@")[0] : "");
        setSections(item.sectionText?.length > 0 ? item.sectionText : []);
        setMarkdown("");
        setTeamId(item.teamid?.length > 0 ? item.teamid : "");
        setTemplateId(item.key?.length > 0 ? item.key : "");
        setCurrentTemplateId(item.name?.length > 0 ? item.name : "");
        setIsNameDuplicate(false);
        toggleHideDialog();
    }

    const modalProps =  useMemo( () => ({
            isBlocking: true,
            styles: { main: { maxWidth: 440 , whiteSpace:'pre-wrap'} },
        })
    );

    const submitTemplate = async () => {
        try{
            const request = templateId?.length > 0 ? {
                "id": templateId,
                "name": name,
                "fields": fields,
                "subject": subject,
                "notificationEmail": notificationEmail+"@microsoft.com",
                "sectionText": sections,
            } : {
                "name": name,
                "fields": fields,
                "subject": subject,
                "notificationEmail": notificationEmail+"@microsoft.com",
                "sectionText": sections,
            }
            const response = await TaskAPI.templateSubmit(teamId, request);
            toggleHideDialog();
            initFn(); 
        } catch (error) {
            MessageActions.errorHandler(error);
            toggleHideDialog();
        }
    };

    const previewTemplate = async () => {
        try{
            const request = {
                "name": name,
                "fields": fields,
                "subject": subject,
                "notificationEmail": notificationEmail+"@microsoft.com",
                "sectionText": sections,
            };
            const response = await TaskAPI.templateReview(request);
            setMarkdown(response.data);
        } catch (error){
            MessageActions.errorHandler(error);
            toggleHideDialog();
        }
    };
    
    const filterList = (isAdmin, newRepoOwner, newRepoName) => {
        var repoOwner = newRepoOwner?.length > 0 ? newRepoOwner : isAdmin ? adminRepoOwner : writeRepoOwner;
        var repoName = newRepoName?.length > 0 ? newRepoName : isAdmin ? adminRepoName : writeRepoName
        if(isAdmin) {
            var adminList = repoOwner == "All" ? adminRepoList : adminRepoList.filter((item) => item.repoOwner == repoOwner);
            var adminListFiltered =  repoName == "All" ? adminList : adminList.filter((item) => item.repoName == repoName);
            setAdminRepoListFiltered(adminListFiltered);
        } else {
            var writeList = repoOwner == "All" ? writeRepoList : writeRepoList.filter((item) => item.repoOwner == repoOwner);
            var writeListFiltered =  repoName == "All" ? writeList : writeList.filter((item) => item.repoName == repoName);
            setWriteRepoListFiltered(writeListFiltered);
        }
    }

    const setRepoOwnerList = (repoList, isAdmin) => {
        var adminOwnerList = [{"key":"All", "text":"All"}];
        repoList.forEach((e)=>{
            var adminOwner = {"key":e.repoOwner, "text":e.repoOwner}
            if(!adminOwnerList.map(a=>a.key).includes(e.repoOwner)) {
                adminOwnerList.push(adminOwner);
            }
        });
        isAdmin == true ? setAdminRepoOwnerList(adminOwnerList) : setWriteRepoOwnerList(adminOwnerList);
    }

    const setRepoNameList = (repoName, isAdmin) => {
        var adminNameList = [{"key":"All", "text":"All"}];
        repoName.forEach((e)=>{
            var adminName = {"key":e.repoName, "text":e.repoName}
            if(!adminNameList.map(a=>a.key).includes(e.repoName)) {
                adminNameList.push(adminName);
            }
        });
        isAdmin == true ? setAdminRepoNameList(adminNameList) : setWriteRepoNameList(adminNameList);
    }

    const UpdateConfigItem = async (id, canBypassBranchPolicy, canNotification,) => {
        try {
          let data = {
            canBypassBranchPolicy: canBypassBranchPolicy,
            canNotification: canNotification,
          };
          await RepoConfigAPI.updateConfigItem(id, data);
        } catch (error) {
            MessageActions.errorHandler(error);
        }
      };

    const initFn = useCallback(async () => {
        try{
            //load to team tab
            const teamTemplateList = await TaskAPI.loadTeamTemplate();
            var tmpTeam = [];
            var tmpTemplateNameList = [];
            teamTemplateList.data.forEach((e)=>{
                var tmpTemplateList = [];
                e.templateConfigs.forEach((t)=>{
                    var templateData = {key: t.id, name: t.name, teamid: e.id, fields: t.fields, subject: t.subject, notificationEmail: t.notificationEmail, sectionText: t.sectionText}
                    tmpTemplateList.push(templateData);
                    tmpTemplateNameList.push(t.name);
                });
                var teamData = {key: e.id, name: e.teamAlias, templateList: tmpTemplateList};
                tmpTeam.push(teamData);
                setTemplateNameList(tmpTemplateNameList);
            });
            setTeamTemplate(tmpTeam);
        }catch (error) {
            MessageActions.errorHandler(error);
        };
        try{
            //load to repo config tab
            var adminDetail = await RepoConfigAPI.getRepoListConfigs("admin");
            const adminFiltered = adminDetail.data.filter((item) => item.repoOwner?.length > 0);
            setAdminRepoList(adminFiltered);
            setRepoOwnerList(adminFiltered,true);
            setRepoNameList(adminFiltered,true);
            var writeDetail = await RepoConfigAPI.getRepoListConfigs("write");
            const writeFiltered = writeDetail.data.filter((item) => item.repoOwner?.length > 0);
            if (writeFiltered[0]?.repoOwner == "*" && writeFiltered[0]?.repoName == "*"){
                setWriteAccessToAll(true);
            }else{
                setWriteRepoList(writeFiltered);
                setRepoOwnerList(writeFiltered,false);
                setRepoNameList(writeFiltered,false);
            }
        }catch (error) {
            MessageActions.errorHandler(error);
        };
    });

    useEffect(() => {
        initFn();
      }, []);

    return {
        tabValue,
        onTabSwitch,
        hideDialog,
        toggleHideDialog,
        modalProps,
        notificationEmail,
        onNotificationEmailChange,
        name,
        onNameChange,
        subject,
        onSubjectChange,
        field,
        onFieldChange,
        fields,
        addFields,
        setFields,
        loadDialog,
        sections,
        addSections,
        setSections,
        markdown,
        previewTemplate,
        submitTemplate,
        teamTemplate,
        adminRepoList,
        writeRepoList,
        adminRepoOwnerList,
        adminRepoOwner,
        onAdminRepoOwnerChange,
        adminRepoNameList,
        adminRepoName,
        onAdminRepoNameChange,
        writeRepoOwnerList,
        writeRepoOwner,
        onWriteRepoOwnerChange,
        writeRepoNameList,
        writeRepoName,
        onWriteRepoNameChange,
        adminRepoListFiltered,
        writeRepoListFiltered,
        UpdateConfigItem,
        setAdminRepoList,
        setAdminRepoListFiltered,
        writeAccessToAll,
        isNameDuplicate,
        setIsNameDuplicate,
    };
}

const Myself = () => {
    document.title = "Metadata Management";
    const history = useHistory();
    const {
        tabValue,
        onTabSwitch,
        hideDialog,
        toggleHideDialog,
        modalProps,
        notificationEmail,
        onNotificationEmailChange,
        name,
        onNameChange,
        subject,
        onSubjectChange,
        field,
        onFieldChange,
        fields,
        addFields,
        setFields,
        loadDialog,
        sections,
        addSections,
        setSections,
        markdown,
        previewTemplate,
        submitTemplate,
        teamTemplate,
        adminRepoList,
        writeRepoList,
        adminRepoOwnerList,
        adminRepoOwner,
        onAdminRepoOwnerChange,
        adminRepoNameList,
        adminRepoName,
        onAdminRepoNameChange,
        writeRepoOwnerList,
        writeRepoOwner,
        onWriteRepoOwnerChange,
        writeRepoNameList,
        writeRepoName,
        onWriteRepoNameChange,
        adminRepoListFiltered,
        writeRepoListFiltered,
        UpdateConfigItem,
        setAdminRepoList,
        setAdminRepoListFiltered,
        writeAccessToAll,
        isNameDuplicate,
        setIsNameDuplicate,
    } = useMyself();

    const BackFn = useCallback(() => {
        history.push("/tasks");
      }, [history]);

    return (
        <div style={DocumentStyles.Container}>
            <BackHomeButton text={Resources.Tasks.BackToTasklist} backFn={BackFn} />
            <ScrollablePane style={DocumentStyles.InnerScroll}>
                <Text variant={"xxLarge"} block>
                    {Resources.Myself.MyselfTitle}
                </Text>
                <Pivot onLinkClick={onTabSwitch} selectedKey={tabValue}>
                    <PivotItem itemKey={tabTeam} headerText={tabTeam} headerButtonProps={{"data-order": 1,}}>
                        {teamTemplate.map((item, index)=>{
                            return(
                                <Stack>
                                <Text variant={"xLarge"} block style={{marginTop: '10px',marginBottom: '10px'}}>
                                    {item.name}
                                </Text>
                                <DetailsList
                                    onRenderItemColumn={renderItemColumn}
                                    items={item.templateList}
                                    selectionMode={SelectionMode.none}
                                    columns={[
                                        {
                                            key: "name",
                                            name: "Template",
                                            fieldName: "name",
                                            minWidth: 100,
                                            maxWidth: 200,
                                        },
                                        {
                                            key: "fields",
                                            name: "Fields",
                                            fieldName: "fields",
                                            minWidth: 100,
                                            maxWidth: 200,
                                        },
                                        {
                                            key: "templateConfigs",
                                            name: "Template Configs",
                                            fieldName: "templateConfigs",
                                            minWidth: 100,
                                            maxWidth: 200,
                                            onRender: (item, _index) => {
                                                return <DefaultButton 
                                                            iconProps={{ iconName: 'Repair' }} title="Add" ariaLabel="Add" 
                                                            style={{marginRight:"2px"}} 
                                                            onClick={()=>loadDialog(item)}
                                                        />;
                                                },
                                        },
                                    ]}
                                />
                                <ActionButton 
                                    iconProps={{ iconName: "Add" }}
                                    onClick={()=>loadDialog({name:"",fields:"",subject:"",notificationEmail:"",sectionText:"",teamid:item.key,key:""})}>
                                    New Template
                                </ActionButton>
                                </Stack>
                            )
                        })}
                    </PivotItem>
                    <PivotItem itemKey={tabRepo} headerText={tabRepo} headerButtonProps={{"data-order": 2,}}>
                        <Stack>
                            <Text variant={"xLarge"} block style={{marginTop: '20px', marginBottom: '20px'}}>
                                Admin Repo
                            </Text>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <StackItem>
                                    <Label styles={LabelStyles}>Repo Organization</Label>
                                    <DropdownSearch
                                        placeHolder={adminRepoOwner}
                                        noMatchText="No Matched Repo, Refine seach"
                                        onChange={onAdminRepoOwnerChange}
                                        items={adminRepoOwnerList}
                                        width={180}
                                        maxHeight={800}
                                        value=""
                                    />
                                </StackItem>
                                <StackItem>
                                    <Label styles={LabelStyles}>Repo Name</Label>
                                    <DropdownSearch
                                        placeHolder={adminRepoName}
                                        noMatchText="No Matched Repo, Refine seach"
                                        onChange={onAdminRepoNameChange}
                                        items={adminRepoNameList}
                                        width={180}
                                        maxHeight={800}
                                        value=""
                                    />
                                </StackItem>
                            </Stack>
                            <DetailsList
                                onRenderItemColumn={renderItemColumn}
                                items={ adminRepoOwner=="All" && adminRepoName == "All" ? adminRepoList : adminRepoListFiltered}
                                selectionMode={SelectionMode.none}
                                styles={{root: {maxHeight:"100vh", marginRight:"30vh"}}}
                                columns={[
                                    {
                                        key: "repoOwner",
                                        name: "Repo Organization",
                                        fieldName: "repoOwner",
                                        minWidth: 100,
                                        maxWidth: 200,
                                    },
                                    {
                                        key: "repoName",
                                        name: "Repo Name",
                                        fieldName: "repoName",
                                        minWidth: 100,
                                        maxWidth: 200,
                                    },
                                    {
                                        key: "repoConfigs",
                                        name: "Bypass BranchPolicy",
                                        fieldName: "repoConfigs",
                                        minWidth: 100,
                                        maxWidth: 150,
                                        onRender: (item, _index) => {
                                            return <Toggle 
                                            label=""
                                            checked={item?.configItem.canBypassBranchPolicy}
                                            inlineLabel
                                            onText="On"
                                            offText="Off"
                                            onChange={() => {
                                                UpdateConfigItem(
                                                  item?.id,
                                                  !item?.configItem.canBypassBranchPolicy,
                                                  item?.configItem.canNotification,
                                                );
                                                var tempList = cloneDeep(adminRepoList);
                                                var configItem =  tempList.filter((e)=>{return e.id == item.id});
                                                var index = tempList.indexOf(configItem[0])
                                                tempList[index].configItem.canBypassBranchPolicy = !item?.configItem.canBypassBranchPolicy;
                                                setAdminRepoList(tempList);
                                                if(adminRepoListFiltered?.length > 0) {
                                                    tempList = cloneDeep(adminRepoListFiltered);
                                                    configItem =  tempList.filter((e)=>{return e.id == item.id});
                                                    index = tempList.indexOf(configItem[0]);
                                                    tempList[index].configItem.canNotification = !item?.configItem.canNotification;
                                                    setAdminRepoListFiltered(tempList);
                                                }
                                              }}
                                          />;},
                                    },
                                    {
                                        key: "templateConfigs",
                                        name: "Notification",
                                        fieldName: "templateConfigs",
                                        minWidth: 100,
                                        maxWidth: 100,
                                        onRender: (item, _index) => {
                                            return <Toggle 
                                            label=""
                                            checked={item?.configItem.canNotification}
                                            inlineLabel
                                            onText="On"
                                            offText="Off"
                                            onChange={() => {
                                                UpdateConfigItem(
                                                  item?.id,
                                                  item?.configItem.canBypassBranchPolicy,
                                                  !item?.configItem.canNotification,
                                                );
                                                var tempList = cloneDeep(adminRepoList);
                                                var configItem =  tempList.filter((e)=>{return e.id == item.id});
                                                var index = tempList.indexOf(configItem[0])
                                                tempList[index].configItem.canNotification = !item?.configItem.canNotification;
                                                setAdminRepoList(tempList);
                                                if(adminRepoListFiltered?.length > 0) {
                                                    tempList = cloneDeep(adminRepoListFiltered);
                                                    configItem =  tempList.filter((e)=>{return e.id == item.id});
                                                    index = tempList.indexOf(configItem[0]);
                                                    tempList[index].configItem.canNotification = !item?.configItem.canNotification;
                                                    setAdminRepoListFiltered(tempList);
                                                }
                                              }}
                                          />;},
                                    },
                                ]}
                            />
                            <Text variant={"xLarge"} block style={{marginTop: '20px', marginBottom: '20px'}}>
                                Usage Request Repo Scope
                            </Text>
                            {writeAccessToAll == true ? 
                            <Text variant={"large"}>* Your metadata usage refactor requests cover all repos *</Text> : 
                            <div><Stack horizontal tokens={{ childrenGap: 10 }} >
                                <StackItem>
                                    <Label styles={LabelStyles}>Repo Organization</Label>
                                    <DropdownSearch
                                        placeHolder={writeRepoOwner}
                                        noMatchText="No Matched Repo, Refine seach"
                                        onChange={onWriteRepoOwnerChange}
                                        items={writeRepoOwnerList}
                                        width={180}
                                        maxHeight={800}
                                        value=""
                                    />
                                </StackItem>
                                <StackItem>
                                    <Label styles={LabelStyles}>Repo Name</Label>
                                    <DropdownSearch
                                        placeHolder={writeRepoName}
                                        noMatchText="No Matched Repo, Refine seach"
                                        onChange={onWriteRepoNameChange}
                                        items={writeRepoNameList}
                                        width={180}
                                        maxHeight={800}
                                        value=""
                                    />
                                </StackItem>
                            </Stack>
                            <DetailsList
                                onRenderItemColumn={renderItemColumn}
                                items={ writeRepoOwner=="All" && writeRepoName == "All" ? writeRepoList : writeRepoListFiltered}
                                selectionMode={SelectionMode.none}
                                styles={{root: {maxHeight:"100vh", marginRight:"30vh"}}}
                                columns={[
                                    {
                                        key: "repoOwner",
                                        name: "Repo Organization",
                                        fieldName: "repoOwner",
                                        minWidth: 100,
                                        maxWidth: 200,
                                    },
                                    {
                                        key: "repoName",
                                        name: "Repo Name",
                                        fieldName: "repoName",
                                        minWidth: 100,
                                        maxWidth: 200,
                                    },
                                ]}
                            /></div>}
                        </Stack>
                    </PivotItem>
                </Pivot>
            </ScrollablePane>
            <div style={{whiteSpace:'pre-wrap'}}>
                <Dialog 
                    hidden = {hideDialog}
                    onDismiss = {toggleHideDialog}
                    modalProps={modalProps}
                    minWidth={1000}
                    >
                    <Stack horizontal tokens={{ childrenGap: 100 }}>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <TextField
                            label={"Name"}
                            value={name}
                            onChange={onNameChange}
                            required={name?.length == 0}
                            styles={TextFieldShortStyles}
                            errorMessage={isNameDuplicate ? "Template name already exists, please enter a new name" : null}
                            />
                            <Stack>
                                {fields?.length > 0 ?  <Label styles={LabelStyles}>Fields</Label>: null}
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    {fields?.length > 0 ?  
                                        (fields.map((item,idx)=>(
                                            <div>
                                                <Text>{item}</Text>
                                                <ActionButton
                                                    iconProps={{ iconName: "Cancel" }}
                                                    onClick={() => {
                                                        var filtered = cloneDeep(fields);
                                                        filtered.splice(idx,1);
                                                        setFields(filtered);
                                                    }}
                                                />
                                            </div>
                                        )))   : null}
                                </Stack>
                            </Stack>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <TextField
                                label={fields?.length > 0 ? "" : "Fields"}
                                value={field}
                                required={field?.length == 0 && fields?.length==0}
                                onChange={onFieldChange}
                                styles={TextFieldShortStyles}
                            />
                            <StackItem align="end">
                                <ActionButton
                                    iconProps={{ iconName: "Add" }}
                                    allowDisabledFocus
                                    onClick={addFields}
                                >
                                </ActionButton>
                            </StackItem>
                        </Stack>
                        <TextField
                            label={"Subject"}
                            value={subject}
                            required={subject?.length == 0}
                            onChange={onSubjectChange}
                            styles={TextFieldShortStyles}
                        />
                        <TextField
                            label="Notification Email"
                            value={notificationEmail}
                            onChange={onNotificationEmailChange}
                            required={notificationEmail?.length == 0}
                            styles={TextFieldLongStyles}
                            suffix="@microsoft.com"
                        />
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <StackItem align="center">
                                <Label styles={LabelStyles}>Section Text</Label>
                            </StackItem>
                            <StackItem align="end">
                                <ActionButton
                                    iconProps={{ iconName: "Add" }}
                                    allowDisabledFocus
                                    onClick={addSections}
                                >
                                </ActionButton>
                            </StackItem>
                        </Stack>
                        {sections.map((item, index) => {
                            return (
                            <Stack>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <TextField
                                        label={"Subject"}
                                        onChange={(_, newVal)=> {
                                            var tmpList = cloneDeep(sections);
                                            tmpList[index].subject=newVal;
                                            setSections(tmpList);
                                        }}
                                        required={sections[index].subject?.length === 0}
                                        value={sections[index].subject}
                                        styles={TextFieldShortStyles}
                                    />
                                    <StackItem align="end">
                                        <ActionButton 
                                            iconProps={{ iconName: "Delete" }}
                                            onClick={() => {
                                                var filtered = cloneDeep(sections);
                                                filtered.splice(index,1);
                                                setSections(filtered);
                                            }}
                                        />
                                    </StackItem>
                                </Stack>
                                <TextField
                                    label={"Content"}
                                    onChange={(_, newVal)=>{
                                        var tmpList = cloneDeep(sections);
                                        tmpList[index].content=newVal;
                                        setSections(tmpList);
                                    }}
                                    required={sections[index].content?.length === 0}
                                    value={sections[index].content}
                                    styles={TextFieldLongStyles}
                                    multiline
                                    autoAdjustHeight
                                />
                            </Stack>
                            )})}
                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                <PrimaryButton text="Preview" onClick={previewTemplate} disabled={name?.length == 0 ||subject?.length == 0 ||notificationEmail?.length == 0 || fields?.length==0}></PrimaryButton>
                                <PrimaryButton text="Submit" onClick={submitTemplate} disabled={name?.length == 0 ||subject?.length == 0 ||notificationEmail?.length == 0 || fields?.length==0 || isNameDuplicate} ></PrimaryButton>
                            </Stack>
                        </Stack>
                        <Stack>
                            <Label styles={LabelStyles}>Preview</Label>
                            {markdown?.length > 0 ? ( <Markdown>{markdown}</Markdown> ): null}
                        </Stack>
                    </Stack>
                </Dialog>
            </div>
        </div>
    )
}

export default memo(Myself);