import { MessageBarType } from "@fluentui/react";
import { EventClient, EventNames } from "./simpleEventClient";

export class MessageActions {
  static error = (message) => {
    const payload = message
      ? {
          type: MessageBarType.error,
          message,
        }
      : null;
    EventClient.emit(EventNames.ShowMessageBar, payload);
  };

  static errorHandler = (error) => {
    let message;
    if(typeof (error.response) != "undefined"){
      message = error.response.data.Message;
    }else{
      message = error.message;
    }
    const payload = message
      ? {
          type: MessageBarType.error,
          message,
        }
      : null;
    EventClient.emit(EventNames.ShowMessageBar, payload);
  };

  static success = (message) => {
    const payload = message
      ? {
          type: MessageBarType.success,
          message,
        }
      : null;
    EventClient.emit(EventNames.ShowMessageBar, payload);
  };

  static info = (message) => {
    const payload = message
      ? {
          type: MessageBarType.info,
          message,
        }
      : null;
    EventClient.emit(EventNames.ShowMessageBar, payload);
  };

  static clear = () => {
    EventClient.emit(EventNames.ShowMessageBar);
  };
}
