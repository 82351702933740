import React, { useEffect, useRef } from "react";
import { SearchBox, Stack } from "@fluentui/react";
import debounce from "lodash/debounce";

/* eslint-disable react/jsx-no-bind */
const stackStyles = {
  root: {
    display: "inline-flex",
    position: "relative",
  },
};

const searchBoxStyles = {
  root: { width: 300 },
};
const ListItemSearch = ({
  showSearch,
  keyword,
  onClear,
  onSearch,
  setKeyword,
}) => {
  const searchBoxInstance = useRef(null);

  useEffect(() => {
    showSearch && searchBoxInstance && searchBoxInstance.current.focus();
  }, [showSearch]);

  if (showSearch) {
    return (
      <Stack horizontal styles={stackStyles}>
        <SearchBox
          underlined={true}
          componentRef={searchBoxInstance}
          styles={searchBoxStyles}
          placeholder="Search"
          onClear={onClear}
          value={keyword}
          onChange={debounce(
            (_, newValue) => {
              setKeyword(newValue);
              onSearch(newValue);
            },
            500,
            { maxWait: 1000 }
          )}
          onSearch={onSearch}
          clearButtonProps={{ iconProps: { iconName: "Clear" } }}
        />
      </Stack>
    );
  }
  return null;
};

export default React.memo(ListItemSearch);
