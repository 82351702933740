import { memo, useMemo, useState } from "react";
import {
  DefaultButton,
  ShimmeredDetailsList,
  SelectionMode,
  Text,
  Stack,
  getTheme,
  mergeStyleSets,
  ScrollablePane,
  IconButton,
  Icon,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  TooltipHost,
} from "@fluentui/react";
import { useBoolean, useId } from "@uifabric/react-hooks";
import { Link } from "react-router-dom";
import Resources from "../../Common/resources";
import TaskAPI from "../../Common/API/taskApi";
import { MessageActions } from "../../Common/Utils/Message";
import { SharedColors } from "@fluentui/theme";
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';
import { FontIcon } from '@fluentui/react/lib/Icon';
const theme = getTheme();
const classNames = mergeStyleSets({
  Table: {
    overflow: "hidden",
    margin: 8,
  },
  headerAndFooter: {
    borderTop: `${1}px solid ${theme.palette.neutralQuaternary}`,
    borderBottom: `${1}px solid ${theme.palette.neutralQuaternary}`,
    // padding: '0 8px',
    // margin: `${4}px 0`,
    background: theme.palette.neutralLighterAlt,
    zIndex: 100,
    display: "flex",
  },
  HeaderButton: {
    flex: "50px 0 0",
  },
  headerContent: {
    flex: "20px 1 1",
  },
  headerTitle: [
    theme.fonts.medium,
    {
      padding: "4px 0",
    },
  ],
  headerLink: {
    height: "100%",
    width: "36px",
  },
  icon: {
    fontSize: 15,
    color: theme.palette.orange,
  },
  Container: {
    position: "relative",
    height: "calc(100vh - 300px)",
  },
  InnerScroll: {
    position: "absolute",
    top: "30px",
    bottom: "50px",
  },
  operationDisableStatus:{
    backgroundColor:"transparent",
    cursor:"not-allowed",
    i: {
      color: "rgb(210, 208, 206)"
    }
  },
  itemCenter:{
    alignItems: "center",
    display: "inline-flex!important"
  }
});

const SubTasks = ({ subtasks, isLoading, subgroup }) => {
  const [subtask, setSubTask] = useState([]);
  const [title, setTitle] = useState('');
  const [subText, setSubText] = useState('');
  const [params, setParams] = useState({});
  const useSubTasksConfirmDialog = () => {
    const [
      hideSubTasksConfirmDialog,
      { toggle: toggleHideSubTasksConfirmDialog },
    ] = useBoolean(true);
    const labelId = useId("dialogLabel");
    const subTextId = useId("subTextLabel");
    const subTasksConfirmDialogContentProps = {
      title: title,
      type: DialogType.normal,
      subText: subText
    };
  
    const subTasksConfirmModalProps = useMemo(
      () => ({
        titleAriaId: labelId,
        subtitleAriaId: subTextId,
        isBlocking: true,
        styles: { main: { maxWidth: 350 } },
      }),
      [labelId, subTextId]
    );
  
    return [
      hideSubTasksConfirmDialog,
      toggleHideSubTasksConfirmDialog,
      subTasksConfirmDialogContentProps,
      subTasksConfirmModalProps
    ];
  }
  const [
    hideSubTasksConfirmDialog,
    toggleHideSubTasksConfirmDialog,
    subTasksConfirmDialogContentProps,
    subTasksConfirmModalProps
  ] = useSubTasksConfirmDialog();
  
  const hideHumanEffort = subtasks.every(
    (subtasks) => subtasks.needHumanEffort !== true
  );
  
  const shimmerColumns = useMemo(() => {
    const mergeIcon = {iconName: 'BranchMerge'};
    const closeIcon = {iconName:'StatusCircleErrorX'};
    const reopenIcon = {iconName:'AzureKeyVault'};//OpenSource
    const rerunIcon = {iconName:'ReturnToSession'};
    const skipIcon = {iconName:'Blocked12'};
    const calloutProps = { gapSpace: 0 };
    const hostStyles = { root: { display: 'inline-block'} };
    const defaultColumns = [
      {
        key: "author",
        name: "Author",
        fieldName: "author",
        minWidth: 100,
        maxWidth: 150,
        className:classNames.itemCenter
      },
      {
        key: "pullRequestStatus",
        name: "PullRequest Status",
        fieldName: "pullRequestStatus",
        minWidth: 100,
        maxWidth: 150,
        className:classNames.itemCenter,
        onRender: (item, index) => {
          // if pr merge blocked, add link to config page
          if (
            item?.pullRequestStatus ===
            Resources.RepoConfig.PullRequestStatusBlocked
          ) {
            return (
              // repoId here is optional, used to filter bypass policyRepos
              <Link to={`/repos/repoconfig/${item.repo}`}>
                <Icon iconName="Warning" className={classNames.icon} />
                <span>{item?.pullRequestStatus}</span>
              </Link>
            );
          }
          return <span> {item?.pullRequestStatus}</span>;
        },
      },
      {
        key: "pullRequestUrl",
        name: "PullRequest Url",
        fieldName: "pullRequestUrl",
        minWidth: 170,
        className:classNames.itemCenter,
        onRender: (item, index) => {
          const url = item?.pullRequestUrl;
          if (url)
            return (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            );
        },
      },
      {
        key: "operation",
        name: "Operation",
        fieldName: "operation",
        minWidth: 180,
        maxWidth: 180,
        onRender: (item, index) => {
          return ( 
            item?.pullRequestUrl ?
            <>
                <TooltipHost
                  content={`Rerun ${!CanOperationPR(item?.pullRequestStatus,'rerun') ? ': '+Resources.SubTasksConfirm.rerunDisable : ''}`}
                  calloutProps={calloutProps}
                  styles={hostStyles}
                  setAriaDescribedBy={false}
                >
                  <IconButton 
                    iconProps={rerunIcon} 
                    className={!CanOperationPR(item?.pullRequestStatus,'rerun')&&classNames.operationDisableStatus}
                    style={{color:SharedColors.cyanBlue10,marginRight:"2px"}}
                    disabled={!CanOperationPR(item?.pullRequestStatus,'rerun')}
                    onClick={() => {
                      setTitle('Rerun');
                      setSubText(Resources.SubTasksConfirm.rerun);
                      toggleHideSubTasksConfirmDialog();
                      const params = Object.assign({},{
                        subTaskFileId:item?.id,operation:'rerun',groupId:item?.groupId
                      });
                      setParams(params);
                    }} 
                  />
                 </TooltipHost>
                 <TooltipHost
                  content={`Reopen ${!CanOperationPR(item?.pullRequestStatus,'reopen') ? ': '+Resources.SubTasksConfirm.reopenDisable : ''}`}
                  calloutProps={calloutProps}
                  styles={hostStyles}
                  setAriaDescribedBy={false}
                 >
                  <IconButton 
                   iconProps={reopenIcon} 
                   className={!CanOperationPR(item?.pullRequestStatus,'reopen')&&classNames.operationDisableStatus}
                   style={{color:SharedColors.yellowGreen10,marginRight:"2px"}}
                   disabled={!CanOperationPR(item?.pullRequestStatus,'reopen')}
                   onClick={() => {
                      setTitle('Reopen');
                      setSubText(Resources.SubTasksConfirm.reopen);
                      toggleHideSubTasksConfirmDialog();
                      const params = Object.assign({},{
                        subTaskFileId:item?.id,operation:'reopen',groupId:item?.groupId
                      });
                      setParams(params);
                   }} 
                 />
                </TooltipHost>
                <TooltipHost
                  content={`Abandon ${!CanOperationPR(item?.pullRequestStatus,'close') ? ': '+Resources.SubTasksConfirm.closeDisable : ''}`}
                  calloutProps={calloutProps}
                  styles={hostStyles}
                  setAriaDescribedBy={false}
                 >
                <IconButton 
                   iconProps={closeIcon}
                   className={!CanOperationPR(item?.pullRequestStatus,'close')&&classNames.operationDisableStatus}
                   style={{color:SharedColors.red10,marginRight:"2px"}}
                   disabled={!CanOperationPR(item?.pullRequestStatus,'close')}
                   onClick={() => {
                    setTitle('Abandon');
                    setSubText(Resources.SubTasksConfirm.close);
                    toggleHideSubTasksConfirmDialog();
                    const params = Object.assign({},{
                      subTaskFileId:item?.id,operation:'close',groupId:item?.groupId
                    });
                    setParams(params);
                   }} 
                />
                </TooltipHost>
                <TooltipHost
                  content={`Merge ${!CanOperationPR(item?.pullRequestStatus,'merge') ? ': '+Resources.SubTasksConfirm.mergeDisable : ''}`}
                  calloutProps={calloutProps}
                  styles={hostStyles}
                  setAriaDescribedBy={false}
                >
                <IconButton 
                   iconProps={mergeIcon} 
                   style={{color:SharedColors.primary}}
                   className={!CanOperationPR(item?.pullRequestStatus,'merge')&&classNames.operationDisableStatus}
                   disabled={!CanOperationPR(item?.pullRequestStatus,'merge')}
                   onClick={() => {
                    setTitle('Merge');
                    setSubText(Resources.SubTasksConfirm.merge);
                    toggleHideSubTasksConfirmDialog();
                    const params = Object.assign({},{
                      subTaskFileId:item?.id,operation:'merge',groupId:item?.groupId
                    });
                    setParams(params);
                   }} 
                />
                </TooltipHost>
                <TooltipHost
                  content={`Skip ${!CanOperationPR(item?.pullRequestStatus,'skip') ? ': '+Resources.SubTasksConfirm.skipDisable : ''}`}
                  calloutProps={calloutProps}
                  styles={hostStyles}
                  setAriaDescribedBy={false}
                 >
                <IconButton 
                   iconProps={skipIcon}
                   className={!CanOperationPR(item?.pullRequestStatus,'skip')&&classNames.operationDisableStatus}
                   style={{color:SharedColors.red10,marginRight:"2px"}}
                   disabled={!CanOperationPR(item?.pullRequestStatus,'skip')}
                   onClick={() => {
                    setTitle('Skip');
                    setSubText(Resources.SubTasksConfirm.skip);
                    toggleHideSubTasksConfirmDialog();
                    const params = Object.assign({},{
                      subTaskFileId:item?.id,operation:'skip',groupId:item?.groupId
                    });
                    setParams(params);
                   }} 
                />
                </TooltipHost>
            </>
            :
            null
          );
        },
      },
    ];

    if (hideHumanEffort !== true) {
      defaultColumns.unshift({
        key: "needHumanEffort",
        name: "NeedHumanEffort",
        fieldName: "needHumanEffort",
        minWidth: 50,
      });
    }

    return defaultColumns;
  }, [hideHumanEffort,toggleHideSubTasksConfirmDialog]);

  const OperationPR = async (params) => {
    const {subTaskFileId,operation,groupId} = params;
    if(subTaskFileId && operation){
      try {
        var resp = operation === 'merge' ? await TaskAPI.subTaskFileMerge({
          SubTaskFileId: subTaskFileId,
          Operation: operation,
        }) : await TaskAPI.subTaskFileOperation({
          SubTaskFileId: subTaskFileId,
          Operation: operation,
        });
        if (resp != null && resp.data != null && resp.data.isSuccess) {
          refreshSubTaskFile(groupId);
        }
        else{
          MessageActions.error(`${resp.data.exceptionMessage}`);
        }
      } catch (e) {
        MessageActions.errorHandler(e);
      }
    }
  };
  
  const CanOperationPR = (status,operation) => {
    if(status && operation){
      const allowlist = {
        merge: ["WAIT_FOR_MERGED", "WAIT_FOR_REVIEW", "BLOCKED_BY_CHECK"],
        reopen: ["ABANDON"],
        close: ["MERGED_TO_MAIN", "MERGED_TO_LIVE", "ABANDON", "RERUNNING"],
        rerun: ["ABANDON"],
        skip: ["ABANDON"],
      };
      return operation==='close' ? !allowlist[operation].includes(status) : allowlist[operation].includes(status);
    }
  }

  const reorderSubGroup = () => {
    let count = 0;
    let task = [];
    subgroup.forEach((e) => {
      e.startIndex = count;
      if(typeof(e.subtaskFile) != "undefined" && e.subtaskFile?.length > 0)
      {
        e.subtaskFile.forEach((ele)=>{
          task.push(ele);
        });
        count += e.subtaskFile?.length;
      }
    });
    setSubTask(task);
  }

  const getSubTaskFile = async (group) => {
    group.isLoading = true;
    const resp = await TaskAPI.getSubTasks(group.id,group.pageNumber,8);
    resp.data.forEach((e) => {
      e.groupId = group.id;
    });
    group.subtaskFile = resp.data;
    group.count = resp.data.length;
    group.isLoading = false;
    reorderSubGroup();
  }

  const refreshSubTaskFile = async (groupId) => {
    const group = subgroup.find((e) => e.id === groupId);
    group.isLoading = true;
    const resp = await TaskAPI.getSubTasks(group.id,group.pageNumber,8);
    resp.data.forEach((e) => {
      e.groupId = group.id;
    });
    group.subtaskFile = resp.data;
    group.count = resp.data.length;
    group.isLoading = false;
    reorderSubGroup();
  }
  
  const onRenderGroupHeader = (props) => {
    if (props && props.group) {
      if(typeof(props.group.isCollapsed) == "undefined") {props.group.isCollapsed = true;}
      return (
        <>
          <div className={classNames.headerAndFooter}>
            <div className={classNames.HeaderButton}>
              <IconButton aria-label='Chevron'
                iconProps={
                  !props.group.isCollapsed
                    ? { iconName: "ChevronDown" }
                    : { iconName: "ChevronRight" }
                }
                className={classNames.headerLink}
                onClick={() => {
                  if(typeof(props.group.subtaskFile) == "undefined"){
                    props.group.pageNumber = 1; 
                    props.group.subtaskFile = [];
                    props.group.isLoading = false;
                  }
                  if(props.group.isCollapsed && props.group.subtaskFile?.length == 0 ) {
                    getSubTaskFile(props.group); 
                  }
                  props.onToggleCollapse(props.group);
                }}
              />
            </div>
            <div className={classNames.headerContent}>
              <div
                className={classNames.headerTitle}
              >{`Repo: ${props.group.repo}`}</div>
              <Link to={`/repos/repoconfig/${props.group.repo}`}>
                <div className={classNames.headerTitle}>Repo Config</div>
              </Link>
            </div>
          </div>
          {props.group.totalCount === 0 && ((props.group.taskStatus == 'WAIT_FOR_TRIGGER' || props.group.taskStatus == 'WAIT_FOR_ATTACH') ? (
            <Stack horizontalAlign="center">
              <Text>{Resources.Tasks.PRsAreWaiting}</Text>
            </Stack>
          ) : (
            <Stack horizontalAlign="center">
              <Text>{Resources.Tasks.PRsAreBuilding}</Text>
            </Stack>
          ))}
        </>
      );
    }

    return null;
  };

  const onRenderGroupFooter = (props) => {
    if (props && props.group.isLoading) {
      return <Text styles={{root: { marginLeft: "80vh",},}}> Loading ... </Text>;
    }
    if (props && props.group && !props.group.isCollapsed){
      return (
        <Pagination
          selectedPageIndex={props.group.pageNumber-1}
          pageCount={Math.ceil(props.group.totalCount/8)}
          itemsPerPage={8}
          totalItemCount={props.group.totalCount}
          format={'buttons'}
          previousPageAriaLabel={'previous page'}
          nextPageAriaLabel={'next page'}
          firstPageAriaLabel={'first page'}
          lastPageAriaLabel={'last page'}
          pageAriaLabel={'page'}
          selectedAriaLabel={'selected'}
          onPageChange={(index) => { 
            props.group.pageNumber = index + 1; 
            getSubTaskFile(props.group);  }}
        />)
    }
    else if(props.group.isAllMerged){
      return <Text styles={{root: { marginLeft: "50px",},}}>Subtask File Count: {props.group.totalCount} All Requests had been merged</Text>;
    }
    else if(props.group.hasAlert){
      return <div>
        <Text styles={{root: { marginLeft: "50px",},}}>Subtask File Count: {props.group.totalCount} </Text>
        <FontIcon  iconName="Warning12"  />
        </div>;
    }
    return <Text styles={{root: { marginLeft: "50px",},}}>Subtask File Count: {props.group.totalCount}</Text>;
  }
  return (
    <Stack.Item className={classNames.Container}>
      <Text variant={"large"}>{Resources.Tasks.TaskDetailSubTitleSubTask}</Text>
      <ScrollablePane className={classNames.InnerScroll}>
        <ShimmeredDetailsList
          className={classNames.Table}
          enableShimmer={isLoading}
          columns={shimmerColumns}
          groups={subgroup}
          groupProps={{
            onRenderHeader: onRenderGroupHeader,
            onRenderFooter: onRenderGroupFooter,
            showEmptyGroups: true,
            isAllGroupsCollapsed: true,
          }}
          setKey="id"
          selectionMode={SelectionMode.none}
          items={subtask}
          //onShouldVirtualize = { () => false  }
        />
      </ScrollablePane>
      <Dialog
        hidden={hideSubTasksConfirmDialog}
        onDismiss={toggleHideSubTasksConfirmDialog}
        dialogContentProps={subTasksConfirmDialogContentProps}
        modalProps={subTasksConfirmModalProps}
      >
        <DialogFooter>
          <PrimaryButton 
            onClick={() => {
              OperationPR(params);
              toggleHideSubTasksConfirmDialog();
            }} 
            text={Resources.SubTasksConfirm.Confirm} 
          />
          <DefaultButton 
            onClick={() => {
              toggleHideSubTasksConfirmDialog();
            }} 
            text={Resources.Jobs.Cancel} 
          />
        </DialogFooter>
      </Dialog>  
    </Stack.Item>
  );
};

export default memo(SubTasks);
