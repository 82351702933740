import React from 'react';
import { CommandBarButton, Stack, Modal, mergeStyleSets, FontWeights, getTheme } from '@fluentui/react';
import Resources from '../Common/resources';
import { btnContainerStyle } from '../Common/customStyle';

const SignOutBtnStyle = {
  root: { position: 'absolute', height: 34, right: '0', margin: '0 0 10px 0', zIndex: 100 }
}

const SignOutButton = (props) => {
  const { onSignOut } = props;
  return (
    <CommandBarButton
      id="SignOut"
      styles={SignOutBtnStyle}
      iconProps={{ iconName: 'SignOut' }}
      text={Resources.Login.SignOut}
      onClick={onSignOut}
    />);
}

const SignInButton = (props) => {
  const { onSignIn } = props;
  return (<Stack horizontal horizontalAlign="center" styles={btnContainerStyle}>
    <CommandBarButton
      id="Signin"
      iconProps={{ iconName: 'SignIn' }}
      text={Resources.Login.SignIn}
      onClick={onSignIn}
    />
  </Stack>);
}

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    width: '400px',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});

const LoginSection = (props) => {
  const { account, onSignIn, error } = props;
  let message = error;
  if (!account || error.includes('User login is required')) {
    message = Resources.Login.loginRequired;
  }
  const showLoginButton = !message.includes('AcquireToken_In_Progress');

  if (!showLoginButton) {
    message = Resources.Login.loginInProgress;
  }

  return (
    <Modal
      titleAriaId={Resources.Login.loginNotifyModalTitleAriaId}
      isOpen={true}
      isModeless={true}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <div className={contentStyles.body}>
          <p>{message} </p>
          {showLoginButton && <SignInButton onSignIn={() => onSignIn(true)} />}
        </div>
      </div>
    </Modal >
  )
}

export { LoginSection, SignOutButton };