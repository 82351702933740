import React from "react";
import { Link } from 'react-router-dom';
import {
  SelectionMode,
  ShimmeredDetailsList,
  mergeStyles,
  Sticky,
  Stack,
  Text,
  Icon
} from "@fluentui/react";
import _ from "lodash";
import {
  overflowCellHover,
  collapseCell,
  expandCell,
} from "../Common/customStyle";

const renderItemColumn = (item, index, column) => {
  let content = item[column.fieldName];
  // work around fabric bug here
  if (content === true || content === false) {
    content = _.toString(content);
  }
  let customStyles = mergeStyles(overflowCellHover);
  return <span className={customStyles}>{content}</span>;
};

function JobErrorsInner(props) {
  const { jobErrors, isLoading, failureReportUrl } = props;
  const shimmeredDetailsListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
  };

  return (
    <div>
      <div>
        <Sticky key="errortitle" stickyBackgroundColor="white">
          <Stack horizontal horizontalAlign="space-between" tokens={{ padding: 's' }}>
            <Text variant="xLarge" nowrap>
              Job errors
            </Text>
            <Stack.Item align="center" >
              <Link to={`/taxonomy/error/${failureReportUrl}`} >
                Full Error Report: <Icon iconName='NavigateExternalInline' />
              </Link>
            </Stack.Item>
          </Stack>
        </Sticky>

        <ShimmeredDetailsList
          setKey="reports"
          items={jobErrors}
          columns={[
            {
              key: "Taxonomy",
              name: "Taxonomy",
              fieldName: "Taxonomy",
              maxWidth: 150,
            },
            {
              key: "ErrorCode",
              name: "Error Code",
              fieldName: "ErrorCode",
              maxWidth: 150,
            },
            {
              key: "ErrorMessage",
              name: "Error Message",
              fieldName: "ErrorMessage",
              minWidth: 200,
              onRender: (item) => {
                return <span style={expandCell}>{item.ErrorMessage}</span>;
              },
            },
            {
              key: "Concepts",
              name: "Concepts",
              fieldName: "Uids",
              minWidth: 200,
              maxWidth: 400,
              onRender: (item, index) => {
                return (
                  <span>
                    {item.Uids &&
                      item.Uids.map((uid, idx) => {
                        let label = "";
                        if (item.Labels) {
                          label = item.Labels[idx];
                        }
                        if (!label) {
                          label = uid && _.last(uid.split("/"));
                        }

                        return (
                          <div
                            key={uid}
                            className={mergeStyles(
                              overflowCellHover,
                              collapseCell
                            )}
                          >
                            {uid && (
                              <a
                                target="_blank"
                                href={uid}
                                rel="noopener noreferrer"
                              >
                                {label}
                              </a>
                            )}
                          </div>
                        );
                      })}
                  </span>
                );
              },
            },
          ]}
          selectionMode={SelectionMode.none}
          shimmerLines={10}
          enableShimmer={isLoading}
          ariaLabelForShimmer="Content is being fetched"
          ariaLabelForGrid="Job errors"
          listProps={shimmeredDetailsListProps}
          onRenderItemColumn={renderItemColumn}
          onRenderDetailsHeader={
            // tslint:disable-next-line:jsx-no-lambda
            (detailsHeaderProps, defaultRender) => (
              <Sticky key="errorreport">{defaultRender(detailsHeaderProps)}</Sticky>
            )
          }
        />
      </div>
    </div>
  );
}

export default JobErrorsInner;
