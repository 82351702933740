import axios from "axios";
import { configs } from "../Config";
import _ from "lodash";
import {
  acquireContentProxyTokenSilent
} from "../Auth/auth-utils";
const baseURL = configs.RagApp.contentProxyEndpoint;

const contentProxyAPI = axios.create({ baseURL: baseURL });

contentProxyAPI.interceptors.request.use(function (config) {
  return acquireContentProxyTokenSilent().then((data) => {
    if (data) {
      config.headers = {
        ...config.headers,
        "Cache-Control": "no-cache",
        Authorization: `Bearer ${data.accessToken}`,
      };
    }
    return config;
  });
});

contentProxyAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
class RagAPI {
  static getGraph(data) {
    return contentProxyAPI.post(`${baseURL}/api/graph/query`, data);
  }
}

export default RagAPI;
