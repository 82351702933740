

const contentWidth = {
  '@media (max-width: 768px)': {
    width: "98%",
  },
  '@media (min-width: 769px)': {
    width: "85%",
  },
  '@media (min-width: 1440px)': {
    width: "80%",
  },
  '@media (min-width: 1920px)': {
    width: "70%",
  }
}
export const mainContaier = {
  selectors: contentWidth,
  margin: "20px auto auto auto",
  position: 'relative',
  paddingBottom: 24
}


export const footer = {
  position: 'fixed',
  height: '24px',
  lineHeight: '24px',
  bottom: 0,
  selectors: contentWidth,
  background: 'white'
}


export const btnContainerStyle = {
  root: { height: 34, margin: "10px 0" }
}

export const expandCell = {
  textOverflow: 'visable',
  whiteSpace: 'normal',
  wordBreak: 'break-all'
}

export const overflowCellHover = {
  selectors: {
    '&:hover': expandCell
  }
}

export const collapseCell = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}

export const PageHeader = {
  textAlign: 'center',
  marginBottom: '50px'
}

