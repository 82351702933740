import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Task from "./Task";
import SubTasks from "./SubTasks";
import TaskApi from "../../Common/API/taskApi";
import BackHomeButton from "../../Components/BackHomeButton";
import { Text, Stack } from "@fluentui/react";
import Resources from "../../Common/resources";
import _ from "lodash";
import { MessageActions } from "../../Common/Utils/Message";
import withAuth from "../../Common/Auth/authProvider";

const emptyObj = {};
const emptyArray = [];

const useTask = (tid) => {
  const [isLoading, setLoading] = useState(true);
  const [task, setTask] = useState(emptyObj);
  const [subtasks, setSubTasks] = useState(emptyArray);
  const [subgroup, setSubGroup] = useState(emptyArray);

  useEffect(() => {
    const initFn = async () => {
      try {
        MessageActions.clear();
        setLoading(true);
        const taskDetail = await TaskApi.getTaskDetail(tid);
        const task = taskDetail.data;
        const sub = [];
        const group = [];
        let startIndex = 0;
        for (let i = 0; i < task.subTasks.length; i++) {
          let subTask = task.subTasks[i];
          const count = subTask.subTaskFiles.length;
          group.push({ key: subTask.repo, repo: subTask.repo, id: subTask.id, notifySwitch: subTask.notifySwitch, startIndex, count, taskStatus: task.status, totalCount:count, isAllMerged: subTask.isAllMerged, hasAlert: subTask.hasAlert });
          startIndex += count;
          subTask.subTaskFiles.forEach((s, idx) => {
            sub.push({
              id: s.id,
              author: s.author,
              repo: subTask.repo,
              pullRequestUrl: s.pullRequestUrl,
              pullRequestStatus: s.pullRequestStatus,
              needHumanEffort: s.needHumanEffort,
            })
          })
        };
        setTask({
          id: task.id,
          title: task.title,
          adoLink: task.adoLink,
          status: task.status,
          description: task.description,
          pRAutoMergeOption: task.prAutoMergeOption,
          conditions: task.conditions,
          taskSource: task.taskSource,
        });
        setSubTasks(sub);
        setSubGroup(group)
        setLoading(false);
      } catch (error) {
        MessageActions.errorHandler(error);
        setLoading(false);
        setTask(emptyObj);
        setSubTasks(emptyArray);
        setSubGroup(emptyArray)
      }
    };
    initFn();
  }, [tid]);
  return { isLoading, task, subtasks, subgroup };
};

const TaskDetail = (props) => {
  document.title = "Metadata Management";
  const {userId} = props;
  const params = useParams();
  const history = useHistory();
  const { isLoading, task, subtasks, subgroup } = useTask(params.id);
  return (
    <div>
      <BackHomeButton
        text={Resources.Tasks.BackToTasklist}
        backFn={() => {
          history.push("/tasks");
        }}
      />
      <Text
        variant={"xxLarge"}
        styles={{ root: { textAlign: "center" } }}
        block
      >
        {Resources.Tasks.TaskDetailTopTitle}
      </Text>
      <Stack tokens={{ childrenGap: "l2" }}>
        <Task task={task} isLoading={isLoading} userId={userId} />
        <SubTasks subtasks={subtasks} subgroup={subgroup} isLoading={isLoading} />
      </Stack>
    </div>
  );
};

export default withAuth(TaskDetail);
