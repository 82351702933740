import { useEffect, useState, memo } from "react";
import { useParams } from "react-router-dom";
import { useBoolean } from "@uifabric/react-hooks";
import DropdownSearch from "../../Components/DropdownSearch";
import BackHomeButton from "../../Components/BackHomeButton";
import { MessageActions } from "../../Common/Utils/Message";
import { ExportToCsv } from "export-to-csv";
import {
  isValidAdoLink,
  isAdoLinkEndWithNumber,
} from "../../Common/Utils/adoLinkValidator";
import {
  Text,
  DetailsList,
  SelectionMode,
  IconButton,
  ActionButton,
  Dropdown,
  TextField,
  Stack,
  StackItem,
  Link,
  CommandBarButton,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  Pivot,
  PivotItem,
  Checkbox,
  getTheme,
  mergeStyles,
  Label,
  ScrollablePane,
  ComboBox,
  mergeStyleSets,
} from "@fluentui/react";
import { SharedColors } from "@fluentui/theme";
import _ from "lodash";
import Resources from "../../Common/resources";
import cloneDeep from "lodash/cloneDeep";
import TaskAPI from "../../Common/API/taskApi";
import { DonutChart, HorizontalBarChart } from "@fluentui/react-charting";
import moment from "moment";

const MetadataStyles = {
  InputRootWidth: { root: { width: 300 } },
  Container: { position: "relative", height: "100vh" },
  InnerScroll: { position: "absolute", top: "150px", bottom: "50px" },
  Title: { root: { textAlign: "center", marginBottom: 30 } },
  StackStyles: { root: { height: 44 } },
  FilterStack: {
    root: {
      height: 220,
      "border-top": "1px solid #666",
      "border-bottom": "1px solid #666",
      "overflow-y": "scroll",
      "padding-bottom": "20px",
    },
  },
  ChartStack: {
    root: {
      height: "calc(100% - 360px)",
    },
  },
  MetadataDataStack: {
    root: {
      height: "calc(100% - 360px)",
    },
  },
  CommandButton: { root: { marginRight: 20 } },
  ListDashboard: { root: { overflow: "visible" } },
  LabelStyles: {
    root: {
      fontFamily:
        '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
      WebkitFontSmoothing: "antialiased",
      fontSize: "14px",
      fontWeight: "600",
      color: "rgb(50, 49, 48)",
      boxSizing: "border-box",
      boxShadow: "none",
      margin: "0px",
      display: "block",
      padding: "5px 0px",
      overflowWrap: "break-word",
      textAlign: "center",
    },
  },
  DropdownStyles: { dropdown: { width: 180, height: 200 } },
};

const theme = getTheme();
const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight,
});

const ActionColumnClassNames = mergeStyleSets({
  ActionIconHeaderIcon: {
    padding: 0,
    fontSize: "12px",
  },
  ActionIconCell: {
    textAlign: "center",
    selectors: {
      "&:before": {
        content: ".",
        display: "inline-block",
        verticalAlign: "middle",
        height: "100%",
        width: "0px",
        visibility: "hidden",
      },
    },
  },
  ActionIcon: {
    verticalAlign: "middle",
    maxHeight: "12px",
    maxWidth: "12px",
  },
});

const DefaultState = {
  FilterItems: {
    IsChecked: false,
    Connection: "and",
    Field: "Select Field",
    Operator: "=",
    Value: "",
  },
  TmpColumn: "Select Field",
  TmpSorting: {
    Field: "Select Field",
    Order: "asc",
  },
  Columns: [
    "PlatformId",
    "LiveUrl",
    "MSProd",
    "MSTechnology",
    "MSService",
    "MSSubService",
    "Products",
    "CMProducts",
    "RepoOwner",
    "RepoName",
    "uhfHeaderId",
    "LastPublished",
    "DocSet",
    "feedback_system",
  ],
};

const ConnectionOptions = [
  { key: "and", text: "and" },
  { key: "or", text: "or" },
];

const OperatorOptions = [
  { key: "Equal", text: "=" },
  { key: "NotEqual", text: "!=" },
  { key: "Contains", text: "Contains" },
  { key: "IsEmpty", text: "Is Empty" },
  { key: "IsNotEmpty", text: "Is Not Empty" },
];

const dateFilterOptions = [
  { key: "Year", text: "Year" },
  { key: "Month", text: "Month" },
  { key: "Day", text: "Day" },
];

const dialogContentProps = {
  type: DialogType.normal,
  title: "Column Options",
  subText:
    "Add or remove columns. To change the column order, drag and drop a field.",
};

const dialogSaveQueryProps = {
  type: DialogType.normal,
  title: "Save Query",
};

const taskOpQueryOpMap = [
  ["Equal", "Equal"],
  ["NotEqual", "NotEqual"],
  ["Existent", "IsNotEmpty"],
  ["NonExistent", "IsEmpty"],
];

const GetQueryOpByTaskOp = (taskOp) => {
  for (let i = 0; i < taskOpQueryOpMap.length; i++) {
    if (taskOpQueryOpMap[i][0] === taskOp) {
      return taskOpQueryOpMap[i][1];
    }
  }
  return "";
};

const GetTaskOpByQueryOp = (queryOp) => {
  for (let i = 0; i < taskOpQueryOpMap.length; i++) {
    if (taskOpQueryOpMap[i][1] === queryOp) {
      return taskOpQueryOpMap[i][0];
    }
  }
  return "";
};

// ------------------ FilterSet ------------------
// We can use Set to get better efficiency, but the total number is small and can use array
let FilterIndexInterval = [];

const IsInFilterIndexInterval = (from, to) => {
  for (let i = 0; i < FilterIndexInterval.length; i++) {
    if (
      from === FilterIndexInterval[i][0] &&
      to === FilterIndexInterval[i][1]
    ) {
      return i;
    }
  }
  return -1;
};

const AddToFilterIndexInterval = (from, to) => {
  if (from >= to) {
    return false;
  }
  if (IsInFilterIndexInterval(from, to) > -1) {
    return false;
  }
  // Can be all-inclusive or included, and cannot have other intersecting conditions
  for (let i = 0; i < FilterIndexInterval.length; i++) {
    if (
      (from < FilterIndexInterval[i][0] &&
        to >= FilterIndexInterval[i][0] &&
        to < FilterIndexInterval[i][1]) ||
      (from <= FilterIndexInterval[i][1] &&
        to > FilterIndexInterval[i][1] &&
        from > FilterIndexInterval[i][0])
    ) {
      return false;
    }
  }

  FilterIndexInterval.push([from, to]);
  return true;
};

const RemoveFromFilterIndexInterval = (from, to) => {
  if (from >= to) {
    return false;
  }
  let index = IsInFilterIndexInterval(from, to);
  if (index === -1) {
    return false;
  }

  FilterIndexInterval.splice(index, 1);
  return true;
};

const AddIndexToFilterIndexInterval = (index) => {
  //All intervals greater than the current one must be incremented by one
  for (let i = 0; i < FilterIndexInterval.length; i++) {
    let from = FilterIndexInterval[i][0];
    let to = FilterIndexInterval[i][1];
    if (from >= index) {
      from++;
    }
    if (to >= index) {
      to++;
    }
    FilterIndexInterval[i] = [from, to];
  }
  FilterIndexInterval.push([index, index]);
  return true;
};

const RemoveIndexFromFilterIndexInterval = (index) => {
  //All intervals greater than the current one will be reduced by one
  let deleteItems = [];
  for (let i = 0; i < FilterIndexInterval.length; i++) {
    let from = FilterIndexInterval[i][0];
    let to = FilterIndexInterval[i][1];
    if (from === index && to === index) {
      deleteItems.push(i);
      continue;
    }
    if (from > index) {
      from--;
    }
    if (to >= index) {
      to--;
    }
    FilterIndexInterval[i] = [from, to];
  }
  for (let i = 0; i < deleteItems.length; i++) {
    FilterIndexInterval.splice(deleteItems[i], 1);
  }
  return true;
};

// ------------------ FilterSet ------------------

// ------------------ FilterTree ------------------
let FilterTree = {};
let FilterParentMap = {};
let FilterTreeMaxLevel = 0;

const GenFilterParentMapKey = (from, to) => {
  return "" + from + "-" + to;
};

const SetFilterTree = (tree) => {
  FilterTree = tree;
};

const GetTreeNode = (index, round) => {
  let FilterParentMapKey = GenFilterParentMapKey(index, index);
  while (FilterParentMapKey in FilterParentMap) {
    let node = FilterParentMap[FilterParentMapKey];
    if (node.level >= round) {
      return node;
    }
    if (IsInFilterIndexInterval(node.pFrom, node.pTo) > -1) {
      FilterParentMapKey = GenFilterParentMapKey(node.pFrom, node.pTo);
    } else {
      return { level: -1 };
    }
  }
  return { level: -1 };
};

const BuildFilterTree = (list) => {
  FilterTree = {};
  FilterParentMap = {};
  FilterTreeMaxLevel = 0;
  if (list.length === 0) {
    return;
  }
  let tree = buildFilterTree(0, list.length - 1, -1, -1);
  SetFilterTree(tree);
};

const buildFilterTree = (from, to, pFrom, pTo) => {
  if (from > to) {
    return {};
  }
  let mapKey = GenFilterParentMapKey(from, to);
  if (from === to) {
    FilterParentMap[mapKey] = {
      from: from,
      to: to,
      pFrom: pFrom,
      pTo: pTo,
      level: 0,
    };
    return {
      from: from,
      to: to,
      children: [],
      level: 0,
    };
  }
  let children = [];
  let tmpIndex = from;
  let level = 0;
  while (tmpIndex <= to) {
    for (let i = to; i >= tmpIndex; i--) {
      if (tmpIndex === from && i === to) {
        continue;
      }
      let index = IsInFilterIndexInterval(tmpIndex, i);
      if (index > -1) {
        let child = buildFilterTree(tmpIndex, i, from, to);
        level = Math.max(level, child.level + 1);
        children.push(child);
        tmpIndex = i + 1;
        break;
      }
    }
  }
  FilterTreeMaxLevel = Math.max(FilterTreeMaxLevel, level);
  FilterParentMap[mapKey] = {
    from: from,
    to: to,
    pFrom: pFrom,
    pTo: pTo,
    level: level,
  };
  return {
    from: from,
    to: to,
    children: children,
    level: level,
  };
};
// ------------------ FilterTree ------------------
const GroupedColorList = ["#E1FFFF", "#FFDEAD", "#FFC0CB"];
const CannedQueryData = {};
const MetadataAllowListMap = {};

const MetadataList = ({ userId }) => {
  document.title = "Metadata Query";
  const { taskId } = useParams();
  const [MetadataOptions, SetMetadataOptions] = useState([]);
  const [GitHubMetadataOptions, SetGitHubMetadataOptions] = useState([]);
  const [FilterItems, SetFilterItems] = useState([]);
  const [MetadataItems, SetMetadataItems] = useState([]);
  const [MetadataItemsCount, SetMetadataItemsCount] = useState(0);
  const [MetadataLastUpdated, SetMetadataLastUpdated] = useState("");
  const [Columns, SetColumns] = useState(DefaultState.Columns);
  const [hideColumnOptions, { toggle: toggleHideColumnOptions }] =
    useBoolean(true);
  const [hideSaveQuery, { toggle: toggleHideSaveQuery }] = useBoolean(true);
  const [hideSubmitTask, { toggle: toggleHideSubmitTask }] = useBoolean(true);
  const [TmpColumns, SetTmpColumns] = useState(DefaultState.Columns);
  const [Sorting, SetSorting] = useState([]);
  const [TmpSorting, SetTmpSorting] = useState([]);
  const [GroupedColumns, SetGroupedColumns] = useState([]);
  const [CannedQuery, SetCannedQuery] = useState([]);
  const [CannedQuerySelected, SetCannedQuerySelected] = useState({});
  const [TmpCannedQuerySelected, SetTmpCannedQuerySelected] = useState({});
  const [LoadingString, SetLoadingString] = useState(
    Resources.MetadataDetail.NoMetadatas
  );
  const [LoadingFieldString, SetLoadingFieldString] = useState(
    Resources.MetadataDetail.NoMetadataFields
  );
  const [tabValue, setTabValue] = useState("Query");
  const [selectedField, setSelectedField] = useState("");
  const [metadataFieldLegend, setMetadataFieldLegend] = useState([]);
  const [metadataFieldValueSummary, setMetadataFieldValueSummary] = useState(
    []
  );
  const [metadataFieldSummaryYear, setMetadataFieldSummaryYear] = useState([]);
  const [metadataFieldSummaryMonth, setMetadataFieldSummaryMonth] = useState(
    []
  );
  const [metadataFieldSummaryDay, setMetadataFieldSummaryDay] = useState([]);
  const [isFilterCollapsed, { toggle: toggleFilterCollapsed }] =
    useBoolean(false);
  const [MetadataFieldItems, SetMetadataFieldItems] = useState([]);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState("Month");

  const [NeedRunQuery, SetNeedRunQuery] = useState(false);

  const onTabSwitch = (item) => {
    var tmpTabValue = cloneDeep(tabValue);
    tmpTabValue = item.props.itemKey;
    setTabValue(tmpTabValue);
  };

  const onSelectedDateChange = (_, val) => {
    setSelectedDate(val.key);
    setMetadataFieldValueSummary(
      val.key == "Year"
        ? metadataFieldSummaryYear
        : val.key == "Month"
        ? metadataFieldSummaryMonth
        : metadataFieldSummaryDay
    );
  };

  const isValidDate = (string) => {
    var formats = [moment.ISO_8601];
    return moment(string, formats, true).isValid();
  };

  const formatDate = (list, filter) => {
    var tmplist = list;
    filter == "Year"
      ? tmplist.forEach((e) => {
          e[0].value = moment(e[0].value).format(
            "YYYY"
          );
        })
      : filter == "Month"
      ? tmplist.forEach((e) => {
          e[0].value = moment(e[0].value).format(
            "YYYY-MM"
          );
        })
      : tmplist.forEach((e) => {
          e[0].value = moment(e[0].value).format(
            "YYYY-MM-DD"
          );
        });
    let newList = [];
    list.forEach((e) => {
      var item = {
        [selectedField]: e[0].value,
        Count: e[1].value,
      };
      if (!newList.map((a) => a[selectedField]).includes(item[selectedField])) {
        newList.push(item);
      } else {
        let filtered = newList.filter(
          (i) => i[selectedField] == item[selectedField]
        );
        filtered[0].Count += item.Count;
      }
    });
    return newList;
  };

  const BuildGroupedColumns = (tmpFilterItems) => {
    let groupColumns = [];
    for (let i = 1; i <= FilterTreeMaxLevel; i++) {
      if (
        i === FilterTreeMaxLevel &&
        IsInFilterIndexInterval(0, tmpFilterItems.length - 1) === -1
      ) {
        break;
      }
      groupColumns.push({
        key: "groupedColumn" + i,
        name: "",
        fieldName: "",
        onRender: (item, _index) => {
          let treeNode = GetTreeNode(_index, i);
          let treeLevel = treeNode.level;
          let styleObj = { height: 38 };
          if (treeLevel > -1) {
            styleObj.background =
              GroupedColorList[treeLevel % GroupedColorList.length];
            styleObj.border =
              "solid 1px " +
              GroupedColorList[treeLevel % GroupedColorList.length];
          }
          if (treeLevel === i) {
            styleObj.borderLeft = "solid 1px #000000";
          }

          if (_index === treeNode.from) {
            styleObj.borderTop = "solid 1px #000000";
          }
          if (_index === treeNode.to) {
            styleObj.borderBottom = "solid 1px #000000";
          }

          let isTopLeft = _index === treeNode.from && treeLevel === i;
          if (isTopLeft) {
            return (
              <div style={styleObj}>
                <IconButton
                  iconProps={{ iconName: "GroupList" }}
                  onClick={() => {
                    RemoveGroupFilterItems(treeNode.from, treeNode.to);
                  }}
                />
              </div>
            );
          } else {
            return (
              <div style={styleObj}>
                <IconButton />
              </div>
            );
          }
        },
        maxWidth: 10,
        minWidth: 10,
      });
    }
    SetGroupedColumns(groupColumns.reverse());
  };

  const RemoveGroupFilterItems = (from, to) => {
    RemoveFromFilterIndexInterval(from, to);
    BuildFilterTree(FilterItems);
    BuildGroupedColumns(FilterItems);
  };

  const GroupFilterItems = () => {
    let filterIndex = [];
    for (let i = 0; i < FilterItems.length; i++) {
      if (FilterItems[i].IsChecked) {
        if (
          filterIndex.length > 0 &&
          filterIndex[filterIndex.length - 1] + 1 !== i
        ) {
          alert("Please select the filter item in order");
          return;
        }
        filterIndex.push(i);
      }
    }

    if (filterIndex.length <= 1) {
      alert("Please select the correct filter");
      return;
    }

    if (
      !AddToFilterIndexInterval(
        filterIndex[0],
        filterIndex[filterIndex.length - 1]
      )
    ) {
      alert("Please select the correct filter");
      return;
    }

    const tmp = cloneDeep(FilterItems);
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].IsChecked = false;
    }
    SetFilterItems(tmp);
    BuildFilterTree(tmp);
    BuildGroupedColumns(tmp);
  };

  const AddToList = (
    value,
    list,
    listFunc,
    index = -1,
    isFilterList = false
  ) => {
    const tmp = cloneDeep(list);
    if (index > -1) {
      tmp.splice(index, 0, cloneDeep(value));
    } else {
      tmp.push(cloneDeep(value));
    }

    listFunc(tmp);
    if (isFilterList) {
      BuildFilterTree(tmp);
      BuildGroupedColumns(tmp);
    }
  };

  const RemoveToList = (index, list, listFunc, isFilterList = false) => {
    const tmp = cloneDeep(list);
    tmp.splice(index, 1);
    listFunc(tmp);
    if (isFilterList) {
      BuildFilterTree(tmp);
      BuildGroupedColumns(tmp);
    }
  };

  const ChangeItem = (index, key, value, list, listFunc) => {
    const tmp = cloneDeep(list);
    if (Object.prototype.toString.call(tmp[index]) === "[object Object]") {
      tmp[index][key] = value;
    } else {
      tmp[index] = value;
    }

    listFunc(tmp);
  };

  const DragItems = (fromIndex, toIndex, list, listFunc) => {
    const tmp = cloneDeep(list);
    const tmpItem = tmp[fromIndex];
    for (let i = fromIndex + 1; i < tmp.length; i++) {
      tmp[i - 1] = tmp[i];
    }
    for (let i = tmp.length - 2; i >= toIndex; i--) {
      tmp[i + 1] = tmp[i];
    }
    tmp[toIndex] = tmpItem;

    listFunc(tmp);
  };

  const GetMetadataList = async () => {
    try {
      SetLoadingString("Loading...");
      SetMetadataItems([]);
      var checkRes = CheckFilter();
      if (!checkRes) {
        alert("Please select the correct filter");
        return;
      }
      var request = {
        GroupedFilters: GenGroupedFilters(
          "from" in FilterTree ? [FilterTree] : []
        ),
        SortingColumns: GenSortingColumns(),
        ProjectColumns: GenProjectColumns(),
      };
      let metadataList = await TaskAPI.getMetadataList(request);
      SetMetadataItems(metadataList.data.data);
      SetMetadataItemsCount(metadataList.data.count);
      SetLoadingString(Resources.MetadataDetail.NoMetadatas);
      if (metadataList.data.data.length > 0) {
        let date = new Date(metadataList.data.lastUpdated);
        let year = date.getUTCFullYear();
        let month = date.getUTCMonth() + 1; // Months are zero-based
        let day = date.getUTCDate();
        let hours = date.getUTCHours();
        let minutes = date.getUTCMinutes();
        let seconds = date.getUTCSeconds();

        // Convert to 12-hour format
        let period = "AM";
        if (hours >= 12) {
          period = "PM";
          if (hours > 12) {
            hours -= 12;
          }
        }

        // Combine the parts
        let formattedDate = month + "/" + day + "/" + year + " " + hours + ":" + minutes + ":" + seconds + " " + period;
 
        SetMetadataLastUpdated(formattedDate);
      }
    } catch (e) {
      MessageActions.errorHandler(e);
    }
  };

  const GetMetadataCount = async () => {
    try {
      SetLoadingFieldString("Loading...");
      SetMetadataFieldItems([]);
      setMetadataFieldLegend([]);
      setMetadataFieldValueSummary([]);
      setMetadataFieldSummaryYear([]);
      setMetadataFieldSummaryMonth([]);
      setMetadataFieldSummaryDay([]);
      setIsDateSelected(false);
      var checkRes = CheckFilter();
      if (!checkRes) {
        alert("Please select the correct filter");
        return;
      }
      var request = {
        GroupedFilters: GenGroupedFilters(
          "from" in FilterTree ? [FilterTree] : []
        ),
        SortingColumns: GenSortingColumns(),
        SummarizeColumns: [{ Key: selectedField }],
      };
      let metadataList = await TaskAPI.getMetadataList(request);
      let setCount = 0;
      let notSetCount = 0;
      //check if date field
      let isAllDateField = true;
      let existNonNullDate = false;
      for (var e of metadataList.data.data) {
        e.forEach((item) => {
          if (item.key === selectedField && item.value?.length > 0 && !isValidDate(item.value)) {
            isAllDateField = false;
          } else if (item.key === selectedField && item.value?.length > 0 && isValidDate(item.value)) {
            existNonNullDate = true;
          }
        });
        if(!isAllDateField) break;
      }
      setIsDateSelected(isAllDateField & existNonNullDate);
      if (isAllDateField & existNonNullDate) {
        let yearValues = [];
        let monthValues = [];
        let dayValues = [];
        let list = metadataList.data.data;
        let sortedList = list.sort((a, b) => b[1].value - a[1].value);
        let dayList = formatDate(list, "Day").sort(
          (a, b) => b[selectedField].value - a[selectedField].value
        );
        let monthList = formatDate(list, "Month").sort(
          (a, b) => b[selectedField].value - a[selectedField].value
        );
        let yearList = formatDate(list, "Year").sort(
          (a, b) => b[selectedField].value - a[selectedField].value
        );
        let maxYearCount = yearList[0].Count;
        let maxMonthCount = monthList[0].Count;
        let maxDayCount = dayList[0].Count;
        dayList.sort(
          (a, b) =>
            new Date(b[selectedField].value) - new Date(a[selectedField].value)
        );
        monthList.sort(
          (a, b) =>
            new Date(b[selectedField].value) - new Date(a[selectedField].value)
        );
        yearList.sort(
          (a, b) =>
            new Date(b[selectedField].value) - new Date(a[selectedField].value)
        );
        sortedList.forEach((e) => {
          e[0].value?.length > 0
            ? (setCount += e[1].value)
            : (notSetCount += e[1].value);
        });
        yearList.forEach((e) => {
          let fieldValue = {
            chartTitle:
              e[selectedField]?.length > 0 ? e[selectedField] : "Not Set",
            chartData: [
              {
                legend:
                  e[selectedField]?.length > 0 ? e[selectedField] : "Not Set",
                horizontalBarChartdata: { x: e.Count, y: maxYearCount },
                color: "#0099BC",
                xAxisCalloutData:
                  e[selectedField]?.length > 0 ? e[selectedField] : "Not Set",
                yAxisCalloutData: e.Count,
              },
            ],
          };
          yearValues.push(fieldValue);
        });
        monthList.forEach((e) => {
          let fieldValue = {
            chartTitle:
              e[selectedField]?.length > 0 ? e[selectedField] : "Not Set",
            chartData: [
              {
                legend:
                  e[selectedField]?.length > 0 ? e[selectedField] : "Not Set",
                horizontalBarChartdata: { x: e.Count, y: maxMonthCount },
                color: "#0099BC",
                xAxisCalloutData:
                  e[selectedField]?.length > 0 ? e[selectedField] : "Not Set",
                yAxisCalloutData: e.Count,
              },
            ],
          };
          monthValues.push(fieldValue);
        });
        dayList.forEach((e) => {
          let fieldValue = {
            chartTitle:
              e[selectedField]?.length > 0 ? e[selectedField] : "Not Set",
            chartData: [
              {
                legend:
                  e[selectedField]?.length > 0 ? e[selectedField] : "Not Set",
                horizontalBarChartdata: { x: e.Count, y: maxDayCount },
                color: "#0099BC",
                xAxisCalloutData:
                  e[selectedField]?.length > 0 ? e[selectedField] : "Not Set",
                yAxisCalloutData: e.Count,
              },
            ],
          };
          dayValues.push(fieldValue);
        });
        setMetadataFieldSummaryYear(yearValues);
        setMetadataFieldSummaryMonth(monthValues);
        setMetadataFieldSummaryDay(dayValues);
        setMetadataFieldValueSummary(monthValues);
      } else {
        let fieldValues = [];
        let list = metadataList.data.data;
        let sortedList = list.sort((a, b) => b[1].value - a[1].value);
        let maxCount = sortedList[0][1].value;
        sortedList.forEach((e) => {
          e[0].value?.length > 0
            ? (setCount += e[1].value)
            : (notSetCount += e[1].value);
        });
        sortedList.forEach((e) => {
          let fieldValue = {
            chartTitle:
              e[0].value?.length > 0
                ? e[0].value
                : "Not Set",
            chartData: [
              {
                legend:
                  e[0].value?.length > 0
                    ? e[0].value
                    : "Not Set",
                horizontalBarChartdata: { x: e[1].value, y: maxCount },
                color: "#0099BC",
                xAxisCalloutData:
                  e[0].value?.length > 0
                    ? e[0].value
                    : "Not Set",
                yAxisCalloutData: e[1].value,
                onClick: () => ChartClick(e[0].value),
              },
            ],
          };

          fieldValues.push(fieldValue);
        });
        setMetadataFieldValueSummary(fieldValues);
      }
      setMetadataFieldLegend({
        chartTitle: "Metadata Null Value Compare",
        chartData: [
          {
            legend: "Not Set",
            data: notSetCount,
            color: "#0099BC",
            onClick: () => ChartClick(""),
          },
          {
            legend: "Set",
            data: setCount,
            color: "#77004D",
            onClick: () => ChartClick("Set"),
          },
        ],
        total: setCount + notSetCount,
      });

      //another request to get records for detailslist
      var GroupedFilters = GenGroupedFilters(
        "from" in FilterTree ? [FilterTree] : []
      );
      GroupedFilters[0].Children.push({
        Connection: "and",
        Key: selectedField,
        Op: "IsNotEmpty",
        Value: "",
        Children: [],
      });
      var newRequest = {
        GroupedFilters: GroupedFilters,
        SortingColumns: GenSortingColumns(),
        ProjectColumns: GenProjectColumns(),
      };
      let metadataFieldList = await TaskAPI.getMetadataList(newRequest);
      SetMetadataFieldItems(metadataFieldList.data.data);
      SetLoadingFieldString(Resources.MetadataDetail.NoMetadataFields);
    } catch (e) {
      MessageActions.errorHandler(e);
    }
  };

  const ChartClick = (metadataValue) => {
    let newFilterItem = {};
    if (metadataValue === "Set") {
      newFilterItem = {
        IsChecked: false,
        Connection: "and",
        Field: selectedField,
        Operator: "IsNotEmpty",
        Value: "",
      };
    } else if (metadataValue.length > 0) {
      // normal value
      newFilterItem = {
        IsChecked: false,
        Connection: "and",
        Field: selectedField,
        Operator: "Equal",
        Value: metadataValue,
      };
    } else {
      // not set
      newFilterItem = {
        IsChecked: false,
        Connection: "and",
        Field: selectedField,
        Operator: "IsEmpty",
        Value: "",
      };
    }

    AddIndexToFilterIndexInterval(FilterItems.length);
    AddToList(newFilterItem, FilterItems, SetFilterItems, -1, true);
    SetNeedRunQuery(true);
  };

  const CheckFilter = () => {
    for (let i = 0; i < FilterItems.length; i++) {
      if (
        FilterItems[i].Connection !== "and" &&
        FilterItems[i].Connection !== "or"
      ) {
        return false;
      }
      if (
        FilterItems[i].Field === "" ||
        FilterItems[i].Field === DefaultState.FilterItems.Field
      ) {
        return false;
      }

      if (FilterItems[i].Operator === "") {
        return false;
      }
    }
    return true;
  };

  const GenGroupedFilters = (FilterTrees) => {
    var gList = [];
    if (FilterTrees) {
      for (let i = 0; i < FilterTrees.length; i++) {
        let data = {};
        let item = FilterTrees[i];
        if (item.from === item.to) {
          data.Connection = FilterItems[item.from].Connection;
          data.Key = FilterItems[item.from].Field;
          data.Op = FilterItems[item.from].Operator;
          data.Value = FilterItems[item.from].Value;
          data.Children = [];
        } else {
          data.Children = GenGroupedFilters(item.children);
        }
        gList.push(data);
      }
    }
    return gList;
  };

  const GenSortingColumns = () => {
    let sortingColumns = [];
    Sorting.forEach((item) => {
      sortingColumns.push({
        Key: item.Field,
        SortType: item.Order,
      });
    });
    return sortingColumns;
  };

  const GenProjectColumns = () => {
    let ProjectColumns = [];
    Columns.forEach((item) => {
      ProjectColumns.push({
        Key: item,
      });
    });
    return ProjectColumns;
  };

  const RunQuery = () => {
    tabValue === "Query" ? GetMetadataList() : GetMetadataCount();
  };

  const Discard = () => {
    DiscardFilter();
    DiscardCannedQuery();
  };

  const DiscardFilter = () => {
    SetFilterItems([]);
    SetMetadataItemsCount(0);
    FilterTree = {};
    FilterParentMap = {};
  };

  const DiscardCannedQuery = () => {
    SetCannedQuerySelected({});
    SetTmpCannedQuerySelected({});
  };

  const SubmitColumnOptions = () => {
    SetColumns(TmpColumns);
    SetSorting(TmpSorting);
    toggleHideColumnOptions();
  };

  const CancelColumnOptions = () => {
    toggleHideColumnOptions();
    SetTmpColumns(Columns);
    SetTmpSorting(Sorting);
  };

  const AddQuery = async () => {
    await SubmitSaveQuery();
  };

  const UpdateQuery = async () => {
    await SubmitSaveQuery(CannedQuerySelected.id);
  };

  const SubmitSaveQuery = async (id = "") => {
    try {
      var checkRes = CheckFilter();
      
      if (!checkRes) {
        alert("Please select the correct filter");
        return;
      }
      const data = {
        id: id,
        name: TmpCannedQuerySelected.name,
        data: JSON.stringify({
          FilterItems: FilterItems,
          FilterIndexInterval: FilterIndexInterval,
          Sorting: Sorting,
          Columns: Columns,
        }),
        submitter: userId,
        allowSharing: TmpCannedQuerySelected.allowSharing,
      };
      await TaskAPI.saveQuery(data);
      SetCannedQuerySelected(TmpCannedQuerySelected);
      initCannedQuery();
      toggleHideSaveQuery();
    } catch (e) {
      MessageActions.errorHandler(e);
    }
  };

  const CancelSaveQuery = () => {
    toggleHideSaveQuery();
    SetTmpCannedQuerySelected(CannedQuerySelected);
  };

  const DisableSubmitColumnOptions = () => {
    let visColumn = new Set();
    for (let col of TmpColumns) {
      if (col === "" || col === DefaultState.TmpColumn || visColumn.has(col)) {
        return true;
      }
      visColumn.add(col);
    }
    let visSorting = new Set();
    for (let item of TmpSorting) {
      if (
        item.Field === "" ||
        item.Field === DefaultState.TmpColumn ||
        visSorting.has(item.Field)
      ) {
        return true;
      }
      visSorting.add(item.Field);
    }
    return false;
  };

  const initCannedQuery = async () => {
    try {
      const newUserId = userId.split("@microsoft.com")[0];
      const CannedQueryRes = await TaskAPI.getCannedQuery(newUserId);
      const Query = CannedQueryRes.data;
      const tmpCannedQuery = [];
      for (let i = 0; i < Query.length; i++) {
        // url decode
        let queryItem = {
          id: decodeURIComponent(Query[i].id),
          name: decodeURIComponent(Query[i].name),
          data: decodeURIComponent(Query[i].data),
          submitter: decodeURIComponent(Query[i].submitter),
          allowSharing: Query[i].allowSharing,
        }
        try{
          CannedQueryData[queryItem.id] = {
            id: queryItem.id,
            name: queryItem.name,
            data: JSON.parse(queryItem.data),
            submitter: queryItem.submitter,
            allowSharing: queryItem.allowSharing,
          };
        } catch (e) {
          continue;
        }
        
        tmpCannedQuery.push({
          text: queryItem.name,
          key: queryItem.id,
        });
      }
      SetCannedQuery(tmpCannedQuery);
    } catch (e) {
      MessageActions.errorHandler(e);
    }
  };

  const GetTaskConditions = async (taskId) => {
    try {
      const taskDetail = await TaskAPI.getTaskDetail(taskId);
      const task = taskDetail.data;
      return task.conditions;
    } catch (e) {
      MessageActions.errorHandler(e);
    }
  };

  const GetGitHubNameByField = (field) => {
    for (let i = 0; i < MetadataOptions.length; i++) {
      if (MetadataOptions[i].key === field) {
        return MetadataOptions[i].gitHubName;
      }
    }
    return "";
  };

  const BuildSubmitTaskConditions = () => {
    let conditions = [];
    let displayConditions = [];
    let displayNotInGithub = [];
    for (let i = 0; i < FilterItems.length; i++) {
      let taskKey = GetGitHubNameByField(FilterItems[i].Field);
      if (["IsLive", "Locale", "RedirectUrl", "Select Field"].includes(FilterItems[i].Field)) {
        continue;
      }
      if (!taskKey) {
        displayNotInGithub.push(FilterItems[i].Field);
      }
      let taskCon = FilterItems[i].Operator;
      let taskValue = FilterItems[i].Value;
      conditions.push({
        con: taskCon,
        conditionKey: FilterItems[i].Field,
        conditionValue:
          taskCon === "IsEmpty" || taskCon === "IsNotEmpty" ? "n/a" : taskValue,
        isArrayValue: false,
      });

      if (taskCon === "Equal") {
        taskCon = "=";
      } else if (taskCon === "NotEqual") {
        taskCon = "!=";
      }
      displayConditions.push(`${taskKey?taskKey:FilterItems[i].Field} ${taskCon} ${taskValue}`);
    }
    SetConItems(conditions);
    SetDisplayCon(displayConditions.join(" and "));
    SetMetadataNotInGitHub(displayNotInGithub);
  };

  const initFilter = async (MetadataOptions) => {
    const DefaultFilter = [
      {
        IsChecked: false,
        Connection: "and",
        Field: "IsLive",
        Operator: "Equal",
        Value: "True",
      },
      {
        IsChecked: false,
        Connection: "and",
        Field: "Locale",
        Operator: "Equal",
        Value: "en-us",
      },
      {
        IsChecked: false,
        Connection: "and",
        Field: "RedirectUrl",
        Operator: "IsEmpty",
        Value: "",
      },
    ];

    if (taskId) {
      const MetadataKeys = MetadataOptions.map((item) => item.key);
      const conditions = await GetTaskConditions(taskId);
      if (conditions) {
        conditions.forEach((item) => {
          let field = "";
          let taskKey = item.metadata.key;
          let queryKey = "";
          for (let i = 0; i < MetadataOptions.length; i++) {
            if (MetadataOptions[i].key === taskKey) {
              queryKey = MetadataOptions[i].key;
              break;
            } else if (MetadataOptions[i].gitHubName === taskKey) {
              queryKey = MetadataOptions[i].key;
              break;
            }
          }

          if (queryKey !== "") {
            if (MetadataKeys.includes(queryKey)) {
              field = queryKey;
            } else if (MetadataKeys.includes(taskKey)) {
              field = taskKey;
            }
          } else {
            if (MetadataKeys.includes(taskKey)) {
              field = taskKey;
            }
          }
          if (field === "") {
            return;
          }

          DefaultFilter.push({
            IsChecked: false,
            Connection: "and",
            Field: field,
            Operator: GetQueryOpByTaskOp(item.con),
            Value: item.metadata.value === "n/a"? "": item.metadata.value,
          });
        });
      }
    }

    SetFilterItems(DefaultFilter);
    for (let i = 0; i < DefaultFilter.length; i++) {
      AddIndexToFilterIndexInterval(i);
    }
    BuildFilterTree(DefaultFilter);
    BuildGroupedColumns(DefaultFilter);
    if (taskId) {
      SetNeedRunQuery(true);
    }
  };

  const initTeamList = async () => {
    try {
      const teamTemplateList = await TaskAPI.loadTeamTemplate();
      if (teamTemplateList.data?.length > 1) {
        setShowTeamSelector(true);
        var teamList = [];
        teamTemplateList.data.forEach((e) => {
          teamList.push({ key: e.id, text: e.teamAlias });
        });
        setTeamList(teamList);
      }
      var templateList = [];
      var templateFieldsList = [];
      teamTemplateList.data.forEach((e) => {
        e.templateConfigs.forEach((item) => {
          templateList.push({ key: item.id, text: item.name, team: e.id });
          templateFieldsList.push({ key: item.id, fields: item.fields });
        });
      });
      setTemplateList(templateList);
      setTemplateFieldsList(templateFieldsList);
    } catch (error) {
      MessageActions.errorHandler(error);
    }
  };

  const initFn = async () => {
    try {
      const MetadataAllowKeys = await TaskAPI.getMetadataKeys();
      const MetadataOptions = [];
      const GitHubMetadata = [];
      MetadataAllowKeys.data.data.forEach((item) => {
        MetadataAllowListMap[item.key] = item.props;
        let text = item.key;
        if (item.props.inRawMetadata) {
          text = "RawMetadata." + text;
        } else {
          if (item.props.gitHubName !== "") {
            text += " (" + item.props.gitHubName + ")";
          }
        }

        MetadataOptions.push({
          text: text,
          key: item.key,
          gitHubName: item.props.gitHubName,
        });

        if (item.props.gitHubName !== "") {
          GitHubMetadata.push({
            text: item.props.gitHubName,
            key: item.props.gitHubName,
          });
        }
      });
      SetMetadataOptions(MetadataOptions);
      SetGitHubMetadataOptions(GitHubMetadata);
      await initFilter(MetadataOptions);
      initCannedQuery();
      initTeamList();
    } catch (error) {}
  };

  const ExportMetadata = () => {
    const options = {
      filename: "MetadataList.csv",
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    let exportMetadata = MetadataItems.map((item) => {
      let exportItem = {};
      let keyList = [];
      item.forEach((e) => {keyList.push(e.key);});
      keyList.forEach((key) => {
        exportItem[key] = item.filter(e=>e.key===key)[0].value;
      });
      return exportItem;
    });

    csvExporter.generateCsv(exportMetadata);
  };

  const ChangeFilterWithCannedQuery = (queryId) => {
    let data = CannedQueryData[queryId].data;
    FilterIndexInterval = data.FilterIndexInterval;
    SetCannedQuerySelected(CannedQueryData[queryId]);
    SetTmpCannedQuerySelected(CannedQueryData[queryId]);
    SetFilterItems(data.FilterItems);
    SetSorting(data.Sorting);
    SetTmpSorting(data.Sorting);
    SetColumns(data.Columns);
    SetTmpColumns(data.Columns);
    BuildFilterTree(data.FilterItems);
    BuildGroupedColumns(data.FilterItems);
  };

  useEffect(() => {
    if (userId !== null) {
      initFn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (selectedField?.length > 0) {
      GetMetadataCount();
    }
  }, [selectedField]);

  useEffect(() => {
    // Build conditions for task
    BuildSubmitTaskConditions();
  }, [FilterItems]);

  useEffect(() => {
    if (NeedRunQuery) {
      RunQuery();
      SetNeedRunQuery(false);
    }
  }, [NeedRunQuery]);

  let DraggedColumnIndex = -1;
  let DraggedSortingIndex = -1;

  const [AdoLink, SetAdoLink] = useState("");
  const [ShowToggleOp, SetShowToggleOp] = useState(false);
  const [ToggleOp, SetToggleOp] = useState("Delete");
  const [OpKey, SetOpKey] = useState("");
  const [OpValue, SetOpValue] = useState("");
  const [OpItems, SetOpItems] = useState([]);
  const [ConItems, SetConItems] = useState([]);
  const [DisplayCon, SetDisplayCon] = useState("");
  const [MetadataNotInGitHub, SetMetadataNotInGitHub] = useState([]);
  // team state
  const [showTeamSelector, setShowTeamSelector] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [templateFieldsList, setTemplateFieldsList] = useState([]);
  const [sourceTeam, setSourceTeam] = useState("");
  const [templateConfigId, setTemplateConfigId] = useState("");
  const [templateListFiltered,setTemplateListFiltered] = useState([])

  const ToggleOpEditor = () => {
    SetShowToggleOp(!ShowToggleOp);
  };

  const AddOpToList = () => {
    const item = {
      op: ToggleOp,
      metaKey: OpKey,
      metaValue: OpValue,
      isArrayValue: false,
    };
    const tmpItems = cloneDeep(OpItems);
    tmpItems.push(item);
    SetOpItems(tmpItems);

    ResetOpEditor();
  };
  const ResetOpEditor = () => {
    SetShowToggleOp(false);
    SetToggleOp("Delete");
    SetOpKey("");
    SetOpValue("");
  };

  


  const CheckSubmitTask = () => {
    if (AdoLink.length === 0) {
      return "AdoLink cannot be empty";
    }
    // check condition
    if (ConItems.length === 0) {
      return "Condition cannot be empty";
    }
    let conditionMap = {};
    for (let i=0; i<ConItems.length; i++) {
      let con = ConItems[i];
      if (con.conditionKey === "" ) {
        return "Condition key cannot be empty";
      }
      if ((con.con === "Equal" || con.con === "NotEqual") && con.conditionValue === ""){
        return `Condition value of ${con.conditionKey} cannot be empty`;
      }
      let conditionKey = GetGitHubNameByField(con.conditionKey);
      conditionMap[conditionKey?conditionKey:con.conditionKey] = con.conditionValue;
    }

    if (OpItems.length === 0) {
      return "Operation cannot be empty";
    }

    for (let i=0; i<OpItems.length; i++) {
      let op = OpItems[i];
      if (op.op === "Delete" && (!(op.metaKey in conditionMap) || conditionMap[op.metaKey] !== op.metaValue)) {
        return `${op.metaKey}=${op.metaValue} needs to be in the condition`;
      }
    }

    return "";
  }

  const SubmitTask = async () => {
    try {
      let errorMessage = CheckSubmitTask();
      if (errorMessage !== "") {
        alert(errorMessage);
        return;
      }
      let title = [];
      const operations = OpItems.map((i) => {
        if (i.op === "Delete") {
          title.push(`${i.op} ${i.metaKey}`);
        } else {
          title.push(`${i.op} ${i.metaKey}=${i.metaValue}`);
        }
        title.push(`${i.op} ${i.metaKey}`);
        const itemData = {
          Op: i.op,
          Item: {
            Key: i.metaKey,
            Value: i.metaValue,
            IsArray: i.isArrayValue,
          },
        };
        return itemData;
      });
      const conditions = ConItems.map((i) => {
        const conditionData = {
          Con: i.con,
          Metadata: {
            Key: i.conditionKey,
            Value: i.conditionValue,
            IsArray: i.isArrayValue,
          },
        };
        return conditionData;
      });
      const data = {
        Title: `[Query Page] ${title.join(" and ")}`,
        AdoLink: AdoLink,
        Description: "This task is created by Query Page",
        Operations: operations,
        Conditions: conditions,
        SourceTeam: sourceTeam,
        TemplateConfigId: templateConfigId,
        TaskSource: "QUERY_PAGE"
      };
      const resp = await TaskAPI.queryPageSubmit(data);
      SetAdoLink("");
      ResetOpEditor();
      window.open(
        `${window.location.origin}/#/tasks/${resp.data[AdoLink].taskId}`
      );
    } catch (e) {
      MessageActions.errorHandler(e);
    }
  };

  return (
    <div style={MetadataStyles.Container}>
      <BackHomeButton />
      <Dialog
        hidden={hideSaveQuery}
        onDismiss={toggleHideSaveQuery}
        dialogContentProps={dialogSaveQueryProps}
        maxWidth={1000}
      >
        <TextField
          label="Name"
          styles={{ root: { width: 600 } }}
          value={TmpCannedQuerySelected.name}
          onChange={(_, newValue) => {
            const tmp = cloneDeep(TmpCannedQuerySelected);
            tmp.name = newValue;
            SetTmpCannedQuerySelected(tmp);
          }}
        />
        <Checkbox
          checked={TmpCannedQuerySelected.allowSharing}
          label="Share to others"
          styles={{ root: { marginTop: 20 } }}
          onChange={(_, isChecked) => {
            const tmp = cloneDeep(TmpCannedQuerySelected);
            tmp.allowSharing = isChecked;
            SetTmpCannedQuerySelected(tmp);
          }}
        />
        <DialogFooter>
          <PrimaryButton
            onClick={AddQuery}
            disabled={TmpCannedQuerySelected.name === ""}
            text="Add Query"
          />
          <PrimaryButton
            onClick={UpdateQuery}
            disabled={
              TmpCannedQuerySelected.submitter !== userId ||
              TmpCannedQuerySelected.name === ""
            }
            text="Update Query"
          />
          <DefaultButton onClick={CancelSaveQuery} text="Cancel" />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideColumnOptions}
        onDismiss={toggleHideColumnOptions}
        dialogContentProps={dialogContentProps}
        maxWidth={1000}
      >
        <Pivot aria-label="Basic Pivot Example">
          <PivotItem headerText="Columns">
            <DetailsList
              items={TmpColumns.map((item, itemIndex) => {
                return {
                  Field: item,
                  Index: itemIndex,
                };
              })}
              selectionMode={SelectionMode.none}
              isHeaderVisible={false}
              columns={[
                {
                  key: "Field",
                  name: "Field",
                  fieldName: "Field",
                  maxWidth: 1000,
                  minWidth: 50,
                  onRender: (item, _index) => (
                    <DropdownSearch
                      placeholder={DefaultState.TmpColumn}
                      options={MetadataOptions}
                      multiSelect={false}
                      selectedKey={item.Field}
                      onChange={(_, newValue) => {
                        ChangeItem(
                          _index,
                          "Field",
                          newValue.key,
                          TmpColumns,
                          SetTmpColumns
                        );
                      }}
                    />
                  ),
                },
                {
                  key: "Remove",
                  name: "",
                  fieldName: "Remove",
                  onRender: (item, _index) => (
                    <IconButton
                      iconProps={{ iconName: "Clear" }}
                      style={{ color: SharedColors.red10 }}
                      onClick={() => {
                        RemoveToList(_index, TmpColumns, SetTmpColumns);
                      }}
                    />
                  ),
                  maxWidth: 10,
                  minWidth: 10,
                },
              ]}
              dragDropEvents={{
                canDrop: (dropContext, dragContext) => {
                  return true;
                },
                canDrag: (item) => {
                  return true;
                },
                onDragEnter: (item) => {
                  // return string is the css classes that will be added to the entering element.
                  return dragEnterClass;
                },
                onDragLeave: (item, event) => {
                  return;
                },
                onDrop: (item, event) => {
                  if (DraggedColumnIndex > -1) {
                    DragItems(
                      DraggedColumnIndex,
                      item.Index,
                      TmpColumns,
                      SetTmpColumns
                    );
                  }
                },
                onDragStart: (item, itemIndex, selectedItems, event) => {
                  DraggedColumnIndex = itemIndex;
                },
                onDragEnd: (item, event) => {
                  DraggedColumnIndex = -1;
                },
              }}
            />
            <ActionButton
              iconProps={{ iconName: "Add" }}
              text="Add a column"
              onClick={() => {
                AddToList(DefaultState.TmpColumn, TmpColumns, SetTmpColumns);
              }}
            />
          </PivotItem>
          <PivotItem headerText="Sorting">
            <DetailsList
              items={TmpSorting.map((item, itemIndex) => {
                return {
                  Field: item.Field,
                  Order: item.Order,
                  Index: itemIndex,
                };
              })}
              selectionMode={SelectionMode.none}
              isHeaderVisible={false}
              columns={[
                {
                  key: "Field",
                  name: "Field",
                  fieldName: "Field",
                  maxWidth: 1000,
                  minWidth: 50,
                  onRender: (item, _index) => (
                    <DropdownSearch
                      placeholder={DefaultState.TmpColumn}
                      options={MetadataOptions}
                      multiSelect={false}
                      selectedKey={item.Field}
                      onChange={(_, newValue) => {
                        ChangeItem(
                          _index,
                          "Field",
                          newValue.key,
                          TmpSorting,
                          SetTmpSorting
                        );
                      }}
                    />
                  ),
                },
                {
                  key: "Order",
                  name: "",
                  fieldName: "Order",
                  onRender: (item, _index) => (
                    <IconButton
                      iconProps={{
                        iconName: item.Order === "asc" ? "SortUp" : "SortDown",
                      }}
                      onClick={() => {
                        ChangeItem(
                          _index,
                          "Order",
                          item.Order === "asc" ? "desc" : "asc",
                          TmpSorting,
                          SetTmpSorting
                        );
                      }}
                    />
                  ),
                  maxWidth: 10,
                  minWidth: 10,
                },
                {
                  key: "Remove",
                  name: "",
                  fieldName: "Remove",
                  onRender: (item, _index) => (
                    <IconButton
                      iconProps={{ iconName: "Clear" }}
                      style={{ color: SharedColors.red10 }}
                      onClick={() => {
                        RemoveToList(_index, TmpSorting, SetTmpSorting);
                      }}
                    />
                  ),
                  maxWidth: 10,
                  minWidth: 10,
                },
              ]}
              dragDropEvents={{
                canDrop: (dropContext, dragContext) => {
                  return true;
                },
                canDrag: (item) => {
                  return true;
                },
                onDragEnter: (item) => {
                  // return string is the css classes that will be added to the entering element.
                  return dragEnterClass;
                },
                onDragLeave: (item, event) => {
                  return;
                },
                onDrop: (item, event) => {
                  if (DraggedSortingIndex > -1) {
                    DragItems(
                      DraggedSortingIndex,
                      item.Index,
                      TmpSorting,
                      SetTmpSorting
                    );
                  }
                },
                onDragStart: (item, itemIndex, selectedItems, event) => {
                  DraggedSortingIndex = itemIndex;
                },
                onDragEnd: (item, event) => {
                  DraggedSortingIndex = -1;
                },
              }}
            />
            <ActionButton
              iconProps={{ iconName: "Add" }}
              text="Add a column"
              onClick={() => {
                AddToList(DefaultState.TmpSorting, TmpSorting, SetTmpSorting);
              }}
            />
          </PivotItem>
        </Pivot>

        <DialogFooter>
          <PrimaryButton
            onClick={SubmitColumnOptions}
            disabled={DisableSubmitColumnOptions()}
            text="OK"
          />
          <DefaultButton onClick={CancelColumnOptions} text="Cancel" />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideSubmitTask}
        onDismiss={toggleHideSubmitTask}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Submit Update Task",
          subText:
            "You can quickly create a task after filling in adoLink and operations. You can also jump to the task submit page to create a Task.",
        }}
        maxWidth={700}
      >
        <Text
          block
          styles={{ root: { marginTop: 15, marginBottom: 15, color: "green" } }}
        >
          Your conditions: {DisplayCon}
        </Text>
        {MetadataNotInGitHub.length > 0 ? (<Text
          block
          styles={{ root: { marginTop: 15, marginBottom: 15 } }}
        >
          <i>Since the metadata <b>{MetadataNotInGitHub.join(", ")}</b> are not defined in GitHub, we will create a FileList task. We will modify up to 5000 files at the same time. If the files that meet the conditions are greater than 5000, please create the task again after the modification is completed.</i>
        </Text>):null}
        
        <TextField
          label="AdoLink"
          underlined
          required={AdoLink.length === 0}
          styles={MetadataStyles.InputTitleRootWidthAndMargin}
          value={AdoLink}
          onChange={(_, newValue) => {
            SetAdoLink(newValue);
          }}
          errorMessage={
            !isValidAdoLink(AdoLink)
              ? Resources.Tasks.NotValidAdoLink
              : isAdoLinkEndWithNumber(AdoLink)
              ? undefined
              : Resources.Tasks.AdoLinkNotEndWithNumber
          }
        />

        {showTeamSelector ? (
          <ComboBox
            label="Team"
            selectedKey={sourceTeam}
            onChange={(_event, option) => {
              setSourceTeam(option.key);
              setTemplateListFiltered(templateList.filter((i) => i.team === option.key));
            }}
            options={teamList}
            styles={MetadataStyles.InputRootWidth}
          />
        ) : null}
        <ComboBox
          label="Template"
          options={
            templateListFiltered?.length > 0
              ? templateListFiltered
              : templateList
          }
          styles={MetadataStyles.InputRootWidth}
          selectedKey={templateConfigId}
          onChange={(_event, option) => {
            setTemplateConfigId(option.key);
          }}
          errorMessage={
            templateList?.length > 0
              ? null
              : "There is no template available, please set in repo config otherwise default template will be used."
          }
        />

        <Stack.Item>
          <ActionButton
            iconProps={{ iconName: "Add" }}
            allowDisabledFocus
            onClick={ToggleOpEditor}
          >
            {Resources.Tasks.AddOpBtnText}
          </ActionButton>
          <Stack
            styles={{
              root: { display: ShowToggleOp ? "block" : "none" },
            }}
          >
            <Stack horizontal>
              <ComboBox
                useComboBoxAsMenuWidth={true}
                selectedKey={ToggleOp}
                styles={MetadataStyles.InputRootWidth}
                onChange={(_event, option) => {
                  SetToggleOp(option.key);
                }}
                label="Operation type"
                options={[
                  { key: "Delete", text: "Delete" },
                  { key: "Set", text: "Set" },
                ]}
              />
            </Stack>
            <Stack horizontal>
              <DropdownSearch
                label="Metadata key"
                placeholder={DefaultState.TmpColumn}
                options={GitHubMetadataOptions}
                selectedKey={OpKey}
                multiSelect={false}
                onChange={(_, newValue) => {
                  SetOpKey(newValue.key);
                }}
                styles={MetadataStyles.InputRootWidth}
              />
              <TextField
                label="Metadata value"
                required={OpValue?.length === 0}
                value={OpValue}
                onChange={(_, newValue) => {
                  SetOpValue(newValue);
                }}
                styles={MetadataStyles.InputRootWidth}
              />
            </Stack>
            <ActionButton
              disabled={OpKey?.length === 0 || OpValue?.length === 0}
              iconProps={{ iconName: "AddToShoppingList" }}
              text="add to list"
              onClick={AddOpToList}
            />
          </Stack>
        </Stack.Item>
        <Stack.Item>
          {OpItems?.length > 0 ? (
            <DetailsList
              setKey="t_id"
              selectionMode={SelectionMode.none}
              columns={[
                {
                  key: "Action",
                  name: "",
                  className: ActionColumnClassNames.ActionIconHeaderIcon,
                  iconClassName: ActionColumnClassNames.ActionIconCell,
                  fieldName: "t_id",
                  onRender: (item, _index) => (
                    <IconButton
                      className={ActionColumnClassNames.ActionIcon}
                      iconProps={{ iconName: "Delete" }}
                      onClick={() => {
                        const filtered = OpItems.filter(
                          (i, ind) => ind !== _index
                        );
                        SetOpItems(filtered);
                      }}
                    />
                  ),
                  maxWidth: 50,
                  minWidth: 30,
                },
                {
                  key: "Op",
                  name: "Operation",
                  fieldName: "op",
                  maxWidth: 150,
                  minWidth: 50,
                },
                {
                  key: "metaKey",
                  name: "Metadata key",
                  fieldName: "metaKey",
                  minWidth: 100,
                  maxWidth: 200,
                },
                {
                  key: "metaValue",
                  name: "Metadata value",
                  fieldName: "metaValue",
                  minWidth: 100,
                  maxWidth: 300,
                },
              ]}
              items={OpItems}
            />
          ) : (
            ""
          )}
        </Stack.Item>
        <DialogFooter>
          <PrimaryButton onClick={SubmitTask} text="Submit" />
          <DefaultButton
            onClick={() => {
              window.open(`${window.location.origin}/#/tasks/op`);
            }}
            text="Go to submit page"
          />
          <DefaultButton onClick={toggleHideSubmitTask} text="Cancel" />
        </DialogFooter>
      </Dialog>

      <Stack horizontal styles={MetadataStyles.StackStyles}>
        <CommandBarButton
          styles={MetadataStyles.CommandButton}
          iconProps={{ iconName: "Play" }}
          text="Run"
          onClick={() => {
            RunQuery();
          }}
        />
        <CommandBarButton
          styles={MetadataStyles.CommandButton}
          iconProps={{ iconName: "RevToggleKey" }}
          text="Discard"
          onClick={() => {
            Discard();
          }}
        />
        <CommandBarButton
          styles={MetadataStyles.CommandButton}
          iconProps={{ iconName: "Repair" }}
          text="Column Options"
          onClick={toggleHideColumnOptions}
        />
        <CommandBarButton
          styles={MetadataStyles.CommandButton}
          iconProps={{ iconName: "Download" }}
          text="Export Results"
          disabled={MetadataItems.length === 0}
          onClick={ExportMetadata}
        />
        <CommandBarButton
          styles={MetadataStyles.CommandButton}
          iconProps={{ iconName: "Save" }}
          text="Save Query"
          disabled={FilterItems.length === 0}
          onClick={toggleHideSaveQuery}
        />
        <CommandBarButton
          styles={MetadataStyles.CommandButton}
          iconProps={{ iconName: "QueryList" }}
          text="Canned Query"
          menuProps={{
            items: CannedQuery,
            onItemClick: (ev, item) => {
              ChangeFilterWithCannedQuery(item.key);
            },
          }}
        />
        <CommandBarButton
          styles={MetadataStyles.CommandButton}
          iconProps={{ iconName: "WebPublish" }}
          text="Submit Update Task"
          onClick={toggleHideSubmitTask}
        />
        <CommandBarButton
          styles={MetadataStyles.CommandButton}
          iconProps={
            isFilterCollapsed
              ? { iconName: "ChevronDown" }
              : { iconName: "ChevronUp" }
          }
          text="Filter Query"
          onClick={toggleFilterCollapsed}
        />
      </Stack>
      <div
        className="collapse-content"
        style={isFilterCollapsed ? { display: "none" } : { display: "block" }}
        aria-expanded={isFilterCollapsed}
      >
        <Stack styles={MetadataStyles.FilterStack}>
          {FilterItems?.length > 0 ? (
            <StackItem>
              <DetailsList
                setKey="t_id"
                selectionMode={SelectionMode.none}
                styles={{
                  root: {
                    selectors: {
                      ".ms-DetailsRow": {
                        border: 0,
                      },
                    },
                  },
                }}
                onRenderRow={(props, defaultRender) => {
                  return (
                    <div>
                      {defaultRender({
                        ...props,
                        styles: {
                          root: {
                            selectors: {
                              ".ms-DetailsRow-cell": {
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                                minHeight: "0px !important",
                              },
                            },
                            minHeight: "0px !important",
                          },
                        },
                      })}
                    </div>
                  );
                }}
                columns={[
                  {
                    key: "Action",
                    name: "",
                    fieldName: "t_id",
                    onRender: (item, _index) => (
                      <IconButton
                        iconProps={{ iconName: "Add" }}
                        onClick={() => {
                          AddIndexToFilterIndexInterval(_index);
                          AddToList(
                            DefaultState.FilterItems,
                            FilterItems,
                            SetFilterItems,
                            _index,
                            true
                          );
                        }}
                      />
                    ),
                    maxWidth: 10,
                    minWidth: 10,
                  },
                  {
                    key: "Remove",
                    name: "",
                    fieldName: "Remove",
                    onRender: (item, _index) => (
                      <IconButton
                        iconProps={{ iconName: "Clear" }}
                        style={{ color: SharedColors.red10 }}
                        onClick={() => {
                          RemoveIndexFromFilterIndexInterval(_index);
                          RemoveToList(
                            _index,
                            FilterItems,
                            SetFilterItems,
                            true
                          );
                        }}
                      />
                    ),
                    maxWidth: 10,
                    minWidth: 10,
                  },
                  {
                    key: "Group",
                    name: "Group",
                    fieldName: "",
                    isIconOnly: true,
                    iconName: "GroupList",
                    onColumnClick: GroupFilterItems,
                    onRender: (item, _index) => (
                      <Checkbox
                        checked={item.IsChecked}
                        styles={{
                          root: {
                            paddingTop: 6,
                            paddingLeft: 10,
                          },
                        }}
                        onChange={(_, isChecked) => {
                          ChangeItem(
                            _index,
                            "IsChecked",
                            isChecked,
                            FilterItems,
                            SetFilterItems
                          );
                        }}
                      />
                    ),
                    maxWidth: 15,
                    minWidth: 10,
                  },
                  ...GroupedColumns,
                  {
                    key: "And/Or",
                    name: "And/Or",
                    fieldName: "And/Or",
                    maxWidth: 80,
                    minWidth: 50,

                    onRender: (item, _index) => {
                      return _index > 0 ? (
                        <Dropdown
                          defaultSelectedKey={item.Connection}
                          selectedKey={item.Connection}
                          options={ConnectionOptions}
                          styles={{ root: { paddingLeft: 10 } }}
                          onChange={(_event, newValue) => {
                            ChangeItem(
                              _index,
                              "Connection",
                              newValue.key,
                              FilterItems,
                              SetFilterItems
                            );
                          }}
                        />
                      ) : (
                        ""
                      );
                    },
                  },
                  {
                    key: "Field",
                    name: "Field",
                    fieldName: "Field",
                    maxWidth: 400,
                    minWidth: 50,
                    onRender: (item, _index) => (
                      <DropdownSearch
                        placeholder={DefaultState.TmpColumn}
                        options={MetadataOptions}
                        // styles={dropdownStyles}
                        multiSelect={false}
                        selectedKey={item.Field}
                        onChange={(_, newValue) => {
                          ChangeItem(
                            _index,
                            "Field",
                            newValue.key,
                            FilterItems,
                            SetFilterItems
                          );
                        }}
                      />
                    ),
                  },
                  {
                    key: "Operator",
                    name: "Operator",
                    fieldName: "Operator",
                    maxWidth: 150,
                    minWidth: 50,
                    onRender: (item, _index) => (
                      <Dropdown
                        defaultSelectedKey={item.Operator}
                        selectedKey={item.Operator}
                        options={OperatorOptions}
                        styles={{ root: { paddingLeft: 10 } }}
                        onChange={(_event, newValue) => {
                          ChangeItem(
                            _index,
                            "Operator",
                            newValue.key,
                            FilterItems,
                            SetFilterItems
                          );
                        }}
                      />
                    ),
                  },
                  {
                    key: "Value",
                    name: "Value",
                    fieldName: "Value",
                    maxWidth: 150,
                    minWidth: 50,
                    onRender: (item, _index) => (
                      <TextField
                        styles={{ root: { paddingLeft: 10 } }}
                        disabled={
                          item.Operator !== "Equal" &&
                          item.Operator !== "NotEqual" &&
                          item.Operator !== "Contains"
                        }
                        value={item.Value}
                        onChange={(_, newValue) => {
                          ChangeItem(
                            _index,
                            "Value",
                            newValue,
                            FilterItems,
                            SetFilterItems
                          );
                        }}
                      />
                    ),
                  },
                ]}
                items={FilterItems}
              />
            </StackItem>
          ) : (
            ""
          )}
          <StackItem>
            <ActionButton
              iconProps={{ iconName: "Add" }}
              text="Add new clause"
              onClick={() => {
                AddIndexToFilterIndexInterval(FilterItems.length);
                AddToList(
                  DefaultState.FilterItems,
                  FilterItems,
                  SetFilterItems,
                  -1,
                  true
                );
              }}
            />
          </StackItem>
        </Stack>
      </div>
      <Pivot
        onLinkClick={onTabSwitch}
        selectedKey={tabValue}
        style={{ height: "100%" }}
      >
        <PivotItem
          itemKey="Query"
          headerText="Query"
          headerButtonProps={{ "data-order": 1 }}
        >
          <ScrollablePane
            style={
              isFilterCollapsed
                ? { position: "absolute", top: "108px", bottom: "50px" }
                : { position: "absolute", top: "348px", bottom: "50px" }
            }
          >
            <Stack styles={MetadataStyles.MetadataDataStack}>
              {MetadataItems.length > 0 ? (
                <div style={{ height: "70vh" }}>
                  <Stack horizontal>
                  <Text
                    variant={"large"}
                    styles={{ root: { marginTop: "20px" } }}
                    block
                  >
                    Last Updated: {MetadataLastUpdated}, Total Count: {MetadataItemsCount}
                  </Text>
                  <StackItem align="end" style={{marginLeft: "auto"}}>
                    <Text variant={"large"}>The default display row limit is 5000</Text>
                  </StackItem>
                  </Stack>
                  <DetailsList
                    selectionMode={SelectionMode.none}
                    layoutMode={1}
                    isHeaderVisible={true}
                    styles={MetadataStyles.ListDashboard}
                    onRenderRow={(props, defaultRender) => {
                      return props.itemIndex % 2 === 0 ? (
                        <div className="metadataList">
                          {defaultRender({
                            ...props,
                            styles: { root: { background: "rgba(0,0,0,.05)" } },
                          })}
                        </div>
                      ) : (
                        <div className="metadataList">
                          {defaultRender({
                            ...props,
                            styles: { root: { background: "white" } },
                          })}
                        </div>
                      );
                    }}
                    columns={Columns.map((col) => {
                      return {
                        key: col,
                        name: MetadataAllowListMap[col].inRawMetadata
                          ? "RawMetadata." + col
                          : col,
                        fieldName: col,
                        maxWidth: 600,
                        minWidth: 250,
                        isResizable: true,
                      };
                    })}
                    onRenderItemColumn={(item, index, column) => {
                      const fieldContent = item.filter((i) => i.key === column.key)[0];
                      if (typeof fieldContent != "undefined") {
                        switch (column.key) {
                          case "PlatformId":
                            const url =
                              "https://taxonomy.learn.microsoft.com/TaxonomyServiceAdminPage/#/metadata/detail/" +
                              fieldContent.value;
                            return (
                              <Link href={url} target="_blank">
                                {fieldContent.value}
                              </Link>
                            );
                          case "LiveUrl":
                            return (
                              <Link href={fieldContent.value} target="_blank">
                                {fieldContent.value}
                              </Link>
                            );
                          default:
                            return (
                              <span title={`Source: ${fieldContent.source}`}>
                                {fieldContent.value}
                              </span>
                            );
                        }
                      } else {
                        return null;
                      }
                    }}
                    items={MetadataItems}
                  />
                </div>
              ) : (
                <Stack
                  horizontalAlign="center"
                  styles={{ root: { marginTop: 30 } }}
                >
                  <Text>{LoadingString}</Text>
                </Stack>
              )}
            </Stack>
          </ScrollablePane>
        </PivotItem>
        <PivotItem
          itemKey="Field"
          headerText="Summary"
          headerButtonProps={{ "data-order": 2 }}
        >
          <ScrollablePane
            style={
              isFilterCollapsed
                ? { position: "absolute", top: "108px", bottom: "50px" }
                : { position: "absolute", top: "348px", bottom: "50px" }
            }
          >
            <Stack styles={MetadataStyles.ChartStack}>
              <Stack horizontal tokens={{ childrenGap: 30 }}>
                <Stack styles={{ root: { marginTop: "24px" } }}>
                  <Stack.Item align="start">
                    <Label styles={MetadataStyles.LabelStyles}>Field</Label>
                  </Stack.Item>
                  <DropdownSearch
                    placeholder={"select"}
                    options={MetadataOptions}
                    multiSelect={false}
                    selectedKey={selectedField}
                    styles={{ root: { width: 220 } }}
                    onChange={(_, newValue) => {
                      setSelectedField(newValue.key);
                    }}
                  />
                  {metadataFieldLegend.chartData?.length > 0 ? (
                  <Stack.Item align="start">
                  <Label styles={MetadataStyles.LabelStyles}>Last Updated: {MetadataLastUpdated}</Label>
                  </Stack.Item>):null}
                </Stack>
                {metadataFieldLegend.chartData?.length > 0 ? (
                  <div>
                    <Label styles={MetadataStyles.LabelStyles}> Usage </Label>
                    <DonutChart
                      data={metadataFieldLegend}
                      innerRadius={20}
                      height={250}
                      width={240}
                    />
                  </div>
                ) : null}
                {metadataFieldValueSummary?.length > 0 ? (
                  <Stack
                    styles={{
                      root: { height: "300px", "overflow-y": "scroll" },
                    }}
                  >
                    <Stack horizontal tokens={{ childrenGap: 150 }}>
                      {isDateSelected ? (
                        <Dropdown
                          styles={{ dropdown: { width: 100 } }}
                          label="Summarize by"
                          selectedKey={selectedDate}
                          options={dateFilterOptions}
                          onChange={onSelectedDateChange}
                        />
                      ) : null}
                      <Stack.Item
                        align="center"
                        styles={{ root: { marginLeft: "280px" } }}
                      >
                        <Label styles={MetadataStyles.LabelStyles}>
                          {" "}
                          Values{" "}
                        </Label>
                      </Stack.Item>
                      <Stack.Item
                        align="center"
                      >
                        <Label styles={MetadataStyles.LabelStyles}>
                          {selectedField} Count: {metadataFieldValueSummary.length}
                        </Label>
                      </Stack.Item>
                    </Stack>
                    <HorizontalBarChart
                      data={metadataFieldValueSummary}
                      width={600}
                      hideRatio={true}
                    />
                  </Stack>
                ) : null}
              </Stack>
              {MetadataFieldItems.length > 0 ? (
                <div>
                  <Text
                    variant={"large"}
                    styles={{ root: { marginTop: "10px" } }}
                    block
                  >
                    Content Using Field
                  </Text>
                  <DetailsList
                    selectionMode={SelectionMode.none}
                    layoutMode={1}
                    isHeaderVisible={true}
                    styles={MetadataStyles.ListDashboard}
                    onRenderRow={(props, defaultRender) => {
                      return props.itemIndex % 2 === 0 ? (
                        <div className="metadataList">
                          {defaultRender({
                            ...props,
                            styles: { root: { background: "rgba(0,0,0,.05)" } },
                          })}
                        </div>
                      ) : (
                        <div className="metadataList">
                          {defaultRender({
                            ...props,
                            styles: { root: { background: "white" } },
                          })}
                        </div>
                      );
                    }}
                    columns={Columns.map((col) => {
                      return {
                        key: col,
                        name: MetadataAllowListMap[col].inRawMetadata
                          ? "RawMetadata." + col
                          : col,
                        fieldName: col,
                        maxWidth: 600,
                        minWidth: 250,
                        isResizable: true,
                      };
                    })}
                    onRenderItemColumn={(item, index, column) => {
                      const fieldContent = item.filter((i) => i.key === column.key)[0];
                      if (typeof fieldContent != "undefined") {
                        switch (column.key) {
                          case "PlatformId":
                            const url =
                              "https://taxonomy.learn.microsoft.com/TaxonomyServiceAdminPage/#/metadata/detail/" +
                              fieldContent.value;
                            return (
                              <Link href={url} target="_blank">
                                {fieldContent.value}
                              </Link>
                            );
                          case "LiveUrl":
                            return (
                              <Link href={fieldContent.value} target="_blank">
                                {fieldContent.value}
                              </Link>
                            );
                          default:
                            return (
                              <span title={`Source: ${fieldContent.source}`}>
                                {fieldContent.value}
                              </span>
                            );
                        }
                      } else {
                        return null;
                      }
                    }}
                    items={MetadataFieldItems}
                  />
                </div>
              ) : (
                <Stack
                  horizontalAlign="center"
                  styles={{ root: { marginTop: 30 } }}
                >
                  <Text>{LoadingFieldString}</Text>
                </Stack>
              )}
            </Stack>
          </ScrollablePane>
        </PivotItem>
      </Pivot>
    </div>
  );
};

export default memo(MetadataList);
