import { memo} from "react";
import { useHistory } from "react-router-dom";
import {
    Text,
    DefaultButton,
    Stack,
  } from "@fluentui/react";
import 'office-ui-fabric-react/dist/css/fabric.css';
import adminimg from '../Image/AdminPortal.png';
import lifecycleimg from '../Image/Lifecycle.png';
import metadataqueryimg from '../Image/MetadataQuery.png';
import tasklistimg from '../Image/TaskList.png';
import ragimg from '../Image/Rag.png';

const homePageIndex = [
    {title: "Taxonomy Admin Portal", description:"Taxonomy Admin Portal is used to manage taxonomy data synchronization with PoolParty, and generate latest Platform Manual for users.", url:"/taxonomy", imgsrc: adminimg},
    {title: "Metadata Management", description:"You can manage Metadata and lifecycle of updating Metadata", url:"/tasks", imgsrc: tasklistimg},
    {title: "Metadata Query", description:"The Metadata Query is a ADO-query-like experience, a simple and powerful tool that lets you easily query metadata on content.", url:"/metadata/query", imgsrc: metadataqueryimg},
    {title: "Taxonomy Lifecycle Tracker", description:"This dashboard is designed to track taxonomy management processes aligned to a taxonomist workflow!", url:"/concepts", imgsrc: lifecycleimg},
    {title: "Rag Playground", description:"", url:"/rag", imgsrc: ragimg},

];

const HomePage = () => {
    document.title = "Taxonomy Home Page";
    const history = useHistory();
    return (
      <div>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
          {homePageIndex.map((item, index)=>{
            return (
            <div className="ms-Grid-col ms-sm4 ms-xl4">
                <Stack>
                <img style= {{width: 80 , height:80, marginTop: '40px',marginBottom: '10px'}} src={item.imgsrc} alt="" />
                <Text variant={"xLarge"} style={{marginTop: '10px',marginBottom: '10px'}}> {item.title} </Text>
                <Text style={{marginBottom: '10px', height: '70px'}}> {item.description} </Text>
                <DefaultButton
                styles={{ root: { width: 100 , align: "center"} }}
                text="View"
                onClick={() => {
                    history.push(item.url);
                }}/>
                </Stack>
            </div>)
        })}
          </div>
        </div>
      </div>
    );
  };
  
  export default memo(HomePage);
  
