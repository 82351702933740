import { useEffect, useState, memo } from "react";
import DropdownSearch from "../../Components/DropdownSearch";
import {
  IDropdownStyles,
  PrimaryButton,
  TextField,
  Stack,
  StackItem,
} from "@fluentui/react";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import TaskAPI from "../../Common/API/taskApi";
const dropdownStyles: Partial<IDropdownStyles> = {
  root: { width: '25%' },
};
const GraphRagInput = ({ submitInputFunc }) => {
  const [goal, SetGoal] = useState("");
  const [skillsObjective, SetSkillsObjective] = useState("");
  const [appliedSkillsObjective, SetAppliedSkillsObjective] = useState("");
  const [certificationsObjective, SetCertificationsObjective] = useState("");
  const [roleObjective, SetRoleObjective] = useState("");
  const sourceUid = []

  const RespToGraph = (data) => {
    const nodes = [];
    const edges = [];
    const uniqueEntities = new Set();
    data.entities.forEach((item) => {
      if (!uniqueEntities.has(item.entity)) {
        uniqueEntities.add(item.entity);
        nodes.push({
          id: item.entity,
          label: item.entity,
          type: item.type,
          raw: item
        });
      }
    });
    data.relationships.forEach((item) => {
      edges.push(item);
    });
    return {nodes: nodes, relationships: edges};
  }

  const submitQuery = async () => {
    await TaskAPI.getX();
    // const data = {}
    // submitInputFunc(RespToGraph(data));
  }

  return (
        <Stack>
          <Stack>
            <TextField 
              label="Goal"
              required={goal?.length === 0} 
              multiline 
              rows={3} 
              onChange={(_, newValue) => {
                SetGoal(newValue)
              }}/>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <DropdownSearch
              placeholder='Select Objective'
              label="SkillsObjective"
              styles={dropdownStyles}
              options={sourceUid}
              multiSelect={false}
              selectedKey={skillsObjective}
              onChange={(_, newValue) => {
                SetSkillsObjective(newValue.key)
              }}
            />
            <DropdownSearch
              placeholder='Select Objective'
              label="AppliedSkillsObjective"
              styles={dropdownStyles}
              options={sourceUid}
              multiSelect={false}
              selectedKey={appliedSkillsObjective}
              onChange={(_, newValue) => {
                SetAppliedSkillsObjective(newValue.key)
              }}
            />
            <DropdownSearch
              placeholder='Select Objective'
              label="CertificationsObjective"
              styles={dropdownStyles}
              options={sourceUid}
              multiSelect={false}
              selectedKey={certificationsObjective}
              onChange={(_, newValue) => {
                SetCertificationsObjective(newValue.key)
              }}
            />
            <DropdownSearch
              placeholder='Select Objective'
              label="RoleObjective"
              styles={dropdownStyles}
              options={sourceUid}
              multiSelect={false}
              selectedKey={roleObjective}
              onChange={(_, newValue) => {
                SetRoleObjective(newValue.key)
              }}
            />
          </Stack>
          <Stack>
            <StackItem>
              <PrimaryButton 
                styles={{ root: { marginTop: 10 } }}
                text="Submit" 
                onClick={submitQuery} 
                allowDisabledFocus 
              />
            </StackItem>
          </Stack>
        </Stack>
  );
};

export default memo(GraphRagInput);
