import APIBase from "./APIBase";
import { configs } from "../Config";
import _ from 'lodash';
const baseURL = configs.TaskApp.endpoint;
class RepoConfigAPI {

    static getRepoConfigs(repoOwner, repoName) {
        let query = "?";
        if (typeof (repoOwner) != "undefined" && repoOwner != "") {
            query = `${query}repoOwner=${repoOwner}&`;
        }

        if (typeof (repoName) != "undefined" && repoName != "") {
            query = `${query}repoName=${repoName}`;
        }

        query = _.trimEnd(query, "&");
    
        return APIBase.get(`${baseURL}/api/configs${query}`);
      }

    static updateConfigItem(configId, data) {
        return APIBase.post(
            `${baseURL}/api/resource/repo/${configId}/configItem`,
            data
        );
      }

    static getRepoListConfigs(permission) {
        return APIBase.get(`${baseURL}/api/resource/repo/list?permission=${permission}`);
    }
}

export default RepoConfigAPI;
