import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import * as ReactDOM from "react-dom";
import {
  SelectionMode,
  ColorClassNames,
  Text,
  Stack,
  DetailsRow,
  DetailsListLayoutMode,
  ConstrainMode,
  getTheme,
  mergeStyles,
  mergeStyleSets,
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
  ScrollablePane,
  Sticky,
  Pivot,
  PivotItem,
  PivotLinkFormat,
  ShimmeredDetailsList,
  AnimationClassNames,
  LayerHost,
  Layer,
} from "@fluentui/react";
import { useParams, useHistory } from "react-router-dom";
import BackHomeButton from "../Components/BackHomeButton";
import _ from "lodash";
import ResponseErrorHandler from "../Common/responseErrorHandler";
import { MessageActions } from "../Common/Utils/Message";
import ManagementAPI from "../Common/API/managementAPI";
import { overflowCellHover } from "../Common/customStyle";
import dayjs from "dayjs";
import { dateTimeFormatUS, ISO8601Re } from "../Common/constants";
import Resources from "../Common/resources";
import { defaultTopic } from "../Common/constants";
import Collapse from "../Components/Collapse";
import ListItemSearch from "../Components/ListItemSearch";
import DownloadTableButton from "../Components/DownloadTableButton";
import JobErrorsInner from "./JobErrorInner";
import TaxonomyMetadataTaskList from "../Components/TaxonomyMetadataTaskList";
import getMetadataTask from "../Common/Utils/deSerializeUtils";
import LocaleSelector from "../Components/LocaleSelector";

// constants and styles
const theme = getTheme();

const viewType = {
  Slug: "Slugs Only",
  Full: "Full Taxonomy",
  MetadataTask: "Metadata Management",
};

const diffTypeColorMapping = {
  Add: theme.palette.yellow,
  Updated: theme.palette.green,
  Removed: theme.palette.red,
};

const shimmeredDetailsListProps = {
  renderedWindowsAhead: 0,
  renderedWindowsBehind: 0,
};

const warningLayerStyles = mergeStyleSets({
  container: {
    position: "absolute",
    top: 0,
    left: "calc(8% + 105px)",
    right: "calc(8% + 95px)",
    "@media(max-width: 768)": {
      left: "calc(1% + 105px)",
      right: "calc(1% + 95px)",
    },
    "@media(min-width: 769)": {
      left: "calc(7.5% + 105px)",
      right: "calc(7.5% + 95px)",
    },
    "@media(min-width: 1440px)": {
      left: "calc(10% + 105px)",
      right: "calc(10% + 95px)",
    },
    "@media(min-width: 1980px)": {
      left: "calc(15% + 105px)",
      right: "calc(15% + 95px)",
    },
    overFlow: "hidden",
  },
  content: [
    {
      background: "rgba(255, 0, 0, 0.2)",
      border: "1px dashed " + theme.palette.black,
      color: theme.palette.redDark,
      lineHeight: "40px",
      padding: "0 20px",
      fontSize: "12px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    AnimationClassNames.scaleUpIn100,
  ],
});

const commonTokens = { childrenGap: 20 };

const headerAndFooterStyles = {
  minWidth: 300,
  minHeight: 40,
  lineHeight: 40,
  paddingLeft: 16,
};

const baseNameStyle = {
  overflow: "hidden",
  height: 24,
  cursor: "default",
  padding: 8,
  boxSizing: "border-box",
  verticalAlign: "top",
  background: "none",
  backgroundColor: "transparent",
  border: "none",
  paddingLeft: 48,
};

const slugViewClassNames = mergeStyleSets({
  container: { padding: 16 },
  header: [headerAndFooterStyles, theme.fonts.large],
  subHeader: [
    headerAndFooterStyles,
    theme.fonts.mediumPlus,
    { paddingLeft: 32 },
  ],
  subHeaderMark: { fontFamily: "monospace", color: "sienna" },
  toggleButton: { height: 40 },
  nestedName: baseNameStyle,
  name: [baseNameStyle, { paddingLeft: 16 }],
});

// helper functions used in hooks or components
const renderRow = (props) => {
  let color = null;
  if (props.item.diffType === "add") {
    color = diffTypeColorMapping.Add;
  } else if (props.item.diffType === "removed") {
    color = diffTypeColorMapping.Removed;
  }
  const customStyles = color
    ? {
        root: {
          color: color,
          selectors: {
            "&:hover": {
              color: color,
            },
          },
        },
      }
    : {};
  return <DetailsRow {...props} styles={customStyles} />;
};

const renderItemColumn = (item, index, column, keyword) => {
  let content = item[column.fieldName];
  // work around fabric bug here
  if (content === true || content === false) {
    content = _.toString(content);
  }
  const modifiedKeys = item.modifiedKeys || [];
  let customStyles = mergeStyles(overflowCellHover);
  if (
    modifiedKeys.includes(column.fieldName) ||
    (column.fieldName === "diffType" && content === "update")
  ) {
    customStyles = mergeStyles(overflowCellHover, {
      color: diffTypeColorMapping.Updated,
      selectors: {
        "&:hover": {
          color: diffTypeColorMapping.Updated,
        },
      },
    });
  }

  switch (column.key) {
    case "uid":
      content = content && (
        <a target="_blank" href={content} rel="noopener noreferrer">
          {getHighlightedText(_.last(content.split("/")), keyword)}
        </a>
      );
      break;
    default:
      if (ISO8601Re.test(content)) {
        content = dayjs(content).format(dateTimeFormatUS);
      }
      if (Array.isArray(content)) {
        content = content.join("; ");
      }
      content = getHighlightedText(content, keyword);
      break;
  }

  return <span className={customStyles}>{content}</span>;
};

export const compareTaxonomies = (from, to, keys) => {
  let fromCpy = _.cloneDeep(from);
  let toCpy = _.cloneDeep(to);
  let diff = [];
  _.forEach(toCpy, (toItem) => {
    let match = _.find(fromCpy, (fromItem) => {
      return keys.every((k) => fromItem[k] === toItem[k]);
    });
    if (match) {
      match.__ismatch__ = true;

      let modifiedKeys = [];
      _.forEach(toItem, (tV, tK) => {
        if (!_.isEqual(tV, match[tK])) {
          modifiedKeys.push(tK);
        }
      });
      if (modifiedKeys.length > 0) {
        toItem.modifiedKeys = modifiedKeys;
        toItem.diffType = "update";
        diff.unshift(toItem);
      } else {
        toItem.diffType = "unchanged";
        diff.push(toItem);
      }
    } else {
      toItem.diffType = "add";
      diff.unshift(toItem);
    }
  });

  const deleted = fromCpy.filter((t) => {
    return t["__ismatch__"] !== true;
  });
  deleted.forEach((d) => {
    diff.unshift(Object.assign({ diffType: "removed" }, d));
  });

  return diff;
};

const makeColumns = (schema) => {
  let columns = [];
  _.forEach(schema.properties, (prop, propName) => {
    if (prop.isHidden !== true) {
      let column = {};
      column.key = propName;
      column.name = _.startCase(propName);
      column.fieldName = propName;
      column.minWidth = 100;
      column.maxWidth = 200;
      column.isResizable = true;
      columns.push(column);
    }
  });
  columns.push({
    key: "Diff",
    name: "Diff Type",
    fieldName: "diffType",
  });

  return columns;
};

const buildSlugViews = (slugs, keyword, metadataTasks, topic) => {
  const showMetadata =
    _.filter(metadataTasks, (t) => t.Taxonomy === topic).length > 0;
  if (slugs) {
    const { data, nestedValue } = slugs;
    if (nestedValue) {
      return (
        <>
          <ScrollablePane
            style={{
              position: "absolute",
              top: 150,
              bottom: showMetadata ? 350 : 50,
            }}
          >
            <div className={slugViewClassNames.container}>
              {Object.keys(data)
                .sort()
                .map((k) => {
                  const v = data[k];
                  return (
                    <Collapse
                      key={k}
                      header={getHighlightedText(k, keyword)}
                      collapsed={true}
                    >
                      <div className={slugViewClassNames.subHeader}>
                        <span>
                          <b className={slugViewClassNames.subHeaderMark}>
                            {nestedValue}
                          </b>
                          should be in:
                        </span>
                      </div>
                      {v.map((sub) => {
                        return (
                          <div key={sub}>
                            <span className={slugViewClassNames.nestedName}>
                              {getHighlightedText(sub, keyword)}
                            </span>
                          </div>
                        );
                      })}
                    </Collapse>
                  );
                })}
            </div>
          </ScrollablePane>
          <div
            style={{
              position: "absolute",
              bottom: 50,
              left: 0,
              right: 0,
              display: showMetadata ? "block" : "none",
            }}
          >
            <TaxonomyMetadataTaskList
              metadataTasks={metadataTasks}
              topic={topic}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <ScrollablePane
            style={{
              position: "absolute",
              top: 150,
              bottom: showMetadata ? 350 : 50,
            }}
          >
            <div className={slugViewClassNames.container}>
              {data.sort().map((k) => {
                return (
                  <div key={k}>
                    <span className={slugViewClassNames.name}>
                      {getHighlightedText(k, keyword)}
                    </span>
                  </div>
                );
              })}
            </div>
          </ScrollablePane>
          <div
            style={{
              position: "absolute",
              bottom: 50,
              left: 0,
              right: 0,
              display: showMetadata ? "block" : "none",
            }}
          >
            <TaxonomyMetadataTaskList
              metadataTasks={metadataTasks}
              topic={topic}
            />
          </div>
        </>
      );
    }
  }
  return null;
};

const getCustomElements = () => {
  return (
    <div style={{ display: "flex" }}>
      <ShimmerElementsGroup
        flexWrap
        width="100%"
        shimmerElements={[
          {
            type: ShimmerElementType.line,
            width: "100%",
            height: 20,
            verticalAlign: "bottom",
          },
          { type: ShimmerElementType.gap, width: "100%", height: 10 },
          { type: ShimmerElementType.line, width: "80%", height: 20 },
          { type: ShimmerElementType.gap, width: "100%", height: 10 },
          {
            type: ShimmerElementType.line,
            width: "100%",
            height: 20,
            verticalAlign: "bottom",
          },
          { type: ShimmerElementType.gap, width: "100%", height: 10 },
          { type: ShimmerElementType.line, width: "80%", height: 20 },
          { type: ShimmerElementType.gap, width: "100%", height: 10 },
          {
            type: ShimmerElementType.line,
            width: "100%",
            height: 20,
            verticalAlign: "bottom",
          },
          { type: ShimmerElementType.gap, width: "100%", height: 10 },
          { type: ShimmerElementType.line, width: "80%", height: 20 },
          { type: ShimmerElementType.gap, width: "100%", height: 10 },
          {
            type: ShimmerElementType.line,
            width: "100%",
            height: 20,
            verticalAlign: "bottom",
          },
          { type: ShimmerElementType.gap, width: "100%", height: 10 },
          { type: ShimmerElementType.line, width: "80%", height: 20 },
          { type: ShimmerElementType.gap, width: "100%", height: 10 },
          {
            type: ShimmerElementType.line,
            width: "100%",
            height: 20,
            verticalAlign: "bottom",
          },
          { type: ShimmerElementType.gap, width: "100%", height: 10 },
          { type: ShimmerElementType.line, width: "80%", height: 20 },
          { type: ShimmerElementType.gap, width: "100%", height: 10 },
        ]}
      />
    </div>
  );
};

const getHighlightedText = (text, highlight) => {
  if (_.isString(text) && _.isString(highlight) && highlight.length > 0) {
    // Split on highlight term and include term into parts, ignore case
    const escapedHighlight = _.escapeRegExp(highlight);
    const parts = text.split(new RegExp(`(${escapedHighlight})`, "gi"));
    return (
      <span>
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { fontWeight: "bold", background: theme.palette.blueLight }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </span>
    );
  }
  return text;
};

const _customPivotItemLinkRenderer = (link, defaultRenderer) => {
  if (!link || !defaultRenderer) {
    return null;
  }

  return (
    <span
      style={{
        outline: "2px solid red",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      {defaultRenderer({ ...link })}
    </span>
  );
};

// custom hooks
const useTaxonomy = (TaxonomyName, toId, jobs) => {
  const [isLoading, setIsLoading] = useState(true);
  const [taxonomies, setTaxonomies] = useState([]);
  const [columns, setColumns] = useState([]);
  const [lastJob, setLastJob] = useState(null);
  const [slugs, setSlugs] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [loadingError, setLoadingError] = useState(true);
  const [failures, setFailures] = useState([]);
  const [failureReportUrl, setFailureReportUrl] = useState("");
  const [warningNames, setWarningNames] = useState({});
  const [metadataTasks, setMetadataTasks] = useState([]);
  const [locale, setLocale] = useState("en-us");

  const taxonomiesRef = useRef();
  const enTaxonomiesRef = useRef();
  const slugsRef = useRef();
  const currentLocaleRef = useRef("en-us");

  const clearState = () => {
    setIsLoading(false);
    setSlugs(null);
    setTaxonomies([]);
    setColumns([]);
    setLastJob(null);
    setKeyword("");
    setWarningNames({});
    setMetadataTasks([]);
    setLocale("en-us");
    taxonomiesRef.current = [];
    enTaxonomiesRef.current = [];
    slugsRef.current = null;
  };

  const clearFilter = () => {
    setKeyword("");
    setTaxonomies(taxonomiesRef.current);
    setSlugs(slugsRef.current);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterTaxonomies = useCallback((keyword) => {
    const innerIncludes = (t, k) => {
      return _.toString(t).toLowerCase().includes(_.toString(k).toLowerCase());
    };
    const orgSlugs = slugsRef.current;
    const orgTaxonomies = taxonomiesRef.current;
    if (_.isEmpty(keyword) || _.isEmpty(orgSlugs) || _.isEmpty(orgTaxonomies)) {
      clearFilter();
      return;
    }

    if (_.isString(keyword) && keyword.length > 0) {
      const orgSlugs = slugsRef.current;
      const { data, nestedValue } = orgSlugs;
      let orgSlugData = data;
      let filteredSlugsData = [];
      if (_.isArray(orgSlugData) && orgSlugData.length > 0) {
        filteredSlugsData = orgSlugData.filter((t) => {
          return innerIncludes(t, keyword);
        });
      } else if (_.isObject(orgSlugData) && !_.isEmpty(orgSlugData)) {
        _.forEach(orgSlugData, (v, k) => {
          if (innerIncludes(k, keyword)) {
            filteredSlugsData[k] = v;
          } else {
            let filteredNestedSlugData =
              v &&
              v.filter((t) => {
                return innerIncludes(t, keyword);
              });
            if (!_.isEmpty(filteredNestedSlugData)) {
              filteredSlugsData[k] = filteredNestedSlugData;
            }
          }
        });
      } else {
        throw new Error("not supported slug format");
      }
      setSlugs({ data: filteredSlugsData, nestedValue: nestedValue });

      const filteredTaxonomies =
        orgTaxonomies &&
        orgTaxonomies.filter((t) => {
          return _.some(t, (p) => {
            if (ISO8601Re.test(p)) {
              return innerIncludes(dayjs(p).format(dateTimeFormatUS), keyword);
            }
            return innerIncludes(p, keyword);
          });
        });
      setTaxonomies(filteredTaxonomies);
    }
  });

  const getToJobInfo = (toId, jobs) => {
    return new Promise((resolve, reject) => {
      const toJob =
        jobs &&
        jobs.find((job) => {
          return job.jobId === toId;
        });
      const toVersion = toJob && toJob.version;
      const toFailureReportUrl = toJob && toJob.failureReport;
      const mgmtTasks = toJob && toJob.metadataTasks;
      if (toId && toVersion) {
        resolve({ toVersion, toFailureReportUrl, mgmtTasks });
      } else {
        let toDate = toId.split("!")[0];
        const newStart = dayjs(toDate).subtract(3, "day").toDate();
        const newEnd = dayjs(toDate).add(3, "day").toDate();
        ManagementAPI.getJobsBetweenTimeRange(newStart, newEnd)
          .then((res) => {
            const jobs = res.data;
            const toJob =
              jobs &&
              jobs.find((job) => {
                return job.jobId === toId;
              });
            const toVersion = toJob && toJob.version;
            const toFailureReportUrl = toJob && toJob.failureReport;
            if (toId && toVersion) {
              resolve({ toVersion, toFailureReportUrl, mgmtTasks });
            } else {
              reject(
                `Failed to find the target job to diff with. The job with id:${toId} might have been deleted from database, please contact dev team for support`
              );
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const translateTaxonomyInternal = useCallback((locale, enTaxonomies, filterCallback) => {
    setIsLoading(true);
    enTaxonomiesRef.current = enTaxonomies;
    if (locale === "en-us") {
      setTimeout(() => {
        taxonomiesRef.current = enTaxonomies;
        setTaxonomies(enTaxonomies);
        filterCallback && filterCallback(enTaxonomies);
        setLocale(locale);
        return;
      }, 0);
    }

    getToJobInfo(toId, jobs)
      .then((res) => {
        const { toVersion } = res;
        ManagementAPI.getLocTaxonomyByVersion(TaxonomyName, toVersion, locale)
          .then((locData) => {
            const locTaxonomy = locData.data;
            const mergedTaxonomies = _.cloneDeep(enTaxonomies);
            _.forEach(mergedTaxonomies, (t) => {
              const locTax = _.find(locTaxonomy, (l) =>
                t.uid ? l.uid === t.uid : t.uri === l.uri
              );
              if (locTax) {
                return _.merge(t, locTax);
              }
            });
            taxonomiesRef.current = mergedTaxonomies;
            setTaxonomies(mergedTaxonomies);
            filterCallback && filterCallback(mergedTaxonomies);
            setLocale(locale);
            setIsLoading(false);
          })
          .catch((error) => {
            ResponseErrorHandler(error);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        ResponseErrorHandler(error);
        setIsLoading(false);
      });
  });

  const translateTaxonomy = useCallback(
    (locale) => {
      currentLocaleRef.current = locale;
      translateTaxonomyInternal(locale, enTaxonomiesRef.current, () => {
        filterTaxonomies(keyword);
      });
    },
    [filterTaxonomies, keyword, translateTaxonomyInternal]
  );

  useEffect(() => {
    const currentLocale = currentLocaleRef.current;
    setIsLoading(true);
    setSlugs(null);
    setTaxonomies([]);
    setKeyword("");
    setLocale(currentLocale);

    taxonomiesRef.current = [];
    enTaxonomiesRef.current = [];
    slugsRef.current = null;

    const generateTaxonomyDiff = (fromVersion, toVersion) => {
      if (fromVersion && toVersion) {
        const fromTaxonomyPromise = ManagementAPI.getTaxonomyByVersion(
          TaxonomyName,
          fromVersion
        );
        const toTaxonomyPromise = ManagementAPI.getTaxonomyByVersion(
          TaxonomyName,
          toVersion
        );
        const schemaPromise = ManagementAPI.getTaxonomySchema(TaxonomyName);
        const simplifiedTaxonomyPromise = ManagementAPI.getSimplifiedTaxonomy(
          TaxonomyName,
          toVersion
        );
        Promise.all([
          toTaxonomyPromise,
          schemaPromise,
          simplifiedTaxonomyPromise,
        ])
          .then((res) => {
            MessageActions.clear();
            const toTaxonomy = res[0].data;
            const schema = res[1].data;
            const compareKeys = schema.keys;
            const simplifiedTaxonomy = res[2].data;
            let display = _.get(schema, "display");
            const nestedSlugs = {
              data: simplifiedTaxonomy,
              nestedValue: display.nestedValue,
            };
            setSlugs(nestedSlugs);
            slugsRef.current = nestedSlugs;
            const detailColumns = makeColumns(schema);
            fromTaxonomyPromise
              .then((fromRes) => {
                const fromTaxonomy = fromRes.data;
                const diffTaxonomies = compareTaxonomies(
                  fromTaxonomy,
                  toTaxonomy,
                  compareKeys
                );
                translateTaxonomyInternal(currentLocale, diffTaxonomies);
              })
              .catch((fromError) => {
                // add this to handle new added taxonomy
                if (_.get(fromError, ["response", "status"]) === 404) {
                  let requestUrl = _.get(fromError, [
                    "response",
                    "config",
                    "url",
                  ]);
                  if (
                    requestUrl &&
                    decodeURIComponent(requestUrl).includes(fromVersion)
                  ) {
                    const diffTaxonomies = compareTaxonomies(
                      [],
                      toTaxonomy,
                      compareKeys
                    );
                    translateTaxonomyInternal(currentLocale, diffTaxonomies);
                  }
                } else {
                  ResponseErrorHandler(fromError);
                  clearState();
                }
              });
            setColumns(detailColumns);
          })
          .catch((error) => {
            ResponseErrorHandler(error);
            clearState();
          });
      }
    };

    const prePareTaxonomiesData = (toId, toVersion, toFailureReportUrl) => {
      if (toFailureReportUrl) {
        setLoadingError(true);
        setFailureReportUrl(toFailureReportUrl);

        ManagementAPI.getFailureReport(toFailureReportUrl)
          .then((res) => {
            const failureRecords = _.filter(
              res.data,
              (f) => f.Taxonomy === TaxonomyName
            );
            const wnames = _.groupBy(res.data, "Taxonomy");
            const newWarningNames = {};
            _.forEach(wnames, (v, k) => {
              newWarningNames[k] = true;
            });
            setWarningNames(newWarningNames);
            setLoadingError(false);
            setFailures(failureRecords);
          })
          .catch(() => {
            setWarningNames({});
            setLoadingError(false);
            setFailures([]);
          });
      }
      ManagementAPI.getLastSuccessJob(toId)
        .then((res) => {
          const fromJob = res.data;
          const fromVersion = fromJob.version;
          setLastJob(fromJob);
          generateTaxonomyDiff(fromVersion, toVersion);
        })
        .catch((error) => {
          ResponseErrorHandler(error);
          clearState();
        });
    };

    try {
      getToJobInfo(toId, jobs)
        .then((res) => {
          const { toVersion, toFailureReportUrl, mgmtTasks } = res;
          setMetadataTasks(getMetadataTask(mgmtTasks));
          prePareTaxonomiesData(toId, toVersion, toFailureReportUrl);
        })
        .catch((error) => {
          ResponseErrorHandler(error);
          clearState();
        });
    } catch (error) {
      ResponseErrorHandler(error);
    }
  }, [TaxonomyName, toId, jobs]);

  // keyword, searchCallback, clearCallback, setKeyword
  const searchProps = {
    keyword,
    onSearch: filterTaxonomies,
    onClear: clearFilter,
    setKeyword: setKeyword,
  };

  return [
    isLoading,
    taxonomies,
    columns,
    lastJob,
    slugs,
    searchProps,
    keyword,
    failures,
    loadingError,
    failureReportUrl,
    warningNames,
    metadataTasks,
    locale,
    translateTaxonomy,
  ];
};

const useTileButtonResizeObserver = () => {
  const [observerEntry, setObserverEntry] = useState({});
  const [node, setNode] = useState(null);
  const observer = useRef(null);

  const disconnect = useCallback(() => observer.current?.disconnect(), []);

  const observe = useCallback(() => {
    if (ResizeObserver) {
      observer.current = new ResizeObserver(([entry]) =>
        setObserverEntry(entry)
      );
      if (node) {
        const dom = ReactDOM.findDOMNode(node);
        if (dom && dom.querySelector) {
          const targetDom = dom.querySelector("div[role='tablist']");
          observer.current.observe(targetDom);
        }
      }
    }
  }, [node]);

  useLayoutEffect(() => {
    observe();
    return () => disconnect();
  }, [disconnect, observe]);

  return [setNode, observerEntry];
};

// JobDetail component
const JobDetails = (props) => {
  const { name, toId } = useParams();

  const { jobs, taxonomyNames } = props;
  const currentTopic = name || _.get(taxonomyNames, "0") || defaultTopic;
  const history = useHistory();
  const toItem = jobs.find((job) => {
    return job.jobId === toId;
  });

  const [
    isLoading,
    taxonomies,
    columns,
    lastJob,
    slugs,
    searchProps,
    keyword,
    failures,
    loadingError,
    failureReportUrl,
    warningNames,
    metadataTasks,
    locale,
    translateTaxonomy,
  ] = useTaxonomy(currentTopic, toId, jobs);

  const [pivotRef, { contentRect }] = useTileButtonResizeObserver();

  const getShimmerHeight = (rect, hasFailures) => {
    const tilesHeight = (rect && rect.height) || 0;
    hasFailures = !!hasFailures;
    const totalRowCount = Math.ceil(tilesHeight / 40);
    let occupiedHeight = 140;
    if (_.isNumber(totalRowCount)) {
      occupiedHeight += totalRowCount * 45;
    }
    if (hasFailures) {
      occupiedHeight += 300;
    }
    return occupiedHeight;
  };

  const shimmerContainerStyle = mergeStyleSets({
    wrapper: {
      selectors: {
        "& > .ms-Shimmer-container": {
          height: `calc(100vh - ${getShimmerHeight(
            contentRect,
            failures.length > 0
          )}px)`,
        },
      },
    },
  });

  const onTopicChange = (item) => {
    if (item.props && item.props.itemKey) {
      history.push(`/taxonomy/detail/${toId}/${item.props.itemKey}`);
    }
  };

  const fullTaxonomyTable = (
    <ScrollablePane style={{ position: "absolute", top: 150 }}>
      <div style={{ width: 2500 }}>
        <ShimmeredDetailsList
          setKey="UID"
          items={taxonomies}
          columns={columns}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          constrainMode={ConstrainMode.unconstrained}
          shimmerLines={10}
          enableShimmer={isLoading && _.isEmpty(taxonomies)}
          ariaLabelForShimmer="Content is being fetched"
          ariaLabelForGrid="Job details"
          listProps={shimmeredDetailsListProps}
          onRenderRow={renderRow}
          onRenderItemColumn={(item, index, column) => {
            return renderItemColumn(item, index, column, keyword);
          }}
          onRenderDetailsHeader={
            // tslint:disable-next-line:jsx-no-lambda
            (detailsHeaderProps, defaultRender) => (
              <Sticky>{defaultRender(detailsHeaderProps)}</Sticky>
            )
          }
        />
      </div>
    </ScrollablePane>
  );

  const TaxonomyContent = (
    <Shimmer
      isDataLoaded={!isLoading}
      customElementsGroup={getCustomElements()}
    >
      <Stack horizontal horizontalAlign="space-between">
        <ListItemSearch showSearch={true} {...searchProps} />
        <LocaleSelector
          locale={locale}
          topic={currentTopic}
          setLocale={translateTaxonomy}
        />
      </Stack>

      {_.isEmpty(slugs) ? (
        fullTaxonomyTable
      ) : (
        <Pivot aria-label="Taxonomy pivot">
          <PivotItem
            headerText={viewType.Slug}
            key={viewType.Slug}
            itemKey={viewType.Slug}
          >
            {buildSlugViews(slugs, keyword, metadataTasks, currentTopic)}
          </PivotItem>
          <PivotItem
            headerText={viewType.Full}
            key={viewType.Full}
            itemKey={viewType.Full}
          >
            <DownloadTableButton
              currentTopic={currentTopic}
              toItem={toItem}
              taxonomies={taxonomies}
              columns={columns}
            ></DownloadTableButton>
            <Stack>{fullTaxonomyTable}</Stack>
          </PivotItem>
        </Pivot>
      )}
    </Shimmer>
  );

  return (
    <div>
      <div style={{ position: "relative" }}>
        <BackHomeButton
          text={Resources.Tasks.BackToTasklist}
          backFn={() => {
            history.push("/taxonomy");
          }}
        />
        {!_.isEmpty(warningNames) && (
          <LayerHost
            id="partialErrorMessage"
            className={warningLayerStyles.container}
          >
            <Layer hostId="partialErrorMessage">
              <div className={warningLayerStyles.content}>
                {Resources.JobDetails.partialSuccessHint}
              </div>
            </Layer>
          </LayerHost>
        )}
      </div>
      <div>
        <Pivot
          aria-label={Resources.JobDetails.pivotArialLabel}
          linkFormat={PivotLinkFormat.tabs}
          onLinkClick={onTopicChange}
          selectedKey={currentTopic}
          styles={{
            root: [
              {
                selectors: {
                  "&.ms-Pivot--tabs": {
                    display: "flex",
                    flexWrap: "wrap",
                  },
                },
              },
            ],
            link: {
              height: 35,
              lineHeight: 35,
              marginRight: 15,
              marginTop: 5,
              backgroundColor: ColorClassNames.blueLightBackground,
            },
            linkIsSelected: {
              height: 35,
              lineHeight: 35,
              marginRight: 15,
              marginTop: 5,
            },
          }}
          componentRef={pivotRef}
        >
          {taxonomyNames.map((n) => {
            const tcontent = (
              <Stack
                tokens={commonTokens}
                styles={{ root: { margin: "20px 0" } }}
              >
                <Stack key="desc" tokens={commonTokens}>
                  <Stack
                    horizontal
                    horizontalAlign="left"
                    verticalAlign="center"
                    tokens={commonTokens}
                  >
                    <Stack.Item grow disableShrink>
                      <Text>
                        <strong>{Resources.Labels.startedAt} </strong>
                        {toItem &&
                          dayjs(toItem.startedAt).format(dateTimeFormatUS)}
                      </Text>
                    </Stack.Item>
                    <Stack.Item grow disableShrink>
                      <Text>
                        <strong>{Resources.Labels.compareWithLastJob}</strong>
                        {lastJob &&
                          dayjs(lastJob.startedAt).format(dateTimeFormatUS)}
                      </Text>
                    </Stack.Item>
                    <Stack.Item grow disableShrink>
                      <Text>
                        <strong>{Resources.Labels.status} </strong>
                        {toItem && toItem.jobStatus}
                      </Text>
                    </Stack.Item>
                  </Stack>
                </Stack>
                <Stack
                  key="taxonomy"
                  tokens={commonTokens}
                  className={shimmerContainerStyle.wrapper}
                >
                  {TaxonomyContent}
                </Stack>
                {failures && failures.length > 0 ? (
                  <ScrollablePane
                    style={{
                      height: 240,
                      bottom: "50px",
                      top: "calc(100vh - 300px)",
                    }}
                  >
                    <JobErrorsInner
                      jobErrors={failures}
                      isLoading={loadingError}
                      failureReportUrl={failureReportUrl}
                    />
                  </ScrollablePane>
                ) : null}
              </Stack>
            );
            if (warningNames[n] === true) {
              return (
                <PivotItem
                  key={n}
                  itemKey={n}
                  headerText={n}
                  onRenderItemLink={_customPivotItemLinkRenderer}
                >
                  {tcontent}
                </PivotItem>
              );
            }
            return (
              <PivotItem key={n} itemKey={n} headerText={n}>
                {tcontent}
              </PivotItem>
            );
          })}
        </Pivot>
      </div>
    </div>
  );
};

export default React.memo(JobDetails);