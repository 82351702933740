import axios from "axios";
import { configs } from "../Config";
import _ from "lodash";
import {
  acquireKnowledgeServiceTokenSilent
} from "../Auth/auth-utils";
const baseURL = configs.RagApp.knowledgeServiceEndpoint;

const knowledgeServiceAPI = axios.create({ baseURL: baseURL });

knowledgeServiceAPI.interceptors.request.use(function (config) {
  return acquireKnowledgeServiceTokenSilent().then((data) => {
    if (data) {
      config.headers = {
        ...config.headers,
        "Cache-Control": "no-cache",
        Authorization: `Bearer ${data.accessToken}`,
      };
    }
    return config;
  });
});

knowledgeServiceAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
class KnowledgeServiceAPI {
  static getRelevantItems(searchScope, threshold, additionalSources, top, data) {
    let url = `${baseURL}/api/knowledge/${searchScope}/relevantItems?scorethreshold=${threshold}&api-version=v2`;
    if (additionalSources) {
      url += `&additionalSources=${additionalSources}`;
    }
    if (top) {
      url += `&top=${top}`;
    }
    return knowledgeServiceAPI.post(url, data);
  }
}

export default KnowledgeServiceAPI;
