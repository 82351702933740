import React from "react";
import dayjs from "dayjs";
import {
  DatePicker,
  DayOfWeek,
  mergeStyleSets,
  addYears,
  addDays,
} from "@fluentui/react";

const today = new Date(Date.now());
const minDate = addYears(today, -10);
const maxDate = addDays(today, 1);

const DayPickerStrings = {
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],

  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],

  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],

  shortDays: ["S", "M", "T", "W", "T", "F", "S"],

  goToToday: "Go to today",
  prevMonthAriaLabel: "Go to previous month",
  nextMonthAriaLabel: "Go to next month",
  prevYearAriaLabel: "Go to previous year",
  nextYearAriaLabel: "Go to next year",
  closeButtonAriaLabel: "Close date picker",
  monthPickerHeaderAriaLabel: "{0}, select to change the year",
  yearPickerHeaderAriaLabel: "{0}, select to change the month",

  isRequiredErrorMessage: "Start date is required.",

  invalidInputErrorMessage: "Invalid date format.",
};

const controlClass = mergeStyleSets({
  control: {
    margin: 0,
    maxWidth: "300px",
  },
});

const onFormatDate = (date) => {
  return dayjs(date).format("D/MM/YY");
};

const desc =
  "This field is required. One of the support input formats is year dash month dash day.";
const firstDayOfWeek = DayOfWeek.Sunday;

const SingleDatePicker = ({
  initialDate,
  setDate,
  label,
  inlineLabel = false,
}) => {
  const onSelectDate = (date) => {
    setDate(date);
  };

  const onParseDateFromString = (val) => {
    const date = initialDate || new Date();
    const values = (val || "").trim().split("/");
    const day =
      val.length > 0
        ? Math.max(1, Math.min(31, parseInt(values[0], 10)))
        : date.getDate();
    const month =
      val.length > 1
        ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1
        : date.getMonth();
    let year = val.length > 2 ? parseInt(values[2], 10) : date.getFullYear();
    if (year < 100) {
      year += date.getFullYear() - (date.getFullYear() % 100);
    }
    return new Date(year, month, day);
  };

  const widget = (
    <DatePicker
      styles={
        inlineLabel
          ? {
              root: [
                {
                  selectors: {
                    ".ms-Label": {
                      float: "left",
                      marginRight: "0.5em",
                    },
                  },
                },
              ],
            }
          : null
      }
      className={controlClass.control}
      label={label}
      isRequired={false}
      allowTextInput={true}
      ariaLabel={desc}
      firstDayOfWeek={firstDayOfWeek}
      strings={DayPickerStrings}
      value={initialDate}
      showGoToToday={false}
      minDate={minDate}
      maxDate={maxDate}
      onSelectDate={onSelectDate}
      formatDate={onFormatDate}
      parseDateFromString={onParseDateFromString}
    />
  );

  return widget;
};

export default SingleDatePicker;
